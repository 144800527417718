import React, { PureComponent, Component } from 'react';
import { Button, Label, Input } from 'reactstrap';

import ModalItem from '../../Modal/Modal.js';
import FacturesTable from './FacturesTable';

class Factures extends PureComponent {
  state = {
    willPay: 0,
    selectedRows: [],
    facturations: [],
    showModal: false
  }

  pay = () => {
    this.props.pay(this.state.facturations);
    this.setState({
      selectedRows: [],
      facturations: [],
      willPay: 0,
      showModal: false,
    });
  }

  payOneFacture = (amount) => {
    const data = {
      amount: amount,
      factures: this.state.selectedRows,
      facturation: this.state.facturations[0].facturation
    };
    if (data.amount > 0) {
      this.props.addPaiement(data);
      this.setState({
        willPay: 0,
        selectedRows: [],
        facturations: [],
        showModal: false,
      });
    }
  }

  selectRow = (row, isSelect, rowIndex, e) => {
    if(!e.target.parentElement.attributes.hasOwnProperty('actions') && !e.target.parentElement.parentElement.attributes.hasOwnProperty('actions'))
      this.willPayRow(row, rowIndex, isSelect);
  }

  willPayRow = (row, rowIndex, isSelect) => {
    if (isSelect) {
      this.setState(state => {
        const selectedRows = [...state.selectedRows, row.id];
        const facturations = [...state.facturations, {facturation: row.facturation, amount: row.solde}];
        const newAmount = state.willPay + row.solde;
        return {
          willPay: newAmount,
          selectedRows: selectedRows,
          facturations: facturations
        };
      });
    } else {
      this.setState(state => {
        const selectedRows = state.selectedRows.filter((id) => id !== row.id);
        const facturations = state.facturations.filter((f) => f.facturation !== row.facturation && f.amount !== row.solde);
        const newAmount = state.willPay - row.solde;
        return {
          willPay: newAmount,
          selectedRows: selectedRows,
          facturations: facturations
        };
      });
    }
  }

  render() {
    return (
      <div>
      <FacturesTable
        selectedRows={this.state.selectedRows}
        selectRow={this.selectRow}
        eleves={this.props.eleves}
        autoecoleId={this.props.autoecoleId}
        relance={this.props.relance}
        printFacture={this.props.printFacture}
        factures={this.props.factures}
        autoecole={this.props.autoecole}
      />
      <Button className="colorButton" onClick={() => this.setState({ showModal: true })}>Encaisser {this.state.willPay.toFixed(0) + " XPF"}</Button>
      <ModalItem modalType="large" modalState={this.state.showModal} toggle={this.toggle}
        title="Payer"
        content={
          this.state.facturations.length > 1 ?
          <PayFacture
            pay={this.pay}
            hide={() => this.setState({showModal: false})}
            factures={this.state.facturations}
            /> :
          <PayOneFacture
            pay={this.payOneFacture}
            hide={() => this.setState({showModal: false})}
            factures={this.state.facturations}
          />
        } />
      </div>
    );
  }
}

class PayFacture extends Component {

  handleClick = (e) => {
    this.props.pay();
  }

  render() {
    const nbFactures = this.props.factures.length;
    return (
      <div>
        <Label>Encaisser {nbFactures > 1 ? `${nbFactures} factures` : `${nbFactures} facture`} pour un montant total de {nbFactures > 0 ? this.props.factures.map(f => f.amount).reduce((sum, value) => sum + value).toFixed(0) : 0} XPF</Label>
        <hr/>
        <Button className="colorButton pull-right" onClick={this.handleClick}>Payer</Button>
        <Button className="pull-right" style={{marginRight: '12px'}} onClick={this.props.hide}>Annuler</Button>
      </div>
    );
  }
}

class PayOneFacture extends Component {
  state = {
    amountPaid: this.props.factures.length > 0 ? this.props.factures.map(f => f.amount).reduce((sum, value) => sum + value).toFixed(0) : 0
  }

  handleChange = (e) => {
    this.setState({ amountPaid: Number(e.target.value) });
  }

  handleClick = (e) => {
    this.props.pay(this.state.amountPaid);
  }

  render() {
    return (
      <div>
        <Label>Solde</Label>
        <Input type="text" name="amountPaid" value={this.state.amountPaid} onChange={this.handleChange} onBlur={this.handleChange}/>
        <hr/>
        <Button className="colorButton pull-right" onClick={this.handleClick}>Payer</Button>
        <Button className="pull-right" style={{marginRight: '12px'}} onClick={this.props.hide}>Annuler</Button>
      </div>
    );
  }
}

export default Factures;
