import {normalizeBon, denormalizeBon} from '../schema/bon.js';
import produce from "immer";

const initialState = {
  result: [],
  entities: {bons: {}},
}

export default function bons(state=initialState, action) {
  switch (action.type) {
    case 'GET_BONS_SUCCESSFUL':
      return {...state, ...normalizeBon(action.bons)};
    case 'CREATE_BON_SUCCESSFUL':
      const bons = denormalizeBon(state.result, state.entities);
      bons.push(action.bon);
      return {...state, ...normalizeBon(bons)};
    case 'DELETE_BON_SUCCESSFUL':
      const newStateDelete = produce(state, draftState => {
        if (action.deletedId in draftState.entities.bons) {
          delete draftState.entities.bons[action.deletedId];
          draftState.result.splice(draftState.result.findIndex(id => id === action.deletedId), 1);
        }
      });
      return {...state, ...newStateDelete};
      default:
        return state;
  }
}
