import {denormalizeFacture, normalizeFacture} from '../schema/facture.js';
import produce from "immer";

const initialState = {
  result: [],
  entities: {factures: {}}
}

export default function factures(state=initialState, action) {
  switch (action.type) {
    case 'GET_FACTURES_SUCCESSFUL':
      return {...state, ...normalizeFacture(action.factures)};
    case 'GET_FACTURE_SUCCESSFUL':
      const addFacture = produce(state, draftState => {
        draftState.entities.factures[action.facture.id] = action.facture;
      });
      return {...state, ...addFacture};
    case 'CREATE_FACTURE_SUCCESSFUL':
      const factures = [...denormalizeFacture(state.result, state.entities), ...action.factures];
      return {...state, ...normalizeFacture(factures)};
    case 'CREATE_PAIEMENT_SUCCESSFUL':
      const updateFacture = produce(state, draftState => {
        action.factures.forEach((facture) => {
          draftState.entities.factures[facture.id] = facture;
        });
      });
      return {...state, ...updateFacture};
    default:
      return state;
  }
}
