import {url} from '../properties.js';

export const addProduct = (autoecoleId, productData) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    productData.article.autoecole = autoecoleId;
    const body = JSON.stringify(productData);
    return fetch(url+'autoecole/'+autoecoleId+'/offre/', {headers, body, method: "PUT"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 201) {
          dispatch({type: 'CREATE_PRODUCT_SUCCESSFUL', product: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_CREATE_PRODUCT'});
      })
  }
}

export const editProduct = (autoecoleId, productId, productData) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    productData.article.autoecole = autoecoleId;
    const body = JSON.stringify(productData);
    return fetch(url+'autoecole/'+autoecoleId+'/offre/'+productId, {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'UPDATE_PRODUCT_SUCCESSFUL', product: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_UPDATE_PRODUCT'})
      })
  }
}

export const addPrestation = (autoecoleId, data) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    data.article.autoecole = autoecoleId;
    const body = JSON.stringify(data);
    return fetch(url+'autoecole/'+autoecoleId+'/offre/', {headers, body, method: "PUT"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 201) {
          dispatch({type: 'CREATE_PRESTATION_SUCCESSFUL', prestation: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_CREATE_PRESTATION'})
      })
  }
}

export const editPrestation = (autoecoleId, id, data) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    data.article.autoecole = autoecoleId;
    const body = JSON.stringify(data);
    return fetch(url+'autoecole/'+autoecoleId+'/offre/'+id, {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'UPDATE_PRESTATION_SUCCESSFUL', prestation: res.data });
          console.log(res.data)
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_UPDATE_PRESTATION'});
      })
  }
}

export const addService = (autoecoleId, data) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    data.article.autoecole = autoecoleId;
    const body = JSON.stringify(data);
    return fetch(url+'autoecole/'+autoecoleId+'/offre/', {headers, body, method: "PUT"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 201) {
          dispatch({type: 'CREATE_SERVICE_SUCCESSFUL', service: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_CREATE_SERVICE'})
      })
  }
}

export const editService = (autoecoleId, id, data) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    data.article.autoecole = autoecoleId;
    const body = JSON.stringify(data);
    return fetch(url+'autoecole/'+autoecoleId+'/offre/'+id, {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'UPDATE_SERVICE_SUCCESSFUL', service: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_UPDATE_SERVICE'});
      })
  }
}

export const addFormule = (autoecoleId, data) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    data.article.autoecole = autoecoleId;
    const body = JSON.stringify(data);
    return fetch(url+'autoecole/'+autoecoleId+'/offre/', {headers, body, method: "PUT"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 201) {
          dispatch({type: 'CREATE_FORMULE_SUCCESSFUL', formule: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_CREATE_FORMULE'});
      })
  }
}

export const editFormule = (autoecoleId, id, data) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    data.article.autoecole = autoecoleId;
    const body = JSON.stringify(data);
    return fetch(url+'autoecole/'+autoecoleId+'/offre/'+id, {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        console.log(res)
        if (res.status === 200) {
          dispatch({type: 'UPDATE_FORMULE_SUCCESSFUL', formule: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_UPDATE_FORMULE'});
      })
  }
}


export const getArticles = (autoecoleId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return fetch(url+'autoecole/'+autoecoleId+'/offres/', {headers, method: "GET"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          const articles = {
            produit: [],
            service: [],
            prestation: [],
            formule: []
          }
          res.data.forEach((offre) => {
            articles[offre.article.type].push(offre);
          });
          dispatch({type: 'GET_PRODUCTS_SUCCESSFUL', products: articles['produit'] });
          dispatch({type: 'GET_PRESTATIONS_SUCCESSFUL', prestations: articles['prestation'] });
          dispatch({type: 'GET_SERVICES_SUCCESSFUL', services: articles['service'] });
          dispatch({type: 'GET_FORMULES_SUCCESSFUL', formules: articles['formule'] });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
      })
  }
}

export const deleteArticle = (autoecoleId, articleId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return fetch(url+'autoecole/'+autoecoleId+'/offre/'+articleId, {headers, method: "DELETE"})
      .then(res => {
        if (res.status === 204) {
          return {status: res.status, data: {}};
        } else if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 204) {
          dispatch({type: 'DELETE_ARTICLE_SUCCESSFUL', deletedId: articleId});
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_DELETE_ARTICLE'});
      })
  }
}
