import React, { Component } from 'react';

import DatePicker, {registerLocale} from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import frDate from 'date-fns/locale/fr';
import moment from 'moment';
registerLocale('fr', frDate);

class OtoschoolDatePicker extends Component {
  handleChange = value => {
    const event = {
      target: {
        name: this.props.name,
        value: value
      }
    };
    this.props.onChange(event);
  };

  render() {
    return(
      <DatePicker
        className="form-control"
        locale="fr"
        name={this.props.name}
        disabled={this.props.disabled}
        selected={
          typeof this.props.value === 'string'
            ? new Date(moment(this.props.value))
            : this.props.value
        }
        onChange={this.handleChange}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        dateFormat="dd/MM/yyyy"
      />
    );
  }
}

export default OtoschoolDatePicker;
