import React, { Component } from 'react';
import { connect } from 'react-redux';

import { organisme, impression } from '../../actions';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import {redPriceFormatter, bluePriceFormatter, priceFormatter, dateFormatter} from '../../common/formatter.js';


class OrganismeReport extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {dataField: 'id', hidden: true},
      {dataField: 'date', text: 'Date', sort: true, formatter: dateFormatter},
      {dataField: 'eleve', text: 'Élève', sort: true, formatter: this.eleveFormatter},
      {dataField: 'numero', text: 'Numéro', sort: true},
      {dataField: 'facture', text: 'Facturé', sort: true, formatter: priceFormatter},
      {dataField: 'encaisse', text: 'Encaissé', sort: true, formatter: bluePriceFormatter},
      {dataField: 'solde', text: 'Solde', sort: true, formatter: redPriceFormatter},
    ];
  }

  componentDidMount() {
    this.props.getOrganismeReport(this.props.autoecoleId, this.props.organisme.id);
  }

  eleveFormatter = (cell, row) => {
    const eleve = this.props.eleves[cell];
    return eleve !== undefined ? eleve.first_name.split(' ')[0] + " " + eleve.last_name : "Élève inconnu";
  }

  prepareData = () => {
    const table = this.props.report.map((f) => ({
      'id': f.id,
      'date': f.date,
      'eleve': f.eleve,
      'numero': <a href="#"
                  target="_blank"
                  onClick={(e) => {
                      e.preventDefault();
                      this.props.printFacture(this.props.autoecoleId, {factureId: f.id});
                    }}>
                    {f.number}
                </a>,
      'facture': Number(f.amount_to_pay),
      'encaisse': Number(f.amount_paid),
      'solde': Number(f.must_pay)
    }));
    return table;
  }

  render = () => {
    const table = this.prepareData();
    const name = this.props.organisme.name;
    return (
      <div>
        <h1>Relevé de {name}</h1>
        <div className="otoschoolTable">
          <BootstrapTable
            keyField='id' data={table}
            columns={this.columns}
            pagination={paginationFactory()}
            striped />
        </div>
      </div>
    )
  }
}

OrganismeReport.defaultProps = {
  organisme: {name: 'Organisme', id: undefined},
  autoecoleId: undefined,
  report: [],
  eleves: []
}

const mapStateToProps = state => {
  return {
    autoecoleId: state.auth.user.profile.auto_ecole,
    report: state.organismes.report,
    eleves: state.eleves.entities.eleves
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getOrganismeReport: (autoecoleId, organismeId) => dispatch(organisme.getOrganismeReport(autoecoleId, organismeId)),
    printFacture: (autoecoleId, data) => dispatch(impression.printFacture(autoecoleId, data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganismeReport);
