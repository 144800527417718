import React, { Component } from 'react';
import {Nav, Col, NavItem, NavLink, Container, TabContent, TabPane} from 'reactstrap';

import Factures from './Tableaux/Factures.jsx';
import Encaissements from './Tableaux/Encaissements.jsx';

class FactureEncaissementPresentation extends Component {
  state = {
    activeTab: "1",
  }

  activateTab = activeTab => {
    this.setState({
      activeTab: activeTab
    });
  }

  render() {
    return (
      <Container fluid>
        <h4 className="pageTitle">Factures & encaissements</h4>
        <Nav tabs className="no-gutters otoschoolNavTabs">
          <Col xl={3}>
              <NavItem className="otoschoolTab">
                <NavLink
                  active={this.state.activeTab === "1"}
                  onClick={() => { this.activateTab("1"); }}>
                  <h4>Factures</h4>
                </NavLink>
              </NavItem>
            </Col>
            <Col xl={3}>
              <NavItem className="otoschoolTab">
                <NavLink
                  active={this.state.activeTab === "2"}
                  onClick={() => { this.activateTab("2"); }}>
                  <h4>Encaissement</h4>
                </NavLink>
              </NavItem>
            </Col>
          </Nav>
        <TabContent activeTab={this.state.activeTab} className="otoschoolTabContent">
          <TabPane tabId="1">
            <Factures
              eleves={this.props.eleves}
              factures={this.props.factures}
              pay={this.props.pay}
              addPaiement={this.props.addPaiement}
              autoecoleId={this.props.autoecoleId}
              autoecole={this.props.autoecole}
              printFacture={this.props.printFacture}
              relance={this.props.relance}/>
          </TabPane>
          <TabPane tabId="2">
            <Encaissements
              eleves={this.props.eleves}
              paiements={this.props.paiements}
              relance={this.props.relance}/>
          </TabPane>
        </TabContent>
      </Container>
    );
  }
}
export default FactureEncaissementPresentation;
