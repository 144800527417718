import React, { Component } from 'react';
import { connect } from 'react-redux';
import {FormGroup, Row, Input, Button, Col} from 'reactstrap';
import { eleve } from '../../actions';
import Message from '../Messages/Message';
import style from './Eleve.module.css'

class EleveMessage extends Component {
  state = {
    message: ''
  }

  handleChange = (event) => {
    this.setState({ message: event.target.value });
  }

  handleClick = () => {
    this.setState({ message: '' });
    this.props.sendMessage({message: this.state.message});
  }

  deleteMessage = message => {
    this.props.deleteMessage(this.props.autoecoleId, message.eleve, message.id);
  }

  render () {
    return (
      <div>
        <h2>Nouveau message</h2>
        <FormGroup>
          <Input type="textarea" name="message" rows="10" value={this.state.message} onChange={this.handleChange} onBlur={this.handleChange}/>
        </FormGroup>
        <Row>
          <Button className="colorButton mx-auto" onClick={this.handleClick}>Envoyer</Button>
        </Row>
        <div className={style.messages}>
          <h2>Historique des messages</h2>
          {Object.values(this.props.messages.messages).map(message => {
            return <Message key={message.id} deleteMessage={() => this.deleteMessage(message)} message={message} />
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    autoecoleId: state.auth.user.profile.auto_ecole,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    deleteMessage: (autoecoleId, eleveId, messageId) => dispatch(eleve.deleteMessage(autoecoleId, eleveId, messageId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EleveMessage);
