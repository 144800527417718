import React, { Component } from 'react';
import {Col, Nav, NavLink, NavItem, TabContent, TabPane} from 'reactstrap';

import EleveInfo from './EleveInfo.jsx';
import ElevePermis from './ElevePermis.jsx';

class EleveProfil extends Component {
  state = {
    activeTab: "1",
    editPermis: 0,
  }

  activateTab = activeTab => {
    this.setState({
      activeTab: activeTab
    });
  }

  callbackPermis = (permisData) => {
    this.props.callbackPermis(permisData, this.state.editPermis);
  }

  render () {
    let permis = Object.assign({}, this.props.eleve.permis[this.state.editPermis]);
    const permisCreateMode = (permis === undefined || this.props.eleve.permis.length === 0);
    if (permisCreateMode)
      permis = this.props.permis;
    if (permis.organization === undefined || permis.organization === null) {
      permis.organization = '';
    }
    else {
      permis.organization = permis.organization.id;
    }

    return (
      <div className="otoschoolCard">
      <Nav tabs className="no-gutters otoschoolNavTabs">
        <Col xl={3}>
            <NavItem className="otoschoolTab">
              <NavLink
                active={this.state.activeTab === "1"}
                onClick={() => { this.activateTab("1"); }}>
                <h4>Coordonnées</h4>
              </NavLink>
            </NavItem>
          </Col>
          <Col xl={3}>
            <NavItem className="otoschoolTab">
              <NavLink
                disabled={this.props.createMode}
                active={this.state.activeTab === "2"}
                onClick={() => { this.activateTab("2"); }}>
                <h4>Permis</h4>
              </NavLink>
            </NavItem>
          </Col>
        </Nav>
        <TabContent activeTab={this.state.activeTab} className="otoschoolTabContent">
          <TabPane tabId="1">
            <div className="animated fadeIn">
              <EleveInfo eleve={this.props.eleve} createMode={this.props.createMode} callback={this.props.callbackInfo} archiveEleve={this.props.archiveEleve}/>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="animated fadeIn">
              {!this.props.createMode &&
              <ElevePermis permis={permis} createMode={permisCreateMode}
                callback={this.callbackPermis}
                formules={this.props.formules}
                organismes={this.props.organismes}/>
              }
            </div>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
EleveProfil.defaultProps = {
  permis: {
    permis: "B",
    identification_number: "",
    etg_completed: null,
    permis_list: [],
    last_permis_date: null,
    last_permis_loc: "",
    last_permis_country: "",
    last_permis_origin: "",
    visit_med: null,
    medical_comission_expiration: null,
    has_lost_license: false,
    has_glasses: false,
    pieces: [],
    organization: "",
  },
};
export default EleveProfil;
