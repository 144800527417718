import React, { Component } from 'react';
import { Container, Nav, Col, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import IntervenantsTable from './IntervenantsTable.jsx';

import ModalItem from '../Modal/Modal.js';

class IntervenantsPresentation extends Component {
  state = {
    activeTab: "1",
    intervenantToDelete: null,
    indexToDelete: null,
    displayConfirmationModal: false
  }

  activateTab = activeTab => {
    this.setState({
      activeTab: activeTab
    });
  }

  resetModal = () => {
    this.setState({
      intervenantToDelete: null,
      indexToDelete: null,
      displayConfirmationModal: false
    });
  }

  deleteIntervenant = (intervenant, index) => {
    this.setState({
      intervenantToDelete: intervenant,
      indexToDelete: index,
      displayConfirmationModal: true
    });
  }

  confirmDeleteIntervenant = () => {
    this.props.deleteIntervenant(this.state.intervenantToDelete.id, this.state.indexToDelete);
    this.resetModal();
  }

  render() {
    return (
      <Container fluid>
        <h4 className="pageTitle">Paramètres</h4>
        <Nav tabs className="no-gutters otoschoolNavTabs">
          <Col xl={3}>
              <NavItem className="otoschoolTab">
                <NavLink
                  active={this.state.activeTab === "1"}
                  onClick={() => { this.activateTab("1"); }}>
                  <h4>Intervenants</h4>
                </NavLink>
              </NavItem>
            </Col>
          </Nav>
        <TabContent activeTab={this.state.activeTab} className="otoschoolTabContent">
          <TabPane tabId="1">
            <IntervenantsTable
              intervenants={this.props.intervenants.filter((i) => i.is_active)}
              eleves={this.props.eleves}
              prestationsStatut={this.props.prestationsStatut}
              prestations={this.props.prestations}
              editIntervenant={this.props.editIntervenant}
              addIntervenant={this.props.addIntervenant}
              roles={this.props.roles}
              permissions={this.props.permissions}
              deleteIntervenant={this.deleteIntervenant}
              resetPassword={this.props.resetPassword}/>
          </TabPane>
        </TabContent>
        <ModalItem modalState={this.state.displayConfirmationModal} toggle={this.resetModal}
          accept="Supprimer" acceptAction={this.confirmDeleteIntervenant} decline="Annuler" declineAction={this.resetModal}
          title="Confirmation de suppression" content={this.state.intervenantToDelete &&
            `Êtes vous sûr de vouloir supprimer l'intervenant ${this.state.intervenantToDelete.last_name} ${this.state.intervenantToDelete.first_name} ?
            Cette action est irréversible. Aucune donnée ne sera perdue mais l'utilisateur ne pourra plus se connecter avec son compte.`}/>
      </Container>
    );
  }
}

export default IntervenantsPresentation;
