import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';

import ModalItem from '../Modal/Modal.js';
import Formule from '../Article/Formule.jsx';
import FormulesTable from './Table/FormulesTable';

class Formules extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      add: false,
      edit: false,
      toEdit: undefined
    };
  }

  toggleAdd = e => {
    this.setState({
      add: !this.state.add
    })
  }

  toggleEdit = (toEdit) => {
    this.setState({
      edit: !this.state.edit,
      toEdit: toEdit
    })
  }

  added = (formuleData) => {
    this.toggleAdd();
    this.props.addFormule(formuleData);
  }

  edited = (formuleData) => {
    this.toggleEdit(undefined);
    this.props.editFormule(formuleData);
  }

  render() {
    return (
      <div>
        <FormulesTable
          edit={this.toggleEdit}
          delete={this.props.delete}
          formules={this.props.formules}
        />
        <Button className="colorButton" onClick={this.toggleAdd}>Créer</Button>
        <ModalItem modalType="large" modalState={this.state.add} toggle={this.toggleAdd}
          title="Ajouter une formule" content={
            <Formule
                  createMode={true}
                  callback={this.added}
                  formule={{articles: [], discount: 0}}
                  formules={this.props.formules}
                  services={this.props.services}
                  products={this.props.products}
                  prestations={this.props.prestations}/>}/>
        {this.state.toEdit !== undefined &&
          <ModalItem modalType="large" modalState={this.state.edit} toggle={e=> {this.toggleEdit(undefined)}}
            title="Modifier la formule" content={
          <Formule
                createMode={false}
                callback={this.edited}
                formule={this.state.toEdit}
                formules={this.props.formules}
                services={this.props.services}
                products={this.props.products}
                prestations={this.props.prestations}/>}/>
          }
      </div>
    );
  }
}
export default Formules;
