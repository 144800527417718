import {normalizeStat, denormalizeStat} from '../schema/stats.js';
import produce from "immer";

const initialState = {
  startDate: null,
  endDate: null,
  reportList: [],
  result: [],
  entities: {stats: {}}
}

export default function stats(state=initialState, action) {
  switch (action.type) {
    case 'REPORT_SUCCESSFUL':
      const stats = denormalizeStat(state.result, state.entities);
      stats.push(action.report);
      const newState = normalizeStat(stats);
      newState.startDate = action.report.start_date;
      newState.endDate = action.report.end_date;
      return {...state, ...newState};
    case 'GET_REPORT_LIST_SUCCESSFUL':
      const reportList = {
        reportList: action.reportList
      };
      return {...state, ...reportList}
    default:
      return state;
  }
}
