import React, { Component } from 'react';
import { connect } from 'react-redux';

import { articles } from '../../actions';
import ArticlesPresentation from './ArticlesPresentation.jsx';


class Articles extends Component {

  addProduct = (data) => {
    this.props.addProduct(this.props.autoecoleId, data);
  }

  editProduct = (data) => {
    this.props.editProduct(this.props.autoecoleId, data.id, data);
  }

  addPrestation = (data) => {
    this.props.addPrestation(this.props.autoecoleId, data);
  }

  toggleComputePrestation = (prestation) => {
    console.log(prestation)
    this.props.editPrestation(this.props.autoecoleId, prestation.id, {
      article: {
        compute_prestation: !prestation.article.compute_prestation
      }
    });
  }

  editPrestation = (data) => {
    this.props.editPrestation(this.props.autoecoleId, data.id, data);
  }

  addService = (data) => {
    this.props.addService(this.props.autoecoleId, data);
  }

  editService = (data) => {
    this.props.editService(this.props.autoecoleId, data.id, data);
  }

  addFormule = (data) => {
    this.props.addFormule(this.props.autoecoleId, data);
  }

  editFormule = (data) => {
    this.props.editFormule(this.props.autoecoleId, data.id, data);
  }

  delete = (articleId) => {
    this.props.deleteArticle(this.props.autoecoleId, articleId);
  }

  componentDidMount() {
    this.props.getArticles(this.props.autoecoleId);
  }

  render() {
    return (
      <ArticlesPresentation
        addProduct={this.addProduct} editProduct={this.editProduct}
        addPrestation={this.addPrestation} editPrestation={this.editPrestation}
        addService={this.addService} editService={this.editService}
        addFormule={this.addFormule} editFormule={this.editFormule}
        toggleComputePrestation={this.toggleComputePrestation}
        delete={this.delete}
        tva={this.props.tva}
        products={this.props.products}
        prestations={this.props.prestations}
        services={this.props.services}
        formules={this.props.formules}/>
    );
  }
}


const mapStateToProps = state => {
  return {
    autoecoleId: state.auth.user.profile.auto_ecole,
    tva: state.autoecole.autoecole.tva,
    products: state.products,
    prestations: state.prestations,
    services: state.services,
    formules: state.formules,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    addProduct: (autoecoleId, data) => dispatch(articles.addProduct(autoecoleId, data)),
    editProduct: (autoecoleId, id, data) => dispatch(articles.editProduct(autoecoleId, id, data)),
    addPrestation: (autoecoleId, data) => dispatch(articles.addPrestation(autoecoleId, data)),
    editPrestation: (autoecoleId, id, data) => dispatch(articles.editPrestation(autoecoleId, id, data)),
    addService: (autoecoleId, data) => dispatch(articles.addService(autoecoleId, data)),
    editService: (autoecoleId, id, data) => dispatch(articles.editService(autoecoleId, id, data)),
    addFormule: (autoecoleId, data) => dispatch(articles.addFormule(autoecoleId, data)),
    editFormule: (autoecoleId, id, data) => dispatch(articles.editFormule(autoecoleId, id, data)),
    getArticles: (autoecoleId) => dispatch(articles.getArticles(autoecoleId)),
    deleteArticle: (autoecoleId, articleId) => dispatch(articles.deleteArticle(autoecoleId, articleId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
