import React, { Component } from 'react';
import {Col, Card, CardBody, Form, FormGroup, Input, Label, Row, Button, FormFeedback, InputGroup } from 'reactstrap';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import {DateUtils} from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate} from 'react-day-picker/moment';
import moment from 'moment';
import 'moment/locale/fr';
import 'react-day-picker/lib/style.css';

import validate from '../../common/utils.js';
import OtoschoolTimePicker from '../OtoschoolTimePicker/OtoschoolTimePicker.jsx';

import './Etablissement.css';

class Etablissement extends Component {
  state = {
      selectedDays: this.props.autoecole.fermetures,
  }

  validationSchema = e => {
    return Yup.object().shape({
      phone: Yup.string().required('Un numéro de téléphone fixe est requis'),
      island: Yup.string().required('Une île est requise'),
      address: Yup.string().required('Une adresse est requise'),
    });
  }

  onSubmit = (values, { setSubmitting, setErrors })  => {
    values.fermetures = values.fermetures.map((jour) => ({jour_fermeture: moment(jour.jour_fermeture).format('YYYY-MM-DD')}));
    this.props.callbackSubmit(values);
  }

  handleDayClick = (day, list, { selected }) => {
    if (selected) {
      const selectedIndex = list.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      );
      list.splice(selectedIndex, 1);
    } else {
      list.push(day);
    }
  }

  render() {
    const options = [
      { value: 'A', label: 'A' },
      { value: 'A1', label: 'A1' },
      { value: 'B', label: 'B' },
      { value: 'B1', label: 'B1' },
      { value: 'C', label: 'C' },
      { value: 'D', label: 'D' },
      { value: 'EB', label: 'EB' },
      { value: 'EC', label: 'EC' },
      { value: 'ED', label: 'ED' },
      { value : 'BSR', label: 'BSR (Scooter -18 ans)'}
    ];
    if (this.props.autoecole.ribs.length === 0)
      this.props.autoecole.ribs.push({rib: ''});
    return (
      <Card className="otoschoolCard">
        <div className="subTitle">
          Informations concernant l'auto école
        </div>
        <CardBody>
          <Formik
            enableReinitialize={true}
            initialValues={this.props.autoecole}
            validate={validate(this.validationSchema)}
            onSubmit={this.onSubmit}
            render={
              ({
                values,
                errors,
                touched,
                status,
                dirty,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValid,
                handleReset,
                setTouched
              }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col xl={6}>
                  <FormGroup row>
                  <Label for="auto_ecole" xl={4}>Nom</Label>
                  <Col xl={8}>
                    <Input type="text" name="auto_ecole" disabled required readOnly value={values.auto_ecole}/>
                  </Col>
                </FormGroup>
                </Col>
                <Col xl={6}>
                  <FormGroup row>
                  <Label for="email" xl={4}>Email</Label>
                  <Col xl={8}>
                    <Input type="text" name="email" disabled required readOnly value={values.email}/>
                  </Col>
                </FormGroup>
                </Col>
              </Row>
              <p>Ces informations sont utilisés pour l'impression des factures, des bordereaux et autres éléments d'impressions</p>
              <Row>
                <Col xl={6}>
                  <FormGroup row>
                    <Label for="mobile_phone" xl={4}>Vini</Label>
                    <Col xl={8}>
                      <Input type="text" name="mobile_phone" autoComplete="tel"
                        invalid={touched.mobile_phone && !!errors.mobile_phone}
                        onChange={handleChange} onBlur={handleBlur}
                        value={values.mobile_phone}/>
                      <FormFeedback>{errors.mobile_phone && touched.mobile_phone && errors.mobile_phone}</FormFeedback>
                    </Col>
                  </FormGroup>
                </Col>
                <Col xl={6}>
                  <FormGroup row>
                    <Label for="phone" xl={4} >Tel/Fax</Label>
                    <Col xl={8}>
                      <Input type="text" name="phone" autoComplete="fixe" required
                        invalid={touched.phone && !!errors.phone}
                        onChange={handleChange} onBlur={handleBlur}
                        value={values.phone}/>
                      <FormFeedback>{errors.phone && touched.phone && errors.phone}</FormFeedback>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xl={6}>
                  <FormGroup row>
                    <Label for="island" xl={4}>Île</Label>
                    <Col xl={8}>
                      <Input type="select" name="island" required
                        invalid={touched.island && !!errors.island}
                        onChange={handleChange} onBlur={handleBlur}
                        value={values.island}>
                        <option>Tahiti</option>
                        <option>Moorea</option>
                        <option>Raiatea</option>
                      </Input>
                      <FormFeedback>{errors.island && touched.island && errors.island}</FormFeedback>
                    </Col>
                  </FormGroup>
                </Col>
                <Col xl={6}>
                  <FormGroup row>
                    <Label for="tahiti_number" xl={4}>Numéro tahiti</Label>
                    <Col xl={8}>
                      <Input type="text" name="tahiti_number" required
                        invalid={touched.tahiti_number && !!errors.tahiti_number}
                        onChange={handleChange} onBlur={handleBlur}
                        value={values.tahiti_number}/>
                      <FormFeedback>{errors.tahiti_number && touched.tahiti_number && errors.tahiti_number}</FormFeedback>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xl={6}>
                  <FormGroup row>
                    <Label for="address" xl={4}>Adresse</Label>
                    <Col>
                      <Input type="text" name="address" required
                        invalid={touched.address && !!errors.address}
                        onChange={handleChange} onBlur={handleBlur}
                        value={values.address}/>
                      <FormFeedback>{errors.address && touched.address && errors.address}</FormFeedback>
                    </Col>
                  </FormGroup>
                </Col>
                <Col xl={6}>
                  <FormGroup row>
                    <Label for="town" xl={4}>Commune</Label>
                    <Col>
                      <Input type="text" name="town" required
                        invalid={touched.town && !!errors.town}
                        onChange={handleChange} onBlur={handleBlur}
                        value={values.town}/>
                      <FormFeedback>{errors.town && touched.town && errors.town}</FormFeedback>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xl={6}>
                  <FormGroup row>
                    <Label for="postal_box" xl={4}>Boite postale</Label>
                    <Col xl={8}>
                      <Input type="text" name="postal_box" required
                        invalid={touched.postal_box && !!errors.postal_box}
                        onChange={handleChange} onBlur={handleBlur}
                        value={values.postal_box}/>
                      <FormFeedback>{errors.postal_box && touched.postal_box && errors.postal_box}</FormFeedback>
                    </Col>
                  </FormGroup>
                </Col>
                <Col xl={6}>
                  <FormGroup row>
                    <Label for="postal_code" xl={4}>Code postal</Label>
                    <Col>
                      <Input type="text" name="postal_code" required
                        invalid={touched.postal_code && !!errors.postal_code}
                        onChange={handleChange} onBlur={handleBlur}
                        value={values.postal_code}/>
                      <FormFeedback>{errors.postal_code && touched.postal_code && errors.postal_code}</FormFeedback>
                    </Col>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
              <Col xl={6}>
                <FormGroup row>
                  <Label for="ribs" xl={4}>RIB</Label>
                  <FieldArray name="ribs" render={arrayHelpers => (
                    <Col xl={8}>
                      {values.ribs.map((rib, i) => (
                        <div key={i}>
                          {i !== values.ribs.length -1 ? (
                            <Input type="text" className="mt-2"
                              onChange={(e) => {
                                rib.rib = e.target.value;
                                handleChange({target: {name: `ribs[${i}]`, value: rib}})
                              }}
                              onBlur={(e) => {
                                rib.rib = e.target.value;
                                handleBlur({target: {name: `ribs[${i}]`, value: rib}})
                              }}
                              value={rib.rib} name={`ribs[${i}]`} />
                          ): (
                            <InputGroup className="mt-2">
                              <Input type="text"
                                onChange={(e) => {
                                  rib.rib = e.target.value;
                                  handleChange({target: {name: `ribs[${i}]`, value: rib}})
                                }}
                                onBlur={(e) => {
                                  rib.rib = e.target.value;
                                  handleBlur({target: {name: `ribs[${i}]`, value: rib}})
                                }}
                                value={rib.rib} name={`ribs[${i}]`} />
                                {' '}
                              <Button className="colorButton ml-2" onClick={() => arrayHelpers.push({rib: ''})}><i className="fa fa-plus" aria-hidden="true"></i></Button>
                            </InputGroup>
                          )}
                        </div>
                      ))}
                    </Col>
                  )}/>
                </FormGroup>
              </Col>
              <Col xl={6}>
                <FormGroup row>
                  <Label for="ribs" xl={4}>Banque</Label>
                  <FieldArray name="ribs" render={arrayHelpers => (
                    <Col xl={8}>
                      {values.ribs.map((rib, i) => (
                        <div key={i}>
                          {i !== values.ribs.length -1 ? (
                            <Input type="text" className="mt-2"
                              onChange={(e) => {
                                rib.bank = e.target.value;
                                handleChange({target: {name: `ribs[${i}]`, value: rib}})
                              }}
                              onBlur={(e) => {
                                rib.bank = e.target.value;
                                handleBlur({target: {name: `ribs[${i}]`, value: rib}})
                              }}
                              value={rib.bank} name={`ribs[${i}]`} />
                          ): (
                            <InputGroup className="mt-2">
                              <Input type="text"
                                onChange={(e) => {
                                  rib.bank = e.target.value;
                                  handleChange({target: {name: `ribs[${i}]`, value: rib}})
                                }}
                                onBlur={(e) => {
                                  rib.bank = e.target.value;
                                  handleBlur({target: {name: `ribs[${i}]`, value: rib}})
                                }}
                                value={rib.bank} name={`ribs[${i}]`} />
                                {' '}
                              <Button className="colorButton ml-2" onClick={() => arrayHelpers.push({rib: ''})}><i className="fa fa-plus" aria-hidden="true"></i></Button>
                            </InputGroup>
                          )}
                        </div>
                      ))}
                    </Col>
                  )}/>
                </FormGroup>
              </Col>
            </Row>
              <h5>Horaires</h5>
              <FormGroup row>
                <Col lg={6} xl={4}>
                  <FormGroup row>
                    <Label for="morningStart" xl={6}>Le matin de</Label>
                    <Col xl={6}>
                      <OtoschoolTimePicker name={`horaires.0.start`}
                        onChange={handleChange} onBlur={handleBlur}
                        value={values.horaires[0].start} />
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg={6} xl={4}>
                  <FormGroup row>
                    <Label for="morningEnd" xl={1}>à</Label>
                    <Col xl={6}>
                      <OtoschoolTimePicker name={`horaires.0.end`}
                        onChange={handleChange} onBlur={handleBlur}
                        value={values.horaires[0].end}/>
                    </Col>
                  </FormGroup>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col lg={6} xl={4}>
                  <FormGroup row>
                    <Label for="afternoonStart" xl={6}>L'après midi de</Label>
                    <Col xl={6}>
                      <OtoschoolTimePicker name={`horaires.1.start`}
                        onChange={handleChange} onBlur={handleBlur}
                        value={values.horaires[1].start}/>
                    </Col>
                  </FormGroup>
                </Col>
                <Col lg={6} xl={4}>
                  <FormGroup row>
                    <Label for="afternoonEnd" xl={1}>à</Label>
                    <Col xl={6}>
                      <OtoschoolTimePicker name={`horaires.1.end`}
                        onChange={handleChange} onBlur={handleBlur}
                        value={values.horaires[1].end}/>
                    </Col>
                  </FormGroup>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label xl={2}>Permis proposé</Label>
                <Col>
                  <Select
                    options={options}
                    isMulti
                    value={values.permis.map((permis) => ({value: permis.short_name, label: permis.short_name}))}
                    onChange={(selectedOptions) => {
                      const permis_api = selectedOptions.map((permis) => ({short_name: permis.value}));
                      handleChange({target: {name: 'permis', value: permis_api}});
                    }}
                    placeholder="Permis proposé"/>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label xl={2}>Jours fériés</Label>
                <Col>
                  <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="L"
                    placeholder="Jours fériés"
                    hideOnDayClick={false}
                    value={values.fermetures.map((holiday) => formatDate(holiday.jour_fermeture, 'L', 'fr')).join()}
                    dayPickerProps={{
                      locale: 'fr',
                      localeUtils: MomentLocaleUtils,
                      selectedDays: values.fermetures.map((fermeture) => fermeture.jour_fermeture),
                      onDayClick: (day, { selected }) => {
                        const updateList = values.fermetures.map((fermeture) => fermeture.jour_fermeture)
                        this.handleDayClick(day, updateList, { selected });
                        const fermetures_api = updateList.map((day) => ({jour_fermeture: day}));
                        handleChange({target: {name: 'fermetures', value: fermetures_api}});
                      }
                    }}
                    inputProps={{
                      className:"form-control"
                    }}/>
                </Col>
              </FormGroup>
              <Button type="submit" className="colorButton pull-right">Enregistrer</Button>
            </Form>
          )} />
        </CardBody>
      </Card>
    );
  }
}

export default Etablissement;
