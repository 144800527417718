import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';

import { eleves, eleve } from '../../actions';

import ModalItem from '../Modal/Modal.js';
import EleveTable from './EleveTable';

class Eleves extends Component {
  constructor(props) {
    super(props)

    this.state = {
      addEleve: false,
      displayConfirmationModal: false,
      eleveToDelete: null
    }
  }

  confirmDelete = (eleve) => {
    this.setState({
      displayConfirmationModal: !this.state.displayConfirmationModal,
      eleveToDelete: eleve
    });
  }

  confirmArchive = (eleve) => {
    this.archiveEleve(eleve, !eleve.archived);
  }

  componentDidMount() {
    this.props.get(this.props.autoecoleId);
  }

  deleteEleve = () => {
    this.props.deleteEleve(this.props.autoecoleId, this.state.eleveToDelete.id);
    this.confirmDelete(null);
  }

  archiveEleve = (eleve, archive) => {
    const eleveToUpdate = eleve;
    eleveToUpdate.actions = undefined;
    eleveToUpdate.archived = archive;
    this.props.archiveEleve(this.props.autoecoleId, eleveToUpdate.id, eleveToUpdate);
  }

  render() {
    return (
        <Container fluid>
          <h4 className="pageTitle">Élèves</h4>
          <EleveTable
            eleves={this.props.eleves}
            deleteEleve={this.confirmDelete}
            archiveEleve={this.confirmArchive}
          />
          <ModalItem modalState={this.state.displayConfirmationModal} toggle={() => this.confirmDelete(null)}
            accept="Supprimer" acceptAction={this.deleteEleve} decline="Annuler" declineAction={() => this.confirmDelete(null)}
            title="Confirmation de suppression" content={this.state.eleveToDelete &&
              `Êtes vous sûr de vouloir supprimer l'élève ${this.state.eleveToDelete.last_name} ${this.state.eleveToDelete.first_name} ?
              Cette action est irréversible et entrainera la perte de données.`}/>
        </Container>
      );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    autoecoleId: state.auth.user.profile.auto_ecole,
    eleves: state.eleves
  };
}

const mapDispatchToProps = dispatch => {
  return {
    get: (autoecoleId) => dispatch(eleves.get(autoecoleId)),
    archiveEleve: (autoecoleId, eleveId, data) => dispatch(eleve.archiveEleve(autoecoleId, eleveId, data)).then(
      () => dispatch(eleve.get(autoecoleId, eleveId))
    ),
    deleteEleve: (autoecoleId, eleveId) => dispatch(eleve.deleteEleve(autoecoleId, eleveId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Eleves);
