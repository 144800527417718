import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Notifications extends Component {
    render() {
      if (this.props.notifications.type === "ERROR") {
        if (this.props.notifications.message !== "")
          toast.error(this.props.notifications.message);
        else
          toast.error("Une erreur est survenue.");
      }
      if (this.props.notifications.type === "SUCCESS") {
        if (this.props.notifications.message !== "")
          toast.success(this.props.notifications.message);
      }
      return (<ToastContainer autoClose={2000}/>);
    }
}

const mapStateToProps = state => {
  return {
    notifications: state.notifications,
  };
}

const mapDispatchToProps = dispatch => {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
