import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Table, Input, Nav, NavItem, NavLink, Col } from 'reactstrap';
import { alertes } from '../../actions';

class Alertes extends Component {
  state = {
    activeTab: 0
  }

  componentDidMount() {
    this.props.getAlertes(this.props.autoecoleId);
  }

  updateAlerte = (id, alerte) => {
    this.props.editAlerte(id, alerte);
  }

  render() {
    let displayTable;
    const alertes_autoecole = this.props.alertes ? Object.values(this.props.alertes.alertes).map((alerte, index) => {
      if (alerte.for_whom === 'A') {
        return <Alerte key={'A_' + index} alerte={alerte} updateAlerte={this.updateAlerte}/>;
      }
    }) : null;
    const alertes_eleve = this.props.alertes ? Object.values(this.props.alertes.alertes).map((alerte, index) => {
      if (alerte.for_whom === 'E') {
        return <Alerte key={'E_' + index} alerte={alerte} updateAlerte={this.updateAlerte}/>;
      }
    }) : null;

    if (this.state.activeTab === 0) {
      displayTable = <div>
        <Table responsive striped>
        <thead>
          <tr>
            <th>Activer</th>
            <th>Alerte</th>
            <th>Délai</th>
            <th>Unité de temps</th>
          </tr>
        </thead>
        <tbody>
          {alertes_autoecole}
        </tbody>
      </Table>
    </div>;
    } else {
      displayTable =  <div>
          <Table responsive striped>
          <thead>
          <tr>
            <th>Activer</th>
            <th>Alerte</th>
            <th>Délai</th>
            <th>Unité de temps</th>
          </tr>
          </thead>
          <tbody>
            {alertes_eleve}
          </tbody>
        </Table>
        </div>
    }
    return (
      <Container fluid>
        <div className="animated fadeIn">
        <h4 className="pageTitle">Alertes</h4>
        <Nav tabs className="no-gutters otoschoolNavTabs">
          <Col xl={3}>
            <NavItem className="otoschoolTab">
              <NavLink active={this.state.activeTab === 0} onClick={e => {this.setState({activeTab:0})}}><h4>Alertes auto école</h4></NavLink>
            </NavItem>
          </Col>
          <Col xl={3}>
            <NavItem className="otoschoolTab">
              <NavLink active={this.state.activeTab === 1} onClick={e => {this.setState({activeTab:1})}}><h4>Alertes éleves</h4></NavLink>
            </NavItem>
          </Col>
        </Nav>
          {displayTable}
        </div>
      </Container>
    );
  }
}

class Alerte extends Component {

  updateNoticeTime = e => {
    this.props.alerte.notice_time = e.target.value;
    this.props.updateAlerte(this.props.alerte.id, this.props.alerte);
  }

  updateActive = e => {
    this.props.alerte.active = e.target.checked;
    this.props.updateAlerte(this.props.alerte.id, this.props.alerte);
  }

  render() {
    const time_unit = {
      'D': 'jours',
      'M': 'mois'
    }
    let message;
    let notice_time;
    if (this.props.alerte.notice_time !== -1) {
      message = this.props.alerte.warn_before_time_limit ? time_unit[this.props.alerte.time_unit] + ' avant' : time_unit[this.props.alerte.time_unit] + ' après';
      notice_time = <Input type="number" onChange={this.updateNoticeTime} value={this.props.alerte.notice_time}/>
    }
    return (
      <tr>
        <td><input type="checkbox" onChange={this.updateActive} checked={this.props.alerte.active}/></td>
        <td>{this.props.alerte.name}</td>
        <td>{notice_time}</td>
        <td>{message}</td>
      </tr>
    )
  }
}

const mapStateToProps = state => {
  return {
    alertes: state.alertes,
    autoecoleId: state.auth.user.profile.auto_ecole
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getAlertes: (autoecoleId) => dispatch(alertes.getAlertes(autoecoleId)),
    editAlerte: (alerteId, alerteData) => dispatch(alertes.editAlerte(alerteId, alerteData)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Alertes);
