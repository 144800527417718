import {url} from '../properties.js';

export const get = (autoecoleId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return fetch(url+'autoecole/'+autoecoleId+'/bordereaux/', {headers, method: "GET"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'GET_BORDEREAUX_SUCCESSFUL', bordereaux: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
      })
  }
}


export const create = (autoecoleId, values) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    values.autoecole = autoecoleId;
    const body = JSON.stringify(values)
    return fetch(url+'autoecole/'+autoecoleId+'/bordereau/', {headers, body, method: "PUT"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 201) {
          dispatch({type: 'CREATE_BORDEREAU_SUCCESSFUL', bordereau: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_CREATE_BORDEREAU'});
      })
  }
}

export const destroy = (autoecoleId, bordereauId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    return fetch(url+'autoecole/'+autoecoleId+'/bordereau/'+bordereauId, {headers, method: "DELETE"})
      .then(res => {
        if (res.status === 204) {
          return {status: res.status, data: {}};
        } else if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 204) {
          dispatch({type: 'DELETE_BORDEREAU_SUCCESSFUL', deletedId: bordereauId });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_DELETE_BORDEREAU'});
      })
  }
}

export const archive = (autoecoleId, bordereauId, data) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    // data.autoecole = autoecoleId;
    const body = JSON.stringify(data)
    return fetch(url+'autoecole/'+autoecoleId+'/bordereau/'+bordereauId, {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          if (data.archived)
            dispatch({type: 'ARCHIVE_BORDEREAU_SUCCESSFUL'});
          else
            dispatch({type: 'UNARCHIVE_BORDEREAU_SUCCESSFUL'});
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        if (data.archived)
          dispatch({type: 'ERROR_ARCHIVE_BORDEREAU'});
        else
          dispatch({type: 'ERROR_UNARCHIVE_BORDEREAU'});
      })
  }
}
