import {denormalizePaiement, normalizePaiement} from '../schema/paiement.js';
import produce from "immer";

const initialState = {
  result: [],
  entities: {}
}

export default function paiements(state=initialState, action) {
  switch (action.type) {
    case 'GET_PAIEMENTS_SUCCESSFUL':
      return {...state, ...normalizePaiement(action.paiements)};
    case 'CREATE_PAIEMENT_SUCCESSFUL':
      const paiements = [...denormalizePaiement(state.result, state.entities), ...action.paiements];
      return {...state, ...normalizePaiement(paiements)};
    case 'CREATE_FACTURE_SUCCESSFUL':
      const updatePaiement = produce(state, draftState => {
        action.paiements.forEach((paiement) => {
          draftState.entities.paiements[paiement.id] = paiement;
        });
      });
      return {...state, ...updatePaiement};
    case 'DELETE_PAIEMENT_SUCCESSFUL':
      const newStateDelete = produce(state, draftState => {
        if (draftState.result.length > 0 && action.deletedId in draftState.entities.paiements) {
          delete draftState.entities.paiements[action.deletedId];
          draftState.result.splice(draftState.result.findIndex(id => id === action.deletedId), 1);
        }
      });
      return {...state, ...newStateDelete};
    default:
      return state;
  }
}
