const initialState = {
  messages: []
}

export default function messages(state=initialState, action) {
  switch (action.type) {
    case 'GET_MESSAGES_SUCCESSFUL':
      return {messages: action.messages};
    case 'DELETE_MESSAGE_SUCCESSFUL':
      const newState = {...state};
      const index = newState.messages.findIndex(message => message.id === action.messageId);
      newState.messages.splice(index, 1);
      return newState;
    case 'SEND_MESSAGE_SUCCESSFUL':
      const messages = state.messages;
      messages.push(action.message);
      messages.sort((a, b) => new Date(b.created) - new Date(a.created));
      return {...state, messages: messages};
    case 'SEND_MESSAGE_MODIFICATION_SUCCESSFUL':
      const existingMessages = state.messages;
      existingMessages.push(action.message);
      existingMessages.sort((a, b) => new Date(b.created) - new Date(a.created));
      return {...state, messages: existingMessages};
    default:
      return state;
  }
}
