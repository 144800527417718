const initialState = {
  alertes: {}
}

export default function alertes(state=initialState, action) {
  switch (action.type) {
    case 'GET_ALERTES_SUCCESSFUL':
      return {alertes: action.alertes};
    case 'UPDATE_ALERTE_SUCCESSFUL':
      const alertes = state.alertes;
      const alerteIndex = alertes.findIndex(alerte => alerte.id === action.alerte.id);
      alertes[alerteIndex] = action.alerte;
      return {...state};
    default:
      return state;
  }
}
