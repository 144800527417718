import React, { Component } from 'react';
import {Container, Col, Row, Nav, NavItem, NavLink} from 'reactstrap';

import Vehicule from './Vehicule/Vehicule.jsx';

class VehiculesPresentation extends Component {
  state = {
    activeTab: "1",
  }

  activateTab = activeTab => {
    this.setState({
      activeTab: activeTab
    });
  }

  render() {
    let vehicules = [];
    if (this.props.vehicules.result && this.props.vehicules.result.length > 0)
      vehicules = Object.values(this.props.vehicules.entities.vehicules);
    return (
      <Container fluid>
        <h3 className="pageTitle">Paramètres</h3>
        <Nav tabs className="no-gutters otoschoolNavTabs">
          <Col xl={3}>
            <NavItem className="otoschoolTab">
              <NavLink
                active={this.state.activeTab === "1"}
                onClick={() => { this.activateTab("1"); }}>
                <h4>Véhicules</h4>
              </NavLink>
            </NavItem>
          </Col>
        </Nav>
        <Row>
        {vehicules.map((vehicule, index) =>
          <Col xs={12} sm={12} md={6} xl={4} key={index}>
            <Vehicule vehicule={vehicule} createOrUpdate={this.props.createOrUpdate} delete={this.props.delete} />
          </Col>
        )}
        <Col xs={12} sm={12} md={6} xl={4}>
          <Vehicule createOrUpdate={this.props.createOrUpdate} />
        </Col>
        </Row>
      </Container>
    );
  }
}
export default VehiculesPresentation;
