import {denormalizePrestation, normalizePrestation} from '../schema/article.js';
import produce from "immer";

const initialState = {
  result: [],
  entities: {prestations: {}}
}

export default function prestations(state=initialState, action) {
  switch (action.type) {
    case 'CREATE_PRESTATION_SUCCESSFUL':
      const prestations = denormalizePrestation(state.result, state.entities);
      prestations.push(action.prestation);
      return {...state, ...normalizePrestation(prestations)};
    case 'GET_PRESTATIONS_SUCCESSFUL':
      return {...state, ...normalizePrestation(action.prestations)};
    case 'UPDATE_PRESTATION_SUCCESSFUL':
      const newState = produce(state, draftState => {
        draftState.entities.prestations[action.prestation.id] = action.prestation;
      });
      return {...state, ...newState};
    case 'DELETE_ARTICLE_SUCCESSFUL':
      const newStateDelete = produce(state, draftState => {
        if (draftState.result.length > 0 && action.deletedId in draftState.entities.prestations) {
          delete draftState.entities.prestations[action.deletedId];
          draftState.result.splice(draftState.result.findIndex(id => id === action.deletedId), 1);
        }
      });
      return {...state, ...newStateDelete};
    default:
      return state;
  }
}
