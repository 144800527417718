import React, { useState, useEffect } from "react";
import { Button } from 'reactstrap';


const UploadProfilePicture = props => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  const [upImg, setUpImg] = useState(props.image);

  useEffect(() => {
    setUpImg(props.image);
  }, [props]);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        {
          setUpImg(reader.result)
        });
      reader.readAsDataURL(e.target.files[0]);
      props.handleFile(e.target.files[0]);
    }
  };

  return (
    <>
      {upImg ?
        <>
        <div className="menuProfilPicture mb-1">
          <Button title="Supprimer la photo de profil" color="danger" onClick={props.deleteFile}>
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Button>
        </div>
        <div className="profilPicture">
          <img src={upImg} alt="Profil à téléverser" onClick={handleClick} />
        </div>
        </>
      :
      <Button className="noStyleButton profilPicture" onClick={handleClick}>
        <i className="fa fa-camera-retro" aria-hidden="true"></i>
        <p>Ajouter une photo de profil</p>
      </Button>
      }
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        accept="image/png, image/jpeg"
        style={{display: 'none'}}
      />
    </>
  );
}
export default UploadProfilePicture;
