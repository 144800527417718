import { normalize, denormalize, schema } from 'normalizr';

const vehicule = new schema.Entity('vehicules');
const vehiculeSchema = [ vehicule ];

export const normalizeVehicule = (data) => {
  return normalize(data, vehiculeSchema);
}

export const denormalizeVehicule = (data, entities) => {
  return denormalize(data, [vehicule], entities);
}
