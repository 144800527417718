import React, { Component } from 'react';
import {Container, Row, Col, Nav, NavItem, NavLink} from 'reactstrap';
import { connect } from 'react-redux';

import { impression } from '../../actions';

import './Impressions.css';

class Impressions extends Component {

  state = {
    activeTab: "1",
  }

  activateTab = activeTab => {
    this.setState({
      activeTab: activeTab
    });
  }

  render() {
    return (
      <Container fluid>
        <h3 className="pageTitle">Paramètres</h3>
        <Nav tabs className="no-gutters otoschoolNavTabs">
          <Col xl={3}>
            <NavItem className="otoschoolTab">
              <NavLink
                active={this.state.activeTab === "1"}
                onClick={() => { this.activateTab("1"); }}>
                <h4>Impressions</h4>
              </NavLink>
            </NavItem>
          </Col>
        </Nav>
        <Row>
          <Col xs={12} className="document-download">
            <button className="print-button" onClick={() => { this.props.getBordereauEmpty(this.props.autoecoleId, 'etg'); }}>
              <div className="document-logo">
                <i className="fa fa-print fa-3x print-full"></i>
              </div>
              <p>Bordereau ETG</p>
            </button>
            <button className="print-button" onClick={() => { this.props.getBordereauEmpty(this.props.autoecoleId, 'examen'); }}>
              <div className="document-logo">
                <i className="fa fa-print fa-3x print-full"></i>
              </div>
              <p>Bordereau EP</p>
            </button>
            <button className="print-button" onClick={() => { this.props.getBordereauEmpty(this.props.autoecoleId, 'examen-hc'); }}>
              <div className="document-logo">
                <i className="fa fa-print fa-3x print-full"></i>
              </div>
              <p>Bordereau EP (Hors Circulation)</p>
            </button>
            <button className="print-button" onClick={() => { this.props.getBordereauEmpty(this.props.autoecoleId, 'specifique'); }}>
              <div className="document-logo">
                <i className="fa fa-print fa-3x print-full"></i>
              </div>
              <p>Bordereau spécifique</p>
            </button>
            <button className="print-button" onClick={() => { this.props.getBordereauEmpty(this.props.autoecoleId, 'enregistrement'); }}>
              <div className="document-logo">
                <i className="fa fa-print fa-3x print-full"></i>
              </div>
              <p>Bordereau enregistrement</p>
            </button>
          </Col>
        </Row>
    </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    autoecoleId: state.auth.user.profile.auto_ecole,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getBordereauEmpty: (autoecoleId, bordereau) => dispatch(impression.getBordereauEmpty(autoecoleId, bordereau)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Impressions);
