import React, { Component } from 'react';
import {Row, Col, Button } from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import OtoschoolDatePicker from '../OtoschoolDatePicker/OtoschoolDatePicker.jsx';
import ModalItem from '../Modal/Modal.js';
import Intervenant from '../Intervenant/Intervenant.jsx';

class IntervenantsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      add: false,
      edit: false,
      showHeures: false,
      toShow: undefined,
      prestationsToShow: undefined,
      toEdit: undefined,
      indexToEdit: -1
    }

    this.columns = [
      {dataField: 'id', hidden: true},
      {dataField: 'name', text: 'Nom', sort: true},
      {dataField: 'username', text: 'Nom d\'utilisateur', sort: true},
      {dataField: 'email', text: 'Adresse email', sort: true},
      {dataField: 'roles', text: 'Rôle', sort: true},
      {dataField: 'permissions', text: 'Permission', sort: true},
      {dataField: 'color', text: 'Couleur', sort: true, formatter: this.colorFormatter},
      {dataField: 'phone', text: 'Téléphone', sort: true},
      {dataField: 'heures', text: 'Heures effectuées', sort: true},
      {dataField: 'actions', text: 'Actions'},
    ];
  }

  colorFormatter = (cell, row) => {
    return <div className="w-100 h-100" style={{backgroundColor: ' '+cell}}>&nbsp;</div>;
  }

  toggleAdd = e => {
    this.setState({
      add: !this.state.add
    })
  }

  toggleEdit = (toEdit, index) => {
    this.setState({
      edit: !this.state.edit,
      toEdit: toEdit,
      indexToEdit: index
    })
  }

  toggleHeures = (toShow, prestations) => {
    this.setState({
      showHeures: !this.state.showHeures,
      toShow: toShow,
      prestationsToShow: prestations,
    })
  }

  added = (intervenantData) => {
    this.props.addIntervenant(intervenantData);
    this.toggleAdd();
  }

  edited = (intervenantData) => {
    this.props.editIntervenant(intervenantData, this.state.indexToEdit);
    this.toggleEdit(undefined, -1);
  }

  prepareData = () => {
    const intervenants = this.props.intervenants;
    const dataTable = [];
    intervenants.forEach((intrvnnt, index) => {
      const intervenant = Object.assign({}, intrvnnt);
      intervenant.data = {
        id: intervenant.id,
        first_name: intervenant.first_name,
        last_name: intervenant.last_name,
        color: intervenant.color,
        phone: intervenant.phone,
        heures: intervenant.heures,
        roles: intervenant.roles,
        permissions: intervenant.permissions,
        email: intervenant.email
      };

      const prestationsStatut = this.props.prestationsStatut.entities.prestationsStatut !== undefined ?
        Object.values(this.props.prestationsStatut.entities.prestationsStatut).filter(prestationsStatut => prestationsStatut.intervenant === intervenant.id) :
        [];
      const totalPrestations = [];
      if (this.props.prestations.entities.prestations !== undefined) {
        Object.values(this.props.prestations.entities.prestations).forEach(prestation => {
          const prestations = prestationsStatut.filter(p => p.prestation === prestation.id);
          totalPrestations.push({prestationsStatut: prestations, name: prestation.article.name});
        });
      }

      const permissionName = [];
      intervenant.permissions.forEach(permission => {
        permissionName.push(permission['display_name']);
      })
      intervenant.permissions = permissionName.join(", ");
      const roleName = [];
      intervenant.roles.forEach(role => {
        roleName.push(role['name']);
      });
      intervenant.roles = roleName.join(", ");
      intervenant.heures =
        <Button color="success" className="ml-lg-2 ml-xl-3" onClick={() => {this.toggleHeures(intervenant.data, totalPrestations)}}><i className="fa fa-eye"></i></Button>
      intervenant.actions =
      <div actions="actions">
        <Button title="Changer les informations de l'intervenant" color="success" className="ml-lg-2 ml-xl-3" onClick={() => {this.toggleEdit(intervenant.data, index)}}><i className="fa fa-edit"></i></Button>
        <Button title="Supprimer l'intervenant" color="danger" className="ml-lg-2 ml-xl-3" onClick={() => {this.props.deleteIntervenant(intervenant.data, index)}}><i className="fa fa-trash"></i></Button>
        <Button title="Changer le mot de passe" className="ml-lg-2 ml-xl-3" onClick={() => {this.props.resetPassword(intervenant.username)}}><i className="fa fa-key"></i></Button>
      </div>;
      intervenant.name = intervenant.first_name + ' ' + intervenant.last_name;
      dataTable.push(intervenant);
    })
    return dataTable;
  }
  render() {
    const { SearchBar } = Search;
    let table = [];
    if (this.props.intervenants.length > 0) {
      table = this.prepareData();
    }
    return (
      <div>
      <ToolkitProvider
        keyField='id' data={table}
        columns={this.columns}
        search>
        {
          props => (
            <div>
              <Row>
                <Col xs={6} sm={6} md={5} lg={4} xl={4}>
                  <SearchBar {...props.searchProps} placeholder="Recherche"/>
                </Col>
              </Row>
              <div className="otoschoolTable">
                <BootstrapTable {...props.baseProps }
                  striped/>
              </div>
            </div>
          )
        }
      </ToolkitProvider>
      <Button className="colorButton" onClick={this.toggleAdd}>Créer</Button>
      <ModalItem modalType="large" modalState={this.state.add} toggle={this.toggleAdd}
        title="Ajouter un intervenant" content={
          <Intervenant
            actionName="Ajouter"
            roles={this.props.roles}
            permissions={this.props.permissions}
            callback={this.added}/>} />
      <ModalItem modalType="large" modalState={this.state.showHeures} toggle={e=> {this.toggleHeures(undefined, undefined)}}
        title={`Heures effectuées par ${this.state.toShow ? this.state.toShow.first_name : ''} ${this.state.toShow ? this.state.toShow.last_name : ''}`} content={
          <IntervenantHeures
            intervenant={this.state.toShow}
            prestations={this.state.prestationsToShow}
            eleves={this.props.eleves}
          />} />
      {this.state.toEdit !== undefined &&
        <ModalItem modalType="large" modalState={this.state.edit} toggle={e=> {this.toggleEdit(undefined, -1)}}
          title="Modifier l'intervenant" content={
            <Intervenant
              actionName="Modifier"
              callback={this.edited}
              roles={this.props.roles}
              permissions={this.props.permissions}
              intervenant={this.state.toEdit}/>} />
        }
      </div>
    );
  }
}

class IntervenantHeures extends Component {
  constructor(props) {
    super(props);


    this.state = {
      startDate: null,
      endDate: null,
    };

    this.columns = [
      {dataField: 'id', hidden: true},
      {dataField: 'type', text: 'Type', sort: true},
      {dataField: 'eleve', text: 'Élèves', sort: true},
      {dataField: 'start_date', text: 'Date de début', sort: true},
      {dataField: 'end_date', text: 'Date de fin', sort: true},
      {dataField: 'duration', text: 'Durée', sort: true},
    ];
  }

  setStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  }

  setEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  }

  findEleve = id => {
    if (this.props.eleves.entities.eleves) {
      const eleve = Object.values(this.props.eleves.entities.eleves)
        .find(eleve => eleve.id === Number(id));
      return eleve;
    }
  }

  prepareData = () => {
    const prestations = this.props.prestations;
    const dataTable = [];
    prestations.forEach((p) => {
      p.prestationsStatut.forEach(prestationS => {
        const hour = Math.trunc(prestationS.duree);
        const floatPart = Number((prestationS.duree - hour).toFixed(2));
        const minutes = floatPart * 60;
        const duree = `${hour}h${minutes > 0 ? minutes : ''}`;

        prestationS.eleve = prestationS.eleves.map((eleveId) => {
                              const eleve = this.findEleve(eleveId);
                              return eleve.first_name + " " + eleve.last_name;
                            }).join(',');
        // const eleve = this.findEleve(prestationS.eleve);
        // if (eleve)
        //   prestationS.eleve = <a target="_blank" rel="noopener noreferrer" href={"#/eleve/" + eleve.id}>{eleve.first_name} {eleve.last_name}</a>

        const prestation = {
          id: prestationS.id,
          type: p.name,
          eleve: prestationS.eleve,
          start_date: new Date(prestationS.start_date).toLocaleString('fr'),
          end_date: new Date(prestationS.end_date).toLocaleString('fr'),
          duration: duree,
          durationInHour: prestationS.duree,
        };
        const noDate = (this.state.startDate === null && this.state.endDate === null);
        const afterStartDate = (this.state.startDate !== null && this.state.endDate === null && new Date(prestationS.start_date) >= this.state.startDate);
        const beforeEndDate = (this.state.endDate !== null && this.state.startDate === null && new Date(prestationS.start_date) <= this.state.endDate);
        const betweenDate = (this.state.startDate !== null && this.state.endDate !== null && new Date(prestationS.start_date) >= this.state.startDate && new Date(prestationS.start_date) <= this.state.endDate);

        if ((noDate || afterStartDate || beforeEndDate || betweenDate))
          dataTable.push(prestation);
      });
    });
    return dataTable;
  }

  render() {
    const { SearchBar } = Search;
    let table = [];
    const totalDuration = [];
    if (this.props.prestations && this.props.prestations.length > 0) {
      table = this.prepareData();

      this.props.prestations.map(p => p.name).forEach(prestationName => {
        let initialValue = 0;
        const duree = table.filter(p => p.type === prestationName).reduce((accumulator, currentValue) => accumulator + Number(currentValue.durationInHour), initialValue);;
        if (duree > 0) {
          const hour = Math.trunc(duree);
          const floatPart = Number((duree - hour).toFixed(2));
          const minutes = floatPart * 60;
          totalDuration.push({name: prestationName, duration: `${hour} ${duree <= 1 ? 'heure' : 'heures'}${minutes > 0 ? ' ' + minutes + ' minutes' : ''}`});
        }
      });
    }

    return(
      <div>
        <div style={{display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '36px'}}>
          {totalDuration.map((d, index) => {
            return (
              <Col sm={12} md={4} style={{textAlign: 'center'}}>
                <h3>{d.name}</h3>
                <h4><strong>{d.duration}</strong></h4>
              </Col>
              )
            }
          )}
        </div>
        <ToolkitProvider
          keyField='id' data={table}
          columns={this.columns}
          search>
          {
            props => (
              <div>
                <Row>
                  <Col xs={6} sm={6} md={5} lg={4} xl={4}>
                    <SearchBar {...props.searchProps} placeholder="Type"/>
                  </Col>
                  <Col xs={6} sm={6} md={7} lg={8} xl={8}>
                    du &nbsp;
                    <OtoschoolDatePicker name="startDate" value={this.state.startDate} onChange={this.setStartDate}/>
                    &nbsp; au &nbsp;
                    <OtoschoolDatePicker name="endDate" value={this.state.endDate} onChange={this.setEndDate}/>
                  </Col>
                </Row>
                <div className="otoschoolTable">
                  <BootstrapTable {...props.baseProps }
                    pagination={paginationFactory()}
                    striped/>
                </div>
              </div>
            )
          }
        </ToolkitProvider>
      </div>
    )
  }
}

export default IntervenantsTable;
