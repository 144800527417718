import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

class ModalItem extends Component {
  constructor(props) {
    super(props);
    this.type = {
      'large': 'modal-lg',
      'small': 'modal-sm',
      'info': 'modal-info',
      'primary': 'modal-primary',
      'danger': 'modal-danger',
      'success': 'modal-success',
      'warning': 'modal-warning'
    };
    if (this.props.modalType in this.type)
      this.className = this.type[this.props.modalType] + ' ' + this.props.className;
    else
      this.className = this.props.className;
    if (this.props.accept !== undefined)
      this.showFooter = <ModalItemFooter acceptAction={this.props.acceptAction} accept={this.props.accept} declineAction={this.props.declineAction} decline={this.props.decline}/>;
  }

  render() {
    return (
      <Modal isOpen={this.props.modalState} toggle={this.props.toggle} className={this.className}>
        <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>
        <ModalBody>
          {this.props.content}
        </ModalBody>
        {this.showFooter}
      </Modal>
    );
  }
}

class ModalItemFooter extends Component {
  render() {
    return (
      <ModalFooter>
        <Button color="success" onClick={this.props.acceptAction}>{this.props.accept}</Button>{' '}
        <Button color="danger" onClick={this.props.declineAction}>{this.props.decline}</Button>
      </ModalFooter>
    );
  }
}
export default ModalItem;
