import React, { PureComponent } from 'react';
import {Button } from 'reactstrap';

import ModalItem from '../Modal/Modal.js';
import Prestation from '../Article/Prestation';
import PrestationsTable from './Table/PrestationsTable';

class Prestations extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      add: false,
      edit: false,
      toEdit: undefined
    };
  }

  toggleAdd = e => {
    this.setState({
      add: !this.state.add
    })
  }

  toggleEdit = (toEdit) => {
    this.setState({
      edit: !this.state.edit,
      toEdit: toEdit
    })
  }

  added = (prestationData) => {
    this.toggleAdd();
    this.props.addPrestation(prestationData);
  }

  edited = (prestationData) => {
    this.toggleEdit({name: "", price: 0, tva: 1});
    this.props.editPrestation(prestationData);
  }

  render() {
    let tvaService = 0;
    this.props.tva.forEach(tva => {
      if (tva.tva_type === 'S') {
        tvaService = tva.id;
      }
    })
    const defaultPrestation = {
      tva: tvaService,
      name: "",
      price: 0
    };

    return (
      <div>
      <PrestationsTable
        delete={this.props.delete}
        edit={this.toggleEdit}
        prestations={this.props.prestations}
        toggleComputePrestation={this.props.toggleComputePrestation}
      />
      <Button className="colorButton" onClick={this.toggleAdd}>Créer</Button>
      <ModalItem modalType="large" modalState={this.state.add} toggle={this.toggleAdd}
        title="Ajouter une prestation" content={
          <Prestation
            createMode={true}
            callback={this.added}
            prestation={defaultPrestation}
            tva={this.props.tva}/>} />
      {this.state.toEdit !== undefined &&
      <ModalItem modalType="large" modalState={this.state.edit} toggle={e => this.toggleEdit(defaultPrestation)}
        title="Modifier la prestation" content={
          <Prestation
            createMode={false}
            callback={this.edited}
            prestation={this.state.toEdit}
            tva={this.props.tva}/>} />
          }
      </div>
    );
  }
}
export default Prestations;
