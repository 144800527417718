import React, { Component } from 'react';
import {Container, Nav, Col, NavItem, NavLink, TabPane, TabContent} from 'reactstrap';

import Prestations from './Prestations.jsx';
import Produits from './Produits.jsx';
import Services from './Services.jsx';
import Formules from './Formules.jsx';

class ArticlesPresentation extends Component {
  state = {
    activeTab: "1",
  }

  activateTab = activeTab => {
    this.setState({
      activeTab: activeTab
    });
  }

  addProduct = (data) => {
    this.props.addProduct(data);
  }

  editProduct = (data) => {
    this.props.editProduct(data);
  }

  addPrestation = (data) => {
    this.props.addPrestation(data);
  }

  editPrestation = (data) => {
    this.props.editPrestation(data);
  }

  addService = (data) => {
    this.props.addService(data);
  }

  editService = (data) => {
    this.props.editService(data);
  }

  addFormule = (data) => {
    this.props.addFormule(data);
  }

  editFormule = (data) => {
    this.props.editFormule(data);
  }

  render() {
    return (
      <Container fluid>
        <div className="animated fadeIn">
          <h4 className="pageTitle">Paramètres</h4>
          <Nav tabs className="no-gutters otoschoolNavTabs">
            <Col xl={3}>
              <NavItem className="otoschoolTab">
                <NavLink
                  active={this.state.activeTab === "1"}
                  onClick={() => { this.activateTab("1"); }}>
                  <h4>Prestations</h4>
                </NavLink>
              </NavItem>
            </Col>
            <Col xl={3}>
              <NavItem className="otoschoolTab">
                <NavLink
                  active={this.state.activeTab === "2"}
                  onClick={() => { this.activateTab("2"); }}>
                  <h4>Produits</h4>
                </NavLink>
              </NavItem>
            </Col>
            <Col xl={3}>
              <NavItem className="otoschoolTab">
                <NavLink
                  active={this.state.activeTab === "3"}
                  onClick={() => { this.activateTab("3"); }}>
                  <h4>Services</h4>
                </NavLink>
              </NavItem>
            </Col>
            <Col xl={3}>
              <NavItem className="otoschoolTab">
                <NavLink
                  active={this.state.activeTab === "4"}
                  onClick={() => { this.activateTab("4"); }}>
                  <h4>Formules</h4>
                </NavLink>
              </NavItem>
            </Col>
          </Nav>
          <TabContent activeTab={this.state.activeTab} className="otoschoolTabContent">
            <TabPane tabId="1">
              <Prestations
                addPrestation={this.addPrestation}
                editPrestation={this.editPrestation}
                delete={this.props.delete}
                tva={this.props.tva}
                prestations={this.props.prestations}
                toggleComputePrestation={this.props.toggleComputePrestation}
                />
            </TabPane>
            <TabPane tabId="2">
              <Produits
                addProduct={this.addProduct}
                editProduct={this.editProduct}
                delete={this.props.delete}
                tva={this.props.tva}
                products={this.props.products}/>
            </TabPane>
            <TabPane tabId="3">
              <Services
                addService={this.addService}
                editService={this.editService}
                delete={this.props.delete}
                tva={this.props.tva}
                services={this.props.services}/>
            </TabPane>
            <TabPane tabId="4">
              <Formules
                addFormule={this.addFormule}
                editFormule={this.editFormule}
                delete={this.props.delete}
                formules={this.props.formules}
                services={this.props.services}
                products={this.props.products}
                prestations={this.props.prestations}/>
            </TabPane>
          </TabContent>
        </div>
      </Container>
    );
  }
}
export default ArticlesPresentation;
