import React, { PureComponent } from 'react';
import {Row, Col} from 'reactstrap';

import Bons from './Bons.jsx';
import Bordereaux from './Bordereaux.jsx';

class ExamenPresentation extends PureComponent {
  deleteBon = (bonId) => {
    this.props.deleteBon(bonId);
  }

  render() {
    const bordereaux = this.props.bordereaux.filter((bordereau) => bordereau.type === 'examen_hc');
    const bons = this.props.bons.filter((bon) => bon.type === 'examen_hc');

    return (
      <div>
        <Bons
          type='examen_hc'
          phrase="Date de l'épreuve pratique hors circulation"
          hasDepotDate={true}
          bons={bons}
          eleves={this.props.eleves}
          intervenants={this.props.intervenants}
          createBordereau={this.props.createBordereau}
          deleteBon={this.deleteBon}
          />
        <Row>
          <Col xl={{offset: 4, size:8}}>
            <Bordereaux
              eleves={this.props.eleves}
              bordereaux={bordereaux}
              deleteBordereau={this.props.deleteBordereau}
              archiveBordereau={this.props.archiveBordereau}
              impression={this.props.impressionExamen}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
export default ExamenPresentation;
