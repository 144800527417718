import {normalizeBordereau, denormalizeBordereau} from '../schema/bordereau.js';
import produce from "immer";

const initialState = {
  result: [],
  entities: {bordereaux: {}},
  elevesETG: [],
  elevesETGSpe: [],
  elevesExam: [],
  elevesExamHC: [],
}

export default function bordereaux(state=initialState, action) {
  switch (action.type) {
    case 'GET_BORDEREAUX_SUCCESSFUL':
      return {...state, ...normalizeBordereau(action.bordereaux)};
    case 'CREATE_BORDEREAU_SUCCESSFUL':
      const bordereaux = denormalizeBordereau(state.result, state.entities);
      bordereaux.push(action.bordereau);
      return {...state, ...normalizeBordereau(bordereaux)};
    case 'DELETE_BORDEREAU_SUCCESSFUL':
      const newStateDelete = produce(state, draftState => {
        if (action.deletedId in draftState.entities.bordereaux) {
          delete draftState.entities.bordereaux[action.deletedId];
          draftState.result.splice(draftState.result.findIndex(id => id === action.deletedId), 1);
        }
      });
      return {...state, ...newStateDelete};
    case 'GET_ELEVES_ETG_SUCCESSFUL':
      state.elevesETG = action.eleves.map((eleve) => eleve.id);
      return {...state}
    case 'GET_ELEVES_ETG_SPE_SUCCESSFUL':
      state.elevesETGSpecifique = action.eleves.map((eleve) => eleve.id);
      return {...state}
    case 'GET_ELEVES_EXAM_SUCCESSFUL':
      state.elevesExam = action.eleves.map((eleve) => eleve.id);
      return {...state}
    case 'GET_ELEVES_EXAM_HC_SUCCESSFUL':
      state.elevesExamHC = action.eleves.map((eleve) => eleve.id);
      return {...state}
    default:
      return state;
  }
}
