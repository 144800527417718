const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  isLoading: true,
  user: {
    profile: {
      first_name: "",
      last_name: ""
    }
  },
  errors: {},
};

export default function auth(state=initialState, action) {
  switch (action.type) {
    case 'USER_LOADING':
      return {...state, isLoading: true};
    case 'USER_LOADED':
      return {...state, isAuthenticated: true, isLoading: false, user: action.user};
    case 'LOGIN_SUCCESSFUL':
    case 'RESET_PASSWORD_SUCCESSFUL':
    case 'CHANGE_PROFILE_SUCCESSFUL':
    case 'UPDATE_PROFILE_SUCCESSFUL':
    case 'REGISTRATION_SUCCESSFUL':
        localStorage.setItem('token', action.data.token);
        return {...state, ...action.data, isAuthenticated: true, isLoading: false, errors: null};
    case 'AUTHENTICATION_ERROR':
    case 'LOGIN_FAILED':
    case 'REGISTRATION_FAILED':
    case 'LOAD_USER_ERROR':
    case 'LOGOUT_SUCCESSFUL':
        localStorage.removeItem('token');
        return {...state, errors: action.data, token: null, user: initialState.user,
            isAuthenticated: false, isLoading: false};
    case 'GET_TOKEN_SUCCESFUL':
      localStorage.setItem('token', action.data.token);
      return {...state, ...action.data};
    default:
      return state;
  }
}
