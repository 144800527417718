import React, { Component } from 'react';

import {Button, Input, Label} from 'reactstrap';

class DetailCompte extends Component {
  state = {
    username: this.props.username
  }

  handleChange = (e) => {
    this.setState({
      username: e.target.value
    });
  }

  registerChange = e => {
    this.props.registerChange(this.state.username);
  }

  render() {
    return (
      <div>
        <Label>Nom d'utilisateur</Label>
        <Input type="text" onChange={this.handleChange} onBlur={this.handleChange} value={this.state.username}/>
        <div className="mt-3">
          <Button onClick={this.props.resetPassword}>
            <i className="fa fa-key" aria-hidden="true"></i> {' '}
            Changer de mot de passe
          </Button>
        </div>
        <div className="mt-3">
          <Button onClick={this.registerChange} className="colorButton">Enregister</Button>
        </div>
      </div>
    );
  }
}

export default DetailCompte;
