import { denormalizeQuantity, normalizeQuantity } from '../schema/quantity.js';

const initialState = {
  result: [],
  entities: {quantities: {}},
}

export default function quantities(state=initialState, action) {
  switch (action.type) {
    case 'GET_QUANTITIES_SUCCESSFUL':
      return {...state, ...normalizeQuantity(action.quantities)}
    default:
      return state;
  }
}
