import React, { PureComponent } from 'react';
import { Button, Label, FormGroup, Form, Col, Row, Input } from 'reactstrap';

import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

import Select from 'react-select';

import ModalItem from '../Modal/Modal.js';

export default class HeurePlanning extends PureComponent {
  state = {
    start: this.props.event.start,
    end: this.props.event.end,
    prestation: this.props.prestationStatut(this.props.event.id).prestation,
    intervenant: this.props.prestationStatut(this.props.event.id).intervenant,
    eleves: this.props.prestationStatut(this.props.event.id).eleves,
    absent: this.props.prestationStatut(this.props.event.id).absent,
    note: this.props.prestationStatut(this.props.event.id).note,
    eventToDelete: null,
    displayConfirmationModal: false
  }

  createNewEvent = (absent = false) => {
    const newEvent = Object.assign({}, this.props.event);
    newEvent.id = this.props.event.id;
    newEvent.start = this.state.start !== '' ? this.state.start : this.props.event.start;
    newEvent.end = this.state.end !== '' ? this.state.end : this.props.event.end;
    newEvent.duree = (newEvent.end - newEvent.start) / (60 * 60 * 1000);
    newEvent.prestation = Number(this.state.prestation);
    newEvent.intervenant = Number(this.state.intervenant);
    newEvent.eleves = this.state.eleves;
    newEvent.absent = absent;
    newEvent.note = this.state.note;
    return newEvent;
  }

  updateStartDate = event => {
    if (event._d)
      this.setState({ start: event._d });
  }

  updateEndDate = event => {
    if (event._d)
      this.setState({ end: event._d });
  }

  updatePrestation = event => {
    this.setState({ prestation: event.target.value });
  }

  updateIntervenant = event => {
    this.setState({ intervenant: event.target.value });
  }

  updateEvent = () => {
    const newEvent = this.createNewEvent();
    this.props.update(newEvent);
  }

  updateNote = event => {
    this.setState({ note: event.target.value });
  }

  selectEleve = id => {
    this.setState({ eleve: id });
  }

  confirmDelete = (event) => {
    this.setState({
      eventToDelete: event,
      displayConfirmationModal: !this.state.displayConfirmationModal
    });
  }

  delete = () => {
    this.props.delete(this.state.eventToDelete.id);
    this.confirmDelete(null);
  }

  absent = () => {
    this.setState((state) => { return { absent: !state.absent }});
    const newEvent = this.createNewEvent(!this.state.absent)
    this.props.update(newEvent);
  }

  render() {
    const { event, minTime, maxTime, prestations, intervenants, eleves } = this.props;
    const optionsEleves = Object.values(eleves).map((eleve) => ({
      label: `${eleve.first_name} ${eleve.last_name}`,
      value: eleve.id
    }));
    const selectedEleves = this.state.eleves.map((eleve) => ({
      label: eleves[eleve] && `${eleves[eleve].first_name} ${eleves[eleve].last_name}`,
      value: eleve
    }));
    const timeConstraints = {
      hours: {
        min: Number(minTime.slice(0, 2)),
        max: Number(maxTime.slice(0, 2)),
      },
      minutes: {
        step: 15,
      }
    };
    return (
      <div>
        {this.state.absent ?
          <FormGroup row>
            <Label sm={3} md={3} lg={3} xl={3}><b style={{color: 'tomato'}}>Élève absent</b></Label>
          </FormGroup>
          : null
        }
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Prestations</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
            <Form>
              <select className="form-control" value={this.state.prestation} onChange={this.updatePrestation} name="prestation" id="prestation">
                {Object.values(prestations).map((prestation) => <option key={prestation.id} value={prestation.id}>{`${prestation.article.name}`}</option>)}
              </select>
            </Form>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Intervenant</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
            <Form>
              <select className="form-control" value={this.state.intervenant} onChange={this.updateIntervenant} name="intervenant" id="intervenant">
                {intervenants.map((intervenant) => <option key={intervenant.id} value={intervenant.id}>{`${intervenant.first_name} ${intervenant.last_name}`}</option>)}
              </select>
            </Form>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Élève</Label>
          <Col sm={8} md={8} lg={8} xl={6}>
            <Select
              isMulti
              options={optionsEleves}
              value={selectedEleves}
              onChange={(selectedOptions) =>
                this.setState({
                  eleves: selectedOptions.map((option) => option.value)
                })}
              placeholder="Élève"/>
          </Col>
          {this.state.eleves.length === 1 &&
            <Col sm={1} md={1} lg={1} xl={1}>
              <a target="_blank" rel="noopener noreferrer" href={"#/eleve/" + this.state.eleves[0]}>
                <Button color="success"><i className="fa fa-eye"></i></Button>
              </a>
            </Col>
          }
          {this.state.eleves.length === 1 &&
            <Col sm={1} md={1} lg={1} xl={1}>
              <a target="_blank" rel="noopener noreferrer" href={"#/eleve/" + this.state.eleves[0] + "/message"}>
                <Button color="success"><i className="fa fa-envelope"></i></Button>
              </a>
            </Col>
          }
        </FormGroup>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Début</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
            <Datetime timeConstraints={timeConstraints} viewMode="time" value={this.state.start} onChange={this.updateStartDate} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Fin</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
            <Datetime timeConstraints={timeConstraints} viewMode="time" value={this.state.end} onChange={this.updateEndDate}  />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Note</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
            <Input type="textarea" name="note" rows="2" value={this.state.note} onChange={this.updateNote} onBlur={this.updateNote}/>
          </Col>
        </FormGroup>
        <Row className="buttons-row">
          {this.props.userRoles.filter((role) => role !== 'Moniteur').length > 0 &&
            <Button className="planning-button deleteButton" onClick={() => this.confirmDelete(event)}>Supprimer</Button>
          }
          <Button className="planning-button primaryButton" onClick={this.updateEvent}>Modifier</Button>
          <Button className="planning-button absent-button" onClick={this.absent}>Marquer {this.state.absent ? 'non' : ''} absent</Button>
        </Row>
        <Row className="buttons-row">
          <Button className="planning-button" onClick={this.props.hide}>Fermer</Button>
        </Row>
        <ModalItem modalState={this.state.displayConfirmationModal} toggle={() => this.confirmDelete(null)}
          accept="Supprimer" acceptAction={this.delete} decline="Annuler" declineAction={() => this.confirmDelete(null)}
          title="Confirmation de suppression" content={this.state.eventToDelete &&
            `Êtes vous sûr de vouloir supprimer cet évènement ?
            Cette action est irréversible et entrainera la perte de données.`}/>
      </div>
    );
  }
}
