import React, { Component } from 'react';
import {Col, Nav, NavLink, NavItem, TabContent, TabPane} from 'reactstrap';

import EleveFacture from './EleveFacture.jsx';
import EleveDevis from './EleveDevis.jsx';
import EleveEncaissement from './EleveEncaissement.jsx';

class EleveCompte extends Component {
  state = {
    activeTab: this.props.activeTab,
    editPermis: 0
  }

  activateTab = activeTab => {
    this.setState({
      activeTab: activeTab
    });
  }

  componentDidUpdate(prevProps) {
    if(this.props.activeTab !== prevProps.activeTab)
        this.activateTab(this.props.activeTab);
  }

  render () {
    let permis = {factures:[], paiements:[]}
    if (this.props.eleve.permis.length > 0)
      permis = this.props.eleve.permis[this.state.editPermis];

    return (
      <div className="otoschoolCard">
      <Nav tabs className="no-gutters otoschoolNavTabs">
        <Col xl={3}>
            <NavItem className="otoschoolTab">
              <NavLink
                active={this.state.activeTab === "1"}
                onClick={() => { this.activateTab("1"); }}>
                <h4>Facturer un forfait</h4>
              </NavLink>
            </NavItem>
          </Col>
          <Col xl={3}>
            <NavItem className="otoschoolTab">
              <NavLink
                active={this.state.activeTab === "2"}
                onClick={() => { this.activateTab("2"); }}>
                <h4>Relevé de compte</h4>
              </NavLink>
            </NavItem>
          </Col>
        </Nav>
        <TabContent activeTab={this.state.activeTab} className="otoschoolTabContent">
          <TabPane tabId="1">
            <EleveFacture
              permis={permis}
              eleve={this.props.eleve}
              autoecole={this.props.autoecole}
              factures={this.props.factures}
              formules={this.props.formules}
              produits={this.props.produits}
              services={this.props.services}
              prestations={this.props.prestations}
              editArticles={this.props.editArticles}
              addArticles={this.props.addArticles}
              printFacture={this.props.printFacture}
              deleteFacture={this.props.deleteFacture}
              sendFacture={this.props.sendFacture}/>
          </TabPane>
          <TabPane tabId="2">
            <EleveEncaissement
              eleve={this.props.eleve}
              autoecole={this.props.autoecole}
              permis={permis}
              factures={this.props.factures}
              paiements={this.props.paiements}
              addPaiement={this.props.addPaiement}
              deletePaiement={this.props.deletePaiement}
              printFacture={this.props.printFacture}
              />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default EleveCompte;
