import { normalize, denormalize, schema } from 'normalizr';

const alerteMessage = new schema.Entity('alerteMessages');
const alerteMessageSchema = [ alerteMessage ];

export const normalizeAlerteMessage = (data) => {
  return normalize(data, alerteMessageSchema);
}

export const denormalizeAlerteMessage = (data, entities) => {
  return denormalize(data, [alerteMessage], entities);
}
