import React, { Component } from 'react';
import {Col, FormGroup, Row, Input, Button} from 'reactstrap';

import moment from 'moment';
import 'moment/locale/fr';

class EleveNotes extends Component {
  state = {
    note: moment().locale('fr').format("[Le] DD/MM/YYYY [à] HH[h]mm") + " par " + this.props.user.first_name + " " + this.props.user.last_name + " : \n" + this.props.note
  }

  handleChange = (event) => {
    this.setState({
      note: event.target.value
    });
  }

  handleClick = () => {
    this.props.sendNote({note: this.state.note});
  }

  render () {
    return (
      <div>
        <FormGroup>
          <div className="pl-3 pr-3 pt-4 pb-4 note">
            <Input type="textarea" name="notes" rows="25" value={this.state.note} onChange={this.handleChange} onBlur={this.handleChange} placeholder="Vos notes sur l'élève"/>
          </div>
        </FormGroup>
        <Row className="text-center">
          <Col>
            <Button className="colorButton" onClick={this.handleClick}>Enregistrer</Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EleveNotes;
