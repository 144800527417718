import {url} from '../properties.js';

export const register = (values) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let body = JSON.stringify(values);
    return fetch(url+'auth/register/', {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          console.log('Server Error!');
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'REGISTRATION_SUCCESSFUL', data: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data,
                    error: 'Vous n\'êtes pas autorisé à vous inscrire.'});
          throw res.data;
        } else {
          dispatch({type: 'REGISTRATION_FAILED', data: res.data});
          throw res.data;
        }
      })
  }
}

export const login = (username, password) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let body = JSON.stringify({username, password});
    return fetch(url+'auth/login/', {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          console.log('Server Error!');
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'LOGIN_SUCCESSFUL', data: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
        } else {
          dispatch({type: 'LOGIN_FAILED', data: res.data});
        }
      })
  }
}

export const askResetPassword = (username) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let body = JSON.stringify({username: username});
    return fetch(url+'auth/reset/password', {headers, body, method: "PUT"})
      .then(res => {
        if (res.status < 500) {
          return {status: res.status, data: ''};
        } else {
          console.log('Server Error!');
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'ASK_RESET_SUCCESSFUL'});
          return res;
        } else if (res.status === 404) {
          dispatch({type:'ASK_RESET_NO_USER', data: username});
          throw res;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res;
        }
        dispatch({type: 'ERROR_ASK_RESET'});
      })
  }
}

export const resetPassword = (token, password) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let body = JSON.stringify({token: token, password: password});
    return fetch(url+'auth/reset/password', {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          return res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'RESET_PASSWORD_SUCCESSFUL', data: res.data});
          return res;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res;
        }
        dispatch({type: 'ERROR_RESET_PASSWORD'});
      })
  }
}

export const changeUserProfile = (username, values) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    let body = JSON.stringify({old_username: username, ...values});
    return fetch(url+'auth/profile', {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          return res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'CHANGE_PROFILE_SUCCESSFUL', data: res.data});
          return res;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res;
        }
        dispatch({type: 'ERROR_CHANGE_PROFILE'});
      })
  }
}

export const updateUserProfile = (profileId, body) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    body = JSON.stringify(body);
    return fetch(url+'user/profile/'+profileId, {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          return res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'UPDATE_PROFILE_SUCCESSFUL', data: res.data});
          return res;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res;
        }
        dispatch({type: 'ERROR_UPDATE_PROFILE'});
      })
  }
}

export const loadUser = () => {
  return (dispatch, getState) => {
    dispatch({type: 'USER_LOADING'});
    const token = getState().auth.token;
    let headers = {};
    if (token) {
      headers['Content-Type'] = "application/json";
      headers['Authorization'] = `Token ${token}`;
    } else {
      // Login as admin, must get token
      dispatch(getToken());
    }
    const credentials = 'include';
    return fetch(url+'auth/user/', {headers, credentials})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          console.log('Server Error!');
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'USER_LOADED', user: res.data });
          return res.data;
        } else if (res.status >= 400 && res.status < 500) {
          dispatch({type: 'LOAD_USER_ERROR', data: res.data});
        }
      });
    }
}

export const logOut = () => {
  return (dispatch, getState) => {
    const credentials = 'include';
    return fetch(url+'auth/logout', {credentials}).then(res => {
      dispatch({type: 'LOGOUT_SUCCESSFUL'})
    });
  }
}

const getToken = () => {
  return (dispatch, getState) => {
    return fetch(url+'auth/token', {credentials: 'include'}
      ).then(res => {
          if (res.status < 500) {
            return res.json().then(data => {return {status: res.status, data}})
          } else {
            console.log('Server error !')
          }
        }
      ).then(res => {
        if(res.status === 200) {
          dispatch({type: 'GET_TOKEN_SUCCESFUL', data: res.data });
          return res.data;
        }
        else {
          dispatch({type: 'LOGIN_FAILED', data: res.data});
        }
      });
    };
}
