import React, { Component } from 'react';
import { connect } from 'react-redux'
import { HashRouter as Router, Route, Redirect } from 'react-router-dom';

import Login from './components/Login/Login.js';
import Register from './components/Register/Register.js';
import Settings from './components/Settings/Settings.jsx';
import Eleves from './components/Eleves/Eleves.jsx';
import Eleve from './components/Eleve/Eleve.jsx';
import FactureEncaissement from './components/FactureEncaissement/FactureEncaissement.jsx';
import Articles from './components/Articles/Articles.jsx';
import Planning from './components/Planning/Planning.jsx';
import Intervenants from './components/Intervenants/Intervenants.jsx';
import Organismes from './components/Organismes/Organismes.jsx';
import Impressions from './components/Impressions/Impressions.jsx';
import Vehicules from './components/Vehicules/Vehicules.jsx';
import Statistiques from './components/Statistiques/Statistiques.jsx';
import Messages from './components/Messages/Messages.jsx';
import Examens from './components/Examens/Examens.jsx';
import Alertes from './components/Alertes/Alertes.jsx';
import Autorisation from './components/Autorisation/Autorisation.jsx';
import ResetPassword from './components/ResetPassword/ResetPassword.jsx';

import PrivateRoot from './PrivateApp.js';

const PERMISSIONS = {
  full_access: 'full_access',
  planning: 'planning',
  eleves: 'eleves',
  examens: 'examens',
  factures: 'factures',
  alertes: 'alertes',
  parametres: 'parametres',
  stats: 'stats',
  all: 'all',
};

class AppRouter extends Component {


  PrivateRoute = ({component: ChildComponent, allowed, ...rest}) => {
    let permissions = [];
    let roles = [];
    if (this.props.auth.isAuthenticated) {
      permissions = this.props.auth.user.profile.permissions.map(p => p.name);
      roles = this.props.auth.user.profile.roles.map(p => p.name);
    }
    return <Route {...rest} render={props => {
      if (this.props.auth.isLoading) {
        return <em>Chargement...</em>;
      } else if (!this.props.auth.isAuthenticated) {
        return <Redirect to="/login" />;
      } else if (permissions.some(permission => allowed.includes(permission))
        || allowed.includes('all')
        || permissions.includes('full_access')
        || roles.includes('Administrateur')) {
        return (
        <PrivateRoot>
          <ChildComponent {...props} />
        </PrivateRoot>
      );
      } else {
        return <Redirect to="/nonautorise" />
      }
    }} />
  }

  render() {
    let { PrivateRoute } = this;
    return (
      <Router>
        <div>
          <Route path="/login/" component={Login} />
          <Route path="/register/" component={Register} />
          <Route path="/reset/password/:token" component={ResetPassword} />
          <PrivateRoute exact path="/" component={Planning} allowed={[PERMISSIONS.planning]}/>
          <PrivateRoute path="/eleves/" component={Eleves} allowed={[PERMISSIONS.eleves]} />
          <PrivateRoute path="/examens/" component={Examens} allowed={[PERMISSIONS.examens]} />
          <PrivateRoute path="/factures/" component={FactureEncaissement} allowed={[PERMISSIONS.factures]} />
          <PrivateRoute path="/statistiques" component={Statistiques} allowed={[PERMISSIONS.stats]} />
          <PrivateRoute path="/messages" component={Messages} allowed={[PERMISSIONS.alertes]} />
          <PrivateRoute path="/alertes" component={Alertes} allowed={[PERMISSIONS.alertes]} />
          <PrivateRoute exact path="/eleve/" component={Eleve} allowed={[PERMISSIONS.eleves]} />
          <PrivateRoute exact path="/eleve/:eleveId" component={Eleve} allowed={[PERMISSIONS.eleves]} />
          <PrivateRoute path="/eleve/:eleveId/:location(profile|factures|encaissement|devis|planning|notes|message|examen)" component={Eleve} allowed={[PERMISSIONS.eleves]} />
          <PrivateRoute path="/settings/etablissements" component={Organismes} allowed={[PERMISSIONS.parametres]} />
          <PrivateRoute path="/settings/intervenants" component={Intervenants} allowed={[PERMISSIONS.parametres]} />
          <PrivateRoute path="/settings/articles" component={Articles} allowed={[PERMISSIONS.parametres]} />
          <PrivateRoute path="/settings/autoecole" component={Settings} allowed={[PERMISSIONS.parametres]} />
          <PrivateRoute path="/settings/vehicules" component={Vehicules} allowed={[PERMISSIONS.parametres]} />
          <PrivateRoute path="/settings/impressions" component={Impressions} allowed={[PERMISSIONS.parametres]} />
          <PrivateRoute path="/nonautorise/" component={Autorisation} allowed={[PERMISSIONS.all]}/>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = state => ({ auth: state.auth })
const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
