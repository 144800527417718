import React, { PureComponent } from 'react';
import {Row, Col} from 'reactstrap';

import BordereauxFicheVerte from './Tableaux/BordereauxFicheVerte.jsx';
import FicheVerte from './Tableaux/FicheVerte.jsx';

class FicheVertePresentation extends PureComponent {
  render() {
    const bordereauxFicheVerte = this.props.bordereaux.filter((bordereau) => bordereau.type === 'fiche_verte');
    return (
      <div>
        <FicheVerte
          eleves={this.props.eleves}
          bordereaux={bordereauxFicheVerte}
          impressionFicheVerte={this.props.impressionFicheVerte}
          createBordereau={this.props.createBordereau}
        />
        <Row>
          <Col xl={{offset: 4, size:8}}>
            <BordereauxFicheVerte
              eleves={this.props.eleves}
              bordereaux={bordereauxFicheVerte}
              deleteBordereau={this.props.deleteBordereau}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
export default FicheVertePresentation;
