import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Container, Row, Col, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import styles from './StatusBar.module.css';
import mail from './logo/mail.svg';
import header from './header.svg';
import SearchBar from '../SearchBar/SearchBar';

class StatusBar extends Component {
  state = {
    dropdownOpen: false,
  }

  selectEleve = id => {
    window.location.href = "#/eleve/" + id;
  }

  toggle = e => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  render() {
    return (
      <div className={styles.header}>
        <img src={header} alt="otoschool" className="img-fluid" onClick={this.props.mainPage}/>
        <Container fluid>
          <Row className={"no-gutters " + styles.status}>
            <Col xs={4} sm={4} md={3} lg={2} className="searchbar">
              <SearchBar selectEleve={this.selectEleve} eleves={this.props.eleves.result.length > 0 ? Object.values(this.props.eleves.entities.eleves) : []} />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1}>
              <Button className="addEleve" onClick={this.props.addEleve}><i className="fa fa-plus" aria-hidden="true"></i></Button>
            </Col>
            <Col xs={{offset:3, size:4}} sm={{offset:3, size:4}} md={{offset:4, size:4}} lg={{offset:5, size:4}} xl={{offset:7, size:2}} className="text-right">
                <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                  <DropdownToggle caret className={styles.dropdownStatus}>
                    {this.props.profile.first_name} {this.props.profile.last_name}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={this.props.displayAccount}>Mon compte</DropdownItem>
                    <DropdownItem onClick={this.props.reportProblem}>Reporter un problème</DropdownItem>
                    <DropdownItem onClick={this.props.logOut}>Déconnexion</DropdownItem>
                  </DropdownMenu>
                </ButtonDropdown>
                <div className="otoschool-badge-item">
                  <span className="badge badge-pill badge-light otoschool-badge">{this.props.unread}</span>
                  <img className={styles.emailIcon} onClick={e => {window.location.href = "#/messages";}} src={mail} alt="mail"/>
                </div>
            </Col>
          </Row>
        </Container>
    </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    unread: state.alerteMessages.unread,
  };
}

const mapDispatchToProps = dispatch => {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusBar);
