import React, { Component } from 'react';
import {Col, Nav, NavLink, NavItem, TabContent, TabPane, FormGroup, Label, Input} from 'reactstrap';

class EleveFichier extends Component {
  state = {
    activeTab: "1",
  }

  activateTab = activeTab => {
    this.setState({
      activeTab: activeTab
    });
  }

  render () {
    return (
      <div className="otoschoolCard">
      <Nav tabs className="no-gutters otoschoolNavTabs">
        <Col xl={3}>
            <NavItem className="otoschoolTab">
              <NavLink
                active={this.state.activeTab === "1"}
                onClick={() => { this.activateTab("1"); }}>
                <h4>Fichiers</h4>
              </NavLink>
            </NavItem>
          </Col>
        </Nav>
        <TabContent activeTab={this.state.activeTab} className="otoschoolTabContent">
          <TabPane tabId="1">
            <Col xl={4}>
              <FormGroup>
                <Label for="file">Ajouter un fichier</Label>
                <Input type="file" name="file" />
              </FormGroup>
            </Col>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default EleveFichier;
