import React, { Component } from 'react';
import { connect } from 'react-redux';

import { facture, paiement, eleve, eleves, impression, autoecole } from '../../actions';
import FactureEncaissementPresentation from './FactureEncaissementPresentation.jsx';

import moment from 'moment';

class FactureEncaissement extends Component {

  componentDidMount() {
    this.props.getFactures(this.props.autoecoleId);
    this.props.getPaiements(this.props.autoecoleId);
    this.props.getEleves(this.props.autoecoleId);
    this.props.get(this.props.autoecoleId);
  }

  payFactures = (payments) => {
    this.props.payFactures(this.props.autoecoleId, {paiements: payments});
  }

  addPaiement = (data) => {
    const eleveId = Object.values(this.props.factures.entities.factures).find(f => f.id === data.factures[0]).eleve;
    if (Number(eleveId) in this.props.eleves.entities.eleves)
      this.props.addPaiement(this.props.autoecoleId, eleveId, data);
  }

  createRelance = (factId, data) => {
    this.props.createRelance(this.props.autoecoleId, factId, data);
  }

  render() {
    let factYear = 0;
    let factMonth = 0;
    if (this.props.factures.result.length > 0) {
      factYear = Object.values(this.props.factures.entities.factures).filter(f => moment(f.created).isAfter(moment().subtract(1, 'years'))).map(f => f.amount).reduce(
        (f1, f2) => f1 + f2, 0
      );
      factMonth = Object.values(this.props.factures.entities.factures).filter(f => moment(f.created).isAfter(moment().subtract(1, 'months'))).map(f => f.amount).reduce(
        (f1, f2) => f1 + f2, 0
      );
    }
    let payYear = 0;
    let payMonth = 0;
    if (this.props.paiements.result.length > 0) {
      payYear = Object.values(this.props.paiements.entities.paiements).filter(p => moment(p.created).isAfter(moment().subtract(1, 'years'))).map(p => Number(p.amount)).reduce(
        (p1, p2) => p1 + p2, 0
      );
      payMonth = Object.values(this.props.paiements.entities.paiements).filter(p => moment(p.created).isAfter(moment().subtract(1, 'months'))).map(p => Number(p.amount)).reduce(
        (p1, p2) => p1 + p2, 0
      );
    }
    let soldeYear = 0;
    let soldeMonth = 0;
    if (this.props.factures.result.length > 0) {
      soldeYear = Object.values(this.props.factures.entities.factures).filter(f => moment(f.created).isAfter(moment().subtract(1, 'years'))).map(f => (f.amount - f.amount_paid)).reduce(
        (f1, f2) => f1 + f2, 0
      );
      soldeMonth = Object.values(this.props.factures.entities.factures).filter(f => moment(f.created).isAfter(moment().subtract(1, 'months'))).map(f => (f.amount - f.amount_paid)).reduce(
        (f1, f2) => f1 + f2, 0
      );
    }
    return (
      // - 0 to always get positive values and no -0
      <FactureEncaissementPresentation
        factures={this.props.factures}
        paiements={this.props.paiements}
        eleves={this.props.eleves}
        printFacture={this.props.printFacture}
        autoecoleId={this.props.autoecoleId}
        autoecole={this.props.autoecole}
        pay={this.payFactures}
        addPaiement={this.addPaiement}
        factYear={factYear.toFixed(0) - 0}
        factMonth={factMonth.toFixed(0) - 0}
        payYear={payYear.toFixed(0) - 0}
        payMonth={payMonth.toFixed(0) - 0}
        soldeYear={soldeYear.toFixed(0) - 0}
        soldeMonth={soldeMonth.toFixed(0) - 0}
        relance={this.createRelance}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    autoecole: state.autoecole.autoecole,
    autoecoleId: state.auth.user.profile.auto_ecole,
    factures: state.factures,
    paiements: state.paiements,
    eleves: state.eleves,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    addPaiement: (autoecoleId, eleveId, data) =>
      dispatch(paiement.addPaiement(autoecoleId, data)).then(
          () => dispatch(eleve.get(autoecoleId, eleveId))
      ),
    payFactures: (autoecoleId, data) => dispatch(paiement.addPaiements(autoecoleId, data)).then(
      () => dispatch(paiement.getPaiements(autoecoleId)).then(
        () => dispatch(facture.getFactures(autoecoleId))
      )
    ),
    createRelance: (autoecoleId, facturationId, data) => dispatch(eleve.createRelance(autoecoleId, facturationId, data)).then(
      () => dispatch(paiement.getPaiements(autoecoleId)).then(
        () => dispatch(facture.getFactures(autoecoleId))
      )
    ),
    getFactures: (autoecoleId) => dispatch(facture.getFactures(autoecoleId)),
    getPaiements: (autoecoleId) => dispatch(paiement.getPaiements(autoecoleId)),
    getEleves: (autoecoleId) => dispatch(eleves.get(autoecoleId)),
    get: (autoecoleId) => dispatch(autoecole.get(autoecoleId)),
    printFacture: (autoecoleId, data) => dispatch(impression.printFacture(autoecoleId, data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FactureEncaissement);
