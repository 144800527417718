import React, { PureComponent } from 'react';

import { Button, Input, Label } from 'reactstrap';
import Select from 'react-select';

export default class ReportProblem extends PureComponent {
  constructor(props) {
    super(props);
    this.optionsPage =  [
      { value: 'planning', label: 'Planning' },
      { value: 'f_eleve', label: 'Fiche élève' },
      { value: 'factures', label: 'Factures' },
      { value: 'encaissements', label: 'Encaissements' },
      { value: 'eleves', label: 'Liste élèves' },
      { value: 'stats', label: 'Statistiques' },
      { value: 'alertes', label: 'Alertes' },
      { value: 'messages', label: 'Messages' },
      { value: 'fiche_verte', label: 'Impression fiche verte' },
      { value: 'etg', label: 'Examen ETG' },
      { value: 'exam', label: 'Examen' },
      { value: 'exam_hc', label: 'Examen HC' },
      { value: 'exam_spe', label: 'Examen spécifique' },
      { value: 'autoecole', label: 'Paramètres autoécole' },
      { value: 'etablissements', label: 'Paramètres établissements' },
      { value: 'intervenants', label: 'Paramètres intervenants' },
      { value: 'articles', label: 'Paramètres articles' },
      { value: 'vehicules', label: 'Paramètres véhicules' },
      { value: 'impression', label: 'Paramètres impression' },
    ];
    this.state = {
      problem: '',
      page: this.optionsPage[0],
      eleve: {value: undefined, label: undefined},
      error: ''
    }
  }

  handleChange = (event) => {
    this.setState(event);
  }

  reportProblem = () => {
    if (!this.state.problem) {
      this.setState({error: "Il faut décrire un problème"});
    } else {
      this.props.reportProblem(this.state.page.value, this.state.eleve.value, this.state.problem);
    }
  }

  render() {
    return (
      <div>
        {this.state.error && <h3 className="text-danger">{this.state.error}<br/></h3>}
        <Label>Page où le problème est apparu</Label>
        <Select options={this.optionsPage} value={this.state.page}
          onChange={(selectedOption) => this.handleChange({page: selectedOption})}/>
        <br/>
        <Label>Élève</Label>
        <Select options={this.props.eleves.map((eleve) => {return {
            value: eleve.id,
            label: eleve.first_name.split(' ')[0] + ' ' + eleve.last_name
          }})}
          onChange={(selectedOption) => this.handleChange({eleve: selectedOption})}
          placeholder="Élève concerné par le problème"/>
        <br/>
        <Label>Description du problème</Label>
        <Input type="textarea" content={this.state.problem}
          onChange={(e) => this.handleChange({problem: e.target.value})}/>
        <br/>
        <div className="text-center">
          <Button type="submit" className="colorButton" onClick={this.reportProblem}>
            Reporter un problème
          </Button>
        </div>
      </div>
    )
  }
}
