import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input,
  InputGroup, InputGroupAddon, InputGroupText, Row, Label, FormGroup } from 'reactstrap';

import ModalItem from '../Modal/Modal.js';

import { auth } from '../../actions';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      modalReset: false
    };
  }

  usernameChange = e => {
    this.setState({
      username: e.target.value
    });
  }

  passwordChange = e => {
    this.setState({
      password: e.target.value
    });
  }

  askResetPassword = e => {
    this.setState({
      modalReset: !this.state.modalReset
    });
  }

  sendResetPassword = e => {
    this.props.askResetPassword(this.state.username);
    this.askResetPassword();
  }

  onSubmit = e => {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password);
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />
    }
    let message = <p className="text-muted">Connectez-vous à votre compte</p>;
    if (this.props.errors.length > 0)
      message = <p className="text-danger">Connexion impossible</p>;
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form onSubmit={this.onSubmit}>
                      <h1>Connexion</h1>
                      {message}
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" placeholder="Nom d'utilisateur" name="username" autoComplete="username" onChange={this.usernameChange}/>
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" placeholder="Mot de passe" name="password" autoComplete="current-password" onChange={this.passwordChange}/>
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button color="primary" className="px-4">Connexion</Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Button color="link" className="px-0" onClick={this.askResetPassword}>Mot de passe oublié ?</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: '44%' }}>
                  <CardBody className="text-center">
                    <div>
                      <h2>Inscription</h2>
                      <p>
                        La 1ère solution complète en ligne pour votre auto école,
                        adaptée à la Polynésie Française</p>
                      <Link to="/register">
                        <Button color="primary" className="mt-3" active tabIndex={-1}>Inscrivez-vous</Button>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
        <ModalItem
          modalState={this.state.modalReset}
          toggle={this.askResetPassword}
          title="Mot de passe oublié"
          content={
            <div>
              <FormGroup>
                <Label>Votre nom d'utilisateur</Label>
                <Input type="text" onChange={this.usernameChange} value={this.state.username}/>
              </FormGroup>
              <Button className="colorButton" onClick={this.sendResetPassword}>Changer de mot de passe</Button>
            </div>
          }/>
      </div>
    );
  }
}
const mapStateToProps = state => {
  let errors = [];
  if (state.auth.errors) {
    errors = Object.keys(state.auth.errors).map(field => {
      return {field, message: state.auth.errors[field]};
    });
    errors = errors.filter((error) => error.field !== 'detail');
  }
  return {
    errors,
    isAuthenticated: state.auth.isAuthenticated
  };
}

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => dispatch(auth.login(username, password)),
    askResetPassword: (username) => dispatch(auth.askResetPassword(username))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
