import React, { Component } from 'react';
import {Button, Label, Input} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import Select from 'react-select';

import ModalItem from '../Modal/Modal.js';
import OtoschoolDatePicker from '../OtoschoolDatePicker/OtoschoolDatePicker.jsx';

import {redPriceFormatter, bluePriceFormatter, priceFormatter, dateFormatter} from '../../common/formatter.js';

class EleveEncaissement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      willPay: 0,
      selectedRows: []
    };
    this.balance = 0;
  }

  willPayRow = (row, isSelect) => {
    if (isSelect)
      this.setState(state => {
        const selectedRows = [...state.selectedRows, row.id];
        const willPay = state.willPay + row.solde;
        return {
          selectedRows: selectedRows,
          willPay: willPay
        }
      });
    else
      this.setState(state => {
        const selectedRows = state.selectedRows.filter((rowId) => rowId !== row.id);
        const willPay = state.willPay - row.solde;
        return {
          selectedRows: selectedRows,
          willPay: willPay
        };
      });
  }

  willPayAll = (rows, isSelect) => {
    if (isSelect)
      this.setState(state => {
        const selectedRows = rows.map((row) => row.id);
        const willPay = rows.map((row) => row.solde).reduce((sum, value) => sum + value);
        return {
          selectedRows: selectedRows,
          willPay: willPay
        }
      });
    else
      this.setState({
        selectedRows: [],
        willPay: 0
      });
  }

  prepareData = () => {
    const data = [];
    const factures = this.props.permis.factures;
    this.balance = 0;
    if (this.props.factures.result.length > 0) {
      factures.sort((a, b) => a in this.props.factures.entities.factures && b in this.props.factures.entities.factures ?
        new Date(this.props.factures.entities.factures[a].date) - new Date(this.props.factures.entities.factures[b].date) : 0)
      factures.forEach(f => {
        if (f in this.props.factures.entities.factures) {
          const factureData = this.props.factures.entities.factures[f];
          const facture = {
            'id': factureData.id,
            'date': factureData.date,
            'numero':
            <a href="#" target="_blank"
              onClick={(e) => {
                e.preventDefault();
                this.printFacture(factureData);
              }
            }>{factureData.number}</a>,
            'facture': Number(factureData.amount_to_pay),
            'encaisse': Number(factureData.amount_paid),
            'solde': Number(factureData.must_pay),
            'articles': factureData.articles,
            'paiements': factureData.paiements
          }
          this.balance -= facture.solde;
          data.push(facture);
        }
      });
      }
    data.sort((a,b) => new Date(a.date) - new Date(b.date));
    return data;
  }

  printFacture = (facture) => {
    const data = {
      factureId: facture.id
    }
    this.props.printFacture(this.props.autoecole.id, data);
  }

  pay = (amount, type, checkNumber, date) => {
    const data = {
      amount: amount,
      type: type,
      check_number: checkNumber,
      date: moment(date).format('YYYY-MM-DD'),
      factures: this.state.selectedRows,
      facturation: this.props.permis.facture
    };
    if (data.amount > 0) {
      this.props.addPaiement(data);
      this.setState({
        willPay: 0,
        selectedRows: []
      });
    }
  }

  render () {
    let table = [];
    table = this.prepareData();
    const advancePayments = this.props.permis.paiements_avance;
    const advancePayment = advancePayments !== undefined && this.props.paiements.entities.paiements ?
      advancePayments.map((ap) => ap in this.props.paiements.entities.paiements ?
      Number(this.props.paiements.entities.paiements[ap].amount) : 0).reduce((sum, value) => sum + value, 0)
      : 0;
    return (
      <EleveEncaissementPresentation
        eleve={this.props.eleve}
        autoecole={this.props.autoecole}
        pay={this.pay}
        willPayRow={this.willPayRow}
        willPayAll={this.willPayAll}
        table={table}
        balance={this.balance.toFixed(0) === "-0" ? 0 : this.balance.toFixed(0)}
        toPay={this.state.willPay.toFixed(0) === "-0" ? "0 XPF": this.state.willPay.toFixed(0) + " XPF"}
        amount={this.state.willPay.toFixed(0)}
        advancePayment={advancePayment.toFixed(0)}
        selected={this.state.selectedRows}
        deletePaiement={this.props.deletePaiement}
      />);
  }
}


class EleveEncaissementPresentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: [],
      showModal: false,
    }

    this.columns = [
      {dataField: 'id', hidden: true},
      {dataField: 'date', text: 'Date', sort: true, formatter: dateFormatter},
      {dataField: 'numero', text: 'Numéro', sort: true},
      {dataField: 'facture', text: 'Facturé', sort: true, formatter: priceFormatter},
      {dataField: 'encaisse', text: 'Encaissé', sort: true, formatter: bluePriceFormatter},
      {dataField: 'solde', text: 'Solde', sort: true, formatter: redPriceFormatter},
    ];

    this.selectRow = {
      mode: 'checkbox',
      hideSelectColumn: false,
      clickToSelect: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        this.setState({ amountPaid: row.solde });
        this.props.willPayRow(row, isSelect);
      },
      onSelectAll: (isSelect, rows, e) => {
        let totalAmount = 0;
        rows.forEach(row => {
          totalAmount += row.solde;
        });
        this.setState({ amountPaid: totalAmount });
        this.props.willPayAll(rows, isSelect);
      }
    };
  }

  handleExpand = (row, isExpand, rowIndex, e) => {
    if (isExpand) {
      this.setState(() => ({
        expanded: [...this.state.expanded, row.id]
      }));
    } else {
      this.setState(() => ({
        expanded: this.state.expanded.filter(x => x !== row.id)
      }));
    }
  }

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  }

  pay = (amount, type, checkNumber, date) => {
    this.props.pay(amount, type, checkNumber, date);
    this.toggle();
  }

  render () {
    this.selectRow['selected'] = this.props.selected;
    const expandRow = {
      expanded: this.state.expanded,
      onExpand: this.handleExpand,
      showExpandColumn: true,
      expandColumnPosition: 'right',
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <div><i className="fa fa-eye-slash" aria-hidden="true"></i></div>;
        }
        return <div><i className="fa fa-eye" aria-hidden="true"></i></div>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return (
            <div><i className="fa fa-eye-slash" aria-hidden="true"></i></div>
          );
        }
        return (
          <div><i className="fa fa-eye" aria-hidden="true"></i></div>
        );
      },
      renderer: row => {
        const paiementsList = [];
        const PAIEMENT_MAP = {
          'BANK': "par virement",
          'CHECK': "par chèque",
          'CASH': "en espèce",
          'CARD': "par carte bleue",
          'UNKNOWN': ""
        }
        row.paiements.forEach(paiement => {
          let paiementString = "";
          paiementString += "Paiement " + paiement.number + " de ";
          paiementString += Number(paiement.amount).toFixed(0) + " XPF le ";
          paiementString += moment(paiement.date).format('DD/MM/YYYY') + " ";
          paiementString += paiement.type in PAIEMENT_MAP ? PAIEMENT_MAP[paiement.type] : "";
          if (paiement.type === "CHECK") {
            paiementString += " " + paiement.check_number;
          }
          const paiementButton =
          <Button color="danger" className="pull-right"
            onClick={() => {this.props.deletePaiement(row.id, paiement.id)}}>
            <i className="fa fa-trash-o"></i>
          </Button>;
          paiementsList.push({string: paiementString, delete: paiementButton});
        });
        return (
          <div>
            {paiementsList.map((paiementElem, index) =>
              <p key={"f" + index}>
                {paiementElem["string"]}
                {paiementElem["delete"]}
              </p>)
            }
          </div>
        );
      },
    };
    return (
    <div className="otoschoolTable">
      {this.props.advancePayment !== "0" && <p>Payé à l'avance : {this.props.advancePayment} XPF</p>}
      <BootstrapTable
        keyField='id' data={this.props.table}
        columns={this.columns}
        selectRow={this.selectRow}
        expandRow={expandRow}
        pagination={paginationFactory()}
        striped />
        <div className="subTitle"><p><span className="pull-right">Balance {this.props.balance < 0 ? <span className="text-danger">{this.props.balance * -1} XPF</span>: this.props.balance + " XPF"} </span></p></div>
      <Button className="colorButton" onClick={this.toggle}>Encaisser {this.props.amount} XPF</Button>
      <ModalItem modalType="large" modalState={this.state.showModal} toggle={this.toggle}
        title="Payer"
        content={<ElevePaiement
          pay={this.pay}
          amountPaid={this.props.amount}
        />} />
    </div>
    );
  }
}

export class ElevePaiement extends Component {
  state = {
    amountPaid: this.props.amountPaid,
    type: this.props.type,
    shouldAskCheckNumber: this.props.type === "CHECK",
    checkNumber: this.props.checkNumber,
    date: new Date()
  }

  handleChange = (e) => {
    this.setState({
      amountPaid: Number(e.target.value)
    });
  }

  handleCheckNumberChange = (e) => {
    this.setState({
      checkNumber: e.target.value
    });
  }

  handleChangeDate = (e) => {
    this.setState({
      date: e.target.value
    });
  }

  handleSelect = (e) => {
    const typeSelected = e;
    this.setState({
      type: typeSelected,
      shouldAskCheckNumber: typeSelected.value === "CHECK"
    });
  }

  handleClick = (e) => {
    this.props.pay(this.state.amountPaid, this.state.type !== undefined ? this.state.type.value : "UNKNOWN", this.state.checkNumber, this.state.date);
  }

  render() {
    const options = [
      {value: "UNKNOWN", label: "-"},
      {value: "BANK", label: "Virement"},
      {value: "CARD", label: "Carte bleue"},
      {value: "CHECK", label: "Chèque"},
      {value: "CASH", label: "Paiement en liquide"},
    ];
    return (
      <div>
        <Label>Solde</Label>
        <Input type="text" name="amountPaid" value={this.state.amountPaid} onChange={this.handleChange} onBlur={this.handleChange}/>
        <Label className="mt-3">Type de paiement</Label>
        <Select placeholder="Type de paiement" options={options} value={this.state.type} onChange={this.handleSelect}/>
        {this.state.shouldAskCheckNumber &&
          <div className="mt-3">
            <Label>Numéro du chèque</Label>
            <Input type="text" name="checkNumber" value={this.state.checkNumber} onChange={this.handleCheckNumberChange} onBlur={this.handleCheckNumberChange}/>
          </div>
        }
        <Label className="mt-3">Date du paiement</Label>
        <div>
          <OtoschoolDatePicker value={this.state.date} onChange={this.handleChangeDate}/>
        </div>
        <hr/>
        <Button className="colorButton pull-right" onClick={this.handleClick}>Payer</Button>
      </div>
    );
  }
}

export default EleveEncaissement;
