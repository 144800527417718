import React, { Component } from 'react';
import { Col, Row, Form, FormGroup, Label, Input, Button, FormFeedback } from 'reactstrap'
import { Formik } from 'formik';
import * as Yup from 'yup';

import validate from '../../common/utils.js';

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

class Intervenant extends Component {
  validationSchema = e => {
    return Yup.object().shape({
      first_name: Yup.string().required('Un prénom est requis'),
      last_name: Yup.string().required('Un nom est requis'),
      email: Yup.string().email('L\'adresse email n\'est pas au bon format').required('Un email est requis'),
      color: Yup.string().required('Une couleur est requise'),
      roles: Yup.array().required('Un rôle est requis'),
      permissions: Yup.array().required('Une permission est requise'),
    });
  }

  onSubmit = (values, { setSubmitting, setErrors }) => {
    this.props.callback(values)
  }

  isChecked = (list, elem_to_check) => {
    let isChecked = false;
    list.forEach(elem => {
      if (elem.name === elem_to_check)
        isChecked = true;
    })
    return isChecked;
  }

  find = (list, elem_to_find) => {
    let index_found = -1;
    list.forEach((elem, index) => {
      if (elem.name === elem_to_find)
        index_found = index;
    })
    return index_found
  }

  render() {
    const intervenant = this.props.intervenant;
    if (intervenant.roles === "")
      intervenant.roles = [];
    if (intervenant.permission === null || intervenant === undefined)
      intervenant.permission = "1";
    return(
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={intervenant}
          validate={validate(this.validationSchema)}
          onSubmit={this.onSubmit}
          render={
            ({
              values,
              errors,
              touched,
              status,
              dirty,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              handleReset,
              setTouched
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row form>
                  <Col sm={6}>
                    <FormGroup>
                      <Label for="first_name">Prénom</Label>
                      <Input type="text" name="first_name"
                        invalid={touched.first_name && !!errors.first_name}
                        onChange={handleChange} onBlur={handleBlur}
                        value={values.first_name}/>
                      <FormFeedback>{errors.first_name && touched.first_name && errors.first_name}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup>
                      <Label for="last_name">Nom</Label>
                      <Input type="text" name="last_name"
                        invalid={touched.last_name && !!errors.last_name}
                        onChange={handleChange} onBlur={handleBlur} value={values.last_name}/>
                      <FormFeedback>{errors.last_name && touched.last_name && errors.last_name}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                <Col sm={12}>
                  <FormGroup>
                    <Label for="email">Adresse email</Label>
                    <Input type="text" name="email"
                      invalid={touched.email && !!errors.email}
                      onChange={handleChange} onBlur={handleBlur}
                      value={values.email}/>
                    <FormFeedback>{errors.email && touched.email && errors.email}</FormFeedback>
                  </FormGroup>
                </Col>
                </Row>
                <Row form>
                  <Col sm={6}>
                    <FormGroup>
                      <Label for="phone">Téléphone</Label>
                      <Input type="text" name="phone"
                        onChange={handleChange} onBlur={handleBlur}
                        value={values.phone}/>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup>
                      <Label for="color">Couleur</Label>
                      <Input type="color" name="color"
                        invalid={touched.color && !!errors.color}
                        onChange={handleChange} onBlur={handleBlur}
                        value={values.color}/>
                      <FormFeedback>{errors.color && touched.color && errors.color}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row form>
                  <Col sm={6}>
                    <FormGroup>
                      <Label for="roles">Rôle</Label>
                        {this.props.roles.map((role, index) =>
                        <div key={role.name}>
                          <input type="checkbox" name={role.name}
                            checked = {this.isChecked(values.roles, role.name)}
                            value = {role.name}
                            onChange = {e => {
                              const index = this.find(values.roles, e.target.value);
                              if(index === -1)
                                values.roles.push({name: e.target.value});
                              else
                                values.roles.splice(index, 1);
                              handleChange(e);
                            }} /> {role.name}</div>)}
                      <div className="text-danger"><small>{errors.roles && touched.roles && errors.roles}</small></div>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup>
                      <Label for="permissions">Permission</Label>
                      {this.props.permissions.map((perm, index) =>
                      <div key={perm.name}>
                        <input type="checkbox" name={perm.display_name}
                          checked = {this.isChecked(values.permissions, perm.name)}
                          value = {perm.name}
                          onChange = {e => {
                            const index = this.find(values.permissions, e.target.value);
                            if(index === -1)
                              values.permissions.push({name: e.target.value});
                            else
                              values.permissions.splice(index, 1);
                            handleChange(e);
                          }} /> {perm.display_name}</div>)}
                      <div className="text-danger"><small>{errors.permissions && touched.permissions && errors.permissions}</small></div>
                    </FormGroup>
                  </Col>
                </Row>
                {this.isChecked(values.roles, 'Moniteur') &&
                  <FormGroup>
                    <Label for="num">Numéro d'agrément</Label>
                    <Input type="text" name="num" onChange={handleChange} onBlur={handleBlur} value={values.num}/>
                  </FormGroup>
                }
                <Button type="submit" className="pull-right colorButton">{this.props.actionName}</Button>
              </Form>
        )} />
      </div>
    );
  }
}

Intervenant.defaultProps = {
  intervenant: {
    roles: [],
    permissions: [],
    first_name: "",
    last_name: "",
    email: "",
    color: getRandomColor(),
    phone: "",
    num: ""
  },
  roles: []
};

export default Intervenant;
