import React, { PureComponent } from 'react';
import {Row, Col, Button} from 'reactstrap';
import { connect } from 'react-redux';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import {dateFormatter} from '../../../common/formatter.js';
import { autoecole, impression } from '../../../actions';

import moment from 'moment';

class BordereauxFicheVerte extends PureComponent {
  constructor(props) {
    super(props);

    this.props.get(this.props.autoecoleId);

    this.columns = [
      {dataField: 'id', hidden: true},
      {dataField: 'date', text: 'Date', sort: true, formatter: dateFormatter, filterValue: dateFormatter},
      {dataField: 'eleves', text: 'Élèves', sort: true, formatter: this.elevesFormatter, filterValue: this.elevesSearchFilter},
      {dataField: 'print', text: 'Imprimer'},
      {dataField: 'delete', text: 'Supprimer'},
    ];
  }

  elevesFormatter = (cell, row) => {
    const eleves = cell.map(eleve => <a key={eleve.id} className="bordereau-eleve-name" target="_blank" rel="noopener noreferrer" href={"#/eleve/" + eleve.id}>{eleve.first_name.split(' ')[0]} {eleve.last_name}</a>);
    return <div className="bordereau-eleve">{eleves}</div>;
  }

  elevesSearchFilter = (cell, row) => {
    const eleves = cell.map(eleve => eleve.first_name + " " + eleve.last_name);
    return eleves;
  }

  delete = (bordereauId) => {
    this.props.deleteBordereau(bordereauId);
  }

  impressionFicheVerte = (blank, eleves, date) => {
    const data = {
      date: moment(date).format('DD/MM/YYYY'),
      elevesId: eleves
    };
    this.props.getFichesVerte(this.props.autoecoleId, blank, data);
  }

  prepareData = (bordereaux) => {
    return bordereaux
    .sort((a, b) => b.id - a.id)
    .map((bordereau) => {
      const bordereauEleves = this.props.eleves
        .filter(eleve => bordereau.eleves.includes(eleve.id));
      const bordereauTable = {};
      bordereauTable.id = bordereau.id;
      bordereauTable.date = bordereau.date;
      bordereauTable.eleves = bordereauEleves
      bordereauTable.print =
      <div actions="actions">
        <Button color="warning" onClick={() => {this.impressionFicheVerte(true, bordereau.eleves, bordereau.date)}}><i className="fa fa-print"></i></Button>
      </div>;
      bordereauTable.delete =
      <div actions="actions">
        <Button color="danger" onClick={() => {this.delete(bordereau.id)}}><i className="fa fa-trash"></i></Button>
      </div>;
      return bordereauTable;
    });
  }

  render() {
    const { SearchBar } = Search;
    const table = this.prepareData(this.props.bordereaux);
    return (
      <ToolkitProvider
        keyField='id' data={table}
        columns={this.columns}
        search>
        {
          props => (
            <div>
              <h4>Fiches verte créées</h4>
              <Row>
                <Col xs={6} sm={6} md={5} lg={4} xl={4}>
                  <SearchBar {...props.searchProps} placeholder="Recherche"/>
                </Col>
              </Row>
              <div className="otoschoolTable">
                <BootstrapTable {...props.baseProps }
                  selectRow={this.selectRow}
                  pagination={paginationFactory()}
                  striped/>
              </div>
            </div>
          )
        }
      </ToolkitProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    autoecoleId: state.auth.user.profile.auto_ecole,
    autoecole: state.autoecole.autoecole,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    get: (autoecoleId) => dispatch(autoecole.get(autoecoleId)),
    getFichesVerte: (autoecoleId, dataOnly, data) => dispatch(impression.getFichesVerte(autoecoleId, dataOnly, data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BordereauxFicheVerte);
