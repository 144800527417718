import { normalize, denormalize, schema } from 'normalizr';

const prestationStatut = new schema.Entity('prestationsStatut');
const prestationStatutSchema = [ prestationStatut ];

export const normalizePrestationStatut = (data) => {
  return normalize(data, prestationStatutSchema);
}

export const denormalizePrestationStatut = (data, entities) => {
  return denormalize(data, [prestationStatut], entities);
}
