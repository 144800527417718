import {denormalizeFormule, normalizeFormule} from '../schema/article.js';
import produce from "immer";

const initialState = {
  result: [],
  entities: {formules: {}}
}

export default function formules(state=initialState, action) {
  switch (action.type) {
    case 'CREATE_FORMULE_SUCCESSFUL':
      const formules = denormalizeFormule(state.result, state.entities);
      formules.push(action.formule);
      return {...state, ...normalizeFormule(formules)};
    case 'GET_FORMULES_SUCCESSFUL':
      return {...state, ...normalizeFormule(action.formules)};
    case 'UPDATE_FORMULE_SUCCESSFUL':
      const newState = produce(state, draftState => {
        draftState.entities.formules[action.formule.id] = action.formule;
      });
      return {...state, ...newState};
    case 'DELETE_ARTICLE_SUCCESSFUL':
      const newStateDelete = produce(state, draftState => {
        if (draftState.result.length > 0 && action.deletedId in draftState.entities.formules) {
          delete draftState.entities.formules[action.deletedId];
          draftState.result.splice(draftState.result.findIndex(id => id === action.deletedId), 1);
        }
      });
      return {...state, ...newStateDelete};
    default:
      return state;
  }
}
