import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { auth } from '../../actions';
import {Button, Input, Label, Form, FormGroup, Container, Card, CardBody, Row, Col} from 'reactstrap';

import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';

import validate from '../../common/utils.js';

class ResetPassword extends Component {
  state = {
    passwordReseted: false,
  }

  validationSchema = values => {
    return Yup.object().shape({
      newPassword: Yup.string()
        .min(6, `Le mot de passe doit faire au moins ${6} caractères`)
        .matches(/(?=.*\d)(?=.*[A-z]).{6,}/, 'Le mot de passe doit contenir au moins six caractères dont un chiffre\n')
        .required('Le mot de passe est requis'),
      confirmNewPassword: Yup.string()
        .oneOf([values.newPassword], 'Les mots de passe ne correspondent pas')
        .required('Il faut confirmer le mot de passe'),
    });
  }

  onSubmit = (values) => {
    this.props.resetPassword(this.props.match.params.token, values.newPassword);
    this.setState({
      passwordReseted: true
    });
  }

  renderFormik = ({ values, errors, touched, status, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, handleReset, setTouched }) => (
    <Form onSubmit={handleSubmit} autoComplete="off">
      <h1>Changer de mot de passe</h1>
      <p className="text-muted">Réinitiliaser votre mot de passe</p>
      <FormGroup>
        <Label>Nouveau mot de passe</Label>
        <Input type="password" name="newPassword"
          value={values.newPassword} onChange={handleChange} onBlur={handleBlur}
          required/>
        <span className="text-danger"><ErrorMessage name="newPassword"/></span>
      </FormGroup>
      <FormGroup>
        <Label>Confirmez le nouveau mot de passe</Label>
        <Input type="password" name="confirmNewPassword"
          value={values.confirmNewPassword} onChange={handleChange} onBlur={handleBlur}
          required/>
        <span className="text-danger"><ErrorMessage name="confirmNewPassword"/></span>
      </FormGroup>
      <FormGroup>
        <Button className="colorButton" type="submit">Confirmer</Button>
      </FormGroup>
    </Form>
  )

  render() {
    if (this.props.isAuthenticated && this.state.passwordReseted) {
      return <Redirect to="/" />
    }
    return (
      <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="9" lg="7" xl="6">
            <Card className="mx-4">
              <CardBody className="p-4">
                <Formik enableReinitialize={true} initialValues={this.props.newPasswords}
                      validate={validate(this.validationSchema)} onSubmit={this.onSubmit}
                      render={this.renderFormik} />
                </CardBody>
              </Card>
          </Col>
        </Row>
      </Container>
      </div>
    );
  }
}
ResetPassword.defaultProps = {
  newPasswords: {
    newPassword: "",
    confirmNewPassword: ""
  }
};

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.isAuthenticated
  }
}

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: (token, password) => dispatch(auth.resetPassword(token, password))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
