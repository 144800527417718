import React, { PureComponent } from 'react';

import { Row, Col, Button } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import OtoschoolDatePicker from '../../OtoschoolDatePicker/OtoschoolDatePicker.jsx';
import {bluePriceFormatter, redPriceFormatter, priceFormatter, dateFormatter} from '../../../common/formatter.js';

export default class FacturesTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
    };

    this.columns = [
      {dataField: 'id', hidden: true},
      {dataField: 'number', text: 'N°Facture', sort: true},
      {dataField: 'eleve', text: 'Élève', sort: true, formatter: this.eleveFormatter, filterValue: this.eleveSearchFilter},
      {dataField: 'organization', text: 'Établissement', sort: true},
      {dataField: 'date', text: 'Création', sort: true, formatter: dateFormatter, filterValue: dateFormatter},
      {dataField: 'amount', text: 'Facturé', sort: true, formatter: priceFormatter},
      {dataField: 'encaisse', text: 'Encaissé', sort: true, formatter: bluePriceFormatter},
      {dataField: 'solde', text: 'Solde', sort: true, formatter: redPriceFormatter},
      {dataField: 'pdf', text: 'Document PDF'},
      {dataField: 'relance', text: 'Relancer'},
    ];

    this.selectRow = {
      mode: 'checkbox',
      hideSelectColumn: false,
      clickToSelect: true,
      onSelect: this.props.selectRow
    };
  }

  eleveFormatter = (cell, row) => {
    const eleve = this.findEleve(cell);
    return eleve !== undefined ? <a target="_blank" rel="noopener noreferrer" href={"#/eleve/" + eleve.id}>{eleve.first_name.split(' ')[0]} {eleve.last_name}</a> : "Élève inconnu";
  }

  eleveSearchFilter = (cell, row) => {
    const eleve = this.findEleve(cell);
    return eleve !== undefined ? eleve.first_name.split(' ')[0] + " " + eleve.last_name : "Élève inconnu";
  }

  setStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  }

  setEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  }

  relance = (factId) => {
    this.props.relance(factId, {facturation: factId});
  }

  printFacture = (facture, eleve) => {
    const data = {
      factureId: facture.id,
    }
    this.props.printFacture(this.props.autoecoleId, data);
  }

  findEleve = id => {
    if (this.props.eleves.entities.eleves) {
      const eleve = Object.values(this.props.eleves.entities.eleves)
        .find(eleve => eleve.id === Number(id));
      return eleve;
    }
  }

  prepareData = () => {
    const keys = this.props.factures.result;
    const dataTable = [];
    keys.forEach(factId => {
      const facture = Object.assign({}, this.props.factures.entities.factures[factId]);
      facture.encaisse = Number(facture.amount_paid);
      facture.solde = Number(facture.must_pay);
      const factureDate = new Date(facture.date);
      const noDate = (this.state.startDate === null && this.state.endDate === null);
      const afterStartDate = (this.state.startDate !== null && this.state.endDate === null && factureDate >= this.state.startDate);
      const beforeEndDate = (this.state.endDate !== null && this.state.startDate === null && factureDate <= this.state.endDate);
      const betweenDate = (this.state.startDate !== null && this.state.endDate !== null && factureDate >= this.state.startDate && factureDate <= this.state.endDate);
      const eleve = this.findEleve(facture.eleve);

      facture.pdf =
        <div actions="actions">
          <Button color="success" className="ml-lg-2 ml-xl-3" onClick={() => this.printFacture(facture, eleve)}><i className="fa fa-print"></i></Button>
        </div>;
      facture.relance =
        <div actions="actions">
          <Button color="success" className="ml-lg-2 ml-xl-3" onClick={() => this.relance(facture.facturation)}><i className="fa fa-envelope"></i></Button>
        </div>;
      if ((noDate || afterStartDate || beforeEndDate || betweenDate))
        dataTable.push(facture);
    })
    return dataTable;
  }

  render() {
    const { SearchBar } = Search;
    let table = [];
    if (this.props.factures.result.length > 0) {
      table = this.prepareData();
    }
    this.selectRow['selected'] = this.props.selectedRows;
    return (
      <ToolkitProvider
        keyField='id' data={table}
        columns={this.columns}
        search>
        {
          props => (
            <div>
              <Row>
                <Col xs={6} sm={6} md={5} lg={4} xl={4}>
                  <SearchBar {...props.searchProps} placeholder="Référence"/>
                </Col>
                <Col xs={12} sm={12} md={7} lg={5} xl={5}>
                  <Row className="no-gutters">
                    <Col>
                      <OtoschoolDatePicker name="startDate" value={this.state.startDate} onChange={this.setStartDate}/>
                      &nbsp; au &nbsp;
                      <OtoschoolDatePicker name="endDate" value={this.state.endDate} onChange={this.setEndDate}/>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="otoschoolTable">
                <BootstrapTable {...props.baseProps }
                  selectRow={this.selectRow}
                  pagination={paginationFactory()}
                  striped/>
              </div>
            </div>
          )
        }
      </ToolkitProvider>

    );
  }
}
