import { normalize, denormalize, schema } from 'normalizr';

const stat = new schema.Entity('stats', undefined, {
  idAttribute: (value, parent, key) => (value.start_date+'_'+value.end_date)
});
const statSchema = [ stat ];

export const normalizeStat = (data) => {
  return normalize(data, statSchema);
}

export const denormalizeStat = (data, entities) => {
  return denormalize(data, [stat], entities);
}
