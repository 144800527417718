import React, { Component } from 'react';
import { Input } from 'reactstrap';

import './SearchBar.css';

export default class SearchBar extends Component {
  state = {
    searchedEleves: [],
    eleve: '',
    cursor: 0,
  };

  componentDidMount() {
    if (this.props.eleve) {
      this.setState({ eleve: this.getEleve(this.props.eleve) });
    }
  }

  handleSearch = event => {
    this.setState({ eleve: event.target.value });
    if (event.target.value.length > 1) {
      const searchedName = event.target.value.toLowerCase();
      const searchedEleves = this.props.eleves.filter(eleve => {
        return eleve.first_name.toLowerCase().includes(searchedName)
          || eleve.last_name.toLowerCase().includes(searchedName)
          || (eleve.first_name + ' ' + eleve.last_name).toLowerCase().includes(searchedName);
      });
      this.setState({ searchedEleves: searchedEleves });
    } else {
      this.setState({ cursor: 0 });
      this.setState({ searchedEleves: [] });
    }
  }

  selectEleve = eleve => {
    const { selectEleve } = this.props;
    this.setState({ eleve: `${eleve.first_name} ${eleve.last_name}` });
    this.setState({ searchedEleves: [] });
    selectEleve(eleve.id);
  }

  getEleve = id => {
    const eleve = this.props.eleves.find(eleve => eleve.id === id);
    return `${eleve.first_name} ${eleve.last_name}`;
  }

  handleKeyDown = e => {
    const { cursor, searchedEleves } = this.state
    if (e.keyCode === 38 && cursor > 0) {
      this.setState( prevState => ({
        cursor: prevState.cursor - 1
      }))
    } else if (e.keyCode === 40 && cursor < searchedEleves.length - 1) {
      this.setState( prevState => ({
        cursor: prevState.cursor + 1
      }))
    } else if (e.keyCode === 13) {
      this.selectEleve(searchedEleves[cursor]);
    }
  }

  render() {
    const { cursor } = this.state;
    return (
      <div>
        <Input onKeyDown={this.handleKeyDown} onChange={this.handleSearch} value={this.state.eleve} placeholder="Rechercher un élève" />
        {this.state.searchedEleves.length > 0 ?
          <ul className="eleves-list">
            {this.state.searchedEleves.map(((eleve, i) =>
              <li className={cursor === i ? 'active-eleve' : null}
                  onClick={() => this.selectEleve(eleve)}
                  value={eleve}
                  key={eleve.id}>
                  {`${eleve.first_name.split(' ')[0]} ${eleve.last_name}`}
              </li>
            ))}
          </ul>
          : null
        }
      </div>
    );
  }
}
