import { normalize, denormalize, schema } from 'normalizr';

const quantity = new schema.Entity('quantities');
const quantitySchema = [ quantity ];

export const normalizeQuantity = (data) => {
  return normalize(data, quantitySchema);
}

export const denormalizeQuantity = (data, entities) => {
  return denormalize(data, [quantity], entities);
}
