import React, { PureComponent } from 'react';
import {Row, Col } from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import OtoschoolDatePicker from '../../OtoschoolDatePicker/OtoschoolDatePicker.jsx';
import {bluePriceFormatter, dateFormatter} from '../../../common/formatter.js';

class Encaissements extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
    };

    this.columns = [
      {dataField: 'id', hidden: true},
      {dataField: 'number', text: 'N°Reçu', sort: true},
      {dataField: 'eleve', text: 'Élève', sort: true, formatter: this.eleveFormatter, filterValue: this.eleveSearchFilter},
      {dataField: 'organization', text: 'Établissement', sort: true},
      {dataField: 'date', text: 'Date', sort: true, formatter: dateFormatter, filterValue: dateFormatter},
      {dataField: 'amount', text: 'Montant', sort: true, formatter: bluePriceFormatter},
      {dataField: 'type', text: 'Méthode', sort: true, formatter: this.paymentTypeFormatter},

    ];
  }
  paymentTypeFormatter = (cell, row) => {
    const PAIEMENT_MAP = {
      'BANK': "Virement",
      'CHECK': "Chèque",
      'CASH': "Espèce",
      'CARD': "Carte bleue",
      'UNKNOWN': ""
    }
    return cell in PAIEMENT_MAP ? PAIEMENT_MAP[cell] : "Inconnu";
  }

  eleveFormatter = (cell, row) => {
    const eleve = this.findEleve(cell);
    return eleve !== undefined ? <a target="_blank" rel="noopener noreferrer" href={"#/eleve/" + eleve.id}>{eleve.first_name.split(' ')[0]} {eleve.last_name}</a> : "Élève inconnu";
  }

  eleveSearchFilter = (cell, row) => {
    const eleve = this.findEleve(cell);
    return eleve !== undefined ? eleve.first_name.split(' ')[0] + " " + eleve.last_name : "Élève inconnu";
  }

  setStartDate = (e) => {
    this.setState({
      startDate: e.target.value
    });
  }

  setEndDate = (e) => {
    this.setState({
      endDate: e.target.value
    });
  }

  findEleve = id => {
    if (this.props.eleves.entities.eleves) {
      const eleve = Object.values(this.props.eleves.entities.eleves)
        .find(eleve => eleve.id === Number(id));
      return eleve;
    }
  }

  prepareData = () => {
    const keys = this.props.paiements.result;
    const dataTable = [];
    keys.forEach(pId => {
      const paiement = Object.assign({}, this.props.paiements.entities.paiements[pId]);
      const noDate = (this.state.startDate === null && this.state.endDate === null);
      const paiementDate = new Date(paiement.date);
      const afterStartDate = (this.state.startDate !== null && this.state.endDate === null && paiementDate >= this.state.startDate);
      const beforeEndDate = (this.state.endDate !== null && this.state.startDate === null && paiementDate <= this.state.endDate);
      const betweenDate = (this.state.startDate !== null && this.state.endDate !== null && paiementDate >= this.state.startDate && paiementDate <= this.state.endDate);
      if (noDate || afterStartDate || beforeEndDate || betweenDate)
        dataTable.push(paiement);
    })
    return dataTable;
  }

  render() {
    const { SearchBar } = Search;
    let table = [];
    if (this.props.paiements.result.length > 0) {
      table = this.prepareData();
    }
    return (
      <div>
      <ToolkitProvider
        keyField='id' data={table}
        columns={this.columns}
        search>
        {
          props => (
            <div>
              <Row>
                <Col xs={6} sm={6} md={5} lg={4} xl={4}>
                  <SearchBar {...props.searchProps} placeholder="Référence"/>
                </Col>
                <Col xs={12} sm={12} md={7} lg={5} xl={5}>
                  <Row className="no-gutters">
                    <Col>
                      <OtoschoolDatePicker name="startDate" value={this.state.startDate} onChange={this.setStartDate}/>
                      &nbsp; au &nbsp;
                      <OtoschoolDatePicker name="endDate" value={this.state.endDate} onChange={this.setEndDate}/>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="otoschoolTable">
                <BootstrapTable {...props.baseProps }
                  pagination={paginationFactory()}
                  striped/>
              </div>
            </div>
          )
        }
      </ToolkitProvider>
      </div>
    );
  }
}
export default Encaissements;
