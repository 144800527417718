import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';

import { auth, navigate, autoecole, eleves } from './actions';

import Navigation from './components/Navigation/Navigation.jsx';
import StatusBar from './components/StatusBar/StatusBar.jsx';
import DetailCompte from './components/DetailCompte/DetailCompte.jsx';
import ReportProblem from './components/ReportProblem/ReportProblem.jsx';
import ModalItem from './components/Modal/Modal.js';
import CGU from './common/cgu.pdf';
import Privacy from './common/privacy_policy.pdf';

class PrivateRoot extends Component {
  state = {
    toggleAccount: false,
    toggleProblem: false,
    isLoading: false
  }

  componentDidMount() {
    if (!this.props.autoecole || this.props.eleves.result.length === 0) {
      this.setState({isLoading: true}, () => {
        this.props.get(this.props.autoecoleId)
        if (this.props.eleves.result.length === 0)
          this.props.getEleves(this.props.autoecoleId);
        this.setState({isLoading: false})})
    }
  }

  changeSection = (newSection) => {
      this.props.changeSection(newSection);
  }

  logOut = e => {
    this.props.logOut();
  }

  registerChange = (username) => {
    this.props.changeUserProfile(this.props.auth.user.username, {username: username});
  }

  resetPassword = e => {
    this.props.askResetPassword(this.props.auth.user.username);
  }

  toggleAccount = e => {
    this.setState({
      toggleAccount: !this.state.toggleAccount
    });
  }

  toggleProblem = e => {
    this.setState({
      toggleProblem: !this.state.toggleProblem
    });
  }

  reportProblem = (page, eleveId, problem) => {
    const data = {
      page: page,
      eleve: eleveId,
      problem: problem,
      autoecole: this.props.autoecoleId,
      user: this.props.auth.user.id
    };
    this.props.reportProblem(this.props.autoecoleId, data);
    this.toggleProblem();
  }

  answerCGU = (accepted) => {
    this.props.updateUserProfile(this.props.auth.user.profile.id, {accepted_cgu: accepted});
  }

  answerCookie = (accepted) => {
    this.props.updateUserProfile(this.props.auth.user.profile.id, {accepted_cookie: accepted});
  }

  render() {
    const mustDisplayCookie = this.props.auth.user.profile.accepted_cgu && this.props.auth.user.profile.accepted_cookie === null;
    const mustDisplayCGU = !this.props.auth.user.profile.accepted_cgu;
    return (
      <div>
        <StatusBar
          profile={this.props.auth.user.profile}
          mainPage={e => {this.changeSection("planning"); window.location.href = "#/";}}
          addEleve={e => {this.changeSection("eleves"); window.location.href = "#/eleve/";}}
          logOut={this.logOut}
          displayAccount={this.toggleAccount}
          reportProblem={this.toggleProblem}
          eleves={this.props.eleves} />
        <Navigation active={this.props.activeSection} changeSection={this.changeSection}/>
        {!this.state.isLoading ? this.props.children :
          <div className="text-center">
            <Loader type="TailSpin" color="#9ff8e7"/>
          </div>}
        <ModalItem
          modalType="large"
          modalState={this.state.toggleAccount}
          toggle={this.toggleAccount}
          title="Mon compte"
          content={<DetailCompte
            username={this.props.auth.user.username}
            registerChange={this.registerChange}
            resetPassword={this.resetPassword}
          />}
        />
        <ModalItem
          modalType="large"
          modalState={this.state.toggleProblem}
          toggle={this.toggleProblem}
          title="Reporter un problème"
          content={<ReportProblem
            reportProblem={this.reportProblem}
            eleves={this.props.eleves.result.length > 0 ? Object.values(this.props.eleves.entities.eleves) : []}
          />}
        />
        <ModalItem
          modalType="large"
          modalState={mustDisplayCookie}
          title="Utilisation des cookies sur Otoschool"
          content={
            <div>
              <p>En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de cookies ayant pour seul objet de faire fonctionner l’application Otoschool et la sécuriser.</p>
              <p>L’application Otoschool et le site Otoschool.fr n’utilisent aucun autre type de cookies, que cela soit à des fins de personnalisation de votre navigation, de réalisation de statistiques, ou  d’amélioration de l'affichage.</p>
              <p>En utilisant ce Site et en cochant la case « j’accepte », vous consentez expressément au traitement des données susvisées par Polysaas, dans les conditions et pour les seules finalités décrites au 1er alinéa ci-dessus.
              </p><p>Vous pouvez également désactiver le réglage des cookies du site et de l’application Otoschool.
              Cependant, si vous le faites, vous ne serez pas en mesure d'utiliser toutes les fonctionnalités de ladite application.
              Afin de procéder à la désactivation de ces cookies, veuillez cliquer sur le bouton "désactiver les cookies" ci-dessous.
              </p><p>Par ailleurs peuvent s’afficher des cookies tiers (utilisés par youtube) qu’il vous est possible de refuser en reparamétrant votre navigateur.
            </p><p>Pour en savoir plus sur notre politique de confidentialité, cliquez <a href={Privacy} target="_blank" rel="noopener noreferrer">ici</a></p>
            </div>
          }
          accept="Accepter"
          decline="Refuser"
          acceptAction={e => this.answerCookie(true)}
          declineAction={e => this.answerCookie(false)}
          />
        <ModalItem
          modalType="large"
          modalState={mustDisplayCGU}
          title="Vous devez accepter les CGU d'Otoschool"
          content={
            <div>
              <p>Afin de continuer à utiliser Otoschool vous devez accepter les conditions générales d'utilisations.
              </p><p>Vous pouvez les consulter <a href={CGU} target="_blank" rel="noopener noreferrer">ici</a></p>
            </div>
          }
          accept="Accepter"
          decline="Refuser"
          acceptAction={e => this.answerCGU(true)}
          declineAction={e => this.answerCGU(false)}
          />
        <br/>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    activeSection: state.navigate.activeSection,
    autoecoleId: state.auth.user.profile.auto_ecole,
    autoecole: state.autoecole,
    eleves: state.eleves
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(auth.logOut()),
    changeSection: (newSection) => dispatch(navigate.changeSection(newSection)),
    askResetPassword: (username) => dispatch(auth.askResetPassword(username)),
    changeUserProfile: (username, newProfile) => dispatch(auth.changeUserProfile(username, newProfile)),
    updateUserProfile: (profileId, newProfile) => dispatch(auth.updateUserProfile(profileId, newProfile)),
    getEleves: (autoecoleId) => dispatch(eleves.get(autoecoleId)),
    get: (autoecoleId) => dispatch(autoecole.get(autoecoleId)),
    reportProblem: (autoecoleId, problem) => dispatch(autoecole.reportProblem(autoecoleId, problem))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoot);
