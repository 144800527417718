import React, { Component } from 'react';
import { Container, Row, Col, ListGroup, ListGroupItem, Button, Input } from 'reactstrap';

import EleveProfil from './EleveProfil.jsx';
import EleveFichier from './EleveFichier.jsx';
import EleveCompte from './EleveCompte.jsx';
import EleveAutoEcole from './EleveAutoEcole.jsx';
import profil from './logo/profil.svg';
import fichier from './logo/fichier.svg';
import facture from './logo/facture.svg';
import devis from './logo/devis.svg';
import encaissement from './logo/encaissement.svg';
import planning from './logo/planning.svg';
import message from './logo/message.svg';
import examen from './logo/examen.svg';
import UploadProfilePicture from '../UploadProfilePicture/UploadProfilePicture'

class ElevePresentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCard: this.props.activeCard,
    }
    this.mapCardTab = {
      factures: "1",
      encaissement: "2",
      planning: "1",
      notes: "2",
      message: "3",
      examen: "4"
    };
  }

  activateCard = activeCard => {
    this.setState({
      activeCard: activeCard
    });
  }

  render() {
    let activeCard;
    if(this.state.activeCard === "profile")
      activeCard =
        <Col lg={9} xl={10}>
          <EleveProfil eleve={this.props.eleve}
            messages={this.props.messages} createMode={this.props.createMode}
            callbackInfo={this.props.callbackInfo}
            callbackPermis={this.props.callbackPermis}
            formules={this.props.formules}
            organismes={this.props.organismes}
            archiveEleve={this.props.archiveEleve}/>
        </Col>;
    if(this.state.activeCard === "fichiers")
      activeCard =
        <Col lg={9} xl={10}>
          <EleveFichier eleve={this.props.eleve}
          messages={this.props.messages}/>
        </Col>;
    if(this.state.activeCard === "factures" || this.state.activeCard === "devis"
    || this.state.activeCard === "encaissement")
      activeCard =
        <Col lg={9} xl={10}>
          <EleveCompte eleve={this.props.eleve}
            autoecole={this.props.autoecole}
            messages={this.props.messages}
            factures={this.props.factures}
            paiements={this.props.paiements}
            formules={this.props.formules}
            produits={this.props.produits}
            services={this.props.services}
            prestations={this.props.prestations}
            addArticles={this.props.addArticles}
            editArticles={this.props.editArticles}
            addPaiement={this.props.addPaiement}
            deletePaiement={this.props.deletePaiement}
            printFacture={this.props.printFacture}
            deleteFacture={this.props.deleteFacture}
            activeTab={this.mapCardTab[this.state.activeCard]}
            sendFacture={this.props.sendFacture}/>
        </Col>;
    if(this.state.activeCard === "planning" || this.state.activeCard === "notes"
    || this.state.activeCard === "message" || this.state.activeCard === "examen")
      activeCard =
        <Col lg={9} xl={10}>
          <EleveAutoEcole
            autoecole={this.props.autoecole}
            eleve={this.props.eleve}
            messages={this.props.messages}
            sendMessage={this.props.sendMessage}
            sendNote={this.props.sendNote}
            updateExamen={this.props.updateExamen}
            goodForExam={this.props.goodForExam}
            goodForExamHC={this.props.goodForExamHC}
            markOk={this.props.markOk}
            updateETG={this.props.updateETG}
            goodForETG={this.props.goodForETG}
            goodForETGSpecifique={this.props.goodForETGSpecifique}
            planning={this.props.planning}
            prestations={this.props.prestations}
            intervenants={this.props.intervenants}
            closeTime={this.props.closeTime}
            openTime={this.props.openTime}
            addPrestationStatut={this.props.addPrestationStatut}
            user={this.props.user}
            activeTab={this.mapCardTab[this.state.activeCard]}
            sendPlanning={this.props.sendPlanning}
            updatePaidLesson={this.props.updatePaidLesson}/>
        </Col>;

    const eleveName = `${this.props.eleve.first_name.split(' ')[0]} ${this.props.eleve.last_name}`
    console.log(this.props.user)
    const permissions = this.props.user.permissions.map((p) => p.name);
    const roles = this.props.user.roles.map((r) => r.name);
    return (
      <Container fluid>
        <h4 className="pageTitle">Élèves</h4>
        <div className="animated fadeIn profilEleve">
          <Row>
            <Col lg={3} xl={2}>
              <div className="menuEleve">
                <UploadProfilePicture
                  handleFile={this.props.callbackUpload}
                  deleteFile={this.props.callbackDelete}
                  image={this.props.eleve.profile_picture}/>
                <h4>{eleveName}</h4>
                <ListGroup>
                  <ListGroupItem tag="button" action
                    active={this.state.activeCard === "profile"}
                    onClick={() => this.activateCard("profile")}
                    className="menuEleveItem">
                    <span>
                      <img src={profil} alt="Profil"/> Profil
                    </span>
                  </ListGroupItem>
                  {/* <ListGroupItem tag="button" action
                    disabled={this.props.createMode}
                    active={this.state.activeCard === "fichiers"}
                    onClick={() => this.activateCard("fichiers")}
                    className="menuEleveItem">
                    <span>
                      <img src={fichier} alt="Fichier"/> Fichiers
                    </span>
                  </ListGroupItem> */}
                  <hr/>
                  <ListGroupItem tag="button" action
                    disabled={
                      this.props.createMode || this.props.eleve.permis.length === 0 ||
                      (!permissions.includes('factures')
                      && !permissions.includes('full_access')
                      && !permissions.includes('all')
                      && !roles.includes('Administrateur')
                    )
                    }
                    active={this.state.activeCard === "factures"}
                    onClick={() => this.activateCard("factures")}
                    className="menuEleveItem">
                    <span>
                      <img src={facture} alt="Facture"/> Facturer un forfait
                    </span>
                  </ListGroupItem>
                  {/* <ListGroupItem tag="button" action
                    disabled={this.props.createMode}
                    active={this.state.activeCard === "devis"}
                    onClick={() => this.activateCard("devis")}
                    className="menuEleveItem">
                    <span>
                      <img src={devis} alt="Devis"/> Devis
                    </span>
                  </ListGroupItem> */}
                  <ListGroupItem tag="button" action
                    disabled={this.props.createMode ||
                      (!permissions.includes('factures')
                      && !permissions.includes('full_access')
                      && !permissions.includes('all')
                      && !roles.includes('Administrateur')
                    )}
                    active={this.state.activeCard === "encaissement"}
                    onClick={() => this.activateCard("encaissement")}
                    className="menuEleveItem">
                    <span>
                      <img src={encaissement} alt="Encaissement"/> Relevé de compte
                    </span>
                  </ListGroupItem>
                  <hr/>
                  <ListGroupItem tag="button" action
                    disabled={this.props.createMode}
                    active={this.state.activeCard === "planning"}
                    onClick={() => this.activateCard("planning")}
                    className="menuEleveItem">
                    <span>
                      <img src={planning} alt="Planning"/> Planning
                    </span>
                  </ListGroupItem>
                  <ListGroupItem tag="button" action
                    disabled={this.props.createMode}
                    active={this.state.activeCard === "notes"}
                    onClick={() => this.activateCard("notes")}
                    className="menuEleveItem">
                    <span>
                      <img src={message} alt="Note"/> Notes internes
                    </span>
                  </ListGroupItem>
                  <ListGroupItem tag="button" action
                    disabled={this.props.createMode}
                    active={this.state.activeCard === "message"}
                    onClick={() => this.activateCard("message")}
                    className="menuEleveItem">
                    <span>
                      <img src={message} alt="Message"/> Message
                    </span>
                  </ListGroupItem>
                  <ListGroupItem tag="button" action
                    disabled={this.props.createMode}
                    active={this.state.activeCard === "examen"}
                    onClick={() => this.activateCard("examen")}
                    className="menuEleveItem">
                    <span>
                      <img src={examen} alt="Examen"/> Examen
                    </span>
                  </ListGroupItem>
                </ListGroup>
              </div>
            </Col>
            {activeCard}
          </Row>
        </div>
      </Container>
    );
  }
}
export default ElevePresentation;
