import {denormalizeProduct, normalizeProduct} from '../schema/article.js';
import produce from "immer";

const initialState = {
  result: [],
  entities: {products: {}}
}

export default function products(state=initialState, action) {
  switch (action.type) {
    case 'CREATE_PRODUCT_SUCCESSFUL':
      const products = denormalizeProduct(state.result, state.entities);
      products.push(action.product);
      return {...state, ...normalizeProduct(products)};
    case 'GET_PRODUCTS_SUCCESSFUL':
      return {...state, ...normalizeProduct(action.products)};
    case 'UPDATE_PRODUCT_SUCCESSFUL':
      const newState = produce(state, draftState => {
        draftState.entities.products[action.product.id] = action.product;
      });
      return {...state, ...newState};
    case 'DELETE_ARTICLE_SUCCESSFUL':
      const newStateDelete = produce(state, draftState => {
        if (draftState.result.length > 0 && action.deletedId in draftState.entities.products) {
          delete draftState.entities.products[action.deletedId];
          draftState.result.splice(draftState.result.findIndex(id => id === action.deletedId), 1);
        }
      });
      return {...state, ...newStateDelete};
    default:
      return state;
  }
}
