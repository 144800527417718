import {url} from '../properties.js';
import { saveAs } from 'file-saver';

export const getFicheVerte = (autoecoleId, dataOnly, data) => {
  return (dispatch, getState) => {
    dispatch({type: 'GENERATE_FICHE_VERTE'});
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const body = JSON.stringify(data);
    const impressionUrl = dataOnly ? '/impression/fiche-verte-data/' : '/impression/fiche-verte/';
    return fetch(url+'autoecole/'+autoecoleId+impressionUrl, {headers, body, method: "POST"})
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'DONE_FICHE_VERTE'});
          return res;
        } else {
          throw res;
        }
      })
      .then(res => res.blob())
      .then(data => {
        const file = new Blob([data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        const newWindow = window.open('/');
        newWindow.onload = () => {newWindow.location = fileURL};
      })
  }
}

export const getEnregistrement = (autoecoleId, dataOnly, data) => {
  return (dispatch, getState) => {
    dispatch({type: 'GENERATE_ENREGISTREMENT'});
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const body = JSON.stringify(data);
    const impressionUrl = dataOnly ? '/impression/enregistrement-data/' : '/impression/enregistrement/';
    return fetch(url+'autoecole/'+autoecoleId+impressionUrl, {headers, body, method: "POST"})
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'DONE_ENREGISTREMENT'});
          return res;
        } else {
          throw res;
        }
      })
      .then(res => res.blob())
      .then(data => {
        const file = new Blob([data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        const newWindow = window.open('/');
        newWindow.onload = () => {newWindow.location = fileURL};
      })
  }
}

export const getFichesVerte = (autoecoleId, dataOnly, data) => {
  return (dispatch, getState) => {
    dispatch({type: 'GENERATE_FICHE_VERTE'});
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const body = JSON.stringify(data);
    const impressionUrl = dataOnly ? '/impression/fiches-verte-data/' : '/impression/fiches-verte/';
    return fetch(url+'autoecole/'+autoecoleId+impressionUrl, {headers, body, method: "POST"})
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'DONE_FICHE_VERTE'});
          return res;
        } else {
          throw res;
        }
      })
      .then(res => res.blob())
      .then(data => {
        const file = new Blob([data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        const newWindow = window.open('/');
        newWindow.onload = () => {newWindow.location = fileURL};
      })
  }
}

export const getBordereauEtg = (autoecoleId, dataOnly, data) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const body = JSON.stringify(data);
    const impressionUrl = dataOnly ? '/impression/bordereau-etg-data/' : '/impression/bordereau-etg/';
    return fetch(url+'autoecole/'+autoecoleId+impressionUrl, {headers, body, method: "POST"})
      .then(res => {
        if (res.status === 200) {
          return res;
        } else {
          throw res;
        }
      })
      .then(res => res.blob())
      .then(data => {
        const file = new Blob([data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        const newWindow = window.open('/');
        newWindow.onload = () => {newWindow.location = fileURL};
      })
  }
}

export const getBordereauEnregistrement = (autoecoleId, dataOnly, data) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const body = JSON.stringify(data);
    const impressionUrl = dataOnly ? '/impression/bordereau-enregistrement-data/' : '/impression/bordereau-enregistrement/';
    return fetch(url+'autoecole/'+autoecoleId+impressionUrl, {headers, body, method: "POST"})
      .then(res => {
        if (res.status === 200) {
          return res;
        } else {
          throw res;
        }
      })
      .then(res => res.blob())
      .then(data => {
        const file = new Blob([data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        const newWindow = window.open('/');
        newWindow.onload = () => {newWindow.location = fileURL};
      })
  }
}

export const getBordereauExamen = (autoecoleId, dataOnly, data) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const body = JSON.stringify(data);
    const impressionUrl = dataOnly ? '/impression/bordereau-examen-data/' : '/impression/bordereau-examen/';
    return fetch(url+'autoecole/'+autoecoleId+impressionUrl, {headers, body, method: "POST"})
      .then(res => {
        if (res.status === 200) {
          return res;
        } else {
          throw res;
        }
      })
      .then(res => res.blob())
      .then(data => {
        const file = new Blob([data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        const newWindow = window.open('/');
        newWindow.onload = () => {newWindow.location = fileURL};
      })
  }
}

export const getBordereauExamenHC = (autoecoleId, dataOnly, data) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const body = JSON.stringify(data);
    const impressionUrl = dataOnly ? '/impression/bordereau-examen-hc-data/' : '/impression/bordereau-examen-hc/';
    return fetch(url+'autoecole/'+autoecoleId+impressionUrl, {headers, body, method: "POST"})
      .then(res => {
        if (res.status === 200) {
          return res;
        } else {
          throw res;
        }
      })
      .then(res => res.blob())
      .then(data => {
        const file = new Blob([data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        const newWindow = window.open('/');
        newWindow.onload = () => {newWindow.location = fileURL};
      })
  }
}

export const getBordereauSpecifique = (autoecoleId, dataOnly, data) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const body = JSON.stringify(data);
    const impressionUrl = dataOnly ? '/impression/bordereau-specifique-data/' : '/impression/bordereau-specifique/';
    return fetch(url+'autoecole/'+autoecoleId+impressionUrl, {headers, body, method: "POST"})
      .then(res => {
        if (res.status === 200) {
          return res;
        } else {
          throw res;
        }
      })
      .then(res => res.blob())
      .then(data => {
        const file = new Blob([data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        const newWindow = window.open('/');
        newWindow.onload = () => {newWindow.location = fileURL};
      })
  }
}

export const printFacture = (autoecoleId, data) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const body = JSON.stringify(data);
    const impressionUrl = '/impression/facture/';
    return fetch(url+'autoecole/'+autoecoleId+impressionUrl, {headers, body, method: "POST"})
      .then(res => {
        if (res.status === 200) {
          return res;
        } else {
          throw res;
        }
      })
      .then(res => res.blob())
      .then(data => {
        const file = new Blob([data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        const newWindow = window.open('/');
        newWindow.onload = () => {newWindow.location = fileURL};
      })
  }
}

export const getPlanning = (autoecoleId, data) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const body = JSON.stringify(data);
    const impressionUrl = '/impression/planning/';
    return fetch(url+'autoecole/'+autoecoleId+impressionUrl, {headers, body, method: "POST"})
      .then(res => {
        if (res.status === 200) {
          return res;
        } else {
          throw res;
        }
      })
      .then(res => res.blob())
      .then(data => {
        const file = new Blob([data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        const newWindow = window.open('/');
        newWindow.onload = () => {newWindow.location = fileURL};
      })
  }
}

export const getBordereauEmpty = (autoecoleId, bordereau) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const impressionUrl = '/impression/bordereau-' + bordereau + '-empty/';
    return fetch(url+'autoecole/'+autoecoleId+impressionUrl, {headers, method: "POST"})
      .then(res => {
        if (res.status === 200) {
          return res;
        } else {
          throw res;
        }
      })
      .then(res => res.blob())
      .then(data => {
        const file = new Blob([data], {type: 'application/pdf'});
        const fileURL = URL.createObjectURL(file);
        const newWindow = window.open('/');
        newWindow.onload = () => {newWindow.location = fileURL};
      })
  }
}
