import React, { PureComponent } from 'react';
import {Container, Nav, Col, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';

import ETGPresentation from './ETG.jsx';
import ExamenPresentation from './Examen.jsx';
import ExamenHCPresentation from './ExamenHC.jsx';
import FicheVertePresentation from './FicheVerte.jsx';
import ETGSpecifiquePresentation from './ETGSpecifique';
import EnregistrementPresentation from './Enregistrement.jsx';

class ExamensPresentation extends PureComponent {
  state = {
    activeTab: "1"
  }

  activateTab = (tabToActivate) => {
    this.setState({
      activeTab: tabToActivate
    });
  }

  render() {
    return (
      <Container fluid>
        <h4 className="pageTitle">Examens</h4>
        <Nav tabs className="no-gutters otoschoolNavTabs">
          <Col xl={2}>
            <NavItem className="otoschoolTab">
              <NavLink
                active={this.state.activeTab === "1"}
                onClick={() => { this.activateTab("1"); }}>
                <h4>Fiche verte</h4>
              </NavLink>
            </NavItem>
          </Col>
          <Col xl={2}>
            <NavItem className="otoschoolTab">
              <NavLink
                active={this.state.activeTab === "6"}
                onClick={() => { this.activateTab("6"); }}>
                <h4>Enregistrement</h4>
              </NavLink>
            </NavItem>
          </Col>
          <Col xl={2}>
            <NavItem className="otoschoolTab">
              <NavLink
                active={this.state.activeTab === "2"}
                onClick={() => { this.activateTab("2"); }}>
                <h4>ETG</h4>
              </NavLink>
            </NavItem>
          </Col>
          <Col xl={2}>
            <NavItem className="otoschoolTab">
              <NavLink
                active={this.state.activeTab === "3"}
                onClick={() => { this.activateTab("3"); }}>
                <h4>Épreuve pratique</h4>
              </NavLink>
            </NavItem>
          </Col>
          <Col xl={2}>
            <NavItem className="otoschoolTab">
              <NavLink
                active={this.state.activeTab === "4"}
                onClick={() => { this.activateTab("4"); }}>
                <h4>Examen spécifique</h4>
              </NavLink>
            </NavItem>
          </Col>
          <Col xl={2}>
            <NavItem className="otoschoolTab">
              <NavLink
                active={this.state.activeTab === "5"}
                onClick={() => { this.activateTab("5"); }}>
                <h4>Épreuve pratique (hors circulation)</h4>
              </NavLink>
            </NavItem>
          </Col>
        </Nav>
        <div className="animated fadeIn">
          <TabContent activeTab={this.state.activeTab} className="otoschoolTabContent">
            <TabPane tabId="1">
              <FicheVertePresentation
                eleves={this.props.eleves}
                bordereaux={this.props.bordereaux}
                deleteBordereau={this.props.deleteBordereau}
                createBordereau={this.props.createBordereau}
                impressionFicheVerte={this.props.impressionFicheVerte}
              />
            </TabPane>
            <TabPane tabId="6">
              <EnregistrementPresentation
                eleves={this.props.eleves}
                intervenants={this.props.intervenants}
                bordereaux={this.props.bordereaux}
                deleteBordereau={this.props.deleteBordereau}
                createBordereau={this.props.createBordereau}
                impressionEnregistrement={this.props.impressionEnregistrement}
                archiveBordereau={this.props.archiveBordereau}
              />
            </TabPane>
            <TabPane tabId="2">
              <ETGPresentation
                bons={this.props.bons}
                eleves={this.props.eleves}
                intervenants={this.props.intervenants}
                bordereaux={this.props.bordereaux}
                deleteBordereau={this.props.deleteBordereau}
                createBordereau={this.props.createBordereau}
                impressionETG={this.props.impressionETG}
                archiveBordereau={this.props.archiveBordereau}
                deleteBon={this.props.deleteBon}
              />
            </TabPane>
            <TabPane tabId="3">
              <ExamenPresentation
                bons={this.props.bons}
                eleves={this.props.eleves}
                intervenants={this.props.intervenants}
                bordereaux={this.props.bordereaux}
                deleteBordereau={this.props.deleteBordereau}
                createBordereau={this.props.createBordereau}
                impressionExamen={this.props.impressionExamen}
                archiveBordereau={this.props.archiveBordereau}
                deleteBon={this.props.deleteBon}
              />
            </TabPane>
            <TabPane tabId="4">
              <ETGSpecifiquePresentation
                bons={this.props.bons}
                eleves={this.props.eleves}
                intervenants={this.props.intervenants}
                bordereaux={this.props.bordereaux}
                deleteBordereau={this.props.deleteBordereau}
                createBordereau={this.props.createBordereau}
                impressionETGSpecifique={this.props.impressionETGSpecifique}
                archiveBordereau={this.props.archiveBordereau}
                deleteBon={this.props.deleteBon}
              />
            </TabPane>
            <TabPane tabId="5">
              <ExamenHCPresentation
                bons={this.props.bons}
                eleves={this.props.eleves}
                intervenants={this.props.intervenants}
                bordereaux={this.props.bordereaux}
                deleteBordereau={this.props.deleteBordereau}
                createBordereau={this.props.createBordereau}
                impressionExamen={this.props.impressionHC}
                archiveBordereau={this.props.archiveBordereau}
                deleteBon={this.props.deleteBon}
              />
            </TabPane>
          </TabContent>
        </div>
      </Container>
    );
  }
}
export default ExamensPresentation;
