import React, { Component } from 'react';
import {Col, Nav, NavLink, NavItem, TabContent, TabPane} from 'reactstrap';

import ElevePlanning from './ElevePlanning.jsx';
import EleveNotes from './EleveNotes.jsx';
import EleveMessage from './EleveMessage.jsx';
import EleveExamen from './EleveExamen.jsx';

class EleveAutoEcole extends Component {
  state = {
    activeTab: this.props.activeTab,
  }

  activateTab = activeTab => {
    this.setState({
      activeTab: activeTab
    });
  }

  componentDidUpdate(prevProps) {
    if(this.props.activeTab !== prevProps.activeTab)
        this.activateTab(this.props.activeTab);
  }

  render () {
    let permis = this.props.eleve.permis[0];
    if (permis === undefined)
      permis = this.props.permis;
    else
      permis.default = false;
    return (
      <div className="otoschoolCard">
      <Nav tabs className="no-gutters otoschoolNavTabs">
        <Col xl={3}>
            <NavItem className="otoschoolTab">
              <NavLink
                active={this.state.activeTab === "1"}
                onClick={() => { this.activateTab("1"); }}>
                <h4>Planning</h4>
              </NavLink>
            </NavItem>
          </Col>
          <Col xl={3}>
            <NavItem className="otoschoolTab">
              <NavLink
                active={this.state.activeTab === "2"}
                onClick={() => { this.activateTab("2"); }}>
                <h4>Notes internes</h4>
              </NavLink>
            </NavItem>
          </Col>
          <Col xl={3}>
            <NavItem className="otoschoolTab">
              <NavLink
                active={this.state.activeTab === "3"}
                onClick={() => { this.activateTab("3"); }}>
                <h4>Message</h4>
              </NavLink>
            </NavItem>
          </Col>
          <Col xl={3}>
            <NavItem className="otoschoolTab">
              <NavLink
                active={this.state.activeTab === "4"}
                onClick={() => { this.activateTab("4"); }}>
                <h4>Examen</h4>
              </NavLink>
            </NavItem>
          </Col>
        </Nav>
        <TabContent activeTab={this.state.activeTab} className="otoschoolTabContent">
          <TabPane tabId="1">
            <ElevePlanning
              autoecole={this.props.autoecole}
              eleve={this.props.eleve}
              permis={permis}
              planning={this.props.planning}
              prestations={this.props.prestations}
              intervenants={this.props.intervenants}
              closeTime={this.props.closeTime}
              openTime={this.props.openTime}
              addPrestationStatut={this.props.addPrestationStatut}
              sendPlanning={this.props.sendPlanning}
              updatePaidLesson={this.props.updatePaidLesson}/>
          </TabPane>
          <TabPane tabId="2">
            <EleveNotes
              note={this.props.eleve.note}
              sendNote={this.props.sendNote}
              user={this.props.user}
            />
          </TabPane>
          <TabPane tabId="3">
            <EleveMessage
              sendMessage={this.props.sendMessage} messages={this.props.messages} />
          </TabPane>
          <TabPane tabId="4">
            <EleveExamen
              permis={permis}
              updateExamen={this.props.updateExamen}
              goodForExam={this.props.goodForExam}
              goodForExamHC={this.props.goodForExamHC}
              markOk={this.props.markOk}
              updateETG={this.props.updateETG}
              goodForETG={this.props.goodForETG}
              goodForETGSpecifique={this.props.goodForETGSpecifique}
              disable={permis.default}
             />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

EleveAutoEcole.defaultProps = {
  permis: {
    is_foreigner: false,
    lost_license: false,
    etg_ready: false,
    etg_done: false,
    paid: false,
    ready: false,
    category: false,
    visitMed: false,
    age: false,
    default: true
  },
};
export default EleveAutoEcole;
