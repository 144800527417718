import React, { PureComponent } from 'react';

import {Row, Col, Button } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import {priceFormatter} from '../../../common/formatter.js';

export default class ServicesTable extends PureComponent {
  constructor(props) {
    super(props);
    this.columns = [
      {dataField: 'id', hidden: true},
      {dataField: 'name', text: 'Services', sort: true},
      {dataField: 'price', text: 'Tarif', sort: true, formatter: priceFormatter},
      {dataField: 'tva', text: 'TVA', sort: true},
      {dataField: 'cps', text: 'CPS', sort: true},
      {dataField: 'montantTTC', text: 'Montant TTC', sort: true, formatter: priceFormatter},
      {dataField: 'actions', text: 'Actions'},
    ];
  }

  prepareData = () => {
    const keys = this.props.services.result;
    const dataTable = [];
    keys.forEach(serviceId => {
      const service = Object.assign({}, this.props.services.entities.services[serviceId]);
      service.data = {}
      service.data.id = service.id;
      service.data.tva = service.tva.id;
      service.data.price = service.montantTTC.toFixed(0);
      service.data.name = service.article.name;
      service.tva = service.tva.tva_value + " %";
      service.cps = service.cps.tva_value + " %";
      service.name = service.article.name;
      service.price = Number(service.price).toFixed(0);
      service.montantTTC = Number(service.montantTTC).toFixed(0);
      service.actions =
      <div actions="actions">
        <Button color="success" className="ml-lg-2 ml-xl-3" onClick={() => {this.props.edit(service.data)}}><i className="fa fa-edit"></i></Button>
        <Button color="danger" className="ml-lg-2 ml-xl-3" onClick={() => {this.props.delete(serviceId)}}><i className="fa fa-trash-o"></i></Button>
      </div>;
      dataTable.push(service);
    })
    return dataTable;
  }

  render() {
    const { SearchBar } = Search;
    let table = [];
    if (this.props.services.result.length > 0) {
      table = this.prepareData();
    }
    return (
      <ToolkitProvider
        keyField='id' data={table}
        columns={this.columns}
        search>
        {
          props => (
            <div>
              <Row>
                <Col xs={6} sm={6} md={5} lg={4} xl={4}>
                  <SearchBar {...props.searchProps} placeholder="Recherche"/>
                </Col>
              </Row>
              <div className="otoschoolTable">
                <BootstrapTable {...props.baseProps }
                  pagination={paginationFactory()}
                  striped/>
              </div>
            </div>
          )
        }
      </ToolkitProvider>
    );
  }
}
