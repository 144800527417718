import {denormalizeVehicule, normalizeVehicule} from '../schema/vehicule.js';
import produce from "immer";

const initialState = {
  result: [],
  entities: {vehicules: {}}
}

export default function vehicules(state=initialState, action) {
  switch (action.type) {
    case 'GET_VEHICULES_SUCCESSFUL':
      return {...state, ...normalizeVehicule(action.vehicules)};
    case 'UPDATE_VEHICULE_SUCCESSFUL':
      const newState = produce(state, draftState => {
        draftState.entities.vehicules[action.vehicule.id] = action.vehicule;
      });
      return {...state, ...newState};
    case 'CREATE_VEHICULE_SUCCESSFUL':
      const vehicules = denormalizeVehicule(state.result, state.entities);
      vehicules.push(action.vehicule);
      return {...state, ...normalizeVehicule(vehicules)};
    case 'DELETE_VEHICULE_SUCCESSFUL':
      const newStateDelete = produce(state, draftState => {
        if (draftState.result.length > 0 && action.id in draftState.entities.vehicules) {
          delete draftState.entities.vehicules[action.id];
          draftState.result.splice(draftState.result.findIndex(id => id === action.id), 1);
        }
      });
      return {...state, ...newStateDelete};
    default:
      return state;
  }
}
