import React, { Component } from 'react';
import { Provider, connect } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import otoschool from './reducers'
import { auth, navigate } from './actions';

import Notifications from './components/Notifications/Notifications.jsx';
import AppRouter from './Routes.jsx';

let store = createStore(otoschool, applyMiddleware(thunk));

class RootComponent extends Component {
  componentDidMount() {
    this.props.loadUser();
    document.title = "otoschool.fr"
  }

  render() {
    return (
      <div>
        <AppRouter />
        <Notifications />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    activeSection: state.navigate.activeSection,
    autoecoleId: state.auth.user.profile.auto_ecole
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadUser: () => dispatch(auth.loadUser()),
    logOut: () => dispatch(auth.logOut()),
    changeSection: (newSection) => dispatch(navigate.changeSection(newSection))
  }
}

let Root = connect(mapStateToProps, mapDispatchToProps)(RootComponent);

export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Root />
      </Provider>
    )
  }
}
