import React, { Component } from 'react';

import {Input, InputGroup, Label, Button, Col} from 'reactstrap';

import OtoschoolDatePicker from '../../OtoschoolDatePicker/OtoschoolDatePicker.jsx';

import moment from 'moment';

class Vehicule extends Component {
  state = {
    modele: this.props.vehicule.modele,
    techControl: this.props.vehicule.tech_control_date,
    licensePlate: this.props.vehicule.license_plate,
    id: this.props.vehicule.id,
  }

  static getDerivedStateFromProps(props, state) {
    if (props.vehicule.id !== state.id) {
      return {
        techControl: props.vehicule.tech_control_date,
        modele: props.vehicule.modele,
        licensePlate: props.vehicule.license_plate,
        id: props.vehicule.id,
      };
    }
    return null;
  }

  handleChangeTechControl = (e) => {
    this.setState({ techControl: e.target.value });
  }

  handleChangeLicensePlate = (e) => {
    this.setState({ licensePlate: e.target.value });
  }

  handleChangeModele = (e) => {
    this.setState({ modele: e.target.value });
  }

  delete = () => {
    this.props.delete(this.props.vehicule)
  }

  submit = (e) => {
    if (this.state.techControl !== null && this.state.techControl !== undefined
      && this.state.licensePlate !== '' && this.state.modele !== '') {
      const vehicule = {
        tech_control_date: moment(this.state.techControl).format('YYYY-MM-DD'),
        license_plate: this.state.licensePlate,
        modele: this.state.modele
      };
      const create = (this.props.vehicule.id === null);
      if (!create)
        vehicule['id'] = this.props.vehicule.id;
      this.props.createOrUpdate(vehicule, create);
      if (create)
        this.setState({
          techControl: null,
          licensePlate: '',
          modele: ''
        });
    }
  }

  render() {
    return (
      <div className="otoschoolCard vehicule">
        <InputGroup>
          <Label xs={12} sm={6}>Modele</Label>
          <Input type="text" value={this.state.modele} onChange={this.handleChangeModele} onBlur={this.handleChangeModele}/>
        </InputGroup>
        <InputGroup>
          <Label xs={12} sm={6}>Plaque d'immatriculation</Label>
          <Input type="text" value={this.state.licensePlate} onChange={this.handleChangeLicensePlate} onBlur={this.handleChangeLicensePlate}/>
        </InputGroup>
        <InputGroup>
          <Label xs={12} sm={6}>Date du prochain controle technique</Label>
          <Col xs={12} sm={6} className="p-0">
            <OtoschoolDatePicker value={this.state.techControl} onChange={this.handleChangeTechControl} />
          </Col>
        </InputGroup>
        <Col className="text-center">
          {this.props.vehicule.id ?
            <div>
              <Button className="colorButton" onClick={this.submit}>
                Modifier
              </Button>
              <Button className="deleteButton" onClick={this.delete}>
                Supprimer
              </Button>
            </div>
            :
              <Button className="colorButton" onClick={this.submit}>
                Enregistrer
              </Button>}
        </Col>
      </div>
    );
  }
}

Vehicule.defaultProps = {
  vehicule: {
    tech_control_date: null,
    license_plate: '',
    modele: '',
    id: null
  }
}

export default Vehicule;
