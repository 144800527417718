import React, { Component } from 'react';
import {Container, InputGroup, Label, Input, Col, Row} from 'reactstrap';

import AjoutHeurePlanning from '../Planning/AjoutHeurePlanning.jsx';
import OtoschoolDatePicker from '../OtoschoolDatePicker/OtoschoolDatePicker.jsx';

class EleveAjoutHeure extends Component {
  state = {
    date: new Date(),
    hours: '00:00',
    completeDate: new Date(),
  }

  updateDate = (event) => {
    const date = event.target.value;
    const hours_minutes = this.state.hours.split(':');
    const completeDate = date;
    completeDate.setHours(Number(hours_minutes[0]), Number(hours_minutes[1]));
    this.setState({
      date: date,
      completeDate: completeDate
    });
  }

  updateHours = (event) => {
    const hours = event.target.value;
    const hours_minutes = hours.split(':');
    const completeDate = this.state.date;
    completeDate.setHours(Number(hours_minutes[0]), Number(hours_minutes[1]));
    this.setState({
      hours: hours,
      completeDate: completeDate
    });
  }

  render() {
    return (
      <div>
        <Container fluid>
          <Row>
          <Col xs={12} sm={6}>
          <InputGroup>
            <Label xs={2}>Le </Label>
            <Col>
              <OtoschoolDatePicker onChange={this.updateDate} value={this.state.date} />
            </Col>
          </InputGroup>
          </Col>
          <Col xs={12} sm={6}>
          <InputGroup>
            <Label xs={2}>à</Label>
            <Input type="time" onChange={this.updateHours} onBlur={this.updateHours} value={this.state.hours}/>
          </InputGroup>
          </Col>
        </Row>
        </Container>
        <hr />
        <AjoutHeurePlanning
          event={{date: this.state.completeDate}}
          intervenants={this.props.intervenants}
          prestations={this.props.prestations.entities.prestations}
          eleves={[this.props.eleve]}
          minTime={this.props.openTime}
          maxTime={this.props.closeTime}
          hide={this.props.hide}
          add={this.props.addEvent}
        />
    </div>
  );
  }
}

export default EleveAjoutHeure;
