import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner';

import { eleve, articles, organisme, facture, paiement, planning, impression } from '../../actions';

import ElevePresentation from './ElevePresentation.jsx';

class Eleve extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeCard: this.props.match.params.location ? this.props.match.params.location : "profile",
      loading: true,
      profilePicture: null,
    };
  }

  componentDidMount() {
    this.onRouteChanged();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location)
      this.onRouteChanged();
  }

  onRouteChanged() {
    if (this.props.match.path !== '/eleve/') {
      const eleveId = this.props.match ? this.props.match.params.eleveId : this.props.eleveId;
      this.setState({loading: true}, () => {
        Promise.all([
          this.props.get(this.props.autoecoleId, eleveId),
          this.props.getFactures(this.props.autoecoleId, eleveId),
          this.props.getPaiements(this.props.autoecoleId, eleveId),
          this.props.getMessages(this.props.autoecoleId, eleveId),
          this.props.getPrestationsStatut(this.props.autoecoleId, eleveId),
          this.props.getOrganismes(this.props.autoecoleId),
          this.props.getArticles(this.props.autoecoleId)
        ]).then(
          () => this.setState({loading: false}));
      });
      this.props.show(eleveId);
    } else {
      this.setState({loading: false})
    }
  }

  actionEleve = (eleveData) => {
    if(this.props.eleveId in this.props.eleves)
      this.props.update(this.props.autoecoleId, this.props.eleveId, eleveData);
    else {
      if (this.state.profilePicture)
        eleveData.profilePicture = this.state.profilePicture;
      this.props.create(this.props.autoecoleId, eleveData)
      this.setState({
        profilePicture: null
      });
    }
  }

  actionElevePermis = (permis, index) => {
    if (this.props.eleveId in this.props.eleves) {
      const eleve = this.props.eleve;
      if (eleve.permis.length <= index) {
        this.props.createPermis(this.props.autoecoleId, this.props.eleveId, permis);
      }
      else {
        this.props.updatePermis(this.props.autoecoleId, this.props.eleveId, permis.id, permis);
      }
    }
    else
      console.log('eleveid not in eleves');
  }

  actionUpload = (file) => {
    // Upload file if eleve exists
    if(this.props.eleveId in this.props.eleves)
      this.props.uploadProfilePicture(this.props.autoecoleId, this.props.eleveId, file)
    else
      this.setState({
        profilePicture: file
      });
  }

  deleteProfilePicture = () => {
    if (this.props.eleveId in this.props.eleves)
      this.props.deleteProfilePicture(this.props.autoecoleId, this.props.eleveId);
  }

  addArticles = (data) => {
    if (this.props.eleveId in this.props.eleves)
      this.props.addFacture(this.props.autoecoleId, this.props.eleveId, data);
  }

  editArticles = (data) => {
    if (this.props.eleveId in this.props.eleves)
      this.props.updateFacture(this.props.autoecoleId, this.props.eleveId, data);
  }

  deleteFacture = id => {
    if (this.props.eleveId in this.props.eleves)
      this.props.deleteFacture(this.props.autoecoleId, this.props.eleveId, id);
  }

  addPaiement = (data) => {
    if (this.props.eleveId in this.props.eleves)
      this.props.addPaiement(this.props.autoecoleId, this.props.eleveId, data);
  }

  deletePaiement = (factureId, paiementId) => {
    if (this.props.eleveId in this.props.eleves)
      this.props.deletePaiement(this.props.autoecoleId, this.props.eleveId, factureId, paiementId);
  }

  sendMessage = (message) => {
    if (this.props.eleveId in this.props.eleves)
      this.props.sendMessage(this.props.autoecoleId, this.props.eleveId, message);
  }

  sendNote = (note) => {
    if (this.props.eleveId in this.props.eleves)
      this.props.sendNote(this.props.autoecoleId, this.props.eleveId, note);
  }

  updateExamen = (permisId, newDate) => {
    if (this.props.eleveId in this.props.eleves)
      this.props.updateExamen(this.props.autoecoleId, this.props.eleveId, permisId, newDate);
  }

  updateETG = (permisId, newDate) => {
    if (this.props.eleveId in this.props.eleves)
      this.props.updateETG(this.props.autoecoleId, this.props.eleveId, permisId, newDate);
  }

  goodForETG = (permisId) => {
    if (this.props.eleveId in this.props.eleves)
      this.props.goodForETG(this.props.autoecoleId, this.props.eleveId, permisId);
  }

  goodForETGSpecifique = (permisId) => {
    if (this.props.eleveId in this.props.eleves)
      this.props.goodForETGSpecifique(this.props.autoecoleId, this.props.eleveId, permisId);
  }

  goodForExam = (permisId) => {
    if (this.props.eleveId in this.props.eleves)
      this.props.goodForExam(this.props.autoecoleId, this.props.eleveId, permisId);
  }

  goodForExamHC = (permisId) => {
    if (this.props.eleveId in this.props.eleves)
      this.props.goodForExamHC(this.props.autoecoleId, this.props.eleveId, permisId);
  }

  markOk = (permisId, piece) => {
    if (this.props.eleveId in this.props.eleves)
      this.props.markOk(this.props.autoecoleId, this.props.eleveId, permisId, piece);
  }

  addPrestationStatut = (data) => {
    if (this.props.eleveId in this.props.eleves)
      this.props.addPrestationStatut(this.props.autoecoleId, this.props.eleveId, data);
  }

  archiveEleve = (eleveId, data) => {
    this.props.archiveEleve(this.props.autoecoleId, eleveId, data);
  }

  componentWillUnmount() {
    this.props.show(null);
  }

  render() {
    const eleve = this.props.match.path !== '/eleve/' ? this.props.eleve : Eleve.defaultProps.eleve;
    return (
        <div>
          {this.state.loading ?
            <div className="text-center">
              <Loader type="TailSpin" color="#9ff8e7"/>
            </div>
             :
            <ElevePresentation
              eleve={eleve}
              factures={this.props.factures}
              paiements={this.props.paiements}
              formules={this.props.formules}
              produits={this.props.produits}
              services={this.props.services}
              prestations={this.props.prestations}
              organismes={this.props.organismes}
              messages={this.props.messages}
              createMode={eleve.hasOwnProperty('first_names')}
              callbackInfo={this.actionEleve}
              callbackPermis={this.actionElevePermis}
              callbackUpload={this.actionUpload}
              callbackDelete={this.deleteProfilePicture}
              addArticles={this.addArticles}
              editArticles={this.editArticles}
              addPaiement={this.addPaiement}
              deletePaiement={this.deletePaiement}
              sendMessage={this.sendMessage}
              sendNote={this.sendNote}
              updateExamen={this.updateExamen}
              goodForExam={this.goodForExam}
              goodForExamHC={this.goodForExamHC}
              markOk={this.markOk}
              updateETG={this.updateETG}
              goodForETG={this.goodForETG}
              goodForETGSpecifique={this.goodForETGSpecifique}
              planning={this.props.prestationsStatut}
              autoecole={this.props.autoecole}
              intervenants={this.props.autoecole.intervenants}
              closeTime={this.props.autoecole.horaires[1].end}
              openTime={this.props.autoecole.horaires[0].start}
              addPrestationStatut={this.addPrestationStatut}
              user={this.props.user}
              printFacture={this.props.printFacture}
              deleteFacture={this.deleteFacture}
              activeCard={this.state.activeCard}
              archiveEleve={this.archiveEleve}
              sendPlanning={this.props.sendPlanning}
              sendFacture={this.props.sendFacture}
              updatePaidLesson={this.props.updatePaidLesson}
            />
        }
        </div>
      );
    }
}

Eleve.defaultProps = {
  eleve: {
    first_names: [""],
    first_name: "",
    last_name: "",
    last_name_use: "",
    email: "",
    permis: [],
    dob: null,
    id_card_expire_time: null,
    mobile_phone: "",
    phone: "",
    postal_code: "",
    city: "",
    city_birth: "",
    blood_group: "",
    rhesus: "",
    country:"PF",
    civility: "M",
    is_giver: "false",
    kilometer_points: "",
    street_number: "",
    street: "",
    servitude: "",
    district: "",
    building: "",
    allotment: "",
    town: "",
    side: "",
    postal_box: "",
    address_bis: "",
    town_section: "",
    department: "TAHITI",
    registration_date: new Date()
  },
  eleves: [],
  eleveId: undefined
};

const mapStateToProps = state => {
  return {
    eleveId: state.eleve.eleveId,
    eleve: state.eleve.eleve,
    autoecoleId: state.auth.user.profile.auto_ecole,
    autoecole: state.autoecole.autoecole,
    eleves: state.eleves.entities.eleves,
    formules: state.formules,
    produits: state.products,
    prestations: state.prestations,
    services: state.services,
    organismes: state.organismes,
    factures: state.factures,
    paiements: state.paiements,
    prestationsStatut: state.prestationsStatut,
    messages: state.messages,
    user: state.auth.user.profile
  };
}

const mapDispatchToProps = dispatch => {
  return {
    show: (eleveId) => dispatch(eleve.show(eleveId)),
    get: (autoecoleId, eleveId) => dispatch(eleve.get(autoecoleId, eleveId)),
    create: (autoecoleId, values) => dispatch(eleve.create(autoecoleId, values)).then(
      (eleve) => {
        if (eleve.id !== undefined)
          window.location.href = "#/eleve/" + eleve.id;
      }
    ),
    update: (autoecoleId, eleveId, values) => dispatch(eleve.update(autoecoleId, eleveId, values)),
    createPermis: (autoecoleId, eleveId, values) => dispatch(eleve.createPermis(autoecoleId, eleveId, values)).then(
      () => dispatch(eleve.get(autoecoleId, eleveId)).then(
        () => dispatch(facture.getFacturesEleve(autoecoleId, eleveId))
      )
    ),
    updatePermis: (autoecoleId, eleveId, permisId, values) => dispatch(eleve.updatePermis(autoecoleId, eleveId, permisId, values)).then(
      () => dispatch(eleve.get(autoecoleId, eleveId)).then(
        () => dispatch(facture.getFacturesEleve(autoecoleId, eleveId))
      )
    ),
    getArticles: (autoecoleId) => dispatch(articles.getArticles(autoecoleId)),
    getOrganismes: (autoecoleId) => dispatch(organisme.getOrganismes(autoecoleId)),
    getFactures: (autoecoleId, eleveId) => dispatch(facture.getFacturesEleve(autoecoleId, eleveId)),
    addFacture: (autoecoleId, eleveId, data) =>
      dispatch(facture.addFacture(autoecoleId, data)).then(
          () => dispatch(eleve.get(autoecoleId, eleveId))
          ),
    deleteFacture: (autoecoleId, eleveId, factureId) =>
      dispatch(facture.deleteFacture(autoecoleId, factureId)).then(
        () => dispatch(eleve.get(autoecoleId, eleveId))
    ),
    updateFacture: (autoecoleId, eleveId, data) =>
      dispatch(facture.addFacture(autoecoleId, data)).then(
        () => dispatch(eleve.get(autoecoleId, eleveId)).then(
          () => dispatch(facture.getFacturesEleve(autoecoleId, eleveId))
        )
      ),
    addPaiement: (autoecoleId, eleveId, data) =>
      dispatch(paiement.addPaiement(autoecoleId, data)).then(
          () => dispatch(eleve.get(autoecoleId, eleveId))
      ),
    deletePaiement: (autoecoleId, eleveId, factureId, paiementId) =>
      dispatch(paiement.deletePaiement(autoecoleId, paiementId)).then(
        () => dispatch(facture.getFacture(autoecoleId, factureId))
      ),
    getPaiements: (autoecoleId, eleveId) => dispatch(paiement.getPaiementsEleve(autoecoleId, eleveId)),
    sendMessage: (autoecoleId, eleveId, message) => dispatch(eleve.sendMessage(autoecoleId, eleveId, message)),
    getMessages: (autoecoleId, eleveId) => dispatch(eleve.getMessages(autoecoleId, eleveId)),
    sendNote: (autoecoleId, eleveId, note) => dispatch(eleve.sendNote(autoecoleId, eleveId, note)).then(
      () => dispatch(eleve.get(autoecoleId, eleveId))
    ),
    updateETG: (autoecoleId, eleveId, permisId, values) => dispatch(eleve.updateETG(autoecoleId, eleveId, permisId, values)).then(
      () => dispatch(eleve.get(autoecoleId, eleveId))
    ),
    updateExamen: (autoecoleId, eleveId, permisId, values) => dispatch(eleve.updateExamen(autoecoleId, eleveId, permisId, values)).then(
      () => dispatch(eleve.get(autoecoleId, eleveId))
    ),
    goodForExam: (autoecoleId, eleveId, permisId) => dispatch(eleve.goodForExam(autoecoleId, eleveId, permisId)).then(
      () => dispatch(eleve.get(autoecoleId, eleveId))
    ),
    goodForExamHC: (autoecoleId, eleveId, permisId) => dispatch(eleve.goodForExamHC(autoecoleId, eleveId, permisId)).then(
      () => dispatch(eleve.get(autoecoleId, eleveId))
    ),
    goodForETG: (autoecoleId, eleveId, permisId) => dispatch(eleve.goodForETG(autoecoleId, eleveId, permisId)).then(
      () => dispatch(eleve.get(autoecoleId, eleveId))
    ),
    goodForETGSpecifique: (autoecoleId, eleveId, permisId) => dispatch(eleve.goodForETGSpecifique(autoecoleId, eleveId, permisId)).then(
      () => dispatch(eleve.get(autoecoleId, eleveId))
    ),
    markOk: (autoecoleId, eleveId, permisId, piece) => dispatch(eleve.markOk(autoecoleId, eleveId, permisId, piece)).then(
      () => dispatch(eleve.get(autoecoleId, eleveId))
    ),
    getPrestationsStatut: (autoecoleId, eleveId) => dispatch(planning.getPrestationsStatutEleve(autoecoleId, eleveId)),
    addPrestationStatut: (autoecoleId, eleveId, data) => dispatch(planning.addPrestationStatut(autoecoleId, data)),
    archiveEleve: (autoecoleId, eleveId, data) => dispatch(eleve.archiveEleve(autoecoleId, eleveId, data)).then(
      () => dispatch(eleve.get(autoecoleId, eleveId))
    ),
    printFacture: (autoecoleId, data) => dispatch(impression.printFacture(autoecoleId, data)),
    sendPlanning: (autecoleId, eleveId, data) => dispatch(eleve.sendPlanning(autecoleId, eleveId, data)),
    sendFacture: (autoecoleId, factureId) => dispatch(eleve.sendFacture(autoecoleId, factureId)),
    updatePaidLesson: (autoecoleId, eleveId, lessonId, paid) => dispatch(eleve.updatePaidLesson(autoecoleId, eleveId, lessonId, paid)).then(
      () => dispatch(planning.getPrestationsStatutEleve(autoecoleId, eleveId))
    ),
    uploadProfilePicture: (autoecoleId, eleveId, picture) => dispatch(eleve.uploadProfilePicture(autoecoleId, eleveId, picture)),
    deleteProfilePicture: (autoecoleId, eleveId) => dispatch(eleve.deleteProfilePicture(autoecoleId, eleveId))
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Eleve));
