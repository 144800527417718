import { normalize, denormalize, schema } from 'normalizr';

const bon = new schema.Entity('bons');
const bonSchema = [ bon ];

export const normalizeBon = (data) => {
  return normalize(data, bonSchema);
}

export const denormalizeBon = (data, entities) => {
  return denormalize(data, [bon], entities);
}
