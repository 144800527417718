import React, { Component } from 'react';
import { Button, Input, Col, Row, InputGroup, InputGroupAddon } from 'reactstrap';

import styles from './Article.module.css';


const computeDiscount = (price, discount) => {
  return price - price * discount / 100;
}

class Formule extends Component {
  state = {
    offres: this.props.formule.articles,
    montantHTND: this.props.formule.articles.length > 0 ? this.props.formule.articles.map((offre) => Number(offre.article_price)).reduce((sum, value) => sum+value) : 0,
    montantTTCND: this.props.formule.articles.length > 0 ? this.props.formule.articles.map((offre) => Number(offre.article_montantTTC)).reduce((sum, value) => sum+value) : 0,
    discount: Number(this.props.formule.discount),
    montantHT: computeDiscount(
      this.props.formule.articles.length > 0 ? this.props.formule.articles.map(
        (offre) => Number(offre.article_price)).reduce((sum, value) => sum+value) : 0,
        Number(this.props.formule.discount)),
    montantTTC: computeDiscount(
      this.props.formule.articles.length > 0 ? this.props.formule.articles.map(
        (offre) => Number(offre.article_montantTTC)).reduce((sum, value) => sum+value) : 0,
        Number(this.props.formule.discount)),
    name: this.props.formule.name,
    quantity: 0,
    selected: null
  }

  handleQtyChange = (e) => {
    if (e.target.value !== undefined && e.target.value !== null) {
      const quantity = e.target.value;
      this.setState({
        quantity: quantity
      });
    }
  }

  handleChange = (e) => {
    if (e.target.value !== undefined && e.target.value !== null) {
      const discount = Number(e.target.value);
      this.setState({
        discount: discount,
        montantHT: computeDiscount(this.state.montantHTND, discount),
        montantTTC: computeDiscount(this.state.montantTTCND, discount)
      });
    }
  }

  handleNameChange = (e) => {
    this.setState({
      name: e.target.value
    });
  }

  onSubmit = () => {
    const dataToSend = {};
    dataToSend.article = {
      type: 'formule',
      name: this.state.name,
      articles: this.state.offres.map((offre) => offre.article_id)
    }
    dataToSend.id = this.props.formule.id;
    dataToSend.discount = this.state.discount;
    this.props.callback(dataToSend);
  }

  addOffre = (event) => {
    const offres = this.state.offres;
    const article = event.target.attributes;
    const offre = {
      article_id: event.target.value,
      article: event.target.innerText,
      article_price: Number(article.montant_ht.value),
      article_montantTTC: Number(article.montant_ttc.value),
    };
    offres.push(offre);
    const montantHTND = offres.length > 0 ? offres.map((offre) => offre.article_price).reduce((sum, value) => sum+value) : 0;
    const montantTTCND = offres.length > 0 ? offres.map((offre) => offre.article_montantTTC).reduce((sum, value) => sum+value) : 0;

    this.setState({
      offres: offres,
      montantHTND: montantHTND,
      montantTTCND: montantTTCND,
      montantHT: computeDiscount(montantHTND, this.state.discount),
      montantTTC: computeDiscount(montantTTCND, this.state.discount)
    });
  }

  removeOffre = (event) => {
    const offres = this.state.offres;
    const article = event.target.attributes;
    offres.splice(article.index, 1);
    const montantHTND = offres.length > 0 ? offres.map((offre) => offre.article_price).reduce((sum, value) => sum+value) : 0;
    const montantTTCND = offres.length > 0 ? offres.map((offre) => offre.article_montantTTC).reduce((sum, value) => sum+value) : 0;
    this.setState({
      offres: offres,
      montantHTND: montantHTND,
      montantTTCND: montantTTCND,
      montantHT: computeDiscount(montantHTND, this.state.discount),
      montantTTC: computeDiscount(montantTTCND, this.state.discount)
    });
  }

  setSelected = (event) => {
    this.setState({
      selected: {
        target: {
          value: event.target.value,
          innerText: event.target.innerText,
          attributes: event.target.attributes
        }
      }
    });
  }

  setQuantity = (event) => {
    for (let i=0; i < Number(this.state.quantity); i++) {
      this.addOffre(this.state.selected);
    }
  }

  changeTTC = (event) => {
    const newTTC = Number(event.target.value);
    if (newTTC !== this.state.montantTTC) {
      let discount = (1 - (newTTC / this.state.montantTTCND)) * 100;
      if (discount < 0)
        discount = 0;
      const montantHT = computeDiscount(this.state.montantHTND, discount);
      this.setState({
        montantTTC: newTTC,
        montantHT: montantHT,
        discount: discount
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      discount: 0,
      montantHT: 0,
      montantTTC: 0,
      selected: null,
      quantity: 0,
      montantHTND: 0,
      montantTTCND: 0,
      name: "",
      offres: []
    });
  }

  render() {
    return (
      <div>
        <Row>
          <Col sm={6}>
            <h5>Produits</h5>
            <Input type="select" className="select-article" multiple>
              {this.props.products.result.map((productId, index) => (
                <option key={index} value={this.props.products.entities.products[productId].id}
                  montant_ht={this.props.products.entities.products[productId].price}
                  montant_ttc={this.props.products.entities.products[productId].montantTTC}
                  onDoubleClick={this.addOffre}
                  onClick={this.setSelected}>
                  {this.props.products.entities.products[productId].article.name}
                </option>
              ))}
            </Input>
          </Col>
          <Col sm={6}>
            <h5>Services</h5>
            <Input type="select" className="select-article" multiple>
              {this.props.services.result.map((serviceId, index) => (
                <option key={index} value={this.props.services.entities.services[serviceId].id}
                  montant_ht={this.props.services.entities.services[serviceId].price}
                  montant_ttc={this.props.services.entities.services[serviceId].montantTTC}
                  onDoubleClick={this.addOffre}
                  onClick={this.setSelected}>
                  {this.props.services.entities.services[serviceId].article.name}
                </option>
              ))}
            </Input>
          </Col>
          <Col sm={6}>
            <h5>Prestations</h5>
            <Input type="select" className="select-article"  multiple>
              {this.props.prestations.result.map((prestationId, index) => (
                <option key={index} value={this.props.prestations.entities.prestations[prestationId].id}
                  montant_ht={this.props.prestations.entities.prestations[prestationId].price}
                  montant_ttc={this.props.prestations.entities.prestations[prestationId].montantTTC}
                  onDoubleClick={this.addOffre}
                  onClick={this.setSelected}>
                  {this.props.prestations.entities.prestations[prestationId].article.name}
                </option>
              ))}
            </Input>
          </Col>
          <Col sm={6}>
            <h5>Formules</h5>
            <Input type="select" className="select-article" multiple>
              {this.props.formules.result.map((formuleId, index) => (
                <option key={index} value={this.props.formules.entities.formules[formuleId].id}
                  montant_ht={this.props.formules.entities.formules[formuleId].price}
                  montant_ttc={this.props.formules.entities.formules[formuleId].montantTTC_with_discount}
                  onDoubleClick={this.addOffre}
                  onClick={this.setSelected}>
                  {this.props.formules.entities.formules[formuleId].article.name}
                </option>
              ))}
            </Input>
          </Col>
        </Row>
        <hr/>
        <Row>
          <Col sm={8}>
            <h5>Formule :
              <Input type="text" placeholder="Nom de la formule" onChange={this.handleNameChange} onBlur={this.handleNameChange} value={this.state.name} />
            </h5>
            <Input type="select" className="select-formule" multiple>
              {this.state.offres.map((offre, index) => (
                <option key={index} index={index} value={offre.article_id}
                  montant_ht={offre.article_price}
                  montant_ttc={offre.article_montantTTC}
                  onDoubleClick={this.removeOffre}
                  onClick={this.setSelected}>
                  {offre.article}
                </option>
              ))}
            </Input>
          </Col>
          <Col sm={4} className={styles.formule}>
            <div className={styles.formuleInfo}>
            <Row className="no-gutters">
              <Col xs={4}>
                <p>Quantité</p>
              </Col>
              <Col>
                <Input type="number" max={100} onChange={this.handleQtyChange} onBlur={this.handleQtyChange} value={this.state.quantity}/>
              </Col>
              <Col>
                <Button onClick={this.setQuantity} className="colorButton">Ajouter</Button>
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col xs={4}>
                <p>Remise</p>
              </Col>
              <Col>
                <InputGroup>
                  <Input type="number" max={100} onChange={this.handleChange} onBlur={this.handleChange} value={this.state.discount.toFixed(6)}/>
                  <InputGroupAddon addonType="append">%</InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col xs={4}>
                <p>Montant HT</p>
              </Col>
              <Col>
                <span className="font-weight-bold">{this.state.montantHT.toFixed(0)} XPF</span>
              </Col>
            </Row>
            <Row className="no-gutters">
              <Col xs={4}>
                <p>Montant TTC</p>
              </Col>
              <Col>
                <InputGroup>
                  <Input type="number" value={this.state.montantTTC.toFixed(0)} onChange={this.changeTTC} onBlur={this.changeTTC}/>
                  <InputGroupAddon addonType="append">XPF</InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
            <p></p>
            <Button className="colorButton" onClick={this.onSubmit} type="submit">{this.props.createMode ? "Ajouter" : "Modifier"}</Button>
            </div>
          </Col>
        </Row>
      </div>
      );
  }
}
export default Formule;
