import {denormalizeOrganisme, normalizeOrganisme} from '../schema/organisme.js';
import produce from "immer";

const initialState = {
  result: [],
  entities: {products: {}},
  report: undefined,
}

export default function organismes(state=initialState, action) {
  switch (action.type) {
    case 'CREATE_ORGANISME_SUCCESSFUL':
      const organismes = denormalizeOrganisme(state.result, state.entities);
      organismes.push(action.organisme);
      return {...state, ...normalizeOrganisme(organismes)};
    case 'GET_ORGANISMES_SUCCESSFUL':
      return {...state, ...normalizeOrganisme(action.organismes)};
    case 'UPDATE_ORGANISME_SUCCESSFUL':
      const newState = produce(state, draftState => {
        draftState.entities.organismes[action.organisme.id] = action.organisme;
      });
      return {...state, ...newState};
    case 'GET_ORGANISME_REPORT_SUCCESSFUL':
      return {...state, report: action.report};
    default:
      return state;
  }
}
