import { normalize, denormalize, schema } from 'normalizr';

const facture = new schema.Entity('factures');
const factureSchema = [ facture ];

export const normalizeFacture = (data) => {
  return normalize(data, factureSchema);
}

export const denormalizeFacture = (data, entities) => {
  return denormalize(data, [facture], entities);
}
