import React, { PureComponent } from 'react';
import {Row, Col} from 'reactstrap';

import Enregistrement from './Tableaux/Enregistrement';
import Bordereaux from './Bordereaux.jsx';

class EnregistrementPresentation extends PureComponent {
  render() {
    const eleves = this.props.eleves.filter((eleve) => eleve.permis.some((p) => !p.identification_number))
    const bordereaux = this.props.bordereaux.filter((bordereau) => bordereau.type === 'register');
    return (
      <div>
        <Enregistrement
          eleves={eleves}
          bordereaux={bordereaux}
          createBordereau={this.props.createBordereau}
          intervenants={this.props.intervenants}
        />
        <Row>
          <Col xl={{offset: 4, size:8}}>
            <Bordereaux
              eleves={this.props.eleves}
              bordereaux={bordereaux}
              deleteBordereau={this.props.deleteBordereau}
              archiveBordereau={this.props.archiveBordereau}
              impression={this.props.impressionEnregistrement}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
export default EnregistrementPresentation;
