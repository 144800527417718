import { combineReducers } from 'redux';
import auth from './auth';
import autoecole from './autoecole';
import eleve from './eleve';
import eleves from './eleves';
import navigate from './navigate';
import products from './products';
import prestations from './prestations';
import services from './services';
import formules from './formules';
import organismes from './organismes';
import factures from './factures';
import paiements from './paiements';
import notifications from './notifications';
import prestationsStatut from './prestationsStatut';
import vehicules from './vehicules';
import messages from './messages';
import bordereaux from './bordereaux';
import alertes from './alertes';
import loading from './loading';
import stats from './stats';
import alerteMessages from './alerteMessages';
import bons from './bons';
import quantities from './quantities';

const otoschool = combineReducers({
  auth, autoecole, eleve, eleves, navigate, products, prestations, services,
  formules, organismes, factures, paiements, notifications, prestationsStatut,
  vehicules, messages, bordereaux, alertes, loading, stats, alerteMessages, bons,
  quantities
})

export default otoschool;
