import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Button, Row, Col} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {hourFormatter} from '../../common/formatter.js';
import ModalItem from '../Modal/Modal.js';
import EleveAjoutHeure from './EleveAjoutHeure.jsx';
import moment from 'moment';

import { impression } from '../../actions';

class ElevePlanning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateModal: false
    };
    this.columns = [
      {dataField: 'id', hidden: true},
      {dataField: 'nb', text: 'Cours n°', sort: true},
      {dataField: 'start_date', text: 'Date', sort: true, formatter: hourFormatter},
      {dataField: 'duree', text: 'Durée', sort: true, formatter: this.lengthFormatter},
      {dataField: 'intervenant', text: 'Moniteur', sort: true, formatter: this.intervenantFormatter},
      {dataField: 'prestation', text: 'Prestation', sort: true, formatter: this.prestationFormatter},
      {dataField: 'absent', text: 'Absent', sort: true, formatter: (cell, row) => row.absent ? "Absent": ""},
      {dataField: 'paid', text: 'Payé', sort: false,
        formatter: (cell, row) => row.paid ? <i className="fa fa-check text-success" aria-hidden="true"></i> : "",
        events: {onClick: (e, column, columnIndex, row, rowIndex) => {
          this.props.updatePaidLesson(this.props.autoecoleId, this.props.eleve.id, row.id, !row.paid)
        }}
      }
    ];
  }

  prestationFormatter = (cell, row) => {
    const prestation = this.props.prestations.entities.prestations[cell];
    return prestation !== undefined ? prestation.article.name : "Prestation inconnue";
  }

  intervenantFormatter = (cell, row) => {
    const intervenant = this.props.intervenants.find((intervenant) => intervenant.id === cell);
    return intervenant === undefined ? "Intervenant inconnu" : intervenant.first_name + " " + intervenant.last_name;
  }

  displayHoursMinutes = (time) => {
    const hour = Math.floor(time);
    const minutes = String((time - hour) * 60);

    return `${hour}h${minutes.padStart(2, '0')}`;
  }

  lengthFormatter = (cell, row) => {
    if(cell)
      return this.displayHoursMinutes(Number(cell));
    else {
      return "00h00";
    }
  }

  showCreateModal = () => {
    this.setState({
      showCreateModal: !this.state.showCreateModal
    });
  }

  addEvent = event => {
    this.setState({ showCreateModal: false });
    const endDate = new Date(event.date.getTime());
    const duration = moment(
        this.props.prestations.entities.prestations[event.prestation].article.duration,
        'HH:mm:ss').hour();
    endDate.setHours(endDate.getHours() + duration);
    const data = {
      prestation: event.prestation,
      intervenant: event.intervenant,
      eleves: event.eleves,
      duree: duration,
      start_date: event.date,
      end_date: endDate,
    }
    this.props.addPrestationStatut(data);
  }

  getPlanningData = () => {
    const prestationsStatut = Object.values(this.props.planning.entities.prestationsStatut).filter((heure) => heure.eleves.includes(this.props.eleve.id) && !heure.absent);
    prestationsStatut.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
    const events = prestationsStatut.map(p => {
      const date = new Date(p.start_date);
      const endDate = new Date(p.end_date);

      return {
        day: date.toLocaleDateString('fr', { weekday: 'long' }),
        date: date.toLocaleDateString('fr'),
        endDate: endDate.toLocaleDateString('fr'),
        endHour: `${endDate.getHours() < 10 ? '0' : ''}${endDate.getHours()}:${endDate.getMinutes() < 10 ? '0' : ''}${endDate.getMinutes()}`,
        hour: `${date.getHours() < 10 ? '0' : ''}${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`,
        intervenant: this.intervenantFormatter(p.intervenant, undefined)
      }
    })
    const data = {
      autoecole: this.props.autoecole,
      events: events,
      eleve: this.props.eleve
    };
    return data;
  }

  printPlanning = () => {
    this.props.getPlanning(this.props.autoecoleId, this.getPlanningData());
  }

  sendPlanning = () => {
    this.props.sendPlanning(this.props.autoecoleId, this.props.eleve.id, this.getPlanningData());
  }

  deletePaidLesson = (e, last) => {
    if(last !== undefined)
      this.props.deletePaidLesson(this.props.autoecoleId, this.props.eleve.id, this.props.permis.id, last.paidId);
    e.preventDefault();
}

  addPaidLesson = (e) => {
    this.props.addPaidLesson(this.props.autoecoleId, this.props.eleve.id, this.props.permis.id);
    e.preventDefault();
  }

  onlyComputeFilter = (h) => {
    const prestation = Object.assign({}, this.props.prestations.entities.prestations[h.prestation]);
    if (prestation.article)
      return prestation.article.compute_prestation;
    return true;
  }

  processTableData = (tableData) => {
    return tableData.map((ps, index) => {
      return {
          ...ps,
          nb: index
        };
      }
    )
  }

  render () {
    const heuresEleves = (this.props.planning.result.length > 0) ?
      Object.values(this.props.planning.entities.prestationsStatut).filter(
        (heure) => heure.eleves.includes(this.props.eleve.id)
      ).sort((ps1, ps2) => ps1.start_date > ps2.start_date)
        .map((ps, index) => ({
          ...ps,
          nb: index + 1
        })) : [];

    const heuresFutures = heuresEleves.filter((heure) => moment(heure.start_date) > moment()).filter(this.onlyComputeFilter);
    const heuresPassees = heuresEleves.filter((heure) => moment(heure.start_date) <= moment()).filter(this.onlyComputeFilter);
    const heuresManquees = heuresEleves.filter((heure) => moment(heure.start_date) <= moment() && heure.absent).filter(this.onlyComputeFilter);
    let totalPasses = heuresPassees.length > 0 ? heuresPassees.map((heure) => Number(heure.duree)).reduce((sum, value) => sum + value) : 0;
    let totalFutures = heuresFutures.length > 0 ? heuresFutures.map((heure) => Number(heure.duree)).reduce((sum, value) => sum + value) : 0;
    let totalManquees = heuresManquees.length > 0 ? heuresManquees.map((heure) => Number(heure.duree)).reduce((sum, value) => sum + value) : 0;
    totalPasses = this.displayHoursMinutes(totalPasses);
    totalFutures = this.displayHoursMinutes(totalFutures);
    totalManquees = this.displayHoursMinutes(totalManquees);

    return (
      <div className="otoschoolTable">
        <Button color="success" style={{margin: '0 0 24px'}} onClick={this.printPlanning}>Imprimer planning <i className="fa fa-print"></i></Button>
        <Button color="success" style={{margin: '0 24px 24px'}} onClick={this.sendPlanning}>Envoyer le planning <i className="fa fa-paper-plane"></i></Button>
        <Row className="text-center">
          <Col className="number">
            <p>Cours à venir</p>
            <h5>{totalFutures}</h5>
          </Col>
          <Col className="number">
            <p>Cours passés</p>
            <h5>{totalPasses}</h5>
          </Col>
          <Col className="number">
            <p>Cours manqués</p>
            <h5>{totalManquees}</h5>
          </Col>
        </Row>
        <BootstrapTable
          keyField='id' data={heuresEleves}
          columns={this.columns}
          selectRow={this.selectRow}
          pagination={paginationFactory()}
          defaultSorted={[{dataField: 'start_date', order: 'desc'}]}
          striped/>
        <Button className="colorButton" onClick={this.showCreateModal}>Ajouter un cours</Button>
        <ModalItem modalType="large" modalState={this.state.showCreateModal} title="Ajouter une heure"
          content={
            <EleveAjoutHeure
              eleve={this.props.eleve}
              intervenants={this.props.intervenants}
              prestations={this.props.prestations}
              closeTime={this.props.closeTime}
              openTime={this.props.openTime}
              addEvent={this.addEvent}
              hide={this.showCreateModal}
            />}/>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    autoecoleId: state.auth.user.profile.auto_ecole,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getPlanning: (autoecoleId, data) => dispatch(impression.getPlanning(autoecoleId, data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ElevePlanning);
