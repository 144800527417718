import React, { Component } from 'react';
import { Navbar, NavbarToggler, Collapse, Nav, NavItem, NavLink, Container, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';

import alertes from './logo/alertes.svg';
import eleves from './logo/eleves.svg';
import examens from './logo/examens.svg';
import factures from './logo/factures.svg';
import planning from './logo/planning.svg';
import stats from './logo/stats.svg';
import parametres from './logo/parametres.svg';


class Navigation extends Component {
  state = {
    dropdownSettings: false
  }

  toggleSettings = e => {
    this.setState({
      dropdownSettings: !this.state.dropdownSettings
    });
  }

  render() {
    return (
      <Container fluid>
        <Navbar light expand="md">
          <NavbarToggler />
          <Collapse navbar>
            <Nav className="mx-auto menu" navbar>
              <NavItem className="mr-5 menuItem">
                <NavLink href="#/" className="menuLink"
                  active={"planning" === this.props.active}
                  onClick={e => {this.props.changeSection("planning")}}>
                  <img src={planning} alt="Planning" className="mx-auto" />
                  Planning
                </NavLink>
              </NavItem>
              <NavItem className="mr-5 menuItem">
                <NavLink href="#/eleves" className="menuLink"
                  active={"eleves" === this.props.active}
                  onClick={e => {this.props.changeSection("eleves")}}>
                  <img src={eleves} alt="Élèves" className="mx-auto"/>
                  Élèves
                </NavLink>
              </NavItem>
              <NavItem className="mr-5 menuItem">
                <NavLink href="#/statistiques" className="menuLink"
                  active={"stats" === this.props.active}
                  onClick={e => {this.props.changeSection("stats")}}>
                  <img src={stats} alt="Statistiques" className="mx-auto"/>
                  Statistiques
                </NavLink>
              </NavItem>
              <NavItem className="mr-5 menuItem">
                <NavLink href="#/examens" className="menuLink"
                  active={"examens" === this.props.active}
                  onClick={e => {this.props.changeSection("examens")}}>
                  <img src={examens} alt="Examens" className="mx-auto"/>
                  Examens & <br/>impressions
                </NavLink>
              </NavItem>
              <NavItem className="mr-5 menuItem">
                <NavLink href="#/factures" className="menuLink"
                  active={"factures" === this.props.active}
                  onClick={e => {this.props.changeSection("factures")}}>
                  <img src={factures} alt="Factures & encaissements" className="mx-auto"/>
                  Factures & <br/>encaissements
                </NavLink>
              </NavItem>
              <NavItem className="mr-5 menuItem">
                <NavLink href="#/alertes" className="menuLink"
                  active={"alertes" === this.props.active}
                  onClick={e => {this.props.changeSection("alertes")}}>
                  <img src={alertes} alt="Alertes" className="mx-auto"/>
                  Alertes
                </NavLink>
              </NavItem>
              <UncontrolledDropdown
                className="mr-5 menuItem">
                <DropdownToggle nav
                  className={("parametres" === this.props.active) ? "menuLink active" : "menuLink"}
                  onClick={e => {this.props.changeSection("parametres")}}>
                  <img src={parametres} alt="Paramètres" className="mx-auto"/>
                  Paramètres
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={e => {window.location.href = "#/settings/autoecole";}}>Mon auto école</DropdownItem>
                  <DropdownItem onClick={e => {window.location.href = "#/settings/etablissements";}}>Établissements</DropdownItem>
                  <DropdownItem onClick={e => {window.location.href = "#/settings/intervenants";}}>Intervenants</DropdownItem>
                  <DropdownItem onClick={e => {window.location.href = "#/settings/articles";}}>Prestations, produits, services & formules</DropdownItem>
                  <DropdownItem onClick={e => {window.location.href = "#/settings/vehicules";}}>Véhicules</DropdownItem>
                  <DropdownItem onClick={e => {window.location.href = "#/settings/impressions";}}>Impressions</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    );
  }
}

export default Navigation;
