import {url} from '../properties.js';

export const addPrestationStatut = (autoecoleId, data) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const body = JSON.stringify(data);
    return fetch(url+'autoecole/'+autoecoleId+'/prestation-statut/', {headers, body, method: "PUT"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 201) {
          dispatch({type: 'CREATE_PRESTATIONS_STATUT_SUCCESSFUL', prestationStatut: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        // dispatch({type: 'ERROR_CREATE_PRODUCT'});
      })
  }
}

export const editPrestationStatut = (autoecoleId, prestationStatutId, data) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const body = JSON.stringify(data);
    return fetch(url+'autoecole/'+autoecoleId+'/prestation-statut/'+prestationStatutId, {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'UPDATE_PRESTATIONS_STATUT_SUCCESSFUL', prestationStatut: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        // dispatch({type: 'ERROR_CREATE_PRODUCT'});
      })
  }
}

export const getPrestationsStatut = (autoecoleId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return fetch(url+'autoecole/'+autoecoleId+'/prestations-statut/', {headers, method: "GET"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          const prestationsStatut = [];
          res.data.forEach((prestationStatut) => {
            prestationsStatut.push(prestationStatut);
          });
          dispatch({type: 'GET_PRESTATIONS_STATUT_SUCCESSFUL', prestationsStatut: prestationsStatut });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
      })
  }
}

export const getPrestationsStatutEleve = (autoecoleId, eleveId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/'+eleveId+'/prestations-statut/', {headers, method: "GET"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          const prestationsStatut = [];
          res.data.forEach((prestationStatut) => {
            prestationsStatut.push(prestationStatut);
          });
          dispatch({type: 'GET_PRESTATIONS_STATUT_SUCCESSFUL', prestationsStatut: prestationsStatut });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
      })
  }
}

export const deletePrestationStatut = (autoecoleId, prestationStatutId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return fetch(url+'autoecole/'+autoecoleId+'/prestation-statut/'+prestationStatutId, {headers, method: "DELETE"})
      .then(res => {
        if (res.status < 500) {
          return res;
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 204) {
          dispatch({type: 'DELETE_PRESTATIONS_STATUT_SUCCESSFUL', prestationStatutId });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        // dispatch({type: 'ERROR_CREATE_PRODUCT'});
      })
  }
}

export const getQuantities = (autoecoleId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return fetch(url+'autoecole/'+autoecoleId+'/quantities/', {headers, method: "GET"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'GET_QUANTITIES_SUCCESSFUL', quantities: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
      })
  }
}

export const getPlanningFromDate = (autoecoleId, date) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return fetch(url+'autoecole/'+autoecoleId+'/prestations-statut/?date='+date, {headers, method: "GET"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          const prestationsStatut = [];
          res.data.forEach((prestationStatut) => {
            prestationsStatut.push(prestationStatut);
          });
          dispatch({type: 'GET_PRESTATIONS_STATUT_SUCCESSFUL', prestationsStatut: prestationsStatut });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
      })
  }
}
