import React, { Component } from 'react';
import {Button} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

class EleveDevis extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {dataField: 'id', hidden: true},
      {dataField: 'article', text: 'Article', sort: true},
      {dataField: 'date', text: 'Date', sort: true},
      {dataField: 'quantity', text: 'Quantité', sort: true},
      {dataField: 'amount', text: 'Montant', sort: true},
      {dataField: 'actions', text: 'Actions', sort: true},
    ];
    this.selectRow = {
      mode: 'checkbox',
      hideSelectColumn: false,
      clickToSelect: true
    };
  }
  render () {
    return (
      <div className="otoschoolTable">
        <BootstrapTable
          keyField='id' data={[]}
          columns={this.columns}
          selectRow={this.selectRow}
          striped/>
        <Button className="colorButton">Accepter les devis</Button>
        <Button className="colorButton">Supprimer les devis</Button>
      </div>
    );
  }
}

export default EleveDevis;
