import React from 'react';
import moment from 'moment';
import 'moment/locale/fr';

export const priceFormatter = (cell, row) => {
  if (cell !== null)
    return Number(cell).toFixed(0) + " XPF";
  return null;
}

export const redPriceFormatter = (cell, row) => {
  if (cell !== null && Number(cell).toFixed(0) > 0) {
    return <span style={{color: 'tomato'}}>{Number(cell).toFixed(0) + " XPF"}</span>;
  } else if (cell !== null) {
    return (Number(cell).toFixed(0) === "-0" ? "0" : Number(cell).toFixed(0)) + " XPF";
  }
  return null;
}

export const bluePriceFormatter = (cell, row) => {
  if (cell !== null && Number(cell).toFixed(0) > 0) {
    return <span style={{color: 'rgb(38, 72, 182)'}}>{Number(cell).toFixed(0) + " XPF"}</span>;
  } else if (cell !== null) {
    return Number(cell).toFixed(0) + " XPF";
  }
  return null;
}

export const percentageFormatter = (cell, row) => {
  return Number(cell).toFixed(2) + " %";
}

export const dateFormatter = (cell, row) => {
  if (cell !== null)
    return moment(cell).locale('fr').format('LL');
  return null;
}

export const hourFormatter = (cell, row) => {
  return moment(cell).locale('fr').format('DD/MM/YYYY [à] HH[h]mm');
}
