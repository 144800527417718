import React, { Component } from 'react';
import {Container, Row, Col, Button, Label} from 'reactstrap';

import Select from 'react-select';
import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import OtoschoolDatePicker from '../OtoschoolDatePicker/OtoschoolDatePicker.jsx';

import {priceFormatter, percentageFormatter, dateFormatter} from '../../common/formatter.js';


const PRETTY_NAMES = {
  eleve: 'Élève',
  permis: 'Permis',
  name: 'Intitulé',
  hours_sold: 'Heures vendues',
  intervenant: 'Intervenant',
  hours_done: 'Heures réalisées',
  facture: 'Facture',
  amount: 'Montant TTC',
  amount_ht: 'Montant HT',
  amount_tva: 'Montant TVA',
  quantity: 'Quantité',
  tva: 'TVA',
  price: 'Prix HT',
  discount: 'Remise',
  registration_date: 'Date d\'inscription',
  registered: 'Inscrits',
  year: 'Année',
  month: 'Mois',
  day: 'Jour',
  week: 'Semaine',
  date: 'Date',
  type: 'Intitulé',
  organization: 'Établissement',
  number: 'N° de paiement'
}

const FORMATTER = {
  // facture: factureFormatter,
  amount: priceFormatter,
  amount_ht: priceFormatter,
  amount_tva: priceFormatter,
  price: priceFormatter,
  discount: percentageFormatter,
  tva: percentageFormatter,
  registration_date: dateFormatter,
  date: dateFormatter,
}


class StatistiquesPresentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: new Date(),
      report: 'registration',
    };
  }

  componentDidMount() {
    this.getReport()
  }

  setDate = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  getReport = () => {
    const reportSelected = this.props.reportList.filter((report) => report.name === this.state.report)[0]
    let filters = [];
    if (reportSelected !== undefined && reportSelected.filters !== undefined)
      filters = reportSelected.filters.map((filter) => filter.name);
    filters = filters.map((filter) => ({[filter]: this.state[filter]}))
    const report = {
      start_date: moment(this.state.startDate).format('YYYY-MM-DD'),
      end_date: moment(this.state.endDate).format('YYYY-MM-DD'),
      report: this.state.report,
      filters: filters
    };
    this.props.getReport(report);
  }

  eleveFormatter = (cell, row) => {
    const eleve = this.props.eleves[cell];
    return eleve !== undefined ? eleve.first_name.split(' ')[0] + " " + eleve.last_name : "Élève inconnu";
  }

  intervenantFormatter = (cell, row) => {
    const intervenant = this.props.intervenants.find((intervenant) => intervenant.id === cell);
    return intervenant === undefined ? "Intervenant inconnu" : intervenant.first_name + " " + intervenant.last_name;
  }

  factureFormatter = (cell, row) => {
    const facture = this.props.factures.find((facture) => String(facture.id) === cell);
    return facture === undefined ? "Numéro facture inconnu" : <a href="#" target="_blank"
      onClick={(e) => {
        e.preventDefault();
        this.printFacture(facture, this.props.eleves[facture.eleve]);
      }
    }>{facture.number}</a>;
  }

  printFacture = (facture, eleve) => {
    const data = {
      factureId: facture.id,
    }
    this.props.printFacture(data);
  }

  optionLabel = (option, name) => {
    if (name === 'intervenants')
      return this.intervenantFormatter(option, null);
    return option;
  }

  render() {
    const { SearchBar } = Search;
    const optionsReport = this.props.reportList.map((report) => ({label: report.display_name, value: report.name}));
    let filters = {}
    this.props.reportList.map((report) =>
        filters[report.name] = report.filters.map((filter) => <Col sm={2}>
          <Label>{filter.label}</Label>
          <Select
            isSearchable
            isMulti
            options={filter.options.map((option) => ({label: this.optionLabel(option, filter.name), value: option}))}
            placeholder={filter.label}
            onChange={(selectedOptions) => {
              this.setState({
                [filter.name]: selectedOptions.map((option) => option.value)
              });
            }}
          />
        </Col>)
      );
    FORMATTER['eleve'] = this.eleveFormatter;
    FORMATTER['intervenant'] = this.intervenantFormatter;
    FORMATTER['facture'] = this.factureFormatter;
    let columns = [{dataField: 'id'}];
    let data = [];
    let summary = [];
    if (this.props.report && this.props.report.data.length > 0) {
      const keys = Object.keys(this.props.report.data[0]);
      columns = keys.map((key) => ({dataField: key, text: PRETTY_NAMES[key], sort: true}));
      columns = columns.map((column) => {
        if (column.dataField in FORMATTER)
          column.formatter = FORMATTER[column.dataField]
        return column;
      });
      data = this.props.report.data;
      const computedSize = 12 % (Math.min(this.props.report.summary, 4));
      summary = this.props.report.summary.map((resume) =>
      <Col sm={computedSize} className="number">
        <p>{resume.label}</p>
        <h5>{resume.value.toLocaleString()}</h5>
      </Col>
      )
    }
    return (
      <Container fluid>
        <h4 className="pageTitle">Statistiques</h4>
        <Row className="text-center">
          {summary}
        </Row>
        <Row>
          <Col sm={9} md={3} lg={3} xl={3}>
              <Label className="font-weight-bold">Rapport</Label>
              <br/>
              <Select
                options={optionsReport}
                isSearchable
                placeholder="Rapport"
                value={optionsReport.filter((option) => this.state.report === option.value)}
                onChange={(selectedOption) => {
                  this.setState({
                    report: selectedOption.value,
                  });
                }}
              />
          </Col>
          <Col>
            <div className="d-inline-block">
              <Label className="font-weight-bold">Début</Label>
              <br/>
              <OtoschoolDatePicker name="startDate" value={this.state.startDate} onChange={this.setDate}/>
            </div>
            <span>&nbsp; au &nbsp;</span>
            <div className="d-inline-block">
              <Label className="font-weight-bold">Fin</Label>
              <br/>
              <OtoschoolDatePicker name="endDate" value={this.state.endDate} onChange={this.setDate}/>
            </div>
            <Button className="colorButton ml-3" onClick={this.getReport}>Obtenir le rapport</Button>
          </Col>
        </Row>
        <Row className="mt-2">
          {filters[this.state.report]}
        </Row>
        <Row>
          <Col>
            <ToolkitProvider
                keyField='id'
                columns={columns}
                data={data}
                search={{searchFormatted:true}}>
                {
                  props => (
                    <div>
                      <Row>
                        <Col xs={{offset: 6, size:6}} sm={{offset: 6, size:6}} md={{offset: 7, size:5}} lg={{offset: 8, size:4}} xl={{offset: 8, size:4}}>
                          <SearchBar {...props.searchProps} placeholder="Rechercher"/>
                        </Col>
                      </Row>
                      <div className="otoschoolTable">
                        <BootstrapTable {...props.baseProps }
                          pagination={paginationFactory()}
                          striped/>
                      </div>
                    </div>
                  )
                }
              </ToolkitProvider>
        </Col>
        </Row>
    </Container>
    );
  }
}
export default StatistiquesPresentation;
