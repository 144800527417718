
export const findPrestationName = (prestationId, prestations) => {
  if (prestations) {
    const prestation = Object.values(prestations)
      .find(prestation => prestation.id === prestationId);
    return prestation ? prestation.article.name : 'Évènement';
  }
  return 'Évènement';
}

export const toTitleCase = (str) => {
  return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}
