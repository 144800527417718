import { normalize, denormalize, schema } from 'normalizr';

const paiement = new schema.Entity('paiements');
const paiementSchema = [ paiement ];

export const normalizePaiement = (data) => {
  return normalize(data, paiementSchema);
}

export const denormalizePaiement = (data, entities) => {
  return denormalize(data, [paiement], entities);
}
