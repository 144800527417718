import { normalize, denormalize, schema } from 'normalizr';

const bordereau = new schema.Entity('bordereaux');
const bordereauSchema = [ bordereau ];

export const normalizeBordereau = (data) => {
  return normalize(data, bordereauSchema);
}

export const denormalizeBordereau = (data, entities) => {
  return denormalize(data, [bordereau], entities);
}
