import React, { PureComponent } from 'react';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Button, Row, Col } from 'reactstrap';
import moment from "moment";

import styles from './Eleves.module.css';
import {dateFormatter} from '../../common/formatter.js';

export default class EleveTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      seeArchived: false
    };

    this.options = {
      sortIndicator: true,
      hideSizePerPage: true,
      paginationSize: 3,
      hidePageListOnlyOnePage: true,
      clearSearch: true,
      alwaysShowAllBtns: false,
      withFirstAndLast: false
    };

    this.columns = [
      {dataField: 'id', hidden: true},
      {dataField: 'last_name', text: 'Nom', hidden: true, sort: true},
      {dataField: 'first_name', text: 'Prénom', hidden: true, sort: true},
      {dataField: 'profile_picture', text:'Profil', formatter: (cell, row) => {
        return (
          <div className="smallProfilPicture">
            {row.profile_picture ?
            <img src={row.profile_picture} className="profilPictureThumbnail" alt="Profil utilisateur"/>
            :
            <img src={process.env.PUBLIC_URL + '/blank.png'} className="profilPictureThumbnail" alt="Profil utilisateur"/>
            }
          </div>
      )
    }, classes: 'py-0'},
      {dataField: 'df1', text: 'Nom', formatter: (cell, row) => `${row.first_name} ${row.last_name}`, sort: true},
      {dataField: 'registration_date', text: 'Date d\'inscription', sort: true, formatter: dateFormatter},
      {dataField: 'city', text: 'Ville', sort: true},
      {dataField: 'mobile_phone', text: 'Mobile', sort: true},
      {dataField: 'permis', text: 'Permis', sort: true},
      {dataField: 'etg_completed', text: 'Date ETG', sort: true, formatter: dateFormatter},
      {dataField: 'success_date', text: 'Date Permis', sort: true, formatter: dateFormatter},
      {dataField: 'actions', text: 'Actions'} ,
    ];

    this.defaultSorted = [{
      dataField: 'registration_date',
      order: 'desc'
    }];

    this.rowEvents = {
      onClick: (e, row, rowIndex) => {
        // Dirty hack to prevent changing window when an action button has been clicked, <i> and <Button> can be clicked
        if(!e.target.parentElement.attributes.hasOwnProperty('actions') && !e.target.parentElement.parentElement.attributes.hasOwnProperty('actions'))
          window.location.href = "#/eleve/"+row['id'];
      },
    }

    this.selectRow = {
      mode: 'radio',
      hideSelectColumn: true,
      clickToSelect: true
    };
  }

  switchArchive = () => {
    this.setState({
      seeArchived: !this.state.seeArchived
    });
  }

  prepareData = () => {
    const keys = this.props.eleves.result;
    var dataTable = [];
    keys.forEach(eleveId => {
      const eleve = Object.assign({}, this.props.eleves.entities.eleves[eleveId]);
      if (eleve.archived === this.state.seeArchived) {
        if (eleve.permis.length > 0) {
          const permis = eleve.permis.slice(-1)[0];
          eleve.permis = permis.permis;
          eleve.success_date = permis.success_date;
          eleve.etg_completed = permis.etg_completed;
        } else {
          eleve.success_date = null;
          eleve.etg_completed = null;
        }
        eleve.actions =
        <div className={styles.actions} actions="actions">
          <Button title="Voir l'élève" color="success" onClick={() => {window.location.href = "#/eleve/"+eleve.id}}><i className="fa fa-eye"></i></Button>
          <Button title={eleve.archived ? "Rendre actif" : "Archiver"} color="warning" onClick={() => {this.props.archiveEleve(eleve)}}><i className="fa fa-archive" aria-hidden="true"></i></Button>
          <Button title="Supprimer l'élève" color="danger" onClick={() => {this.props.deleteEleve(eleve)}}><i className="fa fa-trash"></i></Button>
        </div>;
        dataTable.push(eleve);
      }
    });
    return dataTable;
  }

  createEleve = () => {
    window.location.href = "#/eleve/";
  }

  render() {
    let eleveTable = [];
    let registerMonth = 0;
    let registerYear = 0;
    let registerLastYear = 0;
    if (this.props.eleves.result.length > 0) {
      eleveTable = this.prepareData();
      registerMonth = eleveTable.filter(eleve => moment(eleve.registration_date).isAfter(moment().subtract(1, 'months'))).length;
      registerYear = eleveTable.filter(eleve => moment(eleve.registration_date).isAfter(moment().subtract(1, 'years'))).length;
      registerLastYear = eleveTable.filter(eleve => moment(eleve.registration_date).isBetween(moment().subtract(2, 'years'), moment().subtract(1, 'years'))).length;;
    }
    const { SearchBar } = Search;
    return (
      <div className="animated fadeIn">
        <Row doc="inscription" className="text-center">
          <Col className="number">
            <p>Inscrits ce mois-ci</p>
            <h5>{registerMonth}</h5>
          </Col>
          <Col className="number">
            <p>Inscrits cette année</p>
            <h5>{registerYear}</h5>
          </Col>
          <Col className="number">
            <p>Inscrits l'année dernière</p>
            <h5>{registerLastYear}</h5>
          </Col>
        </Row>
        <ToolkitProvider
          keyField='id' data={eleveTable}
          columns={this.columns}
          search>
          {
            props => (
              <div>
                <Row className="no-gutters">
                  <Col xs={6} sm={6} md={5} lg={4} xl={4}>
                    <Row className="no-gutters searchAdd">
                      <Col xs={9} sm={10} md={10} lg={10} xl={11} className="searchbar">
                        <SearchBar {...props.searchProps} placeholder="Rechercher un élève"/>
                      </Col>
                      <Col xs={3} sm={2} md={2} lg={2} xl={1}>
                        <Button className="addEleve" onClick={this.createEleve}><i className="fa fa-plus" aria-hidden="true"></i></Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="otoschoolTable clickable">
                  <BootstrapTable {...props.baseProps }
                    selectRow={this.selectRow}
                    rowEvents={this.rowEvents}
                    pagination={paginationFactory()}
                    defaultSorted={this.defaultSorted}
                    striped/>
                </div>
              </div>
            )
          }
        </ToolkitProvider>
        <Button onClick={this.switchArchive} className="colorButton">
          {this.state.seeArchived ? "Voir les élèves actifs" : "Voir les élèves archivés"}
        </Button>
      </div>
    );
  }
}
