import {denormalizeService, normalizeService} from '../schema/article.js';
import produce from "immer";

const initialState = {
  result: [],
  entities: {services: {}}
}

export default function services(state=initialState, action) {
  switch (action.type) {
    case 'CREATE_SERVICE_SUCCESSFUL':
      const services = denormalizeService(state.result, state.entities);
      services.push(action.service);
      return {...state, ...normalizeService(services)};
    case 'GET_SERVICES_SUCCESSFUL':
      return {...state, ...normalizeService(action.services)};
    case 'UPDATE_SERVICE_SUCCESSFUL':
      const newState = produce(state, draftState => {
        draftState.entities.services[action.service.id] = action.service;
      });
      return {...state, ...newState};
    case 'DELETE_ARTICLE_SUCCESSFUL':
      const newStateDelete = produce(state, draftState => {
        if (draftState.result.length > 0 && action.deletedId in draftState.entities.services) {
          delete draftState.entities.services[action.deletedId];
          draftState.result.splice(draftState.result.findIndex(id => id === action.deletedId), 1);
        }
      });
      return {...state, ...newStateDelete};
    default:
      return state;
  }
}
