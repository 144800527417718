const initialState = {
  autoecole: {
    auto_ecole: "",
    email: "",
    mobile_phone: "",
    phone: "",
    address: "",
    horaires: [{start: 0, end: 0}, {start: 0, end: 0}],
    intervenants: [],
    alerts: [],
    tva: [],
    gen_invoice: [],
    fermetures: [],
    permis: [],
    ribs: [],
    tahiti_number: ""
  },
  roles: [],
  permissions: []
};

function updateTVA(tvas, action) {
  return tvas.map((item, index) => {
    if (item.tva_type !== action.index) {
      return item
    }
    return {
      ...item,
      ...action.tva
    }
  })
}

function updateGenInvoice(genInvoice, action) {
  return genInvoice.map((item, index) => {
    if (index !== action.index) {
      return item
    }
    return {
      ...item,
      ...action.gen_invoice
    }
  })
}

function updateAlerte(alertes, action) {
  return alertes.map((item, index) => {
    if (index !== action.index) {
      return item
    }
    return {
      ...item,
      ...action.alerte
    }
  })
}

function updateIntervenant(intervenants, action) {
  return intervenants.map((item, index) => {
    if (index !== action.index) {
      return item
    }
    return {
      ...item,
      ...action.intervenant
    }
  })
}

function insertIntervenant(intervenants, action) {
  return [
    ...intervenants.slice(0, action.index),
    action.intervenant,
    ...intervenants.slice(action.index)
  ]
}

function removeIntervenant(intervenants, action) {
  return [...intervenants.slice(0, action.index), ...intervenants.slice(action.index + 1)]
}

export default function autoecole(state=initialState, action) {
  switch (action.type) {
    case 'GET_AUTOECOLE_SUCCESSFUL':
    case 'UPDATE_AUTOECOLE_SUCCESSFUL':
      return {...state, autoecole: action.autoecole};
    case 'CREATE_INTERVENANT_SUCCESSFUL':
      action.index = state.autoecole.intervenants.length
      return {...state,
              autoecole: {
                ...state.autoecole,
                intervenants: insertIntervenant(state.autoecole.intervenants, action)
              }
            }
    case 'DELETE_INTERVENANT_SUCCESSFUL':
      return {...state,
              autoecole: {
                ...state.autoecole,
                intervenants: removeIntervenant(state.autoecole.intervenants, action)
              }
            }
    case 'UPDATE_INTERVENANT_SUCCESSFUL':
      return {...state,
              autoecole: {
                ...state.autoecole,
                intervenants: updateIntervenant(state.autoecole.intervenants, action)
              }
            }
    case 'UPDATE_ALERTE_SUCCESSFUL':
      return {...state,
              autoecole: {
                ...state.autoecole,
                alerts: updateAlerte(state.autoecole.alerts, action)
              }
            }
    case 'UPDATE_TVA_SUCCESSFUL':
      return {...state,
              autoecole: {
                ...state.autoecole,
                tva: updateTVA(state.autoecole.tva, action)
              }
            }
    case 'UPDATE_GEN_INVOICE_SUCCESSFUL':
      return {...state,
              autoecole: {
                ...state.autoecole,
                gen_invoice: updateGenInvoice(state.autoecole.gen_invoice, action)
              }
            }
    case 'GET_ROLES_SUCCESSFUL':
      return {...state, roles: action.roles};
    case 'GET_PERMISSIONS_SUCCESSFUL':
      return {...state, permissions:action.permissions};
    default:
      return state;
  }
}
