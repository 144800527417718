import React, { Component } from 'react';
import { connect } from 'react-redux';
import { stats, eleves, facture, impression } from '../../actions';

import StatistiquesPresentation from './StatistiquesPresentation.jsx';

class Statistiques extends Component {
  state = {
    startDate: null,
    endDate: null,
    report: null
  }

  componentDidMount() {
    if (this.props.reportList.length === 0) {
      this.props.getReportList(this.props.autoecoleId);
    }
    if (Object.keys(this.props.eleves).length === 0) {
      this.props.getEleves(this.props.autoecoleId);
    }
    if (this.props.factures !== undefined && Object.keys(this.props.factures).length === 0) {
      this.props.getFactures(this.props.autoecoleId);
    }
  }

  getReport = (report) => {
    report.autoecole = this.props.autoecoleId;
    this.setState({
      startDate: report.start_date,
      endDate: report.end_date,
      report: report.report
    });
    delete report.report;
    this.props.getReport(this.props.autoecoleId, report);
  }

  findReport = () => {
    const key = this.state.startDate+'_'+this.state.endDate;
    if (this.props.reportKeys.find((reportKey) => reportKey === key) !== undefined)
      return this.props.reports[key][this.state.report];
    return undefined
  }

  printFacture = (data) => {
    data.autoecole = this.props.autoecole;
    this.props.printFacture(this.props.autoecoleId, data);
  }

  render() {
    const report = this.findReport();
    return (
      <StatistiquesPresentation
        reportList={this.props.reportList}
        getReport={this.getReport}
        report={report}
        eleves={this.props.eleves}
        intervenants={this.props.autoecole.intervenants}
        factures={Object.values(this.props.factures, {})}
        printFacture={this.printFacture}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    autoecoleId: state.auth.user.profile.auto_ecole,
    autoecole: state.autoecole.autoecole,
    reportList: state.stats.reportList,
    reportKeys: state.stats.result,
    reports: state.stats.entities.stats,
    eleves: state.eleves.entities.eleves,
    factures: state.factures.entities.factures
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getReport: (autoecoleId, values) => dispatch(stats.getReport(autoecoleId, values)),
    getReportList: (autoecoleId) => dispatch(stats.getReportList(autoecoleId)),
    getEleves: (autoecoleId) => dispatch(eleves.get(autoecoleId)),
    getFactures: (autoecoleId) => dispatch(facture.getFactures(autoecoleId)),
    printFacture: (autoecoleId, data) => dispatch(impression.printFacture(autoecoleId, data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Statistiques);
