import React, { Component } from 'react';
import { Col, Card, CardBody, InputGroup, Label, Input, InputGroupAddon, Button } from 'reactstrap'

import './Facturation.css';

class Facturation extends Component {
  state = {
    changed: false,
    cps_changed:false,
    tva_p: false,
    tva_s: false,
    cps: false
  }

  updateTVA = e => {
    if(this.state.tva_s)
      this.props.updateTVA("S", this.props.tva["S"]);
    if(this.state.tva_p)
      this.props.updateTVA("P", this.props.tva["P"]);
    if(this.state.cps)
      this.props.updateTVA("C", this.props.tva["C"]);
    this.setState({changed:false, cps_changed:false});
  }

  updateGenInvoice = e => {
    let genInvoice = this.props.genInvoice[0];
    genInvoice.gen_invoice = e.target.checked;
    this.props.updateGenInvoice(0, genInvoice);
  }

  tvaChanged = e => {
    this.props.tva[e.target.attributes.tvaType.value].tva_value = e.target.value;
    this.setState({
      changed: e.target.attributes.tvaType.value === "P" || e.target.attributes.tvaType.value == "S",
      cps_changed: e.target.attributes.tvaType.value == "C",
      tva_p: e.target.attributes.tvaType.value === "P",
      tva_s: e.target.attributes.tvaType.value == "S",
      cps: e.target.attributes.tvaType.value == "C",
    });
  }

  render() {
    let tva_s, tva_p, cps, genInvoice;
    console.log(this.props.tva)
    if(Object.keys(this.props.tva).length === 3) {
      const tva_s_obj = this.props.tva["S"];
      const tva_p_obj = this.props.tva["P"];
      const cps_obj = this.props.tva["C"];

      tva_s = <Input type="number" id="tva-service" min={0} step={0.1} onChange={this.tvaChanged} tvaType="S" value={tva_s_obj.tva_value}/>;
      tva_p = <Input type="number" id="tva-produit" min={0} step={0.1} onChange={this.tvaChanged} tvaType="P" value={tva_p_obj.tva_value}/>;
      cps = <Input type="number" id="tva-produit" min={0} step={0.1} onChange={this.tvaChanged} tvaType="C" value={cps_obj.tva_value}/>;
    }

    if(this.props.genInvoice.length === 1) {
      genInvoice = <Input type="checkbox" name="autoInvoice" id="autoInvoice" onChange={this.updateGenInvoice} checked={this.props.genInvoice[0].gen_invoice}/>;
    }
    return (
      <Card className="otoschoolCard">
        <div className="subTitle">
          Paramètres de facturation
        </div>
        <CardBody>
          <h5>Changer le taux de TVA</h5>
          <div id="choose-tva">
            <Col>
              <InputGroup>
                <Label for="tva-service" sm={2}>TVA Service </Label>
                <Col sm={2}>
                  <InputGroup>
                    {tva_s}
                    <InputGroupAddon addonType="append">%</InputGroupAddon>
                  </InputGroup>
                </Col>
                <Label for="tva-produit" sm={2}>TVA Produit </Label>
                <Col sm={2}>
                  <InputGroup>
                    {tva_p}
                    <InputGroupAddon addonType="append">%</InputGroupAddon>
                  </InputGroup>
                </Col>
                {this.state.changed &&
                  <Button className="colorButton" type="submit" onClick={this.updateTVA}>Enregistrer</Button>}
              </InputGroup>
            </Col>
          </div>
          <h5>Changer le taux CPS</h5>
          <div id="choose-tva">
            <Col>
              <InputGroup>
                <Label for="cps" sm={2}>CPS</Label>
                <Col sm={2}>
                  <InputGroup>
                    {cps}
                    <InputGroupAddon addonType="append">%</InputGroupAddon>
                  </InputGroup>
                </Col>
                {this.state.cps_changed &&
                  <Button className="colorButton" type="submit" onClick={this.updateTVA}>Enregistrer</Button>}
              </InputGroup>
            </Col>
          </div>
          <h5>Numéro de facturation</h5>
          <Col>
            <Col>
              <InputGroup>
                {genInvoice}
                <Label for="autoInvoice">Générer automatiquement les numéros de factures</Label>
              </InputGroup>
            </Col>
          </Col>
        </CardBody>
      </Card>
    );
  }
}
export default Facturation;
