import {url} from '../properties.js';

export const show = (eleveId) => {
  return (dispatch, getState) => {
    dispatch({type: 'SHOW_ELEVE', eleveId: eleveId});
  }
}

export const create = (autoecoleId, values) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    values.autoecole = autoecoleId;
    const {profilePicture, ...valuesForCreate} = values;
    const body = JSON.stringify(valuesForCreate)
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/', {headers, body, method: "PUT"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 201) {
          dispatch({type: 'CREATE_ELEVE_SUCCESSFUL', eleve: res.data });
          console.log(res.data)
          dispatch(uploadProfilePicture(autoecoleId, res.data.id, profilePicture));
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_CREATE_ELEVE'});
      })
  }
}

export const get = (autoecoleId, eleveId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/'+eleveId, {headers, method: "GET"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'GET_ELEVE_SUCCESSFUL', eleve: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
      })
  }
}

export const update = (autoecoleId, eleveId, values) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const body = JSON.stringify(values)
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/'+eleveId, {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'UPDATE_ELEVE_SUCCESSFUL', eleve: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_UPDATE_ELEVE'});
      })
  }
}

export const createPermis = (autoecoleId, eleveId, values) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    values.eleve = eleveId;
    const body = JSON.stringify(values)
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/'+eleveId+'/permis/',
      {headers, body, method: "PUT"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 201) {
          dispatch({type: 'CREATE_PERMIS_SUCCESSFUL', permis: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_CREATE_PERMIS'});
      })
  }
}

export const updatePermis = (autoecoleId, eleveId, permisId, values) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const body = JSON.stringify(values)
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/'+eleveId+'/permis/'+permisId,
    {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'UPDATE_PERMIS_SUCCESSFUL', permis: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_UPDATE_PERMIS'});
      })
  }
}

export const sendMessage = (autoecoleId, eleveId, values, modification = false, last = false) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    values.eleve = eleveId;
    const body = JSON.stringify(values)
    return fetch(url+'autoecole/' + autoecoleId + '/eleve/' + eleveId + '/message/', {headers, body, method: "PUT"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 201 && !modification) {
          dispatch({type: 'SEND_MESSAGE_SUCCESSFUL', message: res.data});
          return res.data;
        } else if (res.status === 201 && modification && !last) {
          dispatch({type: 'SEND_MESSAGE_MODIFICATION_SUCCESSFUL', message: res.data});
          return res.data;
        } else if (res.status === 201 && modification && last) {
          dispatch({type: 'SEND_MESSAGE_MODIFICATION_SUCCESSFUL', message: res.data});
          dispatch({type: 'SEND_MESSAGE_MODIFICATION_LAST_SUCCESSFUL', message: res.data});
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_SEND_MESSAGE'});
      })
  }
}

export const deleteMessage = (autoecoleId, eleveId, messageId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return fetch(url+'autoecole/' + autoecoleId + '/eleve/' + eleveId + '/message/' + messageId, {headers, method: "DELETE"})
      .then(res => {
        if (res.status < 500) {
          return res;
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 204) {
          dispatch({type: 'DELETE_MESSAGE_SUCCESSFUL', messageId});
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_SEND_MESSAGE'});
      })
  }
}

export const getMessages = (autoecoleId, eleveId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/'+eleveId+'/messages/',
      {headers, method: "GET"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          const messages = [];
          res.data.forEach((message) => {
            messages.push(message);
          });
          dispatch({type: 'GET_MESSAGES_SUCCESSFUL', messages: messages});
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_SEND_MESSAGE'});
      })
  }
}

export const sendNote = (autoecoleId, eleveId, values) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    values.eleve = eleveId;
    const body = JSON.stringify(values)
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/'+eleveId+'/note/',
      {headers, body, method: "PUT"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 201) {
          dispatch({type: 'SEND_NOTE_SUCCESSFUL' });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_SEND_NOTE'});
      })
  }
}


export const updateETG = (autoecoleId, eleveId, permisId, values) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    values.permis = permisId;
    const body = JSON.stringify(values)
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/'+eleveId+'/permis/'+permisId+'/etg',
      {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'UPDATE_ETG_SUCCESSFUL' });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_UPDATE_ETG'});
      })
  }
}

export const updateExamen = (autoecoleId, eleveId, permisId, values) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    values.permis = permisId;
    const body = JSON.stringify(values)
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/'+eleveId+'/permis/'+permisId+'/examen',
      {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'UPDATE_EXAMEN_SUCCESSFUL' });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_UPDATE_EXAMEN'});
      })
  }
}

export const goodForETG = (autoecoleId, eleveId, permisId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/'+eleveId+'/permis/'+permisId+'/etg/good',
      {headers, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'GOOD_ETG_SUCCESSFUL' });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_GOOD_ETG'});
      })
  }
}

export const goodForETGSpecifique = (autoecoleId, eleveId, permisId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/'+eleveId+'/permis/'+permisId+'/etg_specifique/good',
      {headers, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'GOOD_ETG_SPECIFIQUE_SUCCESSFUL' });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_GOOD_ETG_SPECIFIQUE'});
      })
  }
}


export const goodForExam = (autoecoleId, eleveId, permisId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/'+eleveId+'/permis/'+permisId+'/examen/good',
      {headers, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'GOOD_EXAM_SUCCESSFUL' });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_GOOD_EXAM'});
      })
  }
}

export const goodForExamHC = (autoecoleId, eleveId, permisId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/'+eleveId+'/permis/'+permisId+'/examen-hc/good',
      {headers, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'GOOD_EXAM_SUCCESSFUL' });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_GOOD_EXAM'});
      })
  }
}

export const deleteGoods = (autoecoleId, eleveId, permisId, type) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const body = JSON.stringify(type);
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/'+eleveId+'/permis/'+permisId+'/good/delete',
      {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'DELETE_GOOD_SUCCESSFUL'});
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_DELETE_GOOD'});
      })
  }
}


export const markReadyForExam = (autoecoleId, eleveId, permisId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/'+eleveId+'/permis/'+permisId+'/examen/ready',
      {headers, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'READY_EXAM_SUCCESSFUL' });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_READY_EXAM'});
      })
  }
}

export const markOk = (autoecoleId, eleveId, permisId, piece) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const body = JSON.stringify(piece)
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/'+eleveId+'/permis/'+permisId+'/piece/',
      {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'MARK_OK_SUCCESSFUL' });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_MARK_OK'});
      })
  }
}

export const createRelance = (autoecoleId, facturationId, values) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const body = JSON.stringify(values)
    return fetch(url+'autoecole/'+autoecoleId+'/facturation/'+facturationId+'/relance/',
      {headers, body, method: "PUT"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        console.log(res)
        if (res.status === 201) {
          dispatch({type: 'CREATE_RELANCE_SUCCESSFUL', relance: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_CREATE_RELANCE'});
      })
  }
}

export const archiveEleve = (autoecoleId, eleveId, data) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const body = JSON.stringify(data);
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/'+eleveId,
      {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          if (data.archived)
            dispatch({type: 'ARCHIVE_SUCCESSFUL'});
          else
            dispatch({type: 'UNARCHIVE_SUCCESSFUL'});
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        if (data.archived)
          dispatch({type: 'ERROR_ARCHIVE'});
        else
          dispatch({type: 'ERROR_UNARCHIVE'})
      })
  }
}

export const deleteEleve = (autoecoleId, eleveId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/'+eleveId, {headers, method: "DELETE"})
      .then(res => {
        if (res.status === 204) {
          return {status: res.status, data: {}};
        } else if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 204) {
          dispatch({type: 'DELETE_ELEVE_SUCCESSFUL', deletedId: eleveId });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_DELETE_ELEVE'});
      })
  }
}

export const sendPlanning = (autoecoleId, eleveId, data) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const body = JSON.stringify(data);
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/'+eleveId+'/planning/send', {headers, body, method: "POST"}).then(
      res => {
        if (res.status === 200) {
          dispatch({type: 'PLANNING_SENT_SUCESSFULL'})
        } else {
          dispatch({type: 'ERROR_PLANNING_SENT'})
        }
      })
  }
}

export const sendFacture = (autoecoleId, factureId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return fetch(url+'autoecole/'+autoecoleId+'/facture/'+factureId+'/send', {headers, method: "GET"}).then(
      res => {
        if (res.status === 200) {
          dispatch({type: 'FACTURE_SENT_SUCESSFULL'})
        } else {
          dispatch({type: 'ERROR_FACTURE_SENT'})
        }
      })
    }
}

export const updatePaidLesson = (autoecoleId, eleveId, lessonId, paid) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    const body = JSON.stringify({'id': lessonId, 'paid': paid});
    return fetch(url+'autoecole/'+autoecoleId+'/eleve/' + eleveId + '/paye/'+ lessonId, {headers, body, method: "POST"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 201) {
          dispatch({type: 'UPDATE_PAID_LESSON_SUCCESSFUL'});
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_UPDATE_PAID_LESSON'});
      })
  }
}

export const uploadProfilePicture = (autoecoleId, eleveId, picture) => {
  return (dispatch, getState) => {
    // No Content-Type it will be added automatically
    let headers = {};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    const body = new FormData();
    body.append('file', picture);

    return fetch(url+'autoecole/'+autoecoleId+'/eleve/' + eleveId + '/picture', {headers, body, method: "PUT"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'UPLOAD_PROFILE_PICTURE_SUCCESSFUL'});
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_UPLOAD_PROFILE_PICTURE'});
      })
  }
}

export const deleteProfilePicture = (autoecoleId, eleveId) => {
  return (dispatch, getState) => {
    // No Content-Type it will be added automatically
    let headers = {};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    return fetch(url+'autoecole/'+autoecoleId+'/eleve/' + eleveId + '/picture', {headers, method: "DELETE"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'DELETE_PROFILE_PICTURE_SUCCESSFUL', eleve: res.data});
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_DELETE_PROFILE_PICTURE'});
      })
  }
}
