import React, { Component } from 'react';
import { Button, Form, FormGroup, Input, Label, Col, Row } from 'reactstrap';
import moment from 'moment';
import OtoschoolDatePicker from '../OtoschoolDatePicker/OtoschoolDatePicker.jsx';
import { Formik, FieldArray, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import fr from 'localized-countries/data/fr';
import validate from '../../common/utils.js';

class EleveInfo extends Component {
  preprocess = values => {
    //Dirty hack, act II
    if (this.props.createMode)
      values.first_name = values.first_names.join(' ');
    values.is_from_polynesia = values.country === 'PF';
  }

  validationSchema = values => {
    this.preprocess(values);
    return Yup.object().shape({
      first_name: Yup.string().required('Un prénom est requis'),
      last_name: Yup.string().required('Un nom est requis'),
      dob: Yup.date().typeError('La date n\'est pas valide').required('Une date de naissance est requise'),
      registration_date: Yup.date().typeError('La date n\'est pas valide').required('Une date d\'inscription est requise'),
      email: Yup.string().email('L\'adresse email n\'est pas valide'),
      country: Yup.string().required('Un pays est requis'),
      civility: Yup.string().required('Une civilité est requise'),
    });
  }

  onSubmit = (values, { setSubmitting, setErrors }) => {
    delete values.permis;
    values.dob = moment(values.dob).format('YYYY-MM-DD');
    values.registration_date = moment(values.registration_date).format('YYYY-MM-DD');
    if (values.id_card_expire_time === null)
      delete values.id_card_expire_time;
    else
      values.id_card_expire_time = moment(values.id_card_expire_time).format('YYYY-MM-DD');
    this.props.callback(values);
  }

  archiveEleve = () => {
    const eleveToUpdate = this.props.eleve;
    eleveToUpdate.archived = !eleveToUpdate.archived;
    this.props.archiveEleve(eleveToUpdate.id, eleveToUpdate);
  }

  renderFormik = ({ values, errors, touched, status, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, handleReset, setTouched }) => (
      <Form onSubmit={handleSubmit}>
        <div className="ml-4 mr-4">
          <Row>
            <Col xl={4}>
              <FormGroup>
                <Label>{this.redStar} Civilité</Label>
                <Input type="select" name="civility" onChange={handleChange} onBlur={handleBlur} value={values.civility}>
                  <option value="M">Monsieur</option>
                  <option value="F">Madame</option>
                </Input>
                <span className="text-danger"><ErrorMessage name="civility"/></span>
              </FormGroup>
            </Col>
            <Col xl={4}>
              <FormGroup>
                <Label for="last_name">{this.redStar} Nom</Label>
                <Input type="text" name="last_name" onChange={handleChange} onBlur={handleBlur} value={values.last_name}/>
                <span className="text-danger"><ErrorMessage name="last_name"/></span>
              </FormGroup>
            </Col>
            <Col xl={4}>
              <FormGroup>
                <Label for="last_name_use">Nom d'usage</Label>
                <Input type="text" name="last_name_use" onChange={handleChange} onBlur={handleBlur} value={values.last_name_use}/>
                <span className="text-danger"><ErrorMessage name="last_name_use"/></span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="first_names">{this.redStar} Prénoms</Label>
                {this.props.createMode ? (
                  <FieldArray name="first_names" id="first_names" render={arrayHelpers => (
                    <Row>
                      {values.first_names.map((first_name, i) => (
                        <Col key={i} xl={4}>
                          <Input type="text" onChange={handleChange} onBlur={handleBlur} name={`first_names[${i}]`}/>
                        </Col>
                      ))}
                      <Col xl={4}>
                        <Button className="colorButton" onClick={() => arrayHelpers.push('')}>Ajouter un prénom</Button>
                      </Col>
                    </Row>
                  )}/>) : (
                    <Input type="text" name="first_name" onChange={handleChange} onBlur={handleBlur} value={values.first_name}/>
                  )}
                  <span className="text-danger"><ErrorMessage name="first_name"/></span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <FormGroup>
                <Label for="email">Email</Label>
                <Input type="text" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email}/>
                <span className="text-danger"><ErrorMessage name="email"/></span>
              </FormGroup>
            </Col>
            <Col xl={4}>
              <FormGroup>
                <Label for="mobile_phone">Vini</Label>
                <Input type="text" name="mobile_phone" value={values.mobile_phone} onChange={handleChange} onBlur={handleBlur}/>
              </FormGroup>
            </Col>
            <Col xl={4}>
              <FormGroup>
                <Label for="phone">Téléphone</Label>
                <Input type="text" name="phone" onChange={handleChange} onBlur={handleBlur} value={values.phone}/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <FormGroup>
                <Label for="dob">{this.redStar} Date de naissance</Label>
                <Row>
                  <Col>
                    <OtoschoolDatePicker name="dob" value={values.dob} onChange={handleChange} />
                  </Col>
                </Row>
                <span className="text-danger"><ErrorMessage name="dob"/></span>
              </FormGroup>
            </Col>
            <Col xl={4}>
              <FormGroup>
                <Label for="city_birth">Commune de naissance</Label>
                <Input type="text" name="city_birth" value={values.city_birth} onChange={handleChange} onBlur={handleBlur}/>
              </FormGroup>
            </Col>
            <Col xl={4}>
              <FormGroup>
                <Label for="department">Île / département</Label>
                <Input type="text" name="department" value={values.department} onChange={handleChange} onBlur={handleBlur}/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <FormGroup>
                <Label for="id_card_expire_time" className="text-left">Date expiration carte d'identité</Label>
                <Row>
                  <Col>
                    <OtoschoolDatePicker name="id_card_expire_time" value={values.id_card_expire_time} onChange={handleChange} />
                  </Col>
                </Row>
                <span className="text-danger"><ErrorMessage name="id_card_expire_time"/></span>
              </FormGroup>
            </Col>
            <Col xl={4}>
              <FormGroup>
                <Label for="country">{this.redStar} Pays</Label>
                <Input type="select" name="country" onChange={handleChange} onBlur={handleBlur} value={values.country}>
                    {Object.keys(fr).map((country_key, index) => (
                      <option key={index} value={country_key}>{fr[country_key]}</option>
                    ))}
                </Input>
                <span className="text-danger"><ErrorMessage name="country"/></span>
              </FormGroup>
            </Col>
            <Col xl={4}>
              <FormGroup>
                <Label for="registration_date">{this.redStar} Date d'inscription</Label>
                <Row>
                  <Col>
                    <OtoschoolDatePicker name="registration_date" value={values.registration_date} onChange={handleChange} />
                  </Col>
                </Row>
                <span className="text-danger"><ErrorMessage name="registration_date"/></span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <FormGroup>
                  <Label for="blood_group">{this.redStar} Groupe sanguin</Label>
                  <Input type="select" name="blood_group" onChange={handleChange} onBlur={handleBlur} value={values.blood_group}>
                    <option value={null}>Non</option>
                    <option value="A">A</option>
                    <option value="B">B</option>
                    <option value="AB">AB</option>
                    <option value="O">O</option>
                  </Input>
              </FormGroup>
            </Col>
            <Col xl={4}>
              <FormGroup>
                <Label for="rhesus">{this.redStar} Rhésus</Label>
                <Input type="select" name="rhesus" onChange={handleChange} onBlur={handleBlur} value={values.rhesus} disabled={values.blood_group === 'Non'}>
                  <option value={null}>Non</option>
                  <option value="+">+</option>
                  <option value="-">-</option>
                </Input>
              </FormGroup>
            </Col>
            <Col xl={4}>
              <FormGroup>
                <Label for="is_giver">{this.redStar} Donneur d'organes</Label>
                <Input type="select" name="is_giver" onChange={handleChange} onBlur={handleBlur} value={values.is_giver}>
                  <option value="true">Oui</option>
                  <option value="false">Non</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </div>
        <hr/>
        <h5>Adresse de l'élève</h5>
        <div className="ml-4 mr-4">
        <Row>
          <Col xl={4}>
            <FormGroup>
              <Label for="kilometer_points">Point Kilométrique</Label>
              <Input type="number" step="0.01" min="0" name="kilometer_points" value={values.kilometer_points} onChange={handleChange} onBlur={handleBlur}/>
            </FormGroup>
          </Col>
          <Col xl={4}>
            <FormGroup>
              <Label for="street_number">Numéro</Label>
              <Input type="text" name="street_number" value={values.street_number} onChange={handleChange} onBlur={handleBlur}/>
            </FormGroup>
          </Col>
          <Col xl={4}>
            <FormGroup>
              <Label for="street">Rue</Label>
              <Input type="text" name="street" value={values.street} onChange={handleChange} onBlur={handleBlur}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl={4}>
            <FormGroup tag="fieldset">
                <Label for="side">Côté Mer / Montagne</Label>
                  <Row>
                    <Col sm={3} md={3} lg={3} xl={3}>
                      <FormGroup check>
                        <Label check>
                          <Input type="radio" name="side" value="Mer" onChange={handleChange} onBlur={handleBlur} checked={values.side === 'Mer'}/>
                          Mer
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm={3} md={3} lg={3} xl={3}>
                      <FormGroup check>
                        <Label check>
                          <Input type="radio" name="side" value="Montagne" onChange={handleChange} onBlur={handleBlur} checked={values.side === 'Montagne'}/>
                          Montagne
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
            </FormGroup>
          </Col>
          <Col xl={4}>
            <FormGroup>
              <Label for="servitude">Servitude</Label>
              <Input type="text" name="servitude" value={values.servitude} onChange={handleChange} onBlur={handleBlur}/>
            </FormGroup>
          </Col>
          <Col xl={4}>
            <FormGroup>
              <Label for="district">Quartier</Label>
              <Input type="text" name="district" value={values.district} onChange={handleChange} onBlur={handleBlur}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl={4}>
            <FormGroup>
              <Label for="building">Immeuble</Label>
              <Input type="text" name="building" value={values.building} onChange={handleChange} onBlur={handleBlur}/>
            </FormGroup>
          </Col>
          <Col xl={4}>
            <FormGroup>
              <Label for="allotment">Lotissement</Label>
              <Input type="text" name="allotment" value={values.allotment} onChange={handleChange} onBlur={handleBlur}/>
            </FormGroup>
          </Col>
          <Col xl={4}>
            <FormGroup>
              <Label for="address_bis">Complément d'adresse</Label>
              <Input type="text" name="address_bis" value={values.address_bis} onChange={handleChange} onBlur={handleBlur}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl={4}>
            <FormGroup>
              <Label for="town">Commune</Label>
              <Input type="text" name="town" value={values.town} onChange={handleChange} onBlur={handleBlur}/>
            </FormGroup>
          </Col>
          <Col xl={4}>
            <FormGroup>
              <Label for="town_section">Section de Commune</Label>
              <Input type="text" name="town_section" value={values.town_section} onChange={handleChange} onBlur={handleBlur}/>
            </FormGroup>
          </Col>
          <Col xl={4}>
            <FormGroup>
              <Label for="postal_box">Boîte postale</Label>
              <Input type="text" name="postal_box" value={values.postal_box} onChange={handleChange} onBlur={handleBlur}/>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xl={4}>
            <FormGroup>
              <Label for="postal_code">Code postal</Label>
              <Input type="text" name="postal_code" value={values.postal_code} onChange={handleChange} onBlur={handleBlur}/>
            </FormGroup>
          </Col>
          <Col xl={4}>
            <FormGroup>
              <Label for="city">Commune</Label>
              <Input type="text" name="city" value={values.city} onChange={handleChange} onBlur={handleBlur}/>
            </FormGroup>
          </Col>
        </Row>
      </div>
        <FormGroup row>
          <Col sm={{offset:8, size:4}} xl={{offset:8, size:2}}>
            <Button className="colorButton" type="submit">{this.props.createMode ? "Enregistrer" : "Modifier"}</Button>
          </Col>
          {!this.props.createMode &&
          <Col sm={{offset:8, size:4}} xl={{offset:0, size:2}}>
            <Button className="colorButton" onClick={this.archiveEleve}>{this.props.eleve.archived ? "Rendre actif" : "Archiver"}</Button>
          </Col>
          }
        </FormGroup>
      </Form>
  )

  render() {
    if (this.props.createMode)
      this.redStar = <span className="text-danger">*</span>;
    else
      this.redStar = "";
    // Dirty hack to force formik to reload and change values
    // When user is on eleve and wants to add another one, formik v1.5.1 does not
    // reload even if props changed
    // Must be strange opti in react or js with nested type and empty array
    // or concurrency, shallow link, not update when in render but updated in formik
    // if ('first_names' in this.props.eleve) does not work
    // if (this.props.eleve.first_names !== undefined) does not work
    // Assign to prevent enableReinitialize and passing in create mode when f5
    const eleve = Object.assign({}, this.props.eleve);
    eleve.is_giver = "" + eleve.is_giver;
    eleve.first_names = [""];
    return (
      <Formik
        enableReinitialize={true}
        initialValues={eleve}
        validate={validate(this.validationSchema)} onSubmit={this.onSubmit}
        render={this.renderFormik} />
      );
  }
}

export default EleveInfo;
