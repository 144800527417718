import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row, FormGroup, FormFeedback } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { auth } from '../../actions';

import validate from '../../common/utils.js';
import TooltipItem from '../Tooltip/Tooltip.js';
import ModalItem from '../Modal/Modal.js';
import CGU from '../../common/cgu.pdf';

class Register extends Component {
  constructor(props) {
      super(props);
      this.state = {
        showCGU: false,
        accepted_cgu: false,
        initValues: {
          first_name: "",
          last_name: "",
          auto_ecole: "",
          email: "",
          password: "",
          confirmPassword: "",
        }
      };

      this.toggleCGU = this.toggleCGU.bind(this);
      this.toggleAcceptCGU = this.toggleAcceptCGU.bind(this);
      this.accepted_cgu = this.accepted_cgu.bind(this);
      this.declineCGU = this.declineCGU.bind(this);
      this.closeCGU = this.closeCGU.bind(this);
  }

  validationSchema = values => {
    return Yup.object().shape({
      first_name: Yup.string().required('Un prénom est requis'),
      last_name: Yup.string().required('Un nom est requis'),
      auto_ecole: Yup.string().required('Le nom de votre auto école est requis'),
      email: Yup.string().email('L\'adresse email n\'est pas valide').required('Une adresse email est requise'),
      password: Yup.string()
      .min(6, `Le mot de passe doit faire au moins ${6} caractères`)
      .matches(/(?=.*\d)(?=.*[A-z]).{6,}/, 'Le mot de passe doit contenir au moins six caractères dont un chiffre\n')
      .required('Le mot de passe est requis'),
      confirmPassword: Yup.string()
      .oneOf([values.password], 'Les mots de passe ne correspondent pas')
      .required('Il faut confirmer le mot de passe'),
      accepted_cgu: Yup.bool()
      .test('accepted_cgu', 'Il faut accepter les CGU pour utiliser l\'application', value => value === true),
    });
  }

  onSubmit = (values, { setSubmitting, setErrors }) => {
    this.props.register(values);
  }

  closeCGU() {
    this.setState({
      showCGU: false,
    });
  }

  toggleCGU() {
    this.setState({
      showCGU: !this.state.showCGU,
    });
  }

  accepted_cgu() {
    this.closeCGU();
    this.setState({
      accepted_cgu: true
    });
  }

  declineCGU() {
    this.closeCGU();
    this.setState({
      accepted_cgu: false
    });
  }

  toggleAcceptCGU() {
    this.setState({
      accepted_cgu: !this.state.accepted_cgu
    });
  }

  render() {
    if (this.props.isAuthenticated)
      return <Redirect to="/" />
    let message = <p className="text-muted">Créez votre compte</p>;
    if (this.props.errors.length > 0) {
      message = []
      message.push(<p key={0} className="text-danger">Erreur lors de la création du compte</p>);
      this.props.errors.forEach((error, index) => {
        message.push(<p key={index+1} className="text-danger">{error.message}</p>);
      });
    }
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="9" lg="7" xl="6">
              <Card className="mx-4">
                <CardBody className="p-4">
                  <Formik
                    initialValues={this.state.initValues}
                    validate={validate(this.validationSchema)}
                    onSubmit={this.onSubmit}
                    render={
                      ({
                        values,
                        errors,
                        touched,
                        status,
                        dirty,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        handleReset,
                        setTouched
                      }) => (
                    <Form onSubmit={handleSubmit}>
                      <h1>Inscription</h1>
                        {message}
                        <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend"><InputGroupText><i className="icon-user"></i></InputGroupText></InputGroupAddon>
                        <Input type="text" placeholder="Prénom" name="first_name" autoComplete="given-name"
                          valid={touched.first_name && !errors.first_name} invalid={touched.first_name && !!errors.first_name} required
                          onChange={handleChange} onBlur={handleBlur} value={values.first_name}/>
                        <Input type="text" placeholder="Nom" name="last_name" autoComplete="family-name"
                          required valid={touched.last_name && !errors.last_name} invalid={touched.last_name && !!errors.last_name}
                          onChange={handleChange} onBlur={handleBlur} value={values.last_name} />
                        <FormFeedback>{errors.first_name && touched.first_name && errors.first_name}</FormFeedback>
                        <FormFeedback>{errors.last_name && touched.last_name && errors.last_name}</FormFeedback>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend"><InputGroupText><i className="fa fa-car"></i></InputGroupText></InputGroupAddon>
                        <Input type="text" placeholder="Nom de votre auto école" name="auto_ecole" autoComplete="auto_ecole"
                          required valid={touched.auto_ecole && !errors.auto_ecole} invalid={touched.auto_ecole && !!errors.auto_ecole}
                          onChange={handleChange} onBlur={handleBlur} value={values.auto_ecole} />
                        <InputGroupAddon addonType="append" id="question-societe-register"><InputGroupText><i className="fa fa-question-circle"></i></InputGroupText></InputGroupAddon>
                        <TooltipItem target="question-societe-register" trigger="hover focus" autohide={true}
                          content="Le nom de votre auto école sera utilisé pour générer les factures et documents officiels."/>
                        <FormFeedback>{errors.auto_ecole && touched.auto_ecole && errors.auto_ecole}</FormFeedback>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend"><InputGroupText>@</InputGroupText></InputGroupAddon>
                        <Input type="text" placeholder="Adresse email" name="email" autoComplete="email"
                          required valid={touched.email && !errors.email} invalid={touched.email && !!errors.email}
                          onChange={handleChange} onBlur={handleBlur} value={values.email} />
                        <FormFeedback>{errors.email && touched.email && errors.email}</FormFeedback>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend"><InputGroupText><i className="icon-lock"></i></InputGroupText></InputGroupAddon>
                        <Input type="password" placeholder="Mot de passe" name="password" autoComplete="new-password"
                          required valid={touched.password && !errors.password} invalid={touched.password && !!errors.password}
                          onChange={handleChange} onBlur={handleBlur} value={values.password} />
                        <Input type="password" placeholder="Confirmer" name="confirmPassword"
                          required valid={touched.confirmPassword && !errors.confirmPassword} invalid={touched.confirmPassword && !!errors.confirmPassword}
                          onChange={handleChange} onBlur={handleBlur} value={values.confirmPassword} />
                        <FormFeedback>{errors.password && touched.password && errors.password}</FormFeedback>
                        <FormFeedback>{errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}</FormFeedback>
                      </InputGroup>
                      <p className="text-muted">Utilisez au moins six caractères avec des lettres et des chiffres.</p>
                      <FormGroup>
                        <InputGroup>
                          <Input type="checkbox" name="accepted_cgu"
                            valid={this.state.accepted_cgu} invalid={!!errors.accepted_cgu}
                            onChange={this.toggleAcceptCGU} onBlur={handleBlur} checked={values.accepted_cgu = this.state.accepted_cgu} />
                          <span>
                            En cochant cette case, vous certifiez avoir lu et accepté sans réserve les
                            <a href={CGU} target="_blank" ref="noopener noreferer"> Conditions Générales d'Utilisation</a> de otoschool.
                          </span>
                          <FormFeedback>{errors.accepted_cgu && touched.accepted_cgu && errors.accepted_cgu}</FormFeedback>
                          <ModalItem modalState={this.state.showCGU} toggle={this.toggleCGU}
                            accept="Accepter" acceptAction={this.accepted_cgu} decline="Refuser" declineAction={this.declineCGU}
                            title="Conditions Générales d'Utilisation" content="Texte CGU" />
                        </InputGroup>
                        </FormGroup>
                      <Button type="submit" color="success" block>Inscrivez-vous</Button>
                    </Form>
                  )} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = state => {
  let errors = [];
  if (state.auth.errors) {
    errors = Object.keys(state.auth.errors).map(field => {
      return {field, message: state.auth.errors[field]};
    });
    errors = errors.filter((error) => error['field'] !== 'detail');
  }
  console.log(errors)
  return {
    errors,
    isAuthenticated: state.auth.isAuthenticated
  };
}

const mapDispatchToProps = dispatch => {
  return {
    register: (values) => dispatch(auth.register(values)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
