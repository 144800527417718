import React, { Component } from 'react';
import { Button, Form, FormGroup, Input, Label, Col} from 'reactstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import validate from '../../common/utils.js';

class Produit extends Component {
  preprocess = values => {
  }

  validationSchema = values => {
    this.preprocess(values);
    return Yup.object().shape({
      name: Yup.string().required('Un intitulé est requis'),
      tva: Yup.string().required('Une TVA est nécessaire')
    });
  }

  onSubmit = (values, { setSubmitting, setErrors }) => {
    const dataToSend = {};
    dataToSend.article = {
      type: 'produit',
      name: values.name,
    };
    dataToSend.tva = values.tva;
    const tvaValue = Number(this.props.tva.find((tva) => tva.id === values.tva).tva_value);
    const cpsValue = Number(this.props.tva.find((tva) => tva.tva_type === "C").tva_value);
    dataToSend.price = (values.price * (100 / (100 + tvaValue + cpsValue))).toFixed(2);
    dataToSend.id = values.id;
    this.props.callback(dataToSend);
  }

  renderFormik = ({ values, errors, touched, status, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, handleReset, setTouched }) => (
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Intitulé</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
          <Input type="text" name="name" onChange={handleChange} onBlur={handleBlur} value={values.name}/>
          <span className="text-danger"><ErrorMessage name="name"/></span>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Montant TTC</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
          <Input type="number" step="any" min={0} name="price" onChange={handleChange} onBlur={handleBlur} value={values.price}/>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={{offset:8, size:4}} xl={{offset:8, size:2}}>
            <Button className="colorButton" type="submit">{this.props.createMode ? "Ajouter" : "Modifier"}</Button>
          </Col>
        </FormGroup>
      </Form>
  )

  render() {
    return (
      <Formik enableReinitialize={true} initialValues={this.props.produit}
              validate={validate(this.validationSchema)} onSubmit={this.onSubmit}
              render={this.renderFormik} />
      );
  }
}

Produit.defaultProps = {
  name: "",
  tva: 1,
  price: 0,
  additional_price: 0,
};
export default Produit;
