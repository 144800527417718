import React, { Component } from 'react';
import { Button, Form, FormGroup, Input, Label, Col} from 'reactstrap';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';

import validate from '../../common/utils.js';

class Organisme extends Component {
  constructor(props) {
    super(props);
    this.options = [
      {value: true, label: 'Non'},
      {value: false, label: 'Oui'}
    ];
  }

  preprocess = values => {
  }

  validationSchema = values => {
    this.preprocess(values);
    return Yup.object().shape({
      name: Yup.string().required('Un nom est requis'),
      orga_type: Yup.string().required('Un type d\'établissement est requis'),
      email: Yup.email('L\'adresse email n\'est pas valide')
    });
  }

  onSubmit = (values, { setSubmitting, setErrors }) => {
    values.must_pay_tva = values.must_pay_tva.value;
    this.props.callback(values);
  }

  renderFormik = ({ values, errors, touched, status, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, handleReset, setTouched }) => (
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Nom</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
          <Input type="text" name="name" onChange={handleChange} onBlur={handleBlur} value={values.name}/>
          <span className="text-danger"><ErrorMessage name="name"/></span>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Établissement</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
          <Input type="select" name="orga_type" onChange={handleChange} onBlur={handleBlur} value={values.orga_type}>
            <option value="Auto école">Auto-École</option>
            <option value="Agence">Agence</option>
            <option value="Entreprise">Entreprise</option>
            <option value="Administration">Administration</option>
          </Input>
          <span className="text-danger"><ErrorMessage name="orga_type"/></span>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Adresse</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
          <Input type="text" name="address" onChange={handleChange} onBlur={handleBlur} value={values.address}/>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Code postal</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
          <Input type="text" name="postal_code" onChange={handleChange} onBlur={handleBlur} value={values.postal_code}/>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Ville</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
          <Input type="text" name="city" onChange={handleChange} onBlur={handleBlur} value={values.city}/>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Nom du contact</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
          <Input type="text" name="contact_name" onChange={handleChange} onBlur={handleBlur} value={values.contact_name}/>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Téléphone</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
          <Input type="text" name="phone" onChange={handleChange} onBlur={handleBlur} value={values.phone}/>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Email</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
          <Input type="text" name="email" onChange={handleChange} onBlur={handleBlur} value={values.email}/>
          <span className="text-danger"><ErrorMessage name="email"/></span>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>RIB</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
          <Input type="text" name="rib" onChange={handleChange} onBlur={handleBlur} value={values.rib}/>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Exonéré de TVA</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
            <Select name="must_pay_tva" value={values.must_pay_tva}
              options={this.options}
              onChange={(selectedOptions) => {
                handleChange({target: {name: 'must_pay_tva', value: selectedOptions}});
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={{offset:8, size:4}} xl={{offset:8, size:2}}>
            <Button className="colorButton" type="submit">{this.props.createMode ? "Ajouter" : "Modifier"}</Button>
          </Col>
        </FormGroup>
      </Form>
  )

  render() {
    const must_pay_tva = this.props.organisme.must_pay_tva;
    if (typeof must_pay_tva === typeof true)
      this.props.organisme.must_pay_tva = must_pay_tva ? this.options[0] : this.options[1];
    return (
      <Formik enableReinitialize={true} initialValues={this.props.organisme}
              validate={validate(this.validationSchema)} onSubmit={this.onSubmit}
              render={this.renderFormik} />
      );
  }
}

Organisme.defaultProps = {
  organisme: {
    orga_type: "",
    name: "",
    address: "",
    postal_code: "",
    city: "",
    contact_name: "",
    phone: "",
    email: "",
    rib: "",
    must_pay_tva: true,
  }
};

export default Organisme;
