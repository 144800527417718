import React, { Component } from 'react';

import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import moment from 'moment';

class OtoschoolTimePicker extends Component {
  onChange = (momentValue) => {
    const event = {
      target: {
        name: this.props.name,
        value: momentValue.format('HH:mm')
      }
    };
    this.props.onChange(event);
  }

  render() {
    return (
      <TimePicker
        showSecond={false}
        onChange={this.onChange}
        value={this.props.value !== undefined && this.props.value !== null ? moment(this.props.value, 'HH:mm') : moment().startOf('day')}
        minuteStep={this.props.dayHours ? 1 : 5}/>
    );
  }
}

export default OtoschoolTimePicker;
