const initialState = {
  eleveId: undefined,
  eleve: {},
  permis: {}
}

export default function eleve(state=initialState, action) {
  switch (action.type) {
    case 'GET_ELEVE_SUCCESSFUL':
      if(action.eleve.permis.length > 0)
        return {...state, eleve: action.eleve, permis:action.eleve.permis.slice(-1)[0]};
      return {...state, eleve:action.eleve};
    case 'UPDATE_ELEVE_SUCCESSFUL':
      return {...state, eleve: action.eleve};
    case 'CREATE_PERMIS_SUCCESSFUL':
      return {...state, permis: action.permis};
    case 'UPDATE_PERMIS_SUCCESSFUL':
      return {...state, permis: action.permis};
    case 'SHOW_ELEVE':
      return {...state, eleveId: action.eleveId};
    case 'UPLOAD_PROFILE_PICTURE_SUCCESSFUL':
      return {...state, eleveId: state.eleveId};
    case 'DELETE_PROFILE_PICTURE_SUCCESSFUL':
      return {...state, eleveId: state.eleveId, eleve: action.eleve};
    default:
      return state;
  }
}
