import React, { Component } from 'react';
import {Row, Col, Input, Button} from 'reactstrap';

import styles from './ArticleLine.module.css';

class ArticleLine extends Component {

  updateSelectArticle = e => {
    const index = e.nativeEvent.target.selectedIndex;
    const type = e.nativeEvent.target[index].attributes.type.value;
    const value = e.nativeEvent.target.value;
    const articles = {
      produit: this.props.produits.entities.products,
      service: this.props.services.entities.services,
      prestation: this.props.prestations.entities.prestations,
      formule: this.props.formules.entities.formules
    };
    const article = articles[type][value];
    // Create false events
    const updatePrice = {
      target: {
        name: "price",
        value: Number(article.price)
      }
    };
    const updateMontantTTC = {
      target: {
        name: "montantTTC",
        value: article.montantTTC
      }
    };
    const updatePriceDisplay = {
      target: {
        name: "priceDisplay",
        value: article.priceDisplay === undefined ? article.price_with_discount : article.priceDisplay
      }
    };
    const updateDiscount = {
      target: {
        name: "discount",
        value: Number(article.discount)
      }
    };
    const updateQuantity = {
      target: {
        name: "quantity",
        value: 1
      }
    };
    const updateType = {
      target: {
        name: "type",
        value: type
      }
    };
    const updateTVA = {
      target: {
        name: "tva",
        value: article.tva !== null ? Number(article.tva.tva_value)/100 : null
      }
    };

    const updateCPS = {
      target: {
        name: "cps",
        value: article.cps !== null ? Number(article.cps.tva_value)/100 : null
      }
    };

    this.onChange(e);
    this.onChange(updatePrice);
    this.onChange(updateMontantTTC);
    this.onChange(updatePriceDisplay);
    this.onChange(updateQuantity);
    this.onChange(updateDiscount);
    this.onChange(updateType);
    this.onChange(updateTVA);
    this.onChange(updateCPS);
  }

  onChange = e => {
    this.props.update(e);
  }

  onBlur = e => {
    this.props.update(e);
  }

  addNewRow = () => {
    this.props.callback();
  }

  deleteLine = () => {
    this.props.deleteLine();
  }

  render() {
    return (
      <div>
      <Row className="no-gutters text-center">
        <Col xl={4}>
          <Input type="select" name="article"
            disabled={!(this.props.createMode || this.props.editMode)}
            onChange={this.updateSelectArticle} value={this.props.article}>
            <option value={this.props.createMode ? '' : this.props.sold_article}>{this.props.createMode ? "Choisissez un article" : this.props.name}</option>
            <option disabled>Produits</option>
            {this.props.produits.result.map((productId, index) => (
              <option key={index} type="produit" value={this.props.produits.entities.products[productId].id}>
                {this.props.produits.entities.products[productId].article.name}
              </option>
            ))}
            <option disabled>Services</option>
            {this.props.services.result.map((serviceId, index) => (
              <option key={index} type="service" value={this.props.services.entities.services[serviceId].id}>
                {this.props.services.entities.services[serviceId].article.name}
              </option>
            ))}
            <option disabled>Prestations</option>
            {this.props.prestations.result.map((prestationId, index) => (
              <option key={index} type="prestation" value={this.props.prestations.entities.prestations[prestationId].id}>
                {this.props.prestations.entities.prestations[prestationId].article.name}
              </option>
            ))}
            <option disabled>Formules</option>
            {this.props.formules.result.map((formuleId, index) => (
              <option key={index} type="formule" value={this.props.formules.entities.formules[formuleId].id}>
                {this.props.formules.entities.formules[formuleId].article.name}
              </option>
            ))}
          </Input>
        </Col>
        <Col className={styles.alignWithTop}>
          <div className="px-4 mx-2">
            <Input type="number" name="quantity" step="1"
                disabled={!(this.props.createMode || this.props.editMode)}
                onChange={this.onChange} onBlur={this.onBlur} value={this.props.quantity}/>
          </div>
        </Col>
        <Col>
          <div className="px-3">
            <Input type="text" name="priceDisplay" step="any"
              disabled={!(this.props.createMode || this.props.editMode)}
              onChange={this.onChange} onBlur={this.onBlur} value={Number(this.props.priceDisplay).toFixed(0)}/>
          </div>
        </Col>
        <Col>
          <div className="px-1">
            <Input type="text" name="totalHTDisplay" step="any"
              disabled
              value={Number(this.props.totalHTDisplay).toFixed(0)}/>
          </div>
        </Col>
        <Col>
          <div className="px-1">
            <Input type="text" name="totalTVADisplay" step="any"
              disabled
              value={Number(this.props.totalTVADisplay).toFixed(0)}/>
          </div>
        </Col>
        <Col>
          <div className="px-1">
            <Input type="text" name="totalCPSDisplay" step="any"
              disabled
              value={Number(this.props.totalCPSDisplay).toFixed(0)}/>
          </div>
        </Col>
        <Col>
          <div className="px-1">
            <Input type="text" name="totalTTCDisplay" step="any"
              disabled
              value={Number(this.props.totalTTCDisplay).toFixed(0)}/>
          </div>
        </Col>
      </Row>

      {this.props.createMode &&
      <Row>
        <Col>
          <Button className="addEleve" onClick={this.addNewRow}><i className="fa fa-plus" aria-hidden="true"></i></Button>
          {(this.props.createMode && this.props.index !== 0) &&
            <Button color="danger" onClick={this.deleteLine}><i className="fa fa-trash-o" aria-hidden="true"></i></Button>
          }
        </Col>
      </Row>
      }

      </div>
    );
  }
}

ArticleLine.defaultProps = {
  quantity: 0,
  priceDisplay: 0,
  totalHTDisplay: 0,
  totalTVADisplay: 0,
  totalCPSDisplay: 0,
  totalTTCDisplay: 0,
  discount: 0,
  article: '',
  createMode: true
}

export default ArticleLine;
