import { denormalizePrestationStatut, normalizePrestationStatut } from '../schema/prestationsStatut.js';

const initialState = {
  result: [],
  entities: {prestationsStatut: {}},
  oldest: null,
  newest: null,
}

export default function products(state=initialState, action) {
  switch (action.type) {
    case 'CREATE_PRESTATIONS_STATUT_SUCCESSFUL':
      const prestationsStatut = denormalizePrestationStatut(state.result, state.entities);
      prestationsStatut.push(action.prestationStatut);
      return {...state, ...normalizePrestationStatut(prestationsStatut)};
    case 'DELETE_PRESTATIONS_STATUT_SUCCESSFUL':
      const newState = {...state};
      delete newState.entities.prestationsStatut[action.prestationStatutId];
      return newState;
    case 'UPDATE_PRESTATIONS_STATUT_SUCCESSFUL':
      const updatedState = {...state};
      updatedState.entities.prestationsStatut[action.prestationStatut.id] = action.prestationStatut;
      return {...state};
    case 'GET_PRESTATIONS_STATUT_SUCCESSFUL':
      const newest_date = action.prestationsStatut.length > 0 ?
        new Date(action.prestationsStatut[0].start_date) : state.newest;
      const oldest_date = action.prestationsStatut.length > 0 ?
        new Date(action.prestationsStatut.slice(-1)[0].start_date) : state.oldest;
      if (state.newest === null || state.newest < newest_date)
        state.newest = newest_date;
      if (state.oldest === null || oldest_date < state.oldest)
        state.oldest = oldest_date;
      const oldPlanning = state.result.length > 0 ?
        denormalizePrestationStatut(state.result, state.entities) : [];
      const newPlanning = oldPlanning.concat(action.prestationsStatut);
      return {...state, ...normalizePrestationStatut(newPlanning)};
    default:
      return state;
  }
}
