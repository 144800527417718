import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';

class TooltipItem extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }

  render() {
    return (
      <div>
        <span>
          <Tooltip placement="bottom" isOpen={this.state.tooltipOpen} target={this.props.target} toggle={this.toggle} autohide={this.props.autohide} trigger={this.props.trigger}>
            {this.props.content}
          </Tooltip>
        </span>
      </div>
    );
  }
}

export default TooltipItem;
