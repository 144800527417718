const initialState = {
  generatingFicheVerte: false,
};

export default function notifications(state=initialState, action) {
  switch (action.type) {
    case 'DONE_FICHE_VERTE':
      return {...state, generatingFicheVerte: false};
    case 'GENERATE_FICHE_VERTE':
      return {...state, generatingFicheVerte: true};
    default:
      return state;
  }
}
