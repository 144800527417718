import {normalizeEleve, denormalizeEleve} from '../schema/eleve.js';
import produce from "immer";

const initialState = {
  result: [],
  entities: {eleves: {}}
}

export default function eleves(state=initialState, action) {
  switch (action.type) {
    case 'GET_ELEVES_SUCCESSFUL':
      return {...state, ...normalizeEleve(action.eleves)};
    case 'GET_ELEVE_SUCCESSFUL':
      const getEleve = produce(state, draftState => {
        draftState.entities.eleves[action.eleve.id] = action.eleve;
      });
      return {...state, ...getEleve};
    case 'UPDATE_ELEVE_SUCCESSFUL':
      const updateEleve = produce(state, draftState => {
        draftState.entities.eleves[action.eleve.id] = action.eleve;
      });
      return {...state, ...updateEleve};
    case 'CREATE_ELEVE_SUCCESSFUL':
      const eleves = denormalizeEleve(state.result, state.entities);
      eleves.push(action.eleve);
      return {...state, ...normalizeEleve(eleves)};
    case 'UPDATE_PERMIS_SUCCESSFUL':
      return {...state};
    case 'DELETE_ELEVE_SUCCESSFUL':
      const newStateDelete = produce(state, draftState => {
        if (action.deletedId in draftState.entities.eleves) {
          delete draftState.entities.eleves[action.deletedId];
          draftState.result.splice(draftState.result.findIndex(id => id === action.deletedId), 1);
        }
      });
      return {...state, ...newStateDelete};
    default:
      return state;
  }
}
