import {normalizeAlerteMessage, denormalizeAlerteMessage} from '../schema/alerteMessage.js';
import produce from "immer";

const initialState = {
  result: [],
  entities: {alerteMessages: {}},
  unread: 0
}

export default function alertemessages(state=initialState, action) {
  switch (action.type) {
    case 'GET_ALERTE_MESSAGES_SUCCESSFUL':
      const newState = normalizeAlerteMessage(action.alerteMessages);
      newState.unread = action.alerteMessages.filter((msg) => !msg.read).length;
      return {...state, ...newState};
    case 'MARK_ALERTE_MESSAGE_SUCCESSFUL':
      const unread = 0;
      return {...state, unread: 0};
    case 'USER_LOADED':
      return {...state, unread: action.user.profile.unread}
    default:
      return state;
  }
}
