import React, { Component } from 'react';
import { Container, Nav, Col, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import OrganismesTable from './OrganismesTable.jsx';

class OrganismesPresentation extends Component {
  state = {
    activeTab: "1",
  }

  activateTab = activeTab => {
    this.setState({
      activeTab: activeTab
    });
  }

  render() {
    return (
      <Container fluid>
        <h4 className="pageTitle">Paramètres</h4>
        <Nav tabs className="no-gutters otoschoolNavTabs">
          <Col xl={3}>
              <NavItem className="otoschoolTab">
                <NavLink
                  active={this.state.activeTab === "1"}
                  onClick={() => { this.activateTab("1"); }}>
                  <h4>Établissements</h4>
                </NavLink>
              </NavItem>
            </Col>
          </Nav>
        <TabContent activeTab={this.state.activeTab} className="otoschoolTabContent">
          <TabPane tabId="1">
            <OrganismesTable
              organismes={this.props.organismes}
              editOrganisme={this.props.editOrganisme}
              addOrganisme={this.props.addOrganisme}
            />
          </TabPane>
        </TabContent>
      </Container>
    );
  }
}

export default OrganismesPresentation;
