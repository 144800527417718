import React, { PureComponent } from 'react';

import {Row, Col, Button } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import {priceFormatter} from '../../../common/formatter.js';

export default class PrestationsTable extends PureComponent {
  constructor(props) {
    super(props);
    this.columns = [
      {dataField: 'id', hidden: true},
      {dataField: 'name', text: 'Prestations', sort: true},
      {dataField: 'price', text: 'Tarif', sort: true, formatter: priceFormatter},
      {dataField: 'tva', text: 'TVA', sort: true},
      {dataField: 'cps', text: 'CPS', sort: true},
      {dataField: 'montantTTC', text: 'Montant TTC', sort: true, formatter: priceFormatter},
      {dataField: 'actions', text: 'Actions'},
    ];
  }

  prepareData = () => {
    const keys = this.props.prestations.result;
    const dataTable = [];
    keys.forEach(prestationId => {
      const prestation = Object.assign({}, this.props.prestations.entities.prestations[prestationId]);
      prestation.data = {}
      prestation.data.id = prestation.id;
      prestation.data.tva = prestation.tva.id;
      prestation.data.price = prestation.montantTTC.toFixed(0);
      prestation.data.name = prestation.article.name;
      prestation.data.duration = prestation.article.duration;
      prestation.data.article = prestation.article;
      prestation.tva = prestation.tva.tva_value + " %";
      prestation.cps = prestation.cps.tva_value + " %";
      prestation.price = Number(prestation.price).toFixed(0);
      prestation.montantTTC = Number(prestation.montantTTC).toFixed(0);
      prestation.name = prestation.article.name;
      prestation.actions =
      <div actions="actions">
        <Button color="success" className="ml-lg-2 ml-xl-3" onClick={() => {this.props.edit(prestation.data)}}><i className="fa fa-edit"></i></Button>
        <Button color="warning" className="ml-lg-2 ml-xl-3" onClick={() => {this.props.toggleComputePrestation(prestation.data)}}
          title={prestation.article.compute_prestation ? "Ne pas compter la prestation" : "Compter la prestation"}>
          {prestation.article.compute_prestation ? <i className="fa fa-eye-slash"></i> : <i className="fa fa-eye"></i>}
        </Button>
        <Button color="danger" className="ml-lg-2 ml-xl-3" onClick={() => {this.props.delete(prestationId)}}><i className="fa fa-trash-o"></i></Button>
      </div>;
      dataTable.push(prestation);
    })
    return dataTable;
  }

  render() {
    const { SearchBar } = Search;
    let table = [];
    if (this.props.prestations.result.length > 0) {
      table = this.prepareData();
    }
    return (
      <ToolkitProvider
        keyField='id' data={table}
        columns={this.columns}
        search>
        {
          props => (
            <div>
              <Row>
                <Col xs={6} sm={6} md={5} lg={4} xl={4}>
                  <SearchBar {...props.searchProps} placeholder="Recherche"/>
                </Col>
              </Row>
              <div className="otoschoolTable">
                <BootstrapTable {...props.baseProps }
                  pagination={paginationFactory()}
                  striped/>
              </div>
            </div>
          )
        }
      </ToolkitProvider>
    );
  }
}
