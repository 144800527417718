import {url} from '../properties.js';

export const getPaiements = (autoecoleId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    return fetch(url+'autoecole/'+autoecoleId+'/paiements/', {headers, method: "GET"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'GET_PAIEMENTS_SUCCESSFUL', paiements: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
      })
  }
}

export const getPaiementsEleve = (autoecoleId, eleveId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    return fetch(url+'autoecole/'+autoecoleId+'/eleve/'+eleveId+'/paiements/', {headers, method: "GET"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          dispatch({type: 'GET_PAIEMENTS_SUCCESSFUL', paiements: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
      })
  }
}

export const addPaiement = (autoecoleId, data) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    data.auto_ecole = autoecoleId;
    const body = JSON.stringify(data);
    return fetch(url+'autoecole/'+autoecoleId+'/paiement/', {headers, body, method: "PUT"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 201) {
          dispatch({type: 'CREATE_PAIEMENT_SUCCESSFUL', paiements: res.data.paiements, factures: res.data.factures_payes });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_CREATE_PAIEMENT'});
      })
  }
}

export const deletePaiement = (autoecoleId, paiementId) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    return fetch(url+'autoecole/'+autoecoleId+'/paiement/'+paiementId, {headers, method: "DELETE"})
      .then(res => {
        if (res.status === 204) {
          return {status: res.status, data: {}};
        } else if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 204) {
          dispatch({type: 'DELETE_PAIEMENT_SUCCESSFUL', deletedId: paiementId});
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_DELETE_PAIEMENT'});
      })
  }
}

export const addPaiements = (autoecoleId, data) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    data.auto_ecole = autoecoleId;
    const body = JSON.stringify(data);
    return fetch(url+'autoecole/'+autoecoleId+'/paiements/list', {headers, body, method: "PUT"})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          throw res;
        }
      })
      .then(res => {
        if (res.status === 201) {
          dispatch({type: 'CREATE_PAIEMENTS_SUCCESSFUL', paiements: res.data });
          return res.data;
        } else if (res.status === 403 || res.status === 401) {
          dispatch({type: 'AUTHENTICATION_ERROR', data: res.data});
          throw res.data;
        }
        dispatch({type: 'ERROR_CREATE_PAIEMENTS'});
      })
  }
}
