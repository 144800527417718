import React, { PureComponent } from 'react';
import { Row, Col, Label, Button } from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import OtoschoolDatePicker from '../OtoschoolDatePicker/OtoschoolDatePicker.jsx';

import moment from 'moment';

class Bons extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      date: null,
      depotDate: null,
      selected: [],
      intervenant: '',
    };

    this.columns = [
      {dataField: 'id', hidden: true},
      {dataField: 'eleve', text: 'Élève', sort: true,
        formatter: this.eleveFormatter, filterValue: this.eleveSearchFilter},
      {dataField: 'category', text: 'Catégorie', sort: true},
      {dataField: 'actions', text: 'Actions', sort: true},
    ];

    this.selectRow = {
      mode: 'checkbox',
      hideSelectColumn: false,
      clickToSelect: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        this.select(row, rowIndex, isSelect);
      },
      onSelectAll: (isSelect, rows, e) => {
        this.selectAll(isSelect, rows, e);
      }
    };
  }

  eleveFormatter = (cell, row) => {
    const eleve = this.props.eleves.filter((eleve) => eleve.id === cell)[0];
    return eleve !== undefined ?
      <a target="_blank" rel="noopener noreferrer" href={"#/eleve/" + eleve.id}>
        {eleve.first_name.split(' ')[0]} {eleve.last_name}
      </a>
      : "Élève inconnu";
  }

  eleveSearchFilter = (cell, row) => {
    const eleve = this.props.eleves.filter((eleve) => eleve.id === cell)[0];
    return eleve !== undefined ?
      eleve.first_name.split(' ')[0] + " " + eleve.last_name
      : "Élève inconnu";
  }

  select = (row, index, isSelect) => {
    this.setState(state => {
      const selected = isSelect ?
        [...state.selected, row.id]
        : state.selected.filter((id) => id !== row.id);
      return { selected: selected };
    });
  }

  selectAll = (isSelect, rows, e) => {
    const ids = isSelect ? rows.map((row) => row.id) : []
    this.setState({ selected: ids });
  }

  dateChange = (e) => {
    this.setState({ date: e.target.value });
  }

  depotDateChange = (e) => {
    this.setState({ depotDate: e.target.value });
  }

  updateIntervenant = event => {
    this.setState({ intervenant: event.target.value });
  }

  createBordereau = (event) => {
    const bordereau = {
      date: moment(this.state.date).format('YYYY-MM-DD'),
      depot_date: this.state.depotDate ?
        moment(this.state.depotDate).format('YYYY-MM-DD') : null,
      deposant: this.state.intervenant,
      permis: this.state.selected,
      type: this.props.type
    };
    this.props.createBordereau(bordereau);
    this.setState({ selected: [] });
  }

  prepareData = (bons) => {
    return bons.map((bon, index) => {
      const table = {};
      table.key = index;
      table.id = bon.permis.id;
      table.eleve = bon.eleve;
      table.category = bon.permis.permis;
      table.actions =
      <div actions="actions">
        <Button title="Supprimer le bon" color="danger"
            onClick={() => {this.props.deleteBon(bon.id)}}>
            <i className="fa fa-trash"></i>
        </Button>
      </div>;
      return table;
    });
  }


  render() {
    const { SearchBar } = Search;
    const table = this.prepareData(this.props.bons);
    this.selectRow['selected'] = this.state.selected;
    return (
      <ToolkitProvider keyField='id' data={table} columns={this.columns} search>
        {props => (
          <div>
            <Col xs={6} sm={6} md={5} lg={4} xl={4}>
              <SearchBar {...props.searchProps} placeholder="Élève"/>
            </Col>
            <Col lg={12} xl={6}>
              <div className="otoschoolTable">
                <BootstrapTable {...props.baseProps }
                  selectRow={this.selectRow}
                  pagination={paginationFactory()}
                  striped/>
              </div>
            </Col>
            <Row style={{margin: '24px 0 64px'}}>
              <Col xs={12} sm={12}>
                <Row className="no-gutters">
                  <Col xs={12} style={{display: 'flex', alignItems: 'baseline'}}>
                    <Label>{this.props.phrase} &nbsp; &nbsp;</Label>
                    <OtoschoolDatePicker name="date" value={this.state.date}
                      onChange={this.dateChange}/>
                    <Label>&nbsp; &nbsp; &nbsp; Nom du déposant</Label>
                    <Col xs={3}>
                      <select className="form-control"
                          value={this.state.intervenant} name="intervenant"
                          onChange={this.updateIntervenant} id="intervenant">
                        <option value="">-</option>
                        {this.props.intervenants.map(
                          (intervenant) =>
                            <option key={intervenant.id}
                              value={`${intervenant.first_name} ${intervenant.last_name}`}>
                              {`${intervenant.first_name} ${intervenant.last_name}`}
                            </option>
                          )}
                      </select>
                    </Col>
                    {this.props.hasDepotDate &&
                      <div>
                        <Label>Date de dépot &nbsp; &nbsp;</Label>
                        <OtoschoolDatePicker name="date" value={this.state.depotDate} onChange={this.depotDateChange}/>
                      </div>
                    }
                    <Button className="colorButton ml-5" onClick={this.createBordereau}>
                      Créer
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default Bons;
