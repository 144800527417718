import React, { Component } from 'react';
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { autoecole, eleves, eleve } from '../../actions';
import Message from './Message';
import './Messages.css';


class Messages extends Component {
  state = {
    activeTab: "1"
  }

  componentDidMount() {
    this.props.getEleves(this.props.autoecoleId);
    this.props.getMessages(this.props.autoecoleId);
    this.props.getAlerteMessages(this.props.autoecoleId);
  }

  deleteMessage = message => {
    this.props.deleteMessage(this.props.autoecoleId, message.eleve, message.id);
  }

  markAsRead = () => {
    this.props.markAlerteMessagesAsRead(this.props.autoecoleId);
  }

  activateTab = (tabToActivate) => {
    this.setState({
      activeTab: tabToActivate
    });
  }

  render() {
    return (
      <Container fluid>
        <div className="animated fadeIn">
        <h4 className="pageTitle">Messages</h4>
        <Nav tabs className="no-gutters otoschoolNavTabs">
          <Col xl={3}>
            <NavItem className="otoschoolTab">
              <NavLink
                active={this.state.activeTab === "1"}
                onClick={() => { this.activateTab("1"); }}>
                <h4>Messages envoyés</h4>
              </NavLink>
            </NavItem>
          </Col>
          <Col xl={3}>
            <NavItem className="otoschoolTab">
              <NavLink
                active={this.state.activeTab === "2"}
                onClick={() => { this.activateTab("2"); this.markAsRead()}}>
                <h4>Alertes</h4>
              </NavLink>
            </NavItem>
          </Col>
        </Nav>
        <TabContent activeTab={this.state.activeTab} className="otoschoolTabContent">
          <TabPane tabId="1">
            <Row>
              <Col lg={12} xl={{ size: 8, offset: 2 }}>
                {Object.values(this.props.messages.messages).sort((s1, s2) => new Date(s2.created) - new Date(s1.created)).map(message => {
                  return <Message key={message.id} deleteMessage={() => this.deleteMessage(message)} message={message} eleves={this.props.eleves} displayEleve />
                })}
                {this.props.messages.messages.length === 0 &&
                <Message message={{created: new Date(), message: "Aucun message envoyé aux élèves les 90 derniers jours"}}/>
                }
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col lg={12} xl={{ size: 8, offset: 2 }}>
                {this.props.alerteMessages.result.length > 0 && Object.values(this.props.alerteMessages.entities.alerteMessages).sort((s1, s2) => new Date(s2.created) - new Date(s1.created)).map(message => {
                  return <Message key={message.id} message={message} />
                })}
              </Col>
            </Row>
          </TabPane>
        </TabContent>
        </div>
    </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    autoecoleId: state.auth.user.profile.auto_ecole,
    messages: state.messages,
    alerteMessages: state.alerteMessages,
    eleves: state.eleves,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getMessages: (autoecoleId) => dispatch(autoecole.getMessages(autoecoleId)),
    getAlerteMessages: (autoecoleId) => dispatch(autoecole.getAlerteMessages(autoecoleId)),
    markAlerteMessagesAsRead: (autoecoleId) => dispatch(autoecole.markAllAlerteMessagesAsRead(autoecoleId)),
    getEleves: (autoecoleId) => dispatch(eleves.get(autoecoleId)),
    deleteMessage: (autoecoleId, eleveId, messageId) => dispatch(eleve.deleteMessage(autoecoleId, eleveId, messageId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
