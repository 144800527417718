import { normalize, denormalize, schema } from 'normalizr';

const organisme = new schema.Entity('organismes');
const organismeSchema = [ organisme ];

export const normalizeOrganisme = (data) => {
  return normalize(data, organismeSchema);
}

export const denormalizeOrganisme = (data, entities) => {
  return denormalize(data, [organisme], entities);
}
