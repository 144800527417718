import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import './Messages.css';

class Message extends Component {

  findEleve = id => {
    const eleves = Object.values(this.props.eleves.entities.eleves);
    const eleve = eleves.find(eleve => eleve.id === id);
    return eleve ? eleve : null;
  }

  render() {
    const { displayEleve, message } = this.props;
    const eleve = displayEleve ? this.findEleve(message.eleve) : null;
    return (
      <Row className="message">
        <Col xs={3} className="messageDate">
        {new Date(message.created).toLocaleString()}
        <br/>
        {displayEleve && this.props.eleves.result.length > 0  && eleve  &&
           <a href={"#/eleve/" + eleve.id}>{eleve.first_name} {eleve.last_name}</a>
        }
        </Col>
        <Col>
          {message.message}
        </Col>
        <Col xs={2}>
          {this.props.deleteMessage &&
            <button className="delete-button" onClick={this.props.deleteMessage}>Supprimer</button>
          }
        </Col>
      </Row>
    );
  }
}

export default Message;
