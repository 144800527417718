import { normalize, denormalize, schema } from 'normalizr';

const eleve = new schema.Entity('eleves');
const eleveSchema = [ eleve ];

export const normalizeEleve = (data) => {
  return normalize(data, eleveSchema);
}

export const denormalizeEleve = (data, entities) => {
  return denormalize(data, [eleve], entities);
}
