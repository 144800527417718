import React, { Component } from 'react';
import { Button, Form, FormGroup, Input, Label, Col, Row } from 'reactstrap';
import moment from 'moment';
import OtoschoolDatePicker from '../OtoschoolDatePicker/OtoschoolDatePicker.jsx';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import fr from 'localized-countries/data/fr';

import validate from '../../common/utils.js';

class ElevePermis extends Component {
  constructor(props) {
    super(props);
    this.optionsPermis = [
      { value: 'A', label: 'A' },
      { value: 'A1', label: 'A1' },
      { value: 'B', label: 'B' },
      { value: 'B1', label: 'B1' },
      { value: 'C', label: 'C' },
      { value: 'D', label: 'D' },
      { value: 'EB', label: 'EB' },
      { value: 'EC', label: 'EC' },
      { value: 'ED', label: 'ED' },
      { value: 'BSR', label: 'BSR (Scooter -18 ans)' }
    ];
    const id_card = { value: 'id_card', label: 'Pièce d\'identité' };
    const photos = { value: 'photos', label: '2 photos d\'identités' };
    const stamp = { value: 'stamp', label: '1 timbre fiscale' };
    const med_certificate = { value: 'med_certificate', label: 'Certificat médical' };
    const blood_card = { value: 'blood_card', label: 'Carte de donneur de sang' };
    const assr = { value: 'assr', label: 'ASSR ou ASR' };
    const japd = { value: 'japd', label: 'JAPD/JDC' };
    const census = { value: 'census', label: 'Attestation de recensement' };
    const id_card_parent = { value: 'id_card_parent', label: 'Pièce d\'identité du représentant légal' };
    const authorization_parent = { value: 'authorization_parent', label: 'Autorisation parentale' };
    const birth_certificate = { value: 'birth_certificate', label: 'Acte de naissance de l\'élève'};
    const stay_185 = { value: 'stay_185', label: 'Justifie de 185 jours sur le territoire' };
    const exeat_form = { value: 'exeat_form', label: 'A rempli le formulaire EXEAT' };
    const school_certificate = { value: 'school_certificate', label: 'Possède un certificat de scolarité (non-polynésien)' };
    const cps = { value: 'cps', label: 'CPS (non-polynésien)' };
    const med_truck = { value: 'med_truck', label: 'Visite médicale pour poids lourd' };
    const med_judgement = { value: 'med_judgement', label: 'Visite médicale spécifique' };
    const judgement_dtt = { value: 'judgement_dtt', label: 'Jugement remis à la DTT' };
    const med_com = { value: 'med_com', label: 'Comission médicale' };
    const povc = { value: 'povc', label: 'POVC' };
    this.optionsAdmin = [
      id_card,
      photos,
      stamp,
      med_certificate,
      blood_card,
      japd,
      census
    ];
    this.props.permis.pieces = [];
    if (this.props.permis.id_card_status)
      this.props.permis.pieces.push(id_card);
    if (this.props.permis.photos_status)
      this.props.permis.pieces.push(photos);
    if (this.props.permis.stamp_status)
      this.props.permis.pieces.push(stamp);
    if (this.props.permis.med_certificate_status)
      this.props.permis.pieces.push(med_certificate);
    if (this.props.permis.blood_card_status)
      this.props.permis.pieces.push(blood_card);

    if (this.props.permis.must_check_assr) {
      this.optionsAdmin.push(assr);
      if (this.props.permis.assr_status)
        this.props.permis.pieces.push(assr);
    }
    if (this.props.permis.japd_status)
        this.props.permis.pieces.push(japd);
    if (this.props.permis.census_status)
        this.props.permis.pieces.push(census);
    if (this.props.permis.must_check_parent) {
      this.optionsAdmin.push(id_card_parent);
      if (this.props.permis.id_card_parent_status)
        this.props.permis.pieces.push(id_card_parent);
      this.optionsAdmin.push(authorization_parent);
      if (this.props.permis.authorization_parent_status)
        this.props.permis.pieces.push(authorization_parent);
      this.optionsAdmin.push(birth_certificate);
      if (this.props.permis.birth_certificate_status)
        this.props.permis.pieces.push(birth_certificate);
    }
    if (this.props.permis.must_check_stay) {
      this.optionsAdmin.push(stay_185);
      if (this.props.permis.stay_185_status)
        this.props.permis.pieces.push(stay_185);
      this.optionsAdmin.push(school_certificate);
      if (this.props.permis.school_certificate_status)
        this.props.permis.pieces.push(school_certificate);
      this.optionsAdmin.push(cps);
      if (this.props.permis.cps_status)
        this.props.permis.pieces.push(cps);
      this.optionsAdmin.push(exeat_form);
      if (this.props.permis.exeat_form_status)
        this.props.permis.pieces.push(exeat_form);

    }
    if (this.props.permis.must_check_truck) {
      this.optionsAdmin.push(med_truck);
      if (this.props.permis.med_truck_status)
        this.props.permis.pieces.push(med_truck);
    }
    if (this.props.permis.must_check_judgement) {
      this.optionsAdmin.push(judgement_dtt);
      if (this.props.permis.judgement_dtt_status)
        this.props.permis.pieces.push(judgement_dtt);
      this.optionsAdmin.push(med_judgement);
      if (this.props.permis.med_judgement_status)
        this.props.permis.pieces.push(med_judgement);
      this.optionsAdmin.push(med_com);
      if (this.props.permis.med_com_status)
        this.props.permis.pieces.push(med_com);
    }
    if (this.props.permis.must_check_povc) {
      this.optionsAdmin.push(povc);
      if (this.props.permis.povc_status)
        this.props.permis.pieces.push(povc);
    }
  }

  validationSchema = e => {
    return Yup.object().shape({
      permis: Yup.string().required('Un permis est requis')
    });
  }

  onSubmit = (values, { setSubmitting, setErrors }) => {
    if (values.organization === '' || values.organization === undefined)
      values.organization = null;
    if (values.visit_med !== null)
      values.visit_med = moment(values.visit_med).format('YYYY-MM-DD');
    if (values.medical_comission_expiration !== null)
      values.medical_comission_expiration = moment(values.medical_comission_expiration).format('YYYY-MM-DD');
    if (values.etg_completed !== null)
      values.etg_completed = moment(values.etg_completed).format('YYYY-MM-DD');
    if (values.last_permis_date !== null)
      values.last_permis_date = moment(values.last_permis_date).format('YYYY-MM-DD');
    this.props.callback(values);
  }

  renderFormik = ({ values, errors, touched, status, dirty, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, handleReset, setTouched }) => (
    <Form onSubmit={handleSubmit}>
      <FormGroup row>
        <Label sm={3} md={3} lg={3} xl={3} for="permis">{this.redStar} Catégorie de permis</Label>
        <Col sm={9} md={9} lg={9} xl={6}>
          <Input type="select" name="permis" onChange={handleChange} onBlur={handleBlur} required value={values.permis}>
            <option value="A">A</option>
            <option value="A1">A1</option>
            <option value="B">B</option>
            <option value="B1">B1</option>
            <option value="C">C</option>
            <option value="D">D</option>
            <option value="EB">EB</option>
            <option value="EC">EC</option>
            <option value="ED">ED</option>
            <option value="BSR">BSR (Scooter -18 ans)</option>
          </Input>
          <span className="text-danger"><ErrorMessage name="permis"/></span>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} md={3} lg={3} xl={3} for="identification_number">Numéro d'identification</Label>
        <Col sm={9} md={9} lg={9} xl={6}>
          <Input type="text" name="identification_number" onChange={handleChange} onBlur={handleBlur} value={values.identification_number}/>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} md={3} lg={3} xl={3} for="etg_completed">ETG réussi le</Label>
        <Col sm={9} md={9} lg={9} xl={6}>
          <OtoschoolDatePicker name="etg_completed" value={values.etg_completed} onChange={handleChange} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} md={3} lg={3} xl={3} for="visit_med">Date de la visite médical</Label>
        <Col sm={9} md={9} lg={9} xl={6}>
          <OtoschoolDatePicker name="visit_med" value={values.visit_med} onChange={handleChange} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} md={3} lg={3} xl={3} for="medical_comission_expiration">Date d'expiration de la comission médicale</Label>
        <Col sm={9} md={9} lg={9} xl={6}>
          <OtoschoolDatePicker name="medical_comission_expiration" value={values.medical_comission_expiration} onChange={handleChange} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} md={3} lg={3} xl={3} for="pieces">Pièces administratives</Label>
        <Col sm={9} md={9} lg={9} xl={6}>
          <Select
            options={this.optionsAdmin}
            isMulti
            isSearchable
            value={values.pieces}
            onChange={(selectedOptions) => {
              handleChange({target: {name: 'pieces', value: selectedOptions}});
            }}
            placeholder="Pièces administratives obtenues"/>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} md={3} lg={3} xl={3} for="permis_list">Permis obtenus</Label>
        <Col sm={9} md={9} lg={9} xl={6}>
          <Select
            options={this.optionsPermis}
            isMulti
            value={values.permis_list.map((permis) => ({value: permis.short_name, label: permis.short_name}))}
            onChange={(selectedOptions) => {
              const permis_api = selectedOptions.map((permis) => ({short_name: permis.value}));
              handleChange({target: {name: 'permis_list', value: permis_api}});
            }}
            placeholder="A, B, C..."/>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} md={3} lg={3} xl={3} for="last_permis_date">Date du dernier permis</Label>
        <Col sm={9} md={9} lg={9} xl={6}>
          <OtoschoolDatePicker name="last_permis_date" value={values.last_permis_date} onChange={handleChange} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} md={3} lg={3} xl={3} for="last_permis_loc">Lieu du dernier permis</Label>
        <Col sm={9} md={9} lg={9} xl={6}>
          <Input type="text" name="last_permis_loc" onChange={handleChange} onBlur={handleBlur} value={values.last_permis_loc}/>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} md={3} lg={3} xl={3} for="last_permis_country">Pays du dernier permis</Label>
        <Col sm={9} md={9} lg={9} xl={6}>
          <Input type="select" name="last_permis_country" onChange={handleChange} onBlur={handleBlur} value={values.last_permis_country}>
              <option value="-">-</option>
              {Object.keys(fr).filter((country_key) => ['FR', 'PF', 'RE', 'GP', 'YT', 'MQ', 'GF', 'BL', 'MF', 'NC', 'PM', 'TF', 'WF'].includes(country_key)).map((country_key, index) => (
                  <option key={index} value={country_key}>{fr[country_key]}</option>
              ))}
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} md={3} lg={3} xl={3} for="last_permis_origin">Obtention du dernier permis</Label>
        <Col sm={9} md={9} lg={9} xl={6}>
          <Input type="select" name="last_permis_origin" onChange={handleChange} onBlur={handleBlur} value={values.last_permis_origin}>
            <option value="-">-</option>
            <option value="Examen">Par examen</option>
            <option value="Echange">Par échange</option>
          </Input>
        </Col>
      </FormGroup>
      <FormGroup row tag="fieldset">
        <Label sm={3} md={3} lg={3} xl={3} for="has_lost_license">Permis perdu</Label>
        <Col sm={9} md={9} lg={9} xl={6}>
          <Row>
            <Col sm={3} md={3} lg={3} xl={3}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="has_lost_license" value={true} onChange={handleChange} onBlur={handleBlur} checked={values.has_lost_license === "true"}/>
                  Oui
                </Label>
              </FormGroup>
            </Col>
            <Col sm={3} md={3} lg={3} xl={3}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="has_lost_license" value={false} onChange={handleChange} onBlur={handleBlur} checked={values.has_lost_license === "false"}/>
                  Non
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </FormGroup>
      <FormGroup row tag="fieldset">
        <Label sm={3} md={3} lg={3} xl={3} for="has_glasses">A des lunettes / lentilles</Label>
        <Col sm={9} md={9} lg={9} xl={6}>
          <Row>
            <Col sm={3} md={3} lg={3} xl={3}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="has_glasses" value={true} onChange={handleChange} onBlur={handleBlur} checked={values.has_glasses === "true"}/>
                  Oui
                </Label>
              </FormGroup>
            </Col>
            <Col sm={3} md={3} lg={3} xl={3}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="has_glasses" value={false} onChange={handleChange} onBlur={handleBlur} checked={values.has_glasses === "false"}/>
                  Non
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} md={3} lg={3} xl={3} for="organization">Organisme</Label>
        <Col sm={9} md={9} lg={9} xl={6}>
          <Select
            options={this.props.organismes.result.map((orgaId, index) => (
              {value: orgaId, label: this.props.organismes.entities.organismes[orgaId].name}
            )).sort((a, b) => (a.label.trim().toLowerCase() > b.label.trim().toLowerCase()))}
            isSearchable
            isClearable
            value={values.organization && this.props.organismes.entities.organismes && {value: values.organization, label: this.props.organismes.entities.organismes[values.organization].name}}
            onChange={(selectedOption) => {
              handleChange({target: {name: 'organization', value: selectedOption ? selectedOption.value : ''}});
            }}
            placeholder="Choisir un organisme"/>
        </Col>
        {values.organization && this.props.organismes.entities.organismes &&
          <Col sm={3} md={3} lg={3} xl={3}>
            <p>Nom du contact : {this.props.organismes.entities.organismes[values.organization].contact_name}</p>
          </Col>
        }
      </FormGroup>
      <FormGroup row>
        <Label sm={3} md={3} lg={3} xl={3} for="purchase_order">Numéro de convention / Bon de commande</Label>
        <Col sm={9} md={9} lg={9} xl={6}>
          <Input type="text" name="purchase_order" onChange={handleChange} onBlur={handleBlur} value={values.purchase_order}/>
        </Col>
      </FormGroup>
      <FormGroup row>
          <Col sm={{offset:8, size:4}} xl={{offset:8, size:4}}>
            <Button type="submit" className="colorButton">{this.props.createMode ? "Enregistrer" : "Modifier"}</Button>
          </Col>
      </FormGroup>
    </Form>
  )

  render() {
    if (this.props.createMode)
      this.redStar = <span className="text-danger">* </span>;
    else
      this.redStar = "";
    this.props.permis.has_lost_license = String(this.props.permis.has_lost_license);
    this.props.permis.has_glasses = String(this.props.permis.has_glasses);
    return (
      <Formik enableReinitialize={true} initialValues={this.props.permis}
              validate={validate(this.validationSchema)} onSubmit={this.onSubmit}
              render={this.renderFormik} />
    );
  }
}


export default ElevePermis;
