import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import './common/style.scss';
import App from './App.js';
import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV === 'production')
  Sentry.init({
    release: 'otoschool-react@2.0',
    dsn: 'https://e3a6ac3d88434c69b3c60d50fde03513@sentry.io/1766823'});

// if (process.env.NODE_ENV !== 'production') {
//   const {whyDidYouUpdate} = require('why-did-you-update');
//   whyDidYouUpdate(React, {include: [/PlanningPresentation/]});
// }

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
