import React, { Component } from 'react';
import { connect } from 'react-redux';

import { vehicules } from '../../actions';

import VehiculesPresentation from './VehiculesPresentation.jsx';

class Vehicules extends Component {

  createOrUpdate = (vehicule, create) => {
    if (create)
      this.props.createVehicule(this.props.autoecoleId, vehicule);
    else
      this.props.updateVehicule(this.props.autoecoleId, vehicule.id, vehicule);
    }
    
  delete = (vehicule) => {
      this.props.deleteVehicule(this.props.autoecoleId, vehicule.id);
  }

  componentDidMount() {
    this.props.getVehicules(this.props.autoecoleId);
  }

  render() {
    return (
      <VehiculesPresentation
        vehicules={this.props.vehicules}
        createOrUpdate={this.createOrUpdate}
        delete={this.delete}
        />
    );
  }
}


const mapStateToProps = state => {
  return {
    autoecoleId: state.auth.user.profile.auto_ecole,
    vehicules: state.vehicules
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getVehicules: (autoecoleId) => dispatch(vehicules.getVehicules(autoecoleId)),
    createVehicule: (autoecoleId, data) => dispatch(vehicules.createVehicule(autoecoleId, data)),
    updateVehicule: (autoecoleId, vehiculeId, data) => dispatch(vehicules.updateVehicule(autoecoleId,  vehiculeId, data)),
    deleteVehicule: (autoecoleId, vehiculeId) => dispatch(vehicules.deleteVehicule(autoecoleId,  vehiculeId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Vehicules);
