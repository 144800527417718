import React, { PureComponent } from 'react';
import {Row, Col, Button, Label} from 'reactstrap';
import { connect } from 'react-redux';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import OtoschoolDatePicker from '../../OtoschoolDatePicker/OtoschoolDatePicker';
import { autoecole, impression } from '../../../actions';

import moment from 'moment';

class Enregistrement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      date: new Date(),
      intervenant: ''
    };

    this.props.get(this.props.autoecoleId);

    this.columns = [
      {dataField: 'id', hidden: true},
      {dataField: 'eleve', text: 'Élève', sort: true, formatter: this.eleveFormatter, filterValue: this.eleveSearchFilter},
      {dataField: 'category', text: 'Catégorie', sort: true},
      {dataField: 'action', text: 'Actions'},
    ];

    this.selectRow = {
      mode: 'checkbox',
      hideSelectColumn: false,
      clickToSelect: true,
      onSelect: (row, isSelect, rowIndex, e) => {
        this.select(row, rowIndex, isSelect);
      },
      onSelectAll: (isSelect, rows, e) => {
        this.selectAll(isSelect, rows, e);
      }
    };
  }

  updateIntervenant = event => {
    this.setState({ intervenant: event.target.value });
  }

  eleveFormatter = (cell, row) => {
    const eleve = this.props.eleves.filter((eleve) => eleve.id === cell)[0];
    return eleve !== undefined ? <a target="_blank" rel="noopener noreferrer" href={"#/eleve/" + eleve.id}>{eleve.first_name.split(' ')[0]} {eleve.last_name}</a> : "Élève inconnu";
  }

  eleveSearchFilter = (cell, row) => {
    const eleve = this.props.eleves.filter((eleve) => eleve.id === cell)[0];
    return eleve !== undefined ? eleve.first_name.split(' ')[0] + " " + eleve.last_name : "Élève inconnu";
  }

  select = (row, index, isSelect) => {
    if (isSelect) {
      this.setState(state => {
        const selected = [...state.selected, row.id];
        return {
          selected: selected
        };
      });
    } else {
      this.setState(state => {
        const selected = state.selected.filter((id) => id !== row.id);
        return {
          selected: selected
        };
      });
    }
  }

  selectAll = (isSelect, rows, e) => {
    if (isSelect) {
      const ids = rows.map((row) => row.id);
      this.setState({
        selected: ids
      });
    }
    else
      this.setState({
        selected: []
      });
  }

  dateChange = (e) => {
    this.setState({ date: e.target.value });
  }

  impressionEnregistrement = (blank, eleve) => {
    const data = {
      eleveId: eleve,
      date: new Date().toLocaleDateString()
    }
    this.props.getEnregistrement(this.props.autoecoleId, blank, data);
    this.setState({
      selected: []
    });
  }

  createBordereau = (event) => {
    const bordereau = {
      date: moment(this.state.date).format('YYYY-MM-DD'),
      permis: this.state.selected,
      deposant: this.state.intervenant,
      type: 'register'
    };
    this.props.createBordereau(bordereau);
    this.setState({
      selected: []
    });
  }

  prepareData = (eleves) => {
    // we check all permis id in bordereaux to remove eleves already in a bordereau
    const elevesInBordereaux = [].concat.apply([], this.props.bordereaux.map(x => x.eleves));
    const elevesNotInBordereaux = eleves.filter(eleve => !elevesInBordereaux.includes(eleve.id));
    return elevesNotInBordereaux.map((eleve, index) => {
      const eleveTable = {};
      eleveTable.key = index;
      eleveTable.id = eleve.permis[0].id;
      eleveTable.eleve = eleve.id;
      eleveTable.category = eleve.permis[0].permis;
      eleveTable.action =
      <div actions="actions">
        <Button color="success" onClick={() => {this.impressionEnregistrement(false, eleve.id)}}><i className="fa fa-print"></i></Button>&nbsp;
        <Button color="warning" onClick={() => {this.impressionEnregistrement(true, eleve.id)}}><i className="fa fa-print"></i></Button>
      </div>;
      return eleveTable;
    });
  }

  render() {
    const { SearchBar } = Search;
    const table = this.prepareData(this.props.eleves);
    this.selectRow['selected'] = this.state.selected;
    return (
      <ToolkitProvider
        keyField='id' data={table}
        columns={this.columns}
        search>
        {
          props => (
            <div>
              <Col sm={6} md={5} lg={4} xl={4}>
                <SearchBar {...props.searchProps} placeholder="Élève"/>
              </Col>
              <Col lg={12} xl={6}>
                <div className="otoschoolTable">
                  <BootstrapTable {...props.baseProps }
                    selectRow={this.selectRow}
                    pagination={paginationFactory()}
                    striped/>
                </div>
              </Col>
              <Row style={{margin: '24px 0 64px'}}>
                <Col xs={12} sm={12}>
                  <Row className="no-gutters">
                    <Col xs={12} style={{display: 'flex', alignItems: 'baseline'}}>
                      <Label>Date de création du bordereau &nbsp; &nbsp;</Label>
                      <OtoschoolDatePicker name="date" value={this.state.date} onChange={this.dateChange}/>
                      <Label>&nbsp; &nbsp; &nbsp; Nom du déposant</Label>
                      <Col xs={3}>
                        <select className="form-control"
                            value={this.state.intervenant} name="intervenant"
                            onChange={this.updateIntervenant} id="intervenant">
                          <option value="">-</option>
                          {this.props.intervenants.map(
                            (intervenant) =>
                              <option key={intervenant.id}
                                value={`${intervenant.first_name} ${intervenant.last_name}`}>
                                {`${intervenant.first_name} ${intervenant.last_name}`}
                              </option>
                            )}
                        </select>
                      </Col>
                      <Button className="colorButton ml-5" onClick={this.createBordereau}>Créer</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            <span style={{marginLeft: '12px'}}>{this.props.loading.generatingFicheVerte ? 'Génération en cours' : ''}</span>
            </div>
          )
        }
      </ToolkitProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    autoecoleId: state.auth.user.profile.auto_ecole,
    autoecole: state.autoecole.autoecole,
    loading: state.loading,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    get: (autoecoleId) => dispatch(autoecole.get(autoecoleId)),
    getEnregistrement: (autoecoleId, dataOnly, data) => dispatch(impression.getEnregistrement(autoecoleId, dataOnly, data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Enregistrement);
