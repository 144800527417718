import { normalize, denormalize, schema } from 'normalizr';

/*
Example data
{
    "id": 1,
    "article": {
        "id": 1,
        "name": "Livre",
        "available": true,
        "autoecole": 1,
        "type": "produit",
        "duration": null,
        "articles": []
    },
    "price": "20.00",
    "tva": {
        "id": 1,
        "tva_type": "S",
        "tva_value": "16.00"
    }
}
We want, by Id, by Type
*/
const product = new schema.Entity('products');
const productSchema = [ product ];

export const normalizeProduct = (data) => {
  return normalize(data, productSchema);
}

export const denormalizeProduct = (data, entities) => {
  return denormalize(data, [product], entities);
}

const prestation = new schema.Entity('prestations');
const prestationSchema = [ prestation ];

export const normalizePrestation = (data) => {
  return normalize(data, prestationSchema);
}

export const denormalizePrestation = (data, entities) => {
  return denormalize(data, [prestation], entities);
}

const service = new schema.Entity('services');
const serviceSchema = [ service ];

export const normalizeService = (data) => {
  return normalize(data, serviceSchema);
}

export const denormalizeService = (data, entities) => {
  return denormalize(data, [service], entities);
}

const formule = new schema.Entity('formules');
const formuleSchema = [ formule ];

export const normalizeFormule = (data) => {
  return normalize(data, formuleSchema);
}

export const denormalizeFormule = (data, entities) => {
  return denormalize(data, [formule], entities);
}
