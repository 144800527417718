const initialState = {
  message: "",
  type: ""
};

export default function notifications(state=initialState, action) {
  switch (action.type) {
    case 'AUTHENTICATION_ERROR':
      return {...state, message:"Vous n'êtes pas connecté", type:"ERROR"};
    case 'ERROR_ADD_ORGANISME':
      return {...state, message:"Il y a eu une erreur lors de l'ajout de l'établissement", type:"ERROR"};
    case 'CREATE_ORGANISME_SUCCESSFUL':
      return {...state, message:"L'établissement a bien été créé", type:"SUCCESS"};
    case 'ERROR_EDIT_ORGANISME':
      return {...state, message:"Il y a eu une erreur lors de la modification de l'établissement", type:"ERROR"};
    case 'UPDATE_ORGANISME_SUCCESSFUL':
      return {...state, message:"L'établissement a bien été modifié", type:"SUCCESS"};
    case 'ERROR_GET_ORGANISMES':
      return {...state, message:"Il y a eu une erreur lors de la récupération des établissements", type:"ERROR"};
    case 'ERROR_UPDATE_INTERVENANT':
      return {...state, message:"Il y a eu une erreur lors de la modification de l'intervenant", type:"ERROR"};
    case 'UPDATE_INTERVENANT_SUCCESSFUL':
      return {...state, message:"L'intervenant a bien été modifié", type:"SUCCESS"};
    case 'ERROR_CREATE_INTERVENANT':
      return {...state, message:"Il y a eu une erreur lors de la création de l'intervenant", type:"ERROR"};
    case 'ERROR_CREATE_INTERVENANT_SERVER':
      return {...state, message:"Il y a eu une erreur lors de la création de l'intervenant sur le serveur", type:"ERROR"};
    case 'CREATE_INTERVENANT_SUCCESSFUL':
      return {...state, message:"L'intervenant a bien été créé", type:"SUCCESS"};
    case 'ERROR_CREATE_PRODUCT':
      return {...state, message:"Il y a eu une erreur lors de la création du produit", type:"ERROR"};
    case 'CREATE_PRODUCT_SUCCESSFUL':
      return {...state, message:"Le produit a bien été créé", type:"SUCCESS"};
    case 'ERROR_UPDATE_PRODUCT':
      return {...state, message:"Il y a eu une erreur lors de la modification du produit", type:"ERROR"};
    case 'UPDATE_PRODUCT_SUCCESSFUL':
      return {...state, message:"Le produit a bien été modifié", type:"SUCCESS"};
    case 'ERROR_CREATE_PRESTATION':
      return {...state, message:"Il y a eu une erreur lors de la création de la prestation", type:"ERROR"};
    case 'CREATE_PRESTATION_SUCCESSFUL':
      return {...state, message:"La prestation a bien été créée", type:"SUCCESS"};
    case 'ERROR_UPDATE_PRESTATION':
      return {...state, message:"Il y a eu une erreur lors de la modification de la prestation", type:"ERROR"};
    case 'UPDATE_PRESTATION_SUCCESSFUL':
      return {...state, message:"La prestation a bien été modifiée", type:"SUCCESS"};
    case 'ERROR_CREATE_SERVICE':
      return {...state, message:"Il y a eu une erreur lors de la création du service", type:"ERROR"};
    case 'CREATE_SERVICE_SUCCESSFUL':
      return {...state, message:"Le service a bien été créé", type:"SUCCESS"};
    case 'ERROR_UPDATE_SERVICE':
      return {...state, message:"Il y a eu une erreur lors de la modification du service", type:"ERROR"};
    case 'UPDATE_SERVICE_SUCCESSFUL':
      return {...state, message:"Le service a bien été modifié", type:"SUCCESS"};
    case 'ERROR_CREATE_FORMULE':
      return {...state, message:"Il y a eu une erreur lors de la création de la formule", type:"ERROR"};
    case 'CREATE_FORMULE_SUCCESSFUL':
      return {...state, message:"La formule a bien été créée", type:"SUCCESS"};
    case 'ERROR_UPDATE_FORMULE':
      return {...state, message:"Il y a eu une erreur lors de la modification de la formule", type:"ERROR"};
    case 'UPDATE_FORMULE_SUCCESSFUL':
      return {...state, message:"La formule a bien été modifiée", type:"SUCCESS"};
    case 'UPDATE_AUTOECOLE_ERROR':
      return {...state, message:"Il y a eu une erreur lors de la modification de l'auto école", type:"ERROR"};
    case 'UPDATE_AUTOECOLE_SUCCESSFUL':
      return {...state, message:"Votre auto école a bien été modifiée", type:"SUCCESS"};
    case 'ERROR_UPDATE_TVA':
      return {...state, message:"Il y a eu une erreur lors de la modification de la TVA", type:"ERROR"};
    case 'UPDATE_TVA_SUCCESSFUL':
      return {...state, message:"La TVA a bien été modifiée", type:"SUCCESS"};
    case 'ERROR_UPDATE_GEN_INVOICE':
      return {...state, message:"Il y a eu une erreur lors de la modification de vos paramètres de facturation", type:"ERROR"};
    case 'UPDATE_GEN_INVOICE_SUCCESSFUL':
      return {...state, message:"Vos paramètres de facturation ont bien été modifié", type:"SUCCESS"};
    case 'ERROR_CREATE_ELEVE':
      return {...state, message:"Il y a eu une erreur lors de la création de l'élève", type:"ERROR"};
    case 'CREATE_ELEVE_SUCCESSFUL':
      return {...state, message:"L'élève a bien été créé", type:"SUCCESS"};
    case 'ERROR_UPDATE_ELEVE':
      return {...state, message:"Il y a eu une erreur lors de la modification de l'élève", type:"ERROR"};
    case 'UPDATE_ELEVE_SUCCESSFUL':
      return {...state, message:"L'élève a bien été modifié", type:"SUCCESS"};
    case 'ERROR_CREATE_PERMIS':
      return {...state, message:"Il y a eu une erreur lors de la création du permis", type:"ERROR"};
    case 'CREATE_PERMIS_SUCCESSFUL':
      return {...state, message:"Le permis a bien été créé", type:"SUCCESS"};
    case 'ERROR_UPDATE_PERMIS':
      return {...state, message:"Il y a eu une erreur lors de la modification du permis", type:"ERROR"};
    case 'UPDATE_PERMIS_SUCCESSFUL':
      return {...state, message:"Le permis a bien été modifié", type:"SUCCESS"};
    case 'ERROR_CREATE_FACTURE':
      return {...state, message:"Il y a eu une erreur lors de la création de la facture", type:"ERROR"};
    case 'CREATE_FACTURE_SUCCESSFUL':
      return {...state, message:"La facture a bien été créée", type:"SUCCESS"};
    case 'ERROR_CREATE_PAIEMENT':
      return {...state, message:"Il y a eu une erreur lors de la création du paiement", type:"ERROR"};
    case 'CREATE_PAIEMENT_SUCCESSFUL':
      return {...state, message:"Le paiement a bien été créée", type:"SUCCESS"};
    case 'ERROR_SEND_MESSAGE':
      return {...state, message:"Il y a eu une erreur lors de l'envoi du message", type:"ERROR"};
    case 'SEND_MESSAGE_SUCCESSFUL':
      return {...state, message:"Le message a bien été envoyé", type:"SUCCESS"};
    case 'SEND_MESSAGE_MODIFICATION_LAST_SUCCESSFUL':
      return {...state, message:"Les messages de modifications ont bien été envoyés", type:"SUCCESS"};
    case 'ERROR_SEND_NOTE':
      return {...state, message:"Il y a eu une erreur lors de l'enregistrement de la note", type:"ERROR"};
    case 'SEND_NOTE_SUCCESSFUL':
      return {...state, message:"La note a bien été enregistrée", type:"SUCCESS"};
    case 'ERROR_UPDATE_ETG':
      return {...state, message:"Il y a eu une erreur lors de la mise à jour de la date", type:"ERROR"};
    case 'UPDATE_ETG_SUCCESSFUL':
      return {...state, message:"La date a bien été enregistrée", type:"SUCCESS"};
    case 'ERROR_UPDATE_EXAMEN':
      return {...state, message:"Il y a eu une erreur lors de la mise à jour de la note", type:"ERROR"};
    case 'UPDATE_EXAMEN_SUCCESSFUL':
      return {...state, message:"La date a bien été enregistrée", type:"SUCCESS"};
    case 'ERROR_GOOD_ETG':
      return {...state, message:"Il y a eu une erreur lors de la création du bon pour l'ETG", type:"ERROR"};
    case 'GOOD_ETG_SUCCESSFUL':
      return {...state, message:"Le bon pour ETG a été créée", type:"SUCCESS"};
    case 'ERROR_GOOD_ETG_SPECIFIQUE':
      return {...state, message:"Il y a eu une erreur lors de la création du bon pour l'ETG super lourd", type:"ERROR"};
    case 'GOOD_ETG_SPECIFIQUE_SUCCESSFUL':
      return {...state, message:"Le bon pour ETG super lourd a été créée", type:"SUCCESS"};
    case 'ERROR_GOOD_EXAM':
      return {...state, message:"Il y a eu une erreur lors de la création du bon pour l'examen", type:"ERROR"};
    case 'GOOD_EXAM_SUCCESSFUL':
      return {...state, message:"Le bon pour examen a été créée", type:"SUCCESS"};
    case 'ERROR_READY_EXAM':
      return {...state, message:"Il y a eu une erreur lors de l'enregistrement des vérifications administratives", type:"ERROR"};
    case 'READY_EXAM_SUCCESSFUL':
      return {...state, message:"L'élève est prêt pour l'examen", type:"SUCCESS"};
    case 'ERROR_CREATE_PAIEMENTS':
      return {...state, message:"Il y a eu une erreur lors de l'enregistrement des paiements", type:"ERROR"};
    case 'CREATE_PAIEMENTS_SUCCESSFUL':
      return {...state, message:"Les paiements ont été enregistrés", type:"SUCCESS"};
    case 'ERROR_CREATE_RELANCE':
      return {...state, message:"Il y a eu une erreur lors de l'envoi de la relance", type:"ERROR"};
    case 'CREATE_RELANCE_SUCCESSFUL':
      return {...state, message:"La relance a bien été envoyé", type:"SUCCESS"};
    case 'ERROR_DELETE_ARTICLE':
      return {...state, message:"Il y a eu une erreur lors de la suppression de l'offre", type:"ERROR"};
    case 'DELETE_ARTICLE_SUCCESSFUL':
      return {...state, message:"L'offre a bien été supprimée", type:"SUCCESS"};
    case 'ERROR_UPDATE_VEHICULE':
      return {...state, message:"Il y a eu une erreur lors de l'enregistrement du véhicule", type:"ERROR"};
    case 'UPDATE_VEHICULE_SUCCESSFUL':
      return {...state, message:"Le véhicule a bien été modifié", type:"SUCCESS"};
    case 'ERROR_CREATE_VEHICULE':
      return {...state, message:"Il y a eu une erreur lors de la création du véhicule", type:"ERROR"};
    case 'CREATE_VEHICULE_SUCCESSFUL':
      return {...state, message:"Le véhicule a bien été créé", type:"SUCCESS"};
    case 'ERROR_CREATE_BORDEREAU':
      return {...state, message:"Il y a eu une erreur lors de la création du bordereau", type:"ERROR"};
    case 'CREATE_BORDEREAU_SUCCESSFUL':
      return {...state, message:"Le bordereau a bien été créé", type:"SUCCESS"};
    case 'ERROR_DELETE_BORDEREAU':
      return {...state, message:"Il y a eu une erreur lors de la suppression du bordereau", type:"ERROR"};
    case 'DELETE_BORDEREAU_SUCCESSFUL':
      return {...state, message:"Le bordereau a bien été supprimé", type:"SUCCESS"};
    case 'DELETE_PAIEMENT_SUCCESSFUL':
      return {...state, message:"Le paiement a bien été supprimé", type:"SUCCESS"}
    case 'ERROR_DELETE_PAIEMENT':
      return {...state, message:"Il y a eu une erreur lors de la suppression du paiement", type:"ERROR"}
    case 'ERROR_ASK_RESET':
      return {...state, message:"Il y a eu une erreur lors de la demande de changement de mot de passe", type:"ERROR"}
    case 'ASK_RESET_SUCCESSFUL':
      return {...state, message:"Un email contenant les instructions pour changer de mot de passe a été envoyé", type:"SUCCESS"}
    case 'ERROR_RESET_PASSWORD':
      return {...state, message:"Il y a eu une erreur lors du changement de mot de passe", type:"ERROR"}
    case 'RESET_PASSWORD_SUCCESSFUL':
      return {...state, message:"Le mot de passe a bien été changé", type:"SUCCESS"}
    case 'CHANGE_PROFILE_SUCCESSFUL':
    case 'UPDATE_PROFILE_SUCCESSFUL':
      return {...state, message:"Le profil a bien été changé", type:"SUCCESS"}
    case 'ERROR_CHANGE_PROFILE':
    case 'ERROR_UPDATE_PROFILE':
      return {...state, message:"Il y a eu une erreur lors de la mise à jour du profil", type:"ERROR"}
    case 'ARCHIVE_SUCCESSFUL':
      return {...state, message:"L'élève a bien été archivé", type:"SUCCESS"}
    case 'ERROR_ARCHIVE':
      return {...state, message:"Il y a eu une erreur lors de l'archivement de l'élève", type:"ERROR"}
    case 'UNARCHIVE_SUCCESSFUL':
      return {...state, message:"L'élève a bien été rendu actif", type:"SUCCESS"}
    case 'ERROR_UNARCHIVE':
      return {...state, message:"Il y a eu une erreur lors de la mise à jour de l'élève", type:"ERROR"}
    case 'ARCHIVE_BORDEREAU_SUCCESSFUL':
      return {...state, message:"Le bordereau a bien été archivé", type:"SUCCESS"}
    case 'ERROR_ARCHIVE_BORDEREAU':
      return {...state, message:"Il y a eu une erreur lors de l'archivement du bordereau", type:"ERROR"}
    case 'UNARCHIVE_BORDEREAU_SUCCESSFUL':
      return {...state, message:"Le bordereau a bien été rendu actif", type:"SUCCESS"}
    case 'ERROR_UNARCHIVE_BORDEREAU':
      return {...state, message:"Il y a eu une erreur lors de la mise à jour du bordereau", type:"ERROR"}
    case 'DELETE_GOOD_SUCCESSFUL':
      return {...state, message:"Le bon a bien été supprimé", type:"SUCCESS"}
    case 'ERROR_DELETE_GOOD':
      return {...state, message:"Erreur lors de la suppression du bon", type:"ERROR"}
    case 'DELETE_ELEVE_SUCCESSFUL':
      return {...state, message:"L'élève a bien été supprimé", type:"SUCCESS"}
    case 'ERROR_DELETE_ELEVE':
      return {...state, message:"Erreur lors de la suppression de l'élève", type:"ERROR"}
    case 'DELETE_INTERVENANT_SUCCESSFUL':
      return {...state, message:"L'intervenant a bien été supprimé", type:"SUCCESS"}
    case 'ERROR_DELETE_INTERVENANT':
      return {...state, message:"Erreur lors de la suppression de l'intervenant", type:"ERROR"}
    case 'ASK_RESET_NO_USER':
      return {...state, message:"L'utilisateur demandé n'existe pas. Veuillez vérifier votre saisie", type:"ERROR"}
    case 'ERROR_DELETE_BON':
      return {...state, message:"Il y a eu une erreur lors de la suppression du bon", type:"ERROR"};
    case 'DELETE_BON_SUCCESSFUL':
      return {...state, message:"Le bon a bien été supprimé", type:"SUCCESS"};
    case 'PROBLEM_REPORT_SUCCESSFUL':
      return {...state, message:"Le problème a bien été reporté, l'équipe s'en occupe", type:"SUCCESS"};
    case 'PROBLEM_REPORT_ERROR':
      return {...state, message:"Il y a eu une erreur lors du report du problème, veuillez réessayer ou contacter directement Karl", type:"ERROR"};
    case 'PLANNING_SENT_SUCESSFULL':
      return {...state, message:"Le planning a bien été envoyé à l'élève", type:"SUCCESS"};
    case 'FACTURE_SENT_SUCESSFULL':
      return {...state, message:"La facture a bien été envoyée à l'élève", type:"SUCCESS"};
    case 'ERROR_PLANNING_SENT':
      return {...state, message:"Aucune adresse email n'est liée à cet élève, le planning n'a pas pu être envoyé", type:"ERROR"};
    case 'ERROR_FACTURE_SENT':
      return {...state, message:"Aucune adresse email n'est liée à cet élève, la facture n'a pas pu être envoyée", type:"ERROR"};
    case 'UPLOAD_PROFILE_PICTURE_SUCCESSFUL':
      return {...state, message:"La photo de profil a été enregistré", type:"SUCCESS"};
    case 'ERROR_UPLOAD_PROFILE_PICTURE':
      return {...state, message:"La photo de profil n'a pas été enregistré, veuillez réssayer plus tard", type:"ERROR"};
    case 'DELETE_PROFILE_PICTURE_SUCCESSFUL':
      return {...state, message:"La photo de profil a été supprimé", type:"SUCCESS"};
    case 'ERROR_DELETE_PROFILE_PICTURE':
      return {...state, message:"La photo de profil n'a pas été supprimé, veuillez réssayer plus tard", type:"ERROR"};
    default:
      return state;
  }
}
