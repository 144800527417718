
let appUrl = 'http://localhost:8000/';
let FC_KEY = 'GPL-My-Project-Is-Open-Source';

if (process.env.NODE_ENV === 'production') {
  appUrl = 'https://app.otoschool.fr:8088/';
  FC_KEY = '0089724584-fcs-1575310674';
}

if(process.env.REACT_APP_ENV === 'staging') {
  appUrl = 'https://test.otoschool.fr:8088/';
}

export const url = appUrl;
export const FULLCALENDAR_KEY = FC_KEY;

export const DEBUG = true;
