import React, { PureComponent } from 'react';

import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import frLocale from '@fullcalendar/core/locales/fr';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';
import {FULLCALENDAR_KEY} from '../../properties.js';

export default class FullCalendarWrapper extends PureComponent {
  render () {
    return (
    <div className="animated fadeIn planning">
      <FullCalendar
        schedulerLicenseKey={FULLCALENDAR_KEY}
        defaultView="resourceTimeGridWeek"
        height="auto"
        contentHeight="auto"
        header={{
          left: "prev,next today",
          center: "title",
          right: "resourceDayGridMonth,resourceTimeGridWeek,resourceTimeGridDay,listWeek"
        }}
        plugins={[resourceDayGridPlugin, resourceTimeGridPlugin, interactionPlugin]}
        resources={this.props.intervenants}
        filterResourcesWithEvents={true}
        datesAboveResources={true}
        ref={this.props.calendarRef}
        weekends={true}
        hiddenDays={[0]}
        events={this.props.events}
        dateClick={this.props.handleDateClick}
        eventClick={this.props.handleEventClick}
        eventDrop={this.props.handleDropAndResize}
        eventResize={this.props.handleDropAndResize}
        editable={true}
        allDaySlot={false}
        minTime={this.props.autoecoleHoraires[0].start}
        maxTime={this.props.autoecoleHoraires[1].end}
        locale={frLocale}
        firstDay={1}
        dayRender={this.props.dayRender}
        views={{
          resourceDayGridMonth: {
            filterResourcesWithEvents: false
          }
        }}
      />
    </div>
    );
  }
}
