import React, { Component } from 'react';
import { connect } from 'react-redux';

import { autoecole, planning, articles, eleves, auth } from '../../actions';
import IntervenantsPresentation from './IntervenantsPresentation.jsx';


class Intervenants extends Component {

  addIntervenant = (intervenant) => {
    this.props.addIntervenant(this.props.autoecoleId, intervenant);
  }

  editIntervenant = (intervenant, index) => {
    this.props.editIntervenant(intervenant.id, index, intervenant);
  }

  archiveIntervenant = (data) => {
    // this.props.archiveIntervenant(this.props.autoecoleId, data.id, data);
  }

  deleteIntervenant = (intervenantId, index) => {
    this.props.deleteIntervenant(intervenantId, index);
  }

  resetPassword =  (intervenantUsername) => {
    this.props.askResetPassword(intervenantUsername);
  }

  componentDidMount() {
    this.props.getRoles();
    this.props.getPermissions();
    this.props.getPrestationsStatut(this.props.autoecoleId);
    this.props.getEleves(this.props.autoecoleId);
    this.props.getArticles(this.props.autoecoleId);
  }

  render() {
    return (
      <IntervenantsPresentation
        addIntervenant={this.addIntervenant} editIntervenant={this.editIntervenant}
        archiveIntervenant={this.archiveIntervenant}
        intervenants={this.props.intervenants}
        eleves={this.props.eleves}
        roles={this.props.roles}
        permissions={this.props.permissions}
        prestationsStatut={this.props.prestationsStatut}
        prestations={this.props.prestations}
        deleteIntervenant={this.deleteIntervenant}
        resetPassword={this.resetPassword}
      />
    );
  }
}


const mapStateToProps = state => {
  return {
    autoecoleId: state.auth.user.profile.auto_ecole,
    intervenants: state.autoecole.autoecole.intervenants,
    roles: state.autoecole.roles,
    eleves: state.eleves,
    permissions: state.autoecole.permissions,
    prestationsStatut: state.prestationsStatut,
    prestations: state.prestations,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getEleves: (autoecoleId) => dispatch(eleves.get(autoecoleId)),
    getPrestationsStatut: (autoecoleId) => dispatch(planning.getPrestationsStatut(autoecoleId)),
    getArticles: (autoecoleId) => dispatch(articles.getArticles(autoecoleId)),
    addIntervenant: (autoecoleId, data) => dispatch(autoecole.createIntervenant(autoecoleId, data)),
    editIntervenant: (id, index, data) => dispatch(autoecole.updateIntervenant(id, index, data)),
    deleteIntervenant: (intervenantId, index) => dispatch(autoecole.deleteIntervenant(intervenantId, index)),
    getRoles: () => dispatch(autoecole.roles()),
    getPermissions: () => dispatch(autoecole.permissions()),
    askResetPassword: (username) => dispatch(auth.askResetPassword(username)),
    // archiveIntervenant: (id, index) => dispatch(autoecole.deleteIntervenant(id, index)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Intervenants);
