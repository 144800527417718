import React, { PureComponent } from 'react';

import moment from 'moment';

import FullCalendarWrapper from './FullCalendarWrapper';
import { findPrestationName, toTitleCase } from './utils.js';

export default class PlanningPresentation extends PureComponent {

  idealTextColor = bgColor => {
    const nThreshold = 105;
    const components = this.getRGBComponents(bgColor);
    const bgDelta = (components.R * 0.299) + (components.G * 0.587) + (components.B * 0.114);

    return ((255 - bgDelta) < nThreshold) ? "#000000" : "#ffffff";
  }

  getRGBComponents = color => {
    const r = color.substring(1, 3);
    const g = color.substring(3, 5);
    const b = color.substring(5, 7);

    return {
      R: parseInt(r, 16),
      G: parseInt(g, 16),
      B: parseInt(b, 16)
    };
  }

  findEleveInfos = (eleves, eventId, prestationId, prestationName) => {
    const nameList = eleves.map((eleveId) => {
      if (this.props.eleves.entities.eleves) {
        const eleve = Object.values(this.props.eleves.entities.eleves).find(eleve => eleve.id === eleveId);
        const eleveName = eleve ? `${eleve.first_name.split(' ')[0]} ${eleve.last_name}` : 'Élève inconnu';
        // Compute quantity used and total quantity sold
        let quantityForEleve = '';
        const totalQuantity = this.props.quantities.result.length > 0
          && eleveId in this.props.quantities.entities.quantities
          && prestationName in this.props.quantities.entities.quantities[eleveId].articles ?
          this.props.quantities.entities.quantities[eleveId].articles[prestationName] : 0
        const events = Object.values(this.props.prestationsStatut.entities.prestationsStatut)
          .sort((a, b) => a.intervenant - b.intervenant)
          .filter(event => {
            return event.eleves.includes(eleveId) && !event.absent && event.prestation === prestationId
        });
        const index = events.findIndex(event => event.id === eventId);
        quantityForEleve = `(${index + 1} / ${totalQuantity})`;

        return eleveName + " " + quantityForEleve;
      }
      return '';
    });
    return nameList.join()
  }

  findColor = (prestationStatut) => {
    let color = '#3689d8';
    const intervenant = Object.values(this.props.intervenants)
      .find(intervenant => intervenant.id === prestationStatut.intervenant);
    if (prestationStatut.absent) {
      color = 'tomato';
    } else if (intervenant) {
      color = intervenant.color;
    }
    return color;
  }

  populateEvents = (start, end, successCallback, failureCallback) => {
    const calendarEvents = [];
    if (this.props.prestationsStatut.result.length > 0) {
      Object.values(this.props.prestationsStatut.entities.prestationsStatut).filter(
        (planning) =>
          start < new Date(planning.start_date)
          && new Date(planning.start_date) < end
      ).forEach((prestationStatut) => {
        const prestationName = findPrestationName(prestationStatut.prestation, this.props.prestations.entities.prestations);
        const eleveId = prestationStatut.eleves[0];
        const eleve = Object.values(this.props.eleves.entities.eleves).find(eleve => eleve.id === eleveId);
        const eleveName = toTitleCase(prestationStatut.eleves.length > 1 ? '' :
          eleve ? `- ${eleve.first_name.split(' ')[0]} ${eleve.last_name}` : '');
        const note = prestationStatut.note ? `- ${prestationStatut.note.substring(0, 45)}` : '';
        const color = this.findColor(prestationStatut);
        const formattedPrestationStatut = {
          id: prestationStatut.id,
          start: prestationStatut.start_date,
          end: prestationStatut.end_date,
          title: `${prestationName} ${eleveName} ${note}`,
          backgroundColor: color,
          borderColor: color,
          textColor: this.idealTextColor(color),
          intervenant: prestationStatut.intervenant,
          resourceId: String(prestationStatut.intervenant)
        }
        calendarEvents.push(formattedPrestationStatut);
      });
    }
    if (calendarEvents.length === 0) {
      const admin = this.props.intervenants.filter(
        (intervenant) => intervenant.roles.map(
          (role) => role.name === "Administrateur").filter(
            (role) => role)[0])[0];
      // Fake events to add admin name
      calendarEvents.push({
        classNames: ['d-none'],
        resourceId: admin.id,
        start: start,
        end: start
      });
    }
    successCallback(calendarEvents);
  }

  getCalendarEvents = (fetchInfo, successCallback, failureCallback) => {
    const start = fetchInfo.start;
    const end = fetchInfo.end;
    const requests = [];
    if (start < this.props.oldest) {
      requests.push(
        this.props.getPlanningFromDate(
          this.props.autoecoleId,
          moment(start).format('YYYY-MM-DD')
          )
        );
    }
    if (this.props.newest < end) {
      requests.push(
          this.props.getPlanningFromDate(
            this.props.autoecoleId,
            moment(end).format('YYYY-MM-DD')
          )
        );
    }
    Promise.all(requests).then(() =>
      this.populateEvents(start, end, successCallback, failureCallback));
  }

  getFermetures = (dayRenderInfo) => {
    const date = dayRenderInfo.date;
    const cell = dayRenderInfo.el;
    const dateIsFermeture = this.props.fermetures.map(
      (fermeture) => moment(fermeture)).find(
        (fermeture) => fermeture.isSame(moment(date), 'day'))
    if (dateIsFermeture) {
      cell.className = cell.className + " fermeture";
    }
  }

  render() {
    const resources = this.props.intervenants.filter((intervenant) =>
      intervenant.roles.map((role) =>
        role.name === "Moniteur" || role.name === "Administrateur").filter((role) => role)[0]).map((intervenant) => (
        {id: String(intervenant.id), title: toTitleCase(intervenant.first_name)}))
    return (
      <FullCalendarWrapper
        events={this.getCalendarEvents}
        intervenants={resources}
        handleDateClick={this.props.handleDateClick}
        handleEventClick={this.props.handleEventClick}
        handleDropAndResize={this.props.handleDropAndResize}
        autoecoleHoraires={this.props.autoecoleHoraires}
        calendarRef={this.props.calendarRef}
        dayRender={this.getFermetures}
      />
    );
  }
}
