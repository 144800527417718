import React, { Component } from 'react';
import moment from 'moment';
import {Col, Button, Row, Label, Input} from 'reactstrap';

import styles from './Eleve.module.css';
import ArticleLine from './EleveFacture/ArticleLine.jsx';
import OtoschoolDatePicker from '../OtoschoolDatePicker/OtoschoolDatePicker.jsx';

import ModalItem from '../Modal/Modal.js';

const computePrice = (originalPrice, discount) => {
  return originalPrice * (1 - discount / 100);
}

const computeDiscount = (newPrice, originalPrice) => {
  const computedDiscount = (1 - newPrice / originalPrice) * 100;
  return computedDiscount;
}

const computeTotalHT = (price, quantity) => {
  return Math.round(price * quantity);
}

const computeTotalTVA =  (price, quantity, tva) => {
  return Math.round((price * quantity * (1 + tva)) - (price * quantity));
}

const computeTotalTTC = (price, quantity, tva) => {
  return Math.round(price * quantity * (1+tva));
}

class EleveFacture extends Component {
  addArticles = (articles, date, discount, note) => {
    const newArticles = {
      articles: articles.map((article) => ({
        article: article.article,
        price: article.priceDisplay === undefined ? 0 : article.priceDisplay.toFixed(2),
        quantity: article.quantity,
        discount: article.discount < 0 ? 0 : article.discount.toFixed(6),
        type: article.type
      })),
      facturation: this.props.permis.facture,
      date: moment(date).format('YYYY-MM-DD'),
      discount: discount ? discount : 0,
      note: note,
      type: 'create'
    };
    this.props.addArticles(newArticles);
  }

  editArticles = (articles, factures) => {
    const editArticles = {
      articles: articles.map((article) => {
        return {
          article: article.article,
          sold_article: article.sold_article,
          price: article.priceDisplay === undefined ? 0 : article.priceDisplay.toFixed(2),
          quantity: article.quantity,
          discount: article.discount < 0 ? 0 : article.discount.toFixed(6),
          type: article.type,
          deleted: article.deleted
        }
      }),
      edited_factures: factures.map((facture) => ({
        id: facture.id,
        date: moment(facture.date).format('YYYY-MM-DD'),
        note: facture.note,
        discount: facture.discount
      })),
      facturation: this.props.permis.facture,
      type: 'update'
    };
    this.props.editArticles(editArticles);
  }

  render () {
    const factures = this.props.permis.factures;
    const articles = [];
    const elevesFactures = [];
    let total_amount = 0;
    if (this.props.factures.result.length > 0) {
      factures.forEach(f => {
        if (f in this.props.factures.entities.factures) {
          elevesFactures.push(this.props.factures.entities.factures[f]);
          total_amount += this.props.factures.entities.factures[f].amount_to_pay;
        }
      });
      factures.forEach(f => {
        if (f in this.props.factures.entities.factures) {
          const f_id = this.props.factures.entities.factures[f].id;
          const f_articles = this.props.factures.entities.factures[f].articles;
          f_articles.forEach(a => {
              const article = {
                name: a.name,
                factureId: f_id,
                quantity: a.quantity,
                discount: Number(a.discount),
                price: a.price,
                montantTTC: a.montantTTC,
                priceDisplay: a.price_with_discount,
                totalTTC: Math.round(a.total),
                totalHT: a.total_ht,
                totalTVA: a.total_tva,
                totalCPS: a.total_cps,
                article: a.id,
                sold_article: a.id,
                created: a.created,
                deleted: false
              }
              articles.push(article);
          });
        }
      });
      articles.sort((a, b) => new Date(a.created) - new Date(b.created));
    }
    return (
      <EleveFacturePresentation
        autoecole={this.props.autoecole}
        eleve={this.props.eleve}
        total_amount={total_amount.toFixed(0)}
        articles={articles}
        addArticles={this.addArticles}
        editArticles={this.editArticles}
        factures={elevesFactures}
        produits={this.props.produits}
        services={this.props.services}
        prestations={this.props.prestations}
        formules={this.props.formules}
        printFacture={this.props.printFacture}
        deleteFacture={this.props.deleteFacture}
        sendFacture={this.props.sendFacture}
      />
    );
  }
}

class EleveFacturePresentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newArticles: [{}],
      editArticles: this.props.articles,
      editFactures: this.props.factures,
      editModeFactures: [],
      showModeFactures: [],
      total: 0,
      totalWithDiscount: 0,
      showModal: false,
      factureToDelete: null,
      invoiceDiscount: 0,
      invoiceNote: "",
      invoiceDate: new Date()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.total_amount !== this.props.total_amount) {
      this.setState({
        editArticles: this.props.articles,
        editFactures: this.props.factures
      });
    }
  }

  addNewRow = () => {
    this.setState(state => {
      const newArticles = state.newArticles.concat({});
      return {
        newArticles,
      };
    });
  }

  updateRow = (index, event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState(state => {
      const newArticles = state.newArticles.map((article, i) => {
        if (index === i) {
          article[name] = value;
          if (name === "discount") {
            article[name] = Number(article[name]);
            article["priceDisplay"] = computePrice(article.price, value);
            article["priceTTCComputed"] = computePrice(article.montantTTC, value);
          }
          if (name === "priceDisplay") {
            article[name] = Number(article[name]);
            article["discount"] = Number(computeDiscount(value, article.price));
            article["priceTTCComputed"] = computePrice(article.montantTTC, article["discount"]);
          }
          if ("quantity" in article && "priceDisplay" in article && "tva" in article) {
            article["totalHT"] = computeTotalHT(article.priceDisplay, article.quantity);
            if (article.tva === null) {
              article["totalTTC"] = Math.round(article.quantity * article.priceTTCComputed);
              article["totalCPS"] = computeTotalTVA(article["totalHT"], 1, Number(article.cps));
              article["totalTVA"] = article["totalTTC"] - article["totalHT"] - article["totalCPS"];
            } else {
              article["totalTVA"] = computeTotalTVA(article.priceDisplay, article.quantity, Number(article.tva));
              article["totalCPS"] = computeTotalTVA(article.priceDisplay, article.quantity, Number(article.cps));
              article["totalTTC"] = computeTotalTTC(article.priceDisplay, article.quantity, Number(article.tva) + Number(article.cps));
            }
          }
          return article;
        } else {
          return article;
        }
      });

      const total = state.newArticles.map((article) => article.totalTTC).reduce((value, sum) => value + sum);
      return {
        total: total,
        totalWithDiscount: total - state.invoiceDiscount,
        newArticles: newArticles,
      };
    });
  }

  updateEditRow = (index, event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState(state => {
      const editArticles = state.editArticles.map((article, i) => {
        if (index === i) {
          article[name] = value;
          if (name === "discount") {
            article[name] = Number(article[name]);
            article["priceDisplay"] = computePrice(article.price, value);
            article["priceTTCComputed"] = computePrice(article.montantTTC, value);
          }
          if (name === "priceDisplay") {
            article[name] = Number(article[name]);
            article["discount"] = computeDiscount(value, article.price);
          }
          if ("quantity" in article && "priceDisplay" in article && "tva" in article) {
            article["totalHT"] = computeTotalHT(article.priceDisplay, article.quantity);
            if (article.tva === null) {
              article["totalTTC"] = Math.round(article.quantity * article.priceTTCComputed);
              article["totalCPS"] = computeTotalTVA(article["totalHT"], 1, Number(article.cps));
              article["totalTVA"] = article["totalTTC"] - article["totalHT"] - article["totalCPS"];
            } else {
              article["totalTVA"] = computeTotalTVA(article.priceDisplay, article.quantity, Number(article.tva));
              article["totalCPS"] = computeTotalTVA(article.priceDisplay, article.quantity, Number(article.cps));
              article["totalTTC"] = computeTotalTTC(article.priceDisplay, article.quantity, Number(article.tva) + Number(article.cps));
            }
          }
          return article;
        } else {
          return article;
        }
      });

      return {
        editArticles,
      };
    });
  }

  addArticles = () => {
    if (Object.keys(this.state.newArticles[0]).length !== 0) {
      this.props.addArticles(this.state.newArticles, this.state.invoiceDate, this.state.invoiceDiscount, this.state.invoiceNote);
      this.setState({
        newArticles: [{}],
        total: 0,
        totalWithDiscount: 0
      });
    }
  }

  editArticles = () => {
    this.props.editArticles(this.state.editArticles, this.state.editFactures);
  }

  deleteRow = (index) => {
    this.setState(state => {
      state.newArticles.splice(index, 1);
      const total = state.newArticles.map((article) => article.totalTTC).reduce((sum, value) => sum + value);
      return {
        newArticles: state.newArticles,
        total: total,
        totalWithDiscount: total - state.invoiceDiscount
      };
    });
  }

  deleteEditRow = (index) => {
    this.setState(state => {
      const articleToDelete = state.editArticles[index];
      articleToDelete.deleted = true;
      const editedArticles = state.editArticles.map((article, i) => i === index ? articleToDelete : article);
      return {
        editArticles: editedArticles
      };
    });
  }

  printFacture = (facture) => {
    const data = {
      factureId: facture.id,
    }
    this.props.printFacture(this.props.autoecole.id, data);
  }

  sendFacture = (facture) => {
    this.props.sendFacture(this.props.autoecole.id, facture.id);
  }

  deleteFacture = id => {
    this.setState({showModal: false});
    this.setState(state => {
      const editFactures = state.editFactures.filter((facture) => facture.id !== id);
      return {
        editFactures: editFactures
      };
    });
    this.props.deleteFacture(id);
  }

  editModeFacture = id => {
    this.setState(state => {
      const editModeFactures = state.editModeFactures.find((factureId) => factureId === id) === undefined ?
          [...state.editModeFactures, id] : state.editModeFactures.filter((factureId) => factureId !== id);
      return {
        editModeFactures: editModeFactures
      };
    });
  }

  showModeFacture = id => {
    this.setState(state => {
      const showModeFactures = state.showModeFactures.find((factureId) => factureId === id) === undefined ?
          [...state.showModeFactures, id] : state.showModeFactures.filter((factureId) => factureId !== id);
      return {
        showModeFactures: showModeFactures
      };
    });
  }

  handleDeleteClic = facture => {
    this.setState({ factureToDelete: facture });
    this.setState({ showModal: true });
  }

  changeInvoiceDate = e => {
    this.setState({
      invoiceDate: e.target.value
    });
  }

  changeInvoiceDiscount = e => {
    if (e.target.validity.valid) {
      const discount = e.target.value;
      this.setState({
        invoiceDiscount: discount,
        totalWithDiscount: this.state.total - discount
      });
    }
  }

  changeInvoiceNote = e => {
    if (e.target.value) {
      this.setState({
        invoiceNote: e.target.value
      })
    }
  }

  editInvoiceDate = (index, e) => {
    const date = e.target.value;
    this.setState(state => {
      const editFactures = state.editFactures.map((facture, i) => {
        if (index === i)
          facture.date = date;
        return facture;
      });
      return {
        editFactures: editFactures
      };
    });
  }

  editInvoiceDiscount = (index, e) => {
    const discount = e.target.value;
    this.setState(state => {
      const editFactures = state.editFactures.map((facture, i) => {
        if (index === i)
          facture.discount = discount;
        return facture;
      });
      return {
        editFactures: editFactures
      };
    });
  }


  editInvoiceNote = (index, e) => {
    const note = e.target.value;
    this.setState(state => {
      const editFactures = state.editFactures.map((facture, i) => {
        if (index === i)
          facture.note = note;
        return facture;
      });
      return {
        editFactures: editFactures
      };
    });
  }

  render() {
    return (
      <div>
      <div className="subTitle">
        <Row className="text-center no-gutters">
          <Col xl={4} className="text-left">Facturer un article</Col>
          <Col>Quantité</Col>
          <Col>Prix unitaire</Col>
          <Col>Total HT</Col>
          <Col>Total TVA</Col>
          <Col>Total CPS</Col>
          <Col>Total TTC</Col>
        </Row>
      </div>
      <div className={styles.hell}>
      {this.state.newArticles.map((article, index) => (
      <ArticleLine key={index}
        index={index}
        quantity={article.quantity}
        discount={article.discount}
        priceDisplay={article.priceDisplay}
        totalHTDisplay={article.totalHT}
        totalTVADisplay={article.totalTVA}
        totalCPSDisplay={article.totalCPS}
        totalTTCDisplay={article.totalTTC}
        article={article.article}
        produits={this.props.produits}
        services={this.props.services}
        prestations={this.props.prestations}
        formules={this.props.formules}
        callback={this.addNewRow}
        type={article.type}
        update={e => this.updateRow(index, e)}
        deleteLine={e => this.deleteRow(index)}
        createMode={index === this.state.newArticles.length - 1}
        editMode={index !== this.state.newArticles.length - 1}/>))}
      <Row>
        <Col xl={2}>
          <Label className="font-weight-bold">Date de facturation</Label>
          <OtoschoolDatePicker value={this.state.invoiceDate} onChange={this.changeInvoiceDate}/>
        </Col>
        <Col xl={2}>
          <Label className="font-weight-bold">Remise</Label>
          <Input type="number" value={this.state.invoiceDiscount} onChange={this.changeInvoiceDiscount}/>
        </Col>
        <Col xl={4}>
          <Label className="font-weight-bold">Note</Label>
          <textarea className="form-control" rows="2" onChange={this.changeInvoiceNote}>{this.state.invoiceNote}</textarea>
        </Col>
      </Row>
      <Button className="colorButton" onClick={this.addArticles}>Facturer</Button>
      <div className="subTitle">Montant total TTC à payer<span className="pull-right">{this.state.totalWithDiscount.toFixed(0)} XPF</span></div>
      <hr />
      <br />
      <div className="subTitle">
        <Row className="text-center no-gutters">
          <Col xl={4} className="text-left">Facturer un article</Col>
          <Col>Quantité</Col>
          <Col>Prix unitaire</Col>
          <Col>Total HT</Col>
          <Col>Total TVA</Col>
          <Col>Total CPS</Col>
          <Col>Total TTC</Col>
        </Row>
      </div>
      {this.props.factures.sort((a, b) => b.id - a.id).map((f, index) => {
        const editedFacture = this.state.editFactures[index];
        const editMode = this.state.editModeFactures.find((factureId) => factureId === f.id) !== undefined;
        const canSee = this.state.showModeFactures.find((factureId) => factureId === f.id) !== undefined;
        return <div key={index}>
          <h3>
            Facture {f.number}
            <Button color="success" title="Imprimer la facture" className="ml-lg-2 ml-xl-3" onClick={() => this.printFacture(f)}><i className="fa fa-print"></i></Button>
            <Button color="success" title="Envoyer la facture" className="ml-lg-2 ml-xl-3" onClick={() => this.sendFacture(f)}><i className="fa fa-paper-plane"></i></Button>
            {f.last_status !== "PAYE" && f.last_status !== "EN_COURS" ?
            <Button color="success" title="Modifier la facture" className="ml-lg-2 ml-xl-3" onClick={() => this.editModeFacture(f.id)}><i className="fa fa-edit"></i></Button>
            :
            <Button color="success" title="Voir la facture" className="ml-lg-2 ml-xl-3" onClick={() => this.showModeFacture(f.id)}><i className="fa fa-eye"></i></Button>}
            <Button color="danger" title="Supprimer la facture" className="ml-lg-2 ml-xl-3" onClick={() => this.handleDeleteClic(f)}><i className="fa fa-trash-o" aria-hidden="true"></i></Button>
          </h3>
          {(editMode || canSee) && <div style={{marginBottom: '24px'}}><strong>Montant total TTC à payer {f.amount_to_pay.toFixed(0)} XPF</strong></div>}
          {(editMode || canSee) &&
            this.state.editArticles.map((article, index) => (
              !article.deleted &&
              article.factureId === f.id &&
              <ArticleLine key={index}
                quantity={article.quantity}
                discount={article.discount}
                priceDisplay={article.priceDisplay}
                totalHTDisplay={article.totalHT}
                totalTVADisplay={article.totalTVA}
                totalCPSDisplay={article.totalCPS}
                totalTTCDisplay={article.totalTTC}
                article={article.article}
                sold_article={article.sold_article}
                name={article.name}
                produits={this.props.produits}
                services={this.props.services}
                prestations={this.props.prestations}
                formules={this.props.formules}
                update={e => this.updateEditRow(index, e)}
                deleteLine={e => this.deleteEditRow(index)}
                callback={this.addNewRow}
                type={article.type}
                createMode={false}
                editMode={editMode}
              />
            ))}
            {(editMode || canSee) &&
            <Row>
              <Col xl={2}>
                <Label className="font-weight-bold">Date de facturation</Label>
                <OtoschoolDatePicker disabled={!editMode} value={editedFacture === undefined ? f.date : editedFacture.date} onChange={e => this.editInvoiceDate(index, e)}/>
              </Col>
              <Col xl={2}>
                <Label className="font-weight-bold">Remise</Label>
                <Input type="text" disabled={!editMode} value={editedFacture === undefined ? f.discount : editedFacture.discount} onChange={e => this.editInvoiceDiscount(index, e)}/>
              </Col>
              <Col xl={4}>
                <Label className="font-weight-bold">Note</Label>
                <textarea className="form-control" rows="2" disabled={!editMode} onChange={e => this.editInvoiceNote(index, e)}>
                  {editedFacture === undefined ? f.note : editedFacture.note}
                </textarea>
              </Col>
            </Row>
            }
        </div>
        })
      }
      <ModalItem modalType="large" modalState={this.state.showModal} title="Supprimer facture"
        content={<DeleteFacture
        deleteFacture={this.deleteFacture}
        hide={() => this.setState({showModal: false})}
        factureNumber={this.state.factureToDelete ? this.state.factureToDelete.number : null}
        factureId={this.state.factureToDelete ? this.state.factureToDelete.id : null}
      />} />
      <Button className="colorButton" onClick={this.editArticles}>Modifier</Button>

      <div className="subTitle">Montant total TTC à payer <span className="pull-right">{this.props.total_amount} XPF</span></div>
      </div>
      </div>
    );
  }
}

class DeleteFacture extends Component {

  deleteFacture = () => {
    this.props.deleteFacture(this.props.factureId);
  }

  render() {
    return (
      <div>
        <Label>Voulez-vous supprimer la facture {this.props.factureNumber} ?</Label><br/>
        <div>
          <Button className="colorButton pull-right" onClick={this.deleteFacture}>Supprimer</Button>
          <Button className="pull-right" style={{marginRight: '12px'}} onClick={this.props.hide}>Annuler</Button>
        </div>
      </div>
    );
  }
}

export default EleveFacture;
