const initialState = {
  activeSection: "planning",
};

export default function navigate(state=initialState, action) {
  switch (action.type) {
    case 'CHANGE_SECTION':
      return {...state, activeSection:action.section}
    default:
      return state;
  }
}
