import React, { PureComponent } from 'react';
import { Button, Label, FormGroup, Form, Col, Input } from 'reactstrap';

import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";

import Select from 'react-select';

export default class AjoutHeurePlanning extends PureComponent {
  state = {
    intervenant: this.props.event.resource ? this.props.event.resource.id : this.props.intervenants[0].id,
    prestation: Object.values(this.props.prestations)[0].id,
    eleve: null,
    eleves: [],
    start: this.props.event.date,
    end: this.props.event.date,
    note: ""
  };

  componentDidMount() {
    const endDate = new Date(this.props.event.date);
    endDate.setHours(endDate.getHours() + 1);
    this.setState({ end: endDate })
  }

  updatePrestation = event => {
    this.setState({ prestation: event.target.value });
  }

  updateStartDate = event => {
    if (event._d)
      this.setState({ start: event._d });
  }

  updateEndDate = event => {
    if (event._d)
      this.setState({ end: event._d });
  }

  updateIntervenant = event => {
    this.setState({ intervenant: event.target.value });
  }

  updateNote = event => {
    this.setState({ note: event.target.value });
  }

  add = () => {
    const newEvent = this.props.event;
    newEvent.title = this.state.title;
    newEvent.start = this.state.start;
    newEvent.end = this.state.end;
    newEvent.prestation = Number(this.state.prestation);
    newEvent.intervenant = Number(this.state.intervenant);
    newEvent.eleves = this.state.eleves;
    newEvent.note = this.state.note;
    this.props.add(newEvent);
  }

  render() {
    const { event, minTime, maxTime, intervenants, prestations, eleves } = this.props;
    const optionsEleves = Object.values(eleves).map((eleve) => ({
      label: `${eleve.first_name} ${eleve.last_name}`,
      value: eleve.id
    }));
    // Use this to prevent user to input after closing hour
    const timeConstraints = {
      hours: {
        min: Number(minTime.slice(0, 2)),
        max: Number(maxTime.slice(0, 2)),
      },
      minutes: {
        step: 15,
      }
    };
    return (
      <div>
        <Label>
          {event ? `Le ${event.date.toLocaleDateString('fr')} à ${event.date.toLocaleTimeString('fr').slice(0, -3)}` : null}
        </Label>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Prestation</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
            <Form>
              <select className="form-control" value={this.state.prestation} onChange={this.updatePrestation} name="prestation" id="prestation">
                {Object.values(prestations).map((prestation) => <option key={prestation.id} value={prestation.id}>{`${prestation.article.name}`}</option>)}
              </select>
            </Form>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Intervenant</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
            <Form>
              <select className="form-control" value={this.state.intervenant} onChange={this.updateIntervenant} name="intervenant" id="intervenant">
                {intervenants.filter((intervenant) =>
                  intervenant.roles.map((role) =>
                    role.name === "Moniteur" || role.name === "Administrateur").filter((role) => role)[0]
                  ).map(
                    (intervenant) =>
                      <option key={intervenant.id} value={intervenant.id}>
                        {`${intervenant.first_name} ${intervenant.last_name}`}
                      </option>)}
              </select>
            </Form>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Élève</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
            <Select
              isMulti
              options={optionsEleves}
              onChange={(selectedOptions) => {
                this.setState({
                  eleves: selectedOptions.map((option) => option.value)
                });
              }}
              placeholder="Élève"/>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Début</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
            <Datetime timeConstraints={timeConstraints} viewMode="time" value={this.state.start} onChange={this.updateStartDate} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Fin</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
            <Datetime timeConstraints={timeConstraints} viewMode="time" value={this.state.end} onChange={this.updateEndDate}  />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} md={3} lg={3} xl={3}>Note</Label>
          <Col sm={9} md={9} lg={9} xl={6}>
            <Input type="textarea" name="note" rows="2" value={this.state.note} onChange={this.updateNote} onBlur={this.updateNote}/>
          </Col>
        </FormGroup>
        <Button className="planning-button pull-right" onClick={this.props.hide}>Fermer</Button>
        <Button className="planning-button primaryButton pull-right" onClick={this.add}>Ajouter</Button>
      </div>
    );
  }
}
