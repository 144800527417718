import React, { Component } from 'react';
import {Container, Row, Col} from 'reactstrap';

class Autorisation extends Component {

  render() {
    return (
      <Container fluid>
        <h4 className="pageTitle">Accès non autorisé</h4>
    </Container>
    );
  }
}

export default Autorisation;
