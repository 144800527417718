import React, { Component } from 'react';
import {Col, Label, FormGroup, Row, Button} from 'reactstrap';

import OtoschoolDatePicker from '../OtoschoolDatePicker/OtoschoolDatePicker.jsx';

import styles from './Eleve.module.css';

import moment from 'moment';

class EleveExamen extends Component {
  constructor(props) {
    super(props);
    this.error = {
      icon: <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>,
      color: 'text-danger',
      value: false
    };
    this.success = {
      icon: <i className="fa fa-check" aria-hidden="true"></i>,
      color: 'text-success',
      value: true
    }
    this.state = {
      etg_completed: this.props.permis.etg_completed,
      etg_end_date: this.props.permis.etg_end_date,
      etg_specifique_completed: this.props.permis.etg_specifique_completed,
      etg_specifique_end_date: this.props.permis.etg_specifique_end_date,
      success_date: this.props.permis.success_date
    };
  }

  handleChangeETG = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    }, () => {
      this.props.updateETG(this.props.permis.id, {
        etg_completed: this.state.etg_completed !== null ? moment(this.state.etg_completed).format('YYYY-MM-DD') : null,
        etg_end_date: this.state.etg_end_date !== null ? moment(this.state.etg_end_date).format('YYYY-MM-DD') : null,
        etg_specifique_completed: this.state.etg_specifique_completed !== null ? moment(this.state.etg_specifique_completed).format('YYYY-MM-DD') : null,
        etg_specifique_end_date: this.state.etg_specifique_end_date !== null ? moment(this.state.etg_specifique_end_date).format('YYYY-MM-DD') : null
      });
    });
  }

  handleChangeExamen = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    }, () => {
      this.props.updateExamen(this.props.permis.id, {
        success_date: moment(this.state.success_date).format('YYYY-MM-DD')
      });
    });
  }

  clickETG = () => {
    this.props.goodForETG(this.props.permis.id);
  }

  clickETGSpecifique = () => {
    this.props.goodForETGSpecifique(this.props.permis.id);
  }

  clickExamen = () => {
    this.props.goodForExam(this.props.permis.id);
  }

  clickExamenHC = () => {
    this.props.goodForExamHC(this.props.permis.id);
  }

  markOk = (piece, actualValue) => {
    this.props.markOk(this.props.permis.id, {piece: piece, value: !actualValue});
  }

  render () {
    this.conditions = {
      etgReady: this.props.permis ? this.success : this.error,
      etgDate: this.props.permis.etg_completed ? this.success : this.error,
      idCard: this.props.permis.id_card_status ? this.success : this.error,
      photos: this.props.permis.photos_status ? this.success : this.error,
      stamp: this.props.permis.stamp_status ? this.success : this.error,
      medCertificate: this.props.permis.med_certificate_status ? this.success : this.error,
      bloodCard: this.props.permis.blood_card_status ? this.success : this.error,
      category: this.props.permis.category_status ? this.success : this.error,
      paid: this.props.permis.paid_status ? this.success : this.error,
      japd: this.props.permis.japd_status ? this.success : this.error,
      census: this.props.permis.census_status ? this.success : this.error
    };
    if (this.props.permis.must_check_assr)
      this.conditions['assr'] = this.props.permis.assr_status ? this.success : this.error;
    if (this.props.permis.must_check_parent) {
      this.conditions['idCardParent'] = this.props.permis.id_card_parent_status ? this.success : this.error;
      this.conditions['authorizationParent'] = this.props.permis.authorization_parent_status ? this.success : this.error;
      this.conditions['birthCertificate'] = this.props.permis.birth_certificate_status ? this.success : this.error;
    }
    if (this.props.permis.must_check_stay) {
      this.conditions['stay185'] = this.props.permis.stay_185_status ? this.success : this.error;
      this.conditions['schoolCertificate'] = this.props.permis.school_certificate_status ? this.success : this.error;
      this.conditions['exeatForm'] = this.props.permis.exeat_form_status ? this.success : this.error;
      this.conditions['cps'] = this.props.permis.cps_status ? this.success : this.error;
    }
    if (this.props.permis.must_check_truck)
      this.conditions['medTruck'] = this.props.permis.med_truck_status ? this.success : this.error;
    if (this.props.permis.must_check_judgement) {
      this.conditions['medJudgement'] = this.props.permis.med_judgement_status ? this.success : this.error;
      this.conditions['judgementDtt'] = this.props.permis.judgement_dtt_status ? this.success : this.error;
      this.conditions['medCom'] = this.props.permis.med_com_status ? this.success : this.error;
    }
    if (this.props.permis.must_check_povc)
      this.conditions['povc'] = this.props.permis.povc_status ? this.success : this.error;
    return (
      <div>
        <Row>
          <Col xl={6}>
            <Row>
              <Col>
                <div className={"otoschoolCard " + styles.innerCard}>
                  <h4 className="subTitle">Examen théorique général</h4>
                  <div>
                  <FormGroup row>
                    <Label lg={6} xl={7} for="etg_end_date">Validité du forfait code</Label>
                    <Col lg={6} xl={5}>
                      <OtoschoolDatePicker name="etg_end_date" disabled={this.props.disable} value={this.state.etg_end_date} onChange={this.handleChangeETG} />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label lg={6} xl={7} for="etg_completed">Obtention de l'ETG</Label>
                    <Col lg={6} xl={5}>
                      <OtoschoolDatePicker name="etg_completed" disabled={this.props.disable} value={this.state.etg_completed} onChange={this.handleChangeETG} />
                    </Col>
                  </FormGroup>
                  <Button className="colorButton" disabled={this.props.disable} onClick={this.clickETG}>Bon pour examen ETG</Button>
                  </div>
                </div>
              </Col>
            </Row>
            {['A', 'A1', 'C', 'D', 'EB', 'EC'].includes(this.props.permis.permis) ?
            <Row>
              <Col>
                <div className={"otoschoolCard " + styles.innerCard}>
                  <h4 className="subTitle">Examen spécifique</h4>
                  <div>
                  <FormGroup row>
                    <Label lg={6} xl={7} for="etg_specifique_end_date">Validité du forfait code spécifique</Label>
                    <Col lg={6} xl={5}>
                      <OtoschoolDatePicker name="etg_specifique_end_date" disabled={this.props.disable} value={this.state.etg_specifique_end_date} onChange={this.handleChangeETG} />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label lg={6} xl={7} for="etg_specifique_completed">Obtention de l'examen spécifique</Label>
                    <Col lg={6} xl={5}>
                      <OtoschoolDatePicker name="etg_specifique_completed" disabled={this.props.disable} value={this.state.etg_specifique_completed} onChange={this.handleChangeETG} />
                    </Col>
                  </FormGroup>
                  <Button className="colorButton" disabled={this.props.disable} onClick={this.clickETGSpecifique}>Bon pour examen spécifique</Button>
                  </div>
                </div>
              </Col>
            </Row>
            : null
            }
            {['A', 'A1'].includes(this.props.permis.permis) &&
              <Row>
                <Col>
                  <div className={"otoschoolCard " + styles.innerCard}>
                    <h4 className="subTitle">Examen pratique hors circulation</h4>
                    <div>
                    <FormGroup row>
                      <Label lg={6} xl={7}>Permis obtenu</Label>
                      <Col lg={6} xl={5}>
                        <OtoschoolDatePicker name="success_date" disabled={this.props.disable} value={this.state.success_date} onChange={this.handleChangeExamen} />
                      </Col>
                    </FormGroup>
                    <Button className="colorButton" disabled={this.props.disable} onClick={this.clickExamenHC}>Bon pour examen pratique hors circulation</Button>
                    </div>
                  </div>
                </Col>
              </Row>
            }
            <Row>
              <Col>
                <div className={"otoschoolCard " + styles.innerCard}>
                  <h4 className="subTitle">Examen pratique</h4>
                  <div>
                  <FormGroup row>
                    <Label lg={6} xl={7}>Permis obtenu</Label>
                    <Col lg={6} xl={5}>
                      <OtoschoolDatePicker name="success_date" disabled={this.props.disable} value={this.state.success_date} onChange={this.handleChangeExamen} />
                    </Col>
                  </FormGroup>
                  <Button className="colorButton" disabled={this.props.disable} onClick={this.clickExamen}>Bon pour examen pratique</Button>
                  </div>
                </div>
              </Col>
            </Row>

          </Col>
          <Col xl={6}>
            <div className={"otoschoolCard " + styles.innerCard}>
              <h4 className="subTitle">Vérifications avant examen</h4>
              <div>
                <p className={this.conditions.category.color}>Catégorie de permis renseigné {this.conditions.category.icon}</p>
                <p className={this.conditions.paid.color}>Montant réglé {this.conditions.paid.icon}</p>
                <p className={this.conditions.etgReady.color}>Élève présentable pour l'examen théorique {this.conditions.etgReady.icon}</p>
                <p className={this.conditions.etgDate.color}>Date de l'ETG valide {this.conditions.etgDate.icon}</p>
                <p className={`${this.conditions.idCard.color} condition-clickable`} onClick={() => this.markOk('id_card', this.conditions.idCard.value)}>
                  Pièce d'identité {this.conditions.idCard.icon}
                </p>
                <p className={`${this.conditions.photos.color} condition-clickable`} onClick={() => this.markOk('photos', this.conditions.photos.value)}>
                  2 photos d'identités {this.conditions.photos.icon}
                </p>
                <p className={`${this.conditions.stamp.color} condition-clickable`} onClick={() => this.markOk('stamp', this.conditions.stamp.value)}>
                  1 timbre fiscal {this.conditions.stamp.icon}
                </p>
                <p className={`${this.conditions.medCertificate.color} condition-clickable`} onClick={() => this.markOk('med_certificate', this.conditions.medCertificate.value)}>
                  Visite médicale réalisée {this.conditions.medCertificate.icon}
                </p>
                <p className={`${this.conditions.bloodCard.color} condition-clickable`} onClick={() => this.markOk('blood_card', this.conditions.bloodCard.value)}>
                  Carte de donneur de sang {this.conditions.bloodCard.icon}
                </p>
                {this.props.permis.must_check_japd &&
                <p className={`${this.conditions.japd.color} condition-clickable`} onClick={() => this.markOk('japd', this.conditions.japd.value)}>
                  A fait sa JAPD / JDC {this.conditions.japd.icon}
                </p>
                }
                {this.props.permis.must_check_census &&
                <p className={`${this.conditions.census.color} condition-clickable`} onClick={() => this.markOk('census', this.conditions.census.value)}>
                  A été recensé {this.conditions.census.icon}
                </p>
                }
                {this.props.permis.must_check_assr &&
                  <p className={`${this.conditions.assr.color} condition-clickable`} onClick={() => this.markOk('assr', this.conditions.assr.value)}>
                    Possède l'ASSR ou l'ASR {this.conditions.assr.icon}
                  </p>
                }
                {this.props.permis.must_check_parent &&
                  <p className={`${this.conditions.authorizationParent.color} condition-clickable`} onClick={() => this.markOk('authorization_parent', this.conditions.authorizationParent.value)}>
                    A l'autorisation du représentant légal {this.conditions.authorizationParent.icon}
                  </p>
                }
                {this.props.permis.must_check_parent &&
                  <p className={`${this.conditions.idCardParent.color} condition-clickable`} onClick={() => this.markOk('id_card_parent', this.conditions.idCardParent.value)}>
                    Pièce d'identité du représentant légal {this.conditions.idCardParent.icon}
                  </p>
                }
                {this.props.permis.must_check_parent &&
                  <p className={`${this.conditions.birthCertificate.color} condition-clickable`} onClick={() => this.markOk('birth_certificate', this.conditions.birthCertificate.value)}>
                    Acte de naissance de l'élève {this.conditions.birthCertificate.icon}
                  </p>
                }
                {this.props.permis.must_check_stay &&
                  <p className={`${this.conditions.stay185.color} condition-clickable`} onClick={() => this.markOk('stay_185', this.conditions.stay185.value)}>
                    Justifie d'un titre de séjour sur le territoire {this.conditions.stay185.icon}
                  </p>
                }
                {this.props.permis.must_check_stay &&
                  <p className={`${this.conditions.cps.color} condition-clickable`} onClick={() => this.markOk('cps', this.conditions.cps.value)}>
                    CPS (non-polynésien) {this.conditions.cps.icon}
                  </p>
                }
                {this.props.permis.must_check_stay &&
                  <p className={`${this.conditions.schoolCertificate.color} condition-clickable`} onClick={() => this.markOk('school_certificate', this.conditions.schoolCertificate.value)}>
                    Possède un certificat de scolarité (non-polynésien) {this.conditions.schoolCertificate.icon}
                  </p>
                }
                {this.props.permis.must_check_stay &&
                  <p className={`${this.conditions.exeatForm.color} condition-clickable`} onClick={() => this.markOk('exeat_form', this.conditions.exeatForm.value)}>
                    A rempli le formulaire EXEAT {this.conditions.exeatForm.icon}
                  </p>
                }
                {this.props.permis.must_check_truck &&
                  <p className={`${this.conditions.medTruck.color} condition-clickable`} onClick={() => this.markOk('med_truck', this.conditions.medTruck.value)}>
                    A passé la visite médicale spécifique pour les poids lourds {this.conditions.medTruck.icon}
                  </p>
                }
                {this.props.permis.must_check_judgement &&
                    <p className={`${this.conditions.judgementDtt.color} condition-clickable`} onClick={() => this.markOk('judgement_dtt', this.conditions.judgementDtt.value)}>
                      A rendu son jugement à la DTT {this.conditions.judgementDtt.icon}
                    </p>
                }
                {this.props.permis.must_check_judgement &&
                    <p className={`${this.conditions.medCom.color} condition-clickable`} onClick={() => this.markOk('med_com', this.conditions.medCom.value)}>
                      Comission médicale {this.conditions.medCom.icon}
                    </p>
                }
                {this.props.permis.must_check_judgement &&
                  <p className={`${this.conditions.medJudgement.color} condition-clickable`} onClick={() => this.markOk('med_judgement', this.conditions.medJudgement.value)}>
                    Visite médicale spécifique passée {this.conditions.medJudgement.icon}
                  </p>
                }
                {this.props.permis.must_check_povc &&
                    <p className={`${this.conditions.povc.color} condition-clickable`} onClick={() => this.markOk('povc', this.conditions.povc.value)}>
                      POVC {this.conditions.povc.icon}
                    </p>
                }
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default EleveExamen;
