import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';

import ModalItem from '../Modal/Modal.js';
import HeurePlanning from './HeurePlanning';
import AjoutHeurePlanning from './AjoutHeurePlanning';
import { planning, articles, eleves, eleve, autoecole } from '../../actions';
import PlanningPresentation from './PlanningPresentation';
import { findPrestationName } from './utils.js';

import './Planning.css';

class Planning extends Component {
  calendarRef = React.createRef();

  state = {
    calendarWeekends: true,
    showExistingModal: false,
    showCreateModal: false,
    clickedExistingEvent: null,
    clickedNewEvent: null,
    duplicateMode: false,
    duplicateModeHour: false,
    canSendModifications: false,
    isLoaded: false,
  };

  componentDidMount() {
    this.setState({isLoaded: false}, () =>
      Promise.all([
        this.props.getPlanning(this.props.autoecoleId),
        this.props.getArticles(this.props.autoecoleId),
        this.props.getEleves(this.props.autoecoleId),
        this.props.getQuantities(this.props.autoecoleId)
      ]).then(() => this.setState({isLoaded: true}))
  )
  }

  findIntervenantName = intervenantId => {
    if (this.props.intervenants) {
      const intervenant = Object.values(this.props.intervenants)
        .find(intervenant => intervenant.id === intervenantId);
      const intervenantName = intervenant ? `${intervenant.first_name} ${intervenant.last_name}` : 'Intervenant inconnu';
      return intervenantName;
    }
  }

  createMessage = (data) => {
    const startDate = new Date(data.start_date);
    const endDate = new Date(data.end_date);
    const messageContent = `Modification : ${findPrestationName(data.prestation, this.props.prestations.entities.prestations)} le ${startDate.toLocaleString().slice(0, -3)} à ${endDate.getHours() < 10 ? '0' : ''}${endDate.getHours()}:${endDate.getMinutes() < 10 ? '0' : ''}${endDate.getMinutes()} avec ${this.findIntervenantName(data.intervenant)}`;
    const message = {
      message: messageContent,
      created: data.start_date,
    }
    return message;
  }

  addPrestationStatut = (data) => {
    this.props.addPrestationStatut(this.props.autoecoleId, data).then(
      () => this.calendarRef.current.getApi().refetchEvents());
  }

  handleDateClick = arg => {
    this.setState({ clickedNewEvent: arg })
    this.setState({ showCreateModal: true });
  }

  addEvent = event => {
    this.setState({ showCreateModal: false });
    const duration = event.end && event.start ? (event.end - event.start) / (60 * 60 * 1000) : 0;
    const data = {
      prestation: event.prestation,
      intervenant: event.intervenant,
      eleves: event.eleves,
      duree: duration,
      start_date: event.start,
      end_date: event.end,
      note: event.note
    }
    this.addPrestationStatut(data)
  }

  duplicateEvent = event => {
    this.setState({ showExistingModal: false });
    const { duplicateMode, duplicateModeHour } = this.state;
    const data = {
      prestation: event.prestation,
      intervenant: event.intervenant,
      eleves: event.eleves,
      duree: event.duree,
      start_date: duplicateMode || duplicateModeHour ? event.start_date : event.start,
      end_date: duplicateMode || duplicateModeHour ? event.end_date : event.end,
      note: event.note
    }
    this.addPrestationStatut(data);
  }

  addUpdatedEventToLocalStorage = (id, data) => {
    this.setState({ canSendModifications: true });
    const updatedEvents = localStorage.getItem('updatedEvents') ? JSON.parse(localStorage.getItem('updatedEvents')) : [];
    const eventIndex = updatedEvents ? updatedEvents.findIndex(event => event.id === id) : null;
    eventIndex !== -1 ? updatedEvents[eventIndex] = data : updatedEvents.push(data);
    localStorage.setItem('updatedEvents', JSON.stringify(updatedEvents));
  }

  updateEvent = event => {
    const uneditedEvent = this.getPrestationStatutFromId(event.id);
    this.setState({ showExistingModal: false });
    const duration = event.end && event.start ? (event.end - event.start) / (60 * 60 * 1000) : 0;
    const data = {
      id: event.id ? event.id : uneditedEvent.id,
      prestation: event.prestation ? event.prestation : uneditedEvent.prestation,
      intervenant: event.intervenant ? event.intervenant : uneditedEvent.intervenant,
      eleves: event.eleves ? event.eleves : uneditedEvent.eleves,
      duree: duration,
      start_date: event.start ? event.start : uneditedEvent.start,
      end_date: event.end ? event.end : uneditedEvent.end,
      absent: event.absent !== undefined && event.absent !== null ? event.absent : uneditedEvent.absent,
      note: event.note ? event.note : uneditedEvent.note
    }
    this.props.editPrestationStatut(this.props.autoecoleId, event.id, data).then(
      () => this.calendarRef.current.getApi().refetchEvents());
    this.addUpdatedEventToLocalStorage(event.id, data);
  }

  deleteEvent = id => {
    this.setState({ showExistingModal: false });
    this.props.deletePrestationStatut(this.props.autoecoleId, id).then(
      () => this.calendarRef.current.getApi().refetchEvents());
  }

  handleEventClick = arg => {
    if (arg.event.source.id !== 'holidays') {
      this.setState({ clickedExistingEvent: arg.event, showExistingModal: true });
    }
  }

  handleSelectDuplicateEventClick = arg => {
    const { clickedExistingEvent } = this.state;
    if (arg.event.source.id !== 'holidays') {
      if (clickedExistingEvent && clickedExistingEvent.id === arg.event.id) {
        this.setState({ clickedExistingEvent: null })
      } else if (clickedExistingEvent !== null) {
        this.handleDuplicateEventClick(arg);
      } else {
        this.setState({ clickedExistingEvent: arg.event })
      }
    }
  }

  handleSelectDuplicateEventHourClick = arg => {
    const { clickedExistingEventHour } = this.state;
    if (arg.event.source.id !== 'holidays') {
      if (clickedExistingEventHour && clickedExistingEventHour.id === arg.event.id) {
        this.setState({ clickedExistingEventHour: null })
      } else if (clickedExistingEventHour !== null) {
        this.handleDuplicateEventHourClick(arg);
      } else {
        this.setState({ clickedExistingEventHour: arg.event })
      }
    }
  }

  handleDuplicateEventClick = arg => {
    if (this.state.clickedExistingEvent) {
      const newEvent = Object.assign({}, this.getPrestationStatutFromId(this.state.clickedExistingEvent.id));
      let startDate = arg.date ? arg.date : arg.event.start;
      if (arg.allDay) {
        const startHours = new Date(newEvent.start_date).getHours();
        const startMinutes = new Date(newEvent.start_date).getMinutes();
        startDate.setHours(startHours);
        startDate.setMinutes(startMinutes);
      }
      newEvent.start_date = startDate;
      newEvent.end_date = new Date(startDate.getTime() + newEvent.duree * 60 * 60000);
      this.duplicateEvent(newEvent);
    }
  }

  handleDuplicateEventHourClick = arg => {
    if (this.state.clickedExistingEventHour) {
      const newEvent = Object.assign({}, this.getPrestationStatutFromId(this.state.clickedExistingEventHour.id));
      const newStartDate = new Date(arg.date)
      newStartDate.setHours(new Date(newEvent.start_date).getHours())
      newStartDate.setMinutes(new Date(newEvent.start_date).getMinutes())
      const newEndDate = new Date(arg.date)
      newEndDate.setHours(new Date(newEvent.end_date).getHours())
      newEndDate.setMinutes(new Date(newEvent.end_date).getMinutes())
      newEvent.start_date = newStartDate;
      newEvent.end_date = newEndDate;
      this.duplicateEvent(newEvent);
    }
  }

  handleDropAndResize = arg => {
    this.updateEvent(arg.event);
  }

  getPrestationStatutFromId = id => {
    return Object.values(this.props.prestationsStatut.entities.prestationsStatut).find(prestation => prestation.id === Number(id));
  }

  activateDuplicateMode = () => {
    this.setState({ clickedExistingEvent: null });
    this.setState((state) => {
      return { duplicateMode: !state.duplicateMode }
    });
  }

  activateDuplicateModeHour = () => {
    this.setState({ clickedExistingEventHour: null });
    this.setState((state) => {
      return { duplicateModeHour: !state.duplicateModeHour }
    });
  }

  sendModifications = () => {
    if (localStorage.getItem('updatedEvents')) {
      const updatedEvents = JSON.parse(localStorage.getItem('updatedEvents'));
      updatedEvents.forEach((event, index) => {
        const message = this.createMessage(event);
        event.eleves.forEach((eleve, i) => {
          if (index === updatedEvents.length - 1) {
            this.props.sendMessage(this.props.autoecoleId, eleve, message, true, true);
          } else {
            this.props.sendMessage(this.props.autoecoleId, eleve, message, true, false);
          }
        });
      });
      localStorage.removeItem('updatedEvents');
      this.setState({ canSendModifications: false });
    }
  }

  render() {
    const { canSendModifications, duplicateMode, duplicateModeHour } = this.state;
    return (
      <Container fluid>
        <h4 className="pageTitle">Planning</h4>
        <Button onClick={this.sendModifications} className="pull-right send-button" disabled={!canSendModifications}>Envoyer modifications</Button>
        <Button onClick={this.activateDuplicateMode} className="pull-right duplicate-button">
          {duplicateMode ? 'Désactiver' : 'Activer'} mode dupliquer
        </Button>
        <Button onClick={this.activateDuplicateModeHour} className="pull-right duplicate-button">
          {duplicateModeHour ? 'Désactiver' : 'Activer'} mode dupliquer (heure comprise)
        </Button>
        <ReactToPrint
          trigger={() =>  <Button className="pull-right send-button">Imprimer</Button>}
          content={() => this.planningRef}
          />
        {this.state.isLoaded &&
          <PlanningPresentation
            ref={el => (this.planningRef = el)}
            autoecoleId={this.props.autoecoleId}
            eleves={this.props.eleves}
            getPlanningFromDate={this.props.getPlanningFromDate}
            oldest={this.props.prestationsStatut.oldest}
            newest={this.props.prestationsStatut.newest}
            quantities={this.props.quantities}
            intervenants={this.props.intervenants}
            prestations={this.props.prestations}
            prestationsStatut={this.props.prestationsStatut}
            handleDateClick={this.state.duplicateModeHour ? this.handleDuplicateEventHourClick : this.state.duplicateMode ? this.handleDuplicateEventClick : this.handleDateClick}
            handleEventClick={this.state.duplicateModeHour ? this.handleSelectDuplicateEventHourClick : this.state.duplicateMode ? this.handleSelectDuplicateEventClick : this.handleEventClick}
            handleDropAndResize={this.handleDropAndResize}
            autoecoleHoraires={this.props.autoecoleHoraires}
            fermetures={this.props.fermetures.map((fermeture) => fermeture.jour_fermeture)}
            calendarRef={this.calendarRef}
          />
        }
        <ModalItem modalType="large" modalState={this.state.showCreateModal} title="Ajouter une heure"
          content={<AjoutHeurePlanning
            event={this.state.clickedNewEvent}
            maxTime={this.props.autoecoleHoraires[1].end}
            minTime={this.props.autoecoleHoraires[0].start}
            intervenants={this.props.intervenants}
            prestations={this.props.prestations.entities.prestations}
            eleves={this.props.eleves.entities.eleves}
            hide={() => this.setState({ showCreateModal: false })}
            add={this.addEvent}
            />} />
        <ModalItem modalType="large" modalState={this.state.showExistingModal} title="Heure"
          content={<HeurePlanning
            event={this.state.clickedExistingEvent}
            maxTime={this.props.autoecoleHoraires[1].end}
            minTime={this.props.autoecoleHoraires[0].start}
            prestationStatut={this.getPrestationStatutFromId}
            intervenants={this.props.intervenants}
            prestations={this.props.prestations.entities.prestations}
            eleves={this.props.eleves.entities.eleves}
            hide={() => this.setState({ showExistingModal: false })}
            delete={this.deleteEvent}
            update={this.updateEvent}
            duplicate={this.duplicateEvent}
            userRoles={this.props.user.profile.roles.map(p => p.name)}
          />} />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    autoecoleId: state.auth.user.profile.auto_ecole,
    autoecoleHoraires: state.autoecole.autoecole.horaires,
    fermetures: state.autoecole.autoecole.fermetures,
    intervenants: state.autoecole.autoecole.intervenants,
    prestations: state.prestations,
    eleves: state.eleves,
    prestationsStatut: state.prestationsStatut,
    quantities: state.quantities,
    user: state.auth.user,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getAutoEcole: (autoecoleId) => dispatch(autoecole.get(autoecoleId)),
    addPrestationStatut: (autoecoleId, data) => dispatch(planning.addPrestationStatut(autoecoleId, data)),
    editPrestationStatut: (autoecoleId, prestationStatutId, data) => dispatch(planning.editPrestationStatut(autoecoleId, prestationStatutId, data)),
    getPlanning: (autoecoleId) => dispatch(planning.getPrestationsStatut(autoecoleId)),
    getPlanningFromDate: (autoecoleId, date) => dispatch(planning.getPlanningFromDate(autoecoleId, date)),
    getQuantities: (autoecoleId) => dispatch(planning.getQuantities(autoecoleId)),
    deletePrestationStatut: (autoecoleId, data) => dispatch(planning.deletePrestationStatut(autoecoleId, data)),
    getArticles: (autoecoleId) => dispatch(articles.getArticles(autoecoleId)),
    getEleves: (autoecoleId) => dispatch(eleves.get(autoecoleId)),
    sendMessage: (autoecoleId, eleveId, message, modification, last) => dispatch(eleve.sendMessage(autoecoleId, eleveId, message, modification, last)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Planning);
