import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';

import ModalItem from '../Modal/Modal.js';
import Service from '../Article/Service.jsx';
import ServicesTable from './Table/ServicesTable';

class Services extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      add: false,
      edit: false,
      toEdit: undefined
    };
  }

  toggleAdd = e => {
    this.setState({
      add: !this.state.add
    })
  }

  toggleEdit = (toEdit) => {
    this.setState({
      edit: !this.state.edit,
      toEdit: toEdit
    })
  }

  added = (serviceData) => {
    this.toggleAdd();
    this.props.addService(serviceData);
  }

  edited = (serviceData) => {
    this.toggleEdit(undefined);
    this.props.editService(serviceData);
  }

  render() {
    let tvaService = 0;
    this.props.tva.forEach(tva => {
      if (tva.tva_type === 'S')
        tvaService = tva.id;
    })
    const defaultService = {
      tva: tvaService,
      name: "",
      price: 0
    };
    return (
      <div>
        <ServicesTable
          edit={this.toggleEdit}
          delete={this.props.delete}
          services={this.props.services}
        />
        <Button className="colorButton" onClick={this.toggleAdd}>Créer</Button>
        <ModalItem modalType="large" modalState={this.state.add} toggle={this.toggleAdd}
          title="Ajouter un service" content={
            <Service
              createMode={true}
              callback={this.added}
              service={defaultService}
              tva={this.props.tva}/>} />
        {this.state.toEdit !== undefined &&
        <ModalItem modalType="large" modalState={this.state.edit} toggle={e => this.toggleEdit(defaultService)}
          title="Modifier le service" content={
            <Service
              createMode={false}
              callback={this.edited}
              service={this.state.toEdit}
              tva={this.props.tva}/>} />
          }
      </div>
    );
  }
}
export default Services;
