import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

import { autoecole } from '../../actions';

import './Settings.css';
import Etablissement from '../Etablissement/Etablissement.jsx';
import Facturation from '../Facturation/Facturation.jsx';

class Settings extends Component {

  state = {
    tva: {}
  }

  componentDidMount() {
    const autoecoleId = this.props.user.profile.auto_ecole;
    this.props.get(autoecoleId);
    const tva = {};
    this.props.autoecole.tva.map((tax) => tva[tax.tva_type] = tax);
    this.setState({
      tva: tva
    });
  }

  componentDidUpdate(prevProps) {
    if(this.props.autoecole.tva.length !== prevProps.autoecole.tva.length) {
      const tva = {};
      this.props.autoecole.tva.map((tax) => tva[tax.tva_type] = tax);
      this.setState({
        tva: tva
      });
    }
  }

  updateAutoEcole = (data) => {
    const autoecoleId = this.props.user.profile.auto_ecole;
    this.props.update(autoecoleId, data);
  }

  updateTVA = (index, tva) => {
    this.props.updateTVA(tva.id, index, tva);
  }

  updateGenInvoice = (index, genInvoice) => {
    this.props.updateGenInvoice(genInvoice.id, index, genInvoice);
  }

  render() {
    return (
      <Container fluid>
        <h4 className="pageTitle">Paramètres</h4>
        <Row>
          <Col lg={12} xl={{offset:1, size:10}}>
          <Etablissement autoecole={this.props.autoecole} callbackSubmit={this.updateAutoEcole}/>
          </Col>
        </Row>
        <Row>
          <Col lg={12} xl={{offset:1, size:10}}>
          <Facturation tva={this.state.tva} updateTVA={this.updateTVA}
            genInvoice={this.props.autoecole.gen_invoice} updateGenInvoice={this.updateGenInvoice}/>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    autoecole: state.autoecole.autoecole,
    roles: state.autoecole.roles,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    get: (autoecoleId) => dispatch(autoecole.get(autoecoleId)),
    update: (autoecoleId, values) => dispatch(autoecole.update(autoecoleId, values)),
    updateTVA: (tvaId, index, tva) => dispatch(autoecole.updateTVA(tvaId, index, tva)),
    updateGenInvoice: (genInvoiceId, index, genInvoice) => dispatch(autoecole.updateGenInvoice(genInvoiceId, index, genInvoice))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
