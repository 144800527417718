import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { eleves, eleve, bordereaux, impression, bons } from '../../actions';

import ExamensPresentation from './ExamensPresentation.jsx';

class Examens extends PureComponent {
  state = {
    loading: true
  }

  componentDidMount() {
    this.setState({loading: true}, () =>
      Promise.all([
        this.props.getEleves(this.props.autoecoleId),
        this.props.getBons(this.props.autoecoleId),
        this.props.getBordereaux(this.props.autoecoleId),
      ]).then(() => {
        this.setState({loading: false})
      }));
  }

  createBordereau = (data) => {
    this.props.createBordereau(this.props.autoecoleId, data);
  }

  deleteBordereau = (bordereauId) => {
    this.props.deleteBordereau(this.props.autoecoleId, bordereauId);
  }

  impressionEnregistrement = (blank, data) => {
    this.props.impressionEnregistrement(this.props.autoecoleId, blank, data);
  }

  impressionETG = (blank, data) => {
    this.props.impressionETG(this.props.autoecoleId, blank, data);
  }

  impressionETGSpecifique = (blank, data) => {
    this.props.impressionSpe(this.props.autoecoleId, blank, data);
  }

  impressionHC = (blank, data) => {
    this.props.impressionHc(this.props.autoecoleId, blank, data);
  }

  impressionExamen = (blank, data) => {
    this.props.impressionExamen(this.props.autoecoleId, blank, data);
  }

  impressionFicheVerte = (blank, data) => {
    this.props.impressionFicheVerte(this.props.autoecoleId, blank, data);
  }

  archiveBordereau = (bordereau) => {
    this.props.archiveBordereau(this.props.autoecoleId, bordereau.id, bordereau);
    this.setState({a: false})
  }

  deleteBon = (bonId) => {
    this.props.deleteBon(this.props.autoecoleId, bonId);
  }

  render() {
    const eleves = this.props.eleves.result.length > 0 ?
      Object.values(this.props.eleves.entities.eleves).filter((eleve) => eleve.permis.length > 0 ) : [];
    const bordereaux = this.props.bordereaux.result.length > 0 ? Object.values(this.props.bordereaux.entities.bordereaux) : [];
    const bons = this.props.bons.result.length > 0 ? Object.values(this.props.bons.entities.bons) : [];
    return (
      <div>
          <ExamensPresentation
            eleves={eleves}
            bons={bons}
            intervenants={this.props.intervenants}
            bordereaux={bordereaux}
            deleteBordereau={this.deleteBordereau}
            createBordereau={this.createBordereau}
            impressionETG={this.impressionETG}
            impressionExamen={this.impressionExamen}
            impressionFicheVerte={this.impressionFicheVerte}
            impressionETGSpecifique={this.impressionETGSpecifique}
            impressionEnregistrement={this.impressionEnregistrement}
            impressionHC={this.impressionHC}
            archiveBordereau={this.archiveBordereau}
            deleteBon={this.deleteBon}
          />
    </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    autoecoleId: state.auth.user.profile.auto_ecole,
    autoecole: state.autoecole.autoecole,
    intervenants: state.autoecole.autoecole.intervenants,
    eleves: state.eleves,
    bordereaux: state.bordereaux,
    bons: state.bons
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getEleves: (autoecoleId) => dispatch(eleves.get(autoecoleId)),
    getBons: (autoecoleId) => dispatch(bons.get(autoecoleId)),
    deleteBon: (autoecoleId, bonId) => dispatch(bons.destroy(autoecoleId, bonId)),
    getBordereaux: (autoecoleId) => dispatch(bordereaux.get(autoecoleId)),
    createBordereau: (autoecoleId, data) => dispatch(bordereaux.create(autoecoleId, data)).then(
      () => dispatch(bons.get(autoecoleId))),
    deleteBordereau: (autoecoleId, bordereauId) => dispatch(bordereaux.destroy(autoecoleId, bordereauId)).then(
      () => dispatch(bons.get(autoecoleId))
    ),
    archiveBordereau: (autoecoleId, bordereauId, data) => dispatch(bordereaux.archive(autoecoleId, bordereauId, data)).then(
      () => dispatch(bordereaux.get(autoecoleId))
    ),
    impressionETG: (autoecoleId, blank, data) => dispatch(impression.getBordereauEtg(autoecoleId, blank, data)),
    impressionEnregistrement: (autoecoleId, blank, data) => dispatch(impression.getBordereauEnregistrement(autoecoleId, blank, data)),
    impressionExamen: (autoecoleId, blank, data) => dispatch(impression.getBordereauExamen(autoecoleId, blank, data)),
    impressionSpe: (autoecoleId, blank, data) => dispatch(impression.getBordereauSpecifique(autoecoleId, blank, data)),
    impressionHc: (autoecoleId, blank, data) => dispatch(impression.getBordereauExamenHC(autoecoleId, blank, data)),
    impressionFicheVerte: (autoecoleId, blank, data) => dispatch(impression.getFicheVerte(autoecoleId, blank, data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Examens);
