import React, { PureComponent } from 'react';
import {Row, Col, Button} from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { dateFormatter } from '../../common/formatter.js';

class Bordereaux extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      seeArchive: false
    };

    this.columns = [
      {dataField: 'id', hidden: true},
      {dataField: 'date', text: 'Date', sort: true, formatter: dateFormatter, filterValue: dateFormatter},
      {dataField: 'eleves', text: 'Élèves', sort: true, formatter: this.elevesFormatter, filterValue: this.elevesSearchFilter},
      {dataField: 'print', text: 'Imprimer'},
      {dataField: 'actions', text: 'Actions'},
    ];
  }

  elevesFormatter = (cell, row) => {
    const eleves = cell.map(eleve =>
        <a key={eleve.id} className="bordereau-eleve-name" target="_blank"
          rel="noopener noreferrer" href={"#/eleve/" + eleve.id}>
          {eleve.first_name.split(' ')[0]} {eleve.last_name}
        </a>
      );
    return <div className="bordereau-eleve">{eleves}</div>;
  }

  elevesSearchFilter = (cell, row) => {
    return cell.map(eleve => eleve.first_name.split(' ')[0] + " " + eleve.last_name);
  }

  delete = (bordereauId) => {
    this.props.deleteBordereau(bordereauId);
  }

  impression = (blank, bordereauId) => {
    const data = {
      bordereauId: bordereauId
    };
    this.props.impression(blank, data);
  }

  archiveBordereau = (bordereau) => {
    const data = bordereau;
    data.actions = undefined;
    data.archived = !bordereau.archived;
    this.props.archiveBordereau(data);
  }

  seeArchive = () => {
    this.setState({seeArchive: !this.state.seeArchive});
  }

  prepareData = (bordereaux) => {
    return bordereaux
      .sort((a, b) => b.id - a.id)
      .filter((bordereau) => bordereau.archived === this.state.seeArchive)
      .map((bordereau) => {
        const bordereauEleves = this.props.eleves.filter(
          eleve => bordereau.eleves.includes(eleve.id));
        const bordereauTable = {
          id: bordereau.id,
          date: bordereau.date,
          eleves: bordereauEleves
        };
        bordereauTable.print =
        <div actions="actions">
          <Button color="success" onClick={() =>
              this.impression(false, bordereau.id)
              }>
              <i className="fa fa-print"></i>
          </Button>
          <Button color="warning ml-1" onClick={() =>
              this.impression(true, bordereau.id)
              }>
              <i className="fa fa-print"></i>
          </Button>
        </div>;

        bordereauTable.actions =
        <div actions="actions">
          <Button color="danger" title="Supprimer"
            onClick={() => {this.delete(bordereau.id)}}>
            <i className="fa fa-trash"></i>
          </Button>
          <Button color="warning ml-1"
            title={bordereau.archived ? "Rendre actif" : "Archiver"}
            onClick={() => {this.archiveBordereau(bordereau)}}>
            <i className="fa fa-archive"></i>
          </Button>
        </div>;

        return bordereauTable;
      });
  }

  render() {
    console.log('rerender')
    const { SearchBar } = Search;
    const table = this.prepareData(this.props.bordereaux);
    return (
      <div>
        <ToolkitProvider keyField='id' data={table} columns={this.columns} search>
          { props => (
            <div>
              <h4>Bordereaux créés</h4>
              <Row>
                <Col xs={6} sm={6} md={5} lg={4} xl={4}>
                  <SearchBar {...props.searchProps} placeholder="Recherche"/>
                </Col>
              </Row>
              <div className="otoschoolTable">
                <BootstrapTable {...props.baseProps }
                  selectRow={this.selectRow}
                  pagination={paginationFactory()}
                  striped/>
              </div>
            </div>
          )}
        </ToolkitProvider>
        <Button className="colorButton mt-1" onClick={this.seeArchive}>
          {this.state.seeArchive ?
            "Voir les bordereaux actifs" :
            "Voir les bordereaux archivés"
          }
        </Button>
      </div>
    );
  }
}

export default Bordereaux;
