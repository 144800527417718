import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';

import ModalItem from '../Modal/Modal.js';
import Produit from '../Article/Produit.jsx';
import ProduitsTable from './Table/ProduitsTable'

class Produits extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      add: false,
      edit: false,
      toEdit: undefined
    };
  }

  toggleAdd = e => {
    this.setState({
      add: !this.state.add
    })
  }

  toggleEdit = (toEdit) => {
    this.setState({
      edit: !this.state.edit,
      toEdit: toEdit
    })
  }

  added = (productData) => {
    this.toggleAdd();
    this.props.addProduct(productData);
  }

  edited = (productData) => {
    this.toggleEdit(undefined);
    this.props.editProduct(productData);
  }

  render() {
    let tvaProduit = 0;
    this.props.tva.forEach(tva => {
      if (tva.tva_type === 'P')
        tvaProduit = tva.id;
    })
    const defaultProduit = {
      tva: tvaProduit,
      name: "",
      price: 0
    };
    return (
      <div>
        <ProduitsTable
          edit={this.toggleEdit}
          delete={this.props.delete}
          products={this.props.products}
        />
        <Button className="colorButton" onClick={this.toggleAdd}>Créer</Button>
        <ModalItem modalType="large" modalState={this.state.add} toggle={this.toggleAdd}
          title="Ajouter un produit" content={
            <Produit
              createMode={true}
              callback={this.added}
              produit={defaultProduit}
              tva={this.props.tva}/>} />
        {this.state.toEdit !== undefined &&
        <ModalItem modalType="large" modalState={this.state.edit} toggle={e => this.toggleEdit(defaultProduit)}
          title="Modifier le produit" content={
            <Produit
              createMode={false}
              callback={this.edited}
              produit={this.state.toEdit}
              tva={this.props.tva}/>} />
          }
      </div>
    );
  }
}
export default Produits;
