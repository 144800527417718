import React, { Component } from 'react';
import {Row, Col, Button } from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import ModalItem from '../Modal/Modal.js';
import Organisme from '../Organisme/Organisme.jsx';
import OrganismeReport from '../Organisme/OrganismeReport.jsx';

class OrganismesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      add: false,
      edit: false,
      toEdit: undefined,
      report: false,
      toReport: undefined
    }

    this.columns = [
      {dataField: 'id', hidden: true},
      {dataField: 'name', text: 'Établissement', sort: true},
      {dataField: 'city', text: 'Ville', sort: true},
      {dataField: 'phone', text: 'Téléphone', sort: true},
      {dataField: 'actions', text: 'Actions'},
    ];
  }

  toggleAdd = e => {
    this.setState({
      add: !this.state.add
    })
  }

  toggleEdit = (toEdit) => {
    this.setState({
      edit: !this.state.edit,
      toEdit: toEdit,
    })
  }

  toggleReport = (toReport) => {
    this.setState({
      report: !this.state.report,
      toReport: toReport
    });
  }

  added = (organismeData) => {
    this.props.addOrganisme(organismeData);
    this.toggleAdd();
  }

  edited = (organismeData) => {
    this.props.editOrganisme(organismeData);
    this.toggleEdit(undefined);
  }

  prepareData = () => {
    const keys = this.props.organismes.result;
    const dataTable = [];
    keys.forEach(orgaId => {
      const orga = Object.assign({}, this.props.organismes.entities.organismes[orgaId]);
      orga.data = {
        id: orga.id,
        name: orga.name,
        address: orga.address,
        orga_type: orga.orga_type,
        postal_code: orga.postal_code,
        city: orga.city,
        contact_name: orga.contact_name,
        phone: orga.phone,
        email: orga.email,
        rib: orga.rib,
        must_pay_tva: orga.must_pay_tva
      }
      orga.actions =
      <div actions="actions">
        <Button color="success" title="Éditer l'établissement" className="ml-lg-2 ml-xl-3" onClick={() => {this.toggleEdit(orga.data)}}><i className="fa fa-edit"></i></Button>
        <Button color="success" title="Voir le relevé" className="ml-lg-2 ml-xl-3" onClick={() => {this.toggleReport(orga.data)}}><i className="fa fa-money"></i></Button>
        {/* <Button color="danger" className="ml-lg-2 ml-xl-3" onClick={() => {console.log('archiver')}}><i className="fa fa-bookmark-o"></i></Button> */}
      </div>;
      dataTable.push(orga);
    })
    return dataTable;
  }

  render() {
    const { SearchBar } = Search;
    let table = [];
    if (this.props.organismes.result.length > 0) {
      table = this.prepareData();
    }
    return (
      <div>
      <ToolkitProvider
        keyField='id' data={table}
        columns={this.columns}
        search>
        {
          props => (
            <div>
              <Row>
                <Col xs={6} sm={6} md={5} lg={4} xl={4}>
                  <SearchBar {...props.searchProps} placeholder="Recherche"/>
                </Col>
              </Row>
              <div className="otoschoolTable">
                <BootstrapTable {...props.baseProps }
                  striped/>
              </div>
            </div>
          )
        }
      </ToolkitProvider>
      <Button className="colorButton" onClick={this.toggleAdd}>Ajouter</Button>
      <ModalItem modalType="large" modalState={this.state.add} toggle={this.toggleAdd}
        title="Ajouter un établissement" content={
          <Organisme
            createMode={true}
            callback={this.added}/>} />
      {this.state.toEdit !== undefined &&
        <ModalItem modalType="large" modalState={this.state.edit} toggle={e=> {this.toggleEdit(undefined)}}
          title="Modifier l'établissement" content={
            <Organisme
              createMode={false}
              callback={this.edited}
              organisme={this.state.toEdit}/>} />
        }
        <ModalItem modalType="large" modalState={this.state.report}
          toggle={this.toggleReport} title="Relevé de l'établissement"
          content={<OrganismeReport organisme={this.state.toReport}/>} />
      </div>
    );
  }
}
export default OrganismesTable;
