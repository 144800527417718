import React, { Component } from 'react';
import { connect } from 'react-redux';

import { organisme } from '../../actions';
import OrganismesPresentation from './OrganismesPresentation.jsx';


class Organismes extends Component {

  addOrganisme = (data) => {
    this.props.addOrganisme(this.props.autoecoleId, data);
  }

  editOrganisme = (data) => {
    this.props.editOrganisme(this.props.autoecoleId, data.id, data);
  }

  archiveOrganisme = (data) => {
    // this.props.archiveOrganisme(this.props.autoecoleId, data.id);
  }

  componentDidMount() {
    this.props.getOrganismes(this.props.autoecoleId);
  }

  render() {
    return (
      <OrganismesPresentation
        addOrganisme={this.addOrganisme} editOrganisme={this.editOrganisme}
        archiveOrganisme={this.archiveOrganisme}
        organismes={this.props.organismes}
      />
    );
  }
}


const mapStateToProps = state => {
  return {
    autoecoleId: state.auth.user.profile.auto_ecole,
    organismes: state.organismes,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    addOrganisme: (autoecoleId, data) => dispatch(organisme.addOrganisme(autoecoleId, data)),
    editOrganisme: (autoecoleId, id, data) => dispatch(organisme.editOrganisme(autoecoleId, id, data)),
    getOrganismes: (autoecoleId) => dispatch(organisme.getOrganismes(autoecoleId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Organismes);
